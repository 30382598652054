import { api, getAuthHeaders, get } from "./services.common";

import { constants } from "../../config";

export const sendDataToTelegramAPI = async (payload) => {
  // const { limit, skip } = payload;
  // console.log('skip: ', skip);
  const botToken = constants.botToken;
  const chat_Id = constants.chat_Id;
  let url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chat_Id}&text=${payload}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const sendWorksopEventsDataToTelegramAPI = async (payload) => {
  // const { limit, skip } = payload;
  // console.log('skip: ', skip);
  const botToken = constants.workshopBotToken;
  const chat_Id = constants.workshopChat_Id;
  let url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chat_Id}&text=${payload}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const sendCampusDataToTelegramAPI = async (payload) => {
  // const { limit, skip } = payload;
  // console.log('skip: ', skip);
  const campusBotToken = constants.campusBotToken;
  const campusChatId = constants.campusChatId;
  let url = `https://api.telegram.org/bot${campusBotToken}/sendMessage?chat_id=${campusChatId}&text=${payload}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
