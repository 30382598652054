import React from "react";
import { NavLink,Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

export const CampusDriveHomepage = () => {
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <div className="bg-light" style={{ marginTop: "70px" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-sm-5">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="p-0 text-left text-dark pb-3 pt-4 Fweight-600">
                Campus Placement Drive
              </h4>
            </div>
            <div className="bg-white admin-card rounded-lg p-4 d-flex justify-content-center flex-wrap align-items-stretch mb-5 py-5">
              <NavLink
                className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                to="/admin/campusPlacementDrive"
              >
                <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                  Campus Placement Drive
                </h4>
              </NavLink>
              <NavLink
                className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                to="/admin/campusPlacementDrive/Questions"
              >
                <h4  className="border-bottom pb-3 pt-2 mb-3 text-center">
                  Questions
                </h4>
              </NavLink>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
