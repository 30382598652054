import React from "react";

const Loader = () => {
  return (
    <>
      <div className="fa-1x">
        <i className="fas fa-spinner fa-spin"></i>
        {/* <i className="fas fa-circle-notch fa-spin"></i>
  <i className="fas fa-sync fa-spin"></i>
  <i className="fas fa-cog fa-spin"></i>
  <i className="fas fa-spinner fa-pulse"></i>
  <i className="fas fa-stroopwafel fa-spin"></i> */}
      </div>
    </>
  );
};

export default Loader;
