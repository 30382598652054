import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { checkEmpty } from "../../../../shared/Utils/method";
import { useDispatch } from "react-redux";
import { addBatchItem } from "../../../../data/reducers/admin/batches/batches-admin.reducer";
import { toast } from "react-toastify";

//add batch modal
const AddBatchModal = ({}) => {
  const [batchDetails, setBatchDetails] = useState({
    name: "",
    startDate: "",
    book_demo_redirect_url: "",
    mode: "",
    timing: "",
    duration: "",
    track: "",
  });
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    // console.log({ ...batchDetails, [e.target.name]: e.target.value });
    setBatchDetails({ ...batchDetails, [e.target.name]: e.target.value });
  };

  const addBatch = async (payload) => {
    const res = checkEmpty(payload);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }

    const response = await dispatch(addBatchItem(payload));
    if (response.payload.isSuccessful) {
      closeModal();
      return toast.success("Batch added Successfully");
    }
    return toast.error(response.payload.message.data.message);
  };

  return (
    <>
      <button className="btn btn-primary mb-3" onClick={OpenModal}>
        Add Batch
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 10000000,
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            height:"80vh",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f5f5f5",
            width: "400px",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h3 className="text-center">Batch</h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("name") ? "is-invalid" : ""
                  }`}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={batchDetails.name}
                  placeholder={"Enter batch name.. "}
                />

                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-3"
                >
                  Batch Start Date
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("startDate") ? "is-invalid" : ""
                  }`}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.startDate}
                  name="startDate"
                  // value={batchDetails.startDate}
                  placeholder="Batch Start Date"
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Book a demo redirect URL
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("book_demo_redirect_url")
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={handleChange}
                  name="book_demo_redirect_url"
                  value={batchDetails.book_demo_redirect_url}
                  placeholder="Book a demo link"
                />
                <div className="input-group mt-2">
                  <div className="input-group-prepend">
                    <label
                      className="input-group-text"
                      for="inputGroupSelect01"
                    >
                      Choose
                    </label>
                  </div>
                  <select
                    className="custom-select"
                    name="mode"
                    className={`form-control form-control-lg ${
                      inputErr.includes("mode") ? "is-invalid" : ""
                    }`}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    id="inputGroupSelect01"
                    aria-label="Example select with button addon"
                  >
                    <option disabled selected>
                      Mode...
                    </option>
                    <option value="online">ONLINE</option>
                    <option value="offline">OFFLINE</option>
                  </select>
                </div>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-3"
                >
                  Batch Start Timings
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("timing") ? "is-invalid" : ""
                  }`}
                  name="timing"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.timing}
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Batch Duration
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("duration") ? "is-invalid" : ""
                  }`}
                  name="duration"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.duration}
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Track
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("track") ? "is-invalid" : ""
                  }`}
                  name="track"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.track}
                />
              </div>

              <div className="col-12 p-0">
                <button
                  className="btn btn-dark m-1"
                  onClick={() => {
                    addBatch(batchDetails);
                    return checkEmpty(batchDetails).length == 0
                      ? closeModal()
                      : null;
                  }}
                >
                  Add Batch
                </button>

                <button
                  className="btn btn-dark m-1"
                  onClick={() => {
                    closeModal();
                    setBatchDetails({ name: "" });
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBatchModal;
