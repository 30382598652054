import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const Skills = (props) => {
  const skillsList = props.location.state.skillsList;
  //
  const i = props.location.state.i;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  //
  //
  const id = user._id;
  const [skills, setSkills] = useState([...skillsList]);
  const [editskills, setEditSkills] = useState(skills[i]);

  //

  const submit = async () => {
    skills[i] = editskills;
    const payload = { skills, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  const handleskillsInputChange = (e) => {
    const { name, value } = e.target;
    setEditSkills({ ...editskills, [name]: value });
  };
  // handle click event of the Remove button

  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>skills Details</h3>
      </div>

      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              onChange={(e) => handleskillsInputChange(e)}
              defaultValue={skills[i].name}
              className="form-control"
            />
          </div>
        </div>
        <button type="submit" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default Skills;
