import {
  api,
  getAuthHeaders,
  get,
  post,
  patch,
  del,
} from "../../services.common";

//get all badges
export const getAllBadgesUserAPI = async () => {
  let url = `${api}/user/getAllBadges?isActive=${true}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//get all badges
export const getAllBadgesAdminAPI = async () => {
  let url = `${api}/admin/getAllBadges`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//edit badge
export const EditBadgeAdminAPI = async (payload) => {
  const { _id, name, points, icon } = payload;
  let url = `${api}/admin/editBadge?_id=${_id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { name, points, icon }, { ...authHeader });
};

//add badge
export const CreateBadgeAdminAPI = async (payload) => {
  const { name, points } = payload;
  let url = `${api}/admin/createBadge`;
  let authHeader = getAuthHeaders();
  return await post(url, { name, points }, { ...authHeader });
};

//toggle badge status
export const toggleBadgesStatusAdminAPI = async (payload) => {
  const { _id, status } = payload;
  let url = `${api}/admin/toggleBadgesStatus?_id=${_id}&status=${status}`;
  let authHeader = getAuthHeaders();
  return await patch(url, {}, { ...authHeader });
};

//delete badge
export const deleteBadgesAdminAPI = async (payload) => {
  const { _id } = payload;
  let url = `${api}/admin/deleteBadges?_id=${_id}`;
  let authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

//set badge to users
export const setBadgeAdminAPI = async (payload) => {
  const { _id, badge, userType, badgeName } = payload;
  let url = `${api}/admin/user/setBadges?_id=${_id}&userType=${userType}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { badge, badgeName }, { ...authHeader });
};

//revoke  badge from  users
export const revokeBadgeAdminAPI = async (payload) => {
  const { _id, badge, description, userType, status, badgeName } = payload;
  let url = `${api}/admin/user/revokeBadges?_id=${_id}&userType=${userType}&status=${status}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { badge, description, badgeName }, { ...authHeader });
};
