import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addUserToZohoItem } from "../../..//data/reducers/zoho.reducer";
import { toast } from "react-toastify";
import { checkNumber, checkEmpty } from "../../Utils/method";
import { sendDataToTelegramItem } from "../../../data/reducers/telegram.reducer";

export default function QuickCallbackNFloatingForm({ closeModal, pool }) {
  const poolName = pool?.name ? pool?.name : "CAME FROM PHONE CALL";
  const [inputErr, setInputErr] = useState([]);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();

  const [studentDetails, setStudentDetails] = useState({
    First_Name: "",
    Last_Name: "",
    Mobile: "",
    Batch: poolName,
    UserAction: "QUICK_CALLBACK",
  });

  // console.log("studentDetails: ", studentDetails);

  const sendDataToTelegram = async (payload) => {
    const data = `Name: ${payload?.First_Name} ${payload?.Last_Name}\n Phone: ${
      payload?.Mobile
    }, \n Batch: ${payload?.Batch}, \n UserAction: ${
      payload?.UserAction
    } \n timeStamp:${new Date(Date.now()).toLocaleString()}  `;
    const encoded = encodeURI(data);
    const response = await dispatch(sendDataToTelegramItem(encoded));
  };

  const submit = async () => {
    const res = checkEmpty(studentDetails);
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (!checkNumber(studentDetails.Mobile)) {
      return toast.error("Please check number format");
    }
    sendDataToTelegram(studentDetails);
    setLoad(true);

    const response = await dispatch(
      addUserToZohoItem({ payload: studentDetails })
    );
    // console.log('response: ', response);
    if (response.payload.data[0].status == "success") {
      setLoad(false);
      closeModal();
      toast.success("We'll call you ASAP");
    }
  };

  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };

  return (
    <>
      <center>
        <h5 className="text-muted">Quick Callback</h5>
      </center>

      <div className="row my-2">
        <div className="col">
          <div className="row my-2">
            <div className="col">
              <input
                className={`form-control ${
                  inputErr.includes("First_Name") ? "is-invalid" : ""
                }`}
                onChange={handleChange}
                name="First_Name"
                placeholder="First Name"
              />
            </div>
            <div className="col">
              <input
                className={`form-control ${
                  inputErr.includes("Last_Name") ? "is-invalid" : ""
                }`}
                onChange={handleChange}
                name="Last_Name"
                placeholder="Last Name"
              />
            </div>
          </div>
          <input
            className={`form-control ${
              inputErr.includes("Mobile") ? "is-invalid" : ""
            }`}
            onChange={handleChange}
            name="Mobile"
            placeholder="Mobile Number"
          />
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-sm-12">
          <div className=" d-flex justify-content-center">
            <button
              disabled={load}
              className="btn btn-success px-sm-4 px-2 mr-2"
              onClick={() => {
                submit();
              }}
            >
              {load ? (
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={load}
              className="btn btn-danger px-sm-4 px-2"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
