import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useHistory } from 'react-router-dom';
import DeclineRequestDescriptionModal from "./Decline-request-description.model";
import DeclineActionDescriptionModal from "./Decline-action-description.modal";
import AcceptConfirmModal from "./Accept-confirm.model";
import SendRewardConfirmModal from "./Send-reward-confirm.model";
import ProceedPaymentConfirmModal from "./Proceed-payment.model";
import PartiallyConfirmModal from "./Partially-confirm.model";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  toggleRedeemRequestItem,
  validateActionInRedeemRequestItem,
} from "../../../../data/reducers/referral.reducer";

const Model = ({ item, index }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //init state of accepted and declined ids and points array
  const [acceptedIdsState, setAcceptedIdsState] = useState([]);
  const [declinedIdsState, setDeclinedIdsState] = useState([]);
  const [acceptedPointsArray, setAcceptedPointsArray] = useState([]);
  const [declinedPointsArray, setDeclinedPointsArray] = useState([]);
  const [pointsAccepted, setPointsAccepted] = useState();

  useEffect(() => {
    //
    //
    //
    //
    acceptedPointsArray.map((state) => state.movePoints).length === 0
      ? setPointsAccepted(0)
      : setPointsAccepted(
        acceptedPointsArray
          .map((state) => state.movePoints)
          .reduce((total, point) => total + point)
      );
  }, [acceptedIdsState, declinedIdsState]);

  //accept decline req
  const toggleReq = async (_id, reqStatus, description) => {
    let payload;
    description
      ? (payload = { _id, reqStatus, description })
      : (payload = { _id, reqStatus });

    //
    try {
      const response = await dispatch(toggleRedeemRequestItem(payload));
      //
      if (response.payload.isSuccessful) {
        return toast.success(
          `Req ${reqStatus ? "Accepted" : "Declined"} successfully!`
        );
      }
      toast.error(response.payload.message.data.message);
    } catch (err) { }
  };

  //addAcceptedDeclinedArray
  const addAcceptedDeclinedArray = (field, _id, description, movePoints) => {
    if (field === "accept") {
      const obj = { _id, movePoints };
      if (declinedIdsState.map((state) => state._id).includes(_id)) {
        setDeclinedPointsArray(
          declinedPointsArray.filter((action) => action._id != _id)
        );
        setDeclinedIdsState(
          declinedIdsState.filter((action) => action._id != _id)
        );

        setAcceptedPointsArray([...acceptedPointsArray, obj]);
        return setAcceptedIdsState([...acceptedIdsState, _id]);
      }
      setAcceptedPointsArray([...acceptedPointsArray, obj]);
      setAcceptedIdsState([...acceptedIdsState, _id]);
    } else {
      const obj = { _id, movePoints };
      //
      if (acceptedIdsState.includes(_id)) {
        setAcceptedPointsArray(
          acceptedPointsArray.filter((action) => action._id != _id)
        );
        setAcceptedIdsState(acceptedIdsState.filter((id) => id != _id));
        const data = { _id, description };
        //
        setDeclinedPointsArray([...declinedPointsArray, obj]);
        return setDeclinedIdsState([...declinedIdsState, data]);
      }
      const data = { _id, description };
      //
      setDeclinedPointsArray([...declinedPointsArray, obj]);
      setDeclinedIdsState([...declinedIdsState, data]);
    }
  };

  const yes = async (_id) => {
    //
    //
    const acceptedRequestActions = acceptedIdsState;
    const declinedRequestActions = declinedIdsState;
    const payload = { _id, acceptedRequestActions, declinedRequestActions };
    try {
      const response = await dispatch(
        validateActionInRedeemRequestItem(payload)
      );
      //
      if (response.payload.isSuccessful) {
        return toast.success(`Req updated successfully!`);
      }
      toast.error(response.payload.message.data.message);
    } catch (err) { }
  };

  return (

    <>
      {/* /all req table start  */}
      <tbody className="cursor-pointer" onClick={() => OpenModal()}>
        <tr
          className="text-center"
          data-toggle="tooltip"
          data-placement="top"
          title="Tap to see actions!"
        >
          <td className="text-center"> {index}</td>
          <td className="text-center">
            {new Date(item.createdAt).toLocaleDateString()}
          </td>
          <td className="text-center">
            {new Date(item.createdAt).toLocaleTimeString()}
          </td>
          <td className="text-left pl-3">
            {item.user.firstName}
            {item.user.lastName}
          </td>
          <td className="text-center"> {item.pointsRequestedForRedemption}</td>
          <td className="text-left"> {item.status}</td>
          <td className="text-center"> {item.rewardType}</td>
          {/* {item.status == "REQUEST_PENDING" ? ( */}
          <td className="text-center">
            {item.pointsAcceptedForReward === 0
              ? "--"
              : item.pointsAcceptedForReward}
          </td>
          {/* ) : null} */}
          {item.status === "REWARD_RECIEVED" ||
            item.status === "DECLINE_REDEMPTION" ? (
            <td className="text-center">
              <span className="btn-dark btn btn-sm">
                Request Closed
              </span>
            </td>
          ) : item.status === "REWARD_PENDING" ||
            item.status === "PARTIALLY_ACCEPTED" ? (
            <td className="text-left " onClick={(e) => e.stopPropagation()}>
              <SendRewardConfirmModal item={item} />
            </td>
          ) : item.status === "SUBMITTED_FOR_REWARD" ? (
            <td className="text-center" onClick={(e) => e.stopPropagation()}>
              {/* <ProceedPaymentConfirmModal item={item} /> */}
              {
                console.log('something : ', item) 
              }
              <button className="btn btn-success btn-sm" onClick={() => {
                history.push({
                  pathname : '/admin/rewards/plan',
                  state: { request: item }
                }) ;
              }}>
                Plan for Reward
              </button>
            </td>
          ) : (
            <td
              className="d-flex justify-content-start m-0"
              onClick={(e) => e.stopPropagation()}
            >
              {/*final decline/accept button */}
              <DeclineRequestDescriptionModal
                item={item}
                toggleReq={toggleReq}
              />
              <AcceptConfirmModal item={item} toggleReq={toggleReq} />
            </td>
          )}
          <hr />
        </tr>

      </tbody>
      {/*all req table end */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
            // width:"800px"
          },
        }}
      >
        {/* <div className="container-fluid"> */}
        {/* <div className="row table-responsive"> */}
        {/* particulars actions table modal start */}
        <table
          style={{ width: "100%" }}
          className="table table-responsive table-hover"
        >
          <thead>
            <tr>
              <th align="center" scope="col">
                UserName
              </th>
              <th align="center" scope="col">
                Action
              </th>
              <th align="center" scope="col">
                Points
              </th>
              <th align="center" scope="col">
                Status
              </th>
              <th align="center" scope="col">
                Description
              </th>
              {item.status !== "REQUEST_PENDING" ? null : (
                <th align="center" scope="col">
                  Decline/Accept
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {item.actions.map((data, i) => {
              return (
                <tr key={i}>
                  <td>
                    {item.user.firstName}
                    {item.user.lastName}
                  </td>
                  <td>{data.actionName}</td>
                  <td>{data.actionPoints}</td>
                  <td>{data.actionStatus.status}</td>
                  <td className="text-center">
                    {data.actionStatus.description ? (
                      data.actionStatus.description
                    ) : (
                      <span>--</span>
                    )}
                  </td>
                  {item.status !== "REQUEST_PENDING" ? null : (
                    <td onClick={(e) => e.stopPropagation()}>
                      <DeclineActionDescriptionModal
                        declinedIdsState={declinedIdsState}
                        _id={data._id}
                        addAcceptedDeclinedArray={addAcceptedDeclinedArray}
                        actionPoints={data.actionPoints}
                      />

                      <button
                        disabled={
                          acceptedIdsState.includes(data._id) ? true : false
                        }
                        className="btn btn-dark"
                        onClick={() => {
                          addAcceptedDeclinedArray(
                            "accept",
                            data._id,
                            null,
                            data.actionPoints
                          );
                        }}
                      >
                        Accept
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* </div> */}
        <>
          {/* particulars actions table modal end */}
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="col-12 p-0 text-center">
                {item.status !== "REQUEST_PENDING" ? null : (
                  <h6>Points Accepted: {pointsAccepted}</h6>
                )}
              </div>
              <div className="col-12 d-flex p-0">
                <button
                  className="btn text-white m-1"
                  style={{ backgroundColor: "#5A6268" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
                {item.status !== "REQUEST_PENDING" ? null : (
                  <PartiallyConfirmModal
                    length={item.actions.length}
                    _id={item._id}
                    yes={yes}
                    acceptedIdsState={acceptedIdsState}
                    declinedIdsState={declinedIdsState}
                  />
                )}
              </div>
            </div>
          </div>
        </>
        {/* </div> */}
      </Modal>
    </>
    // </div>
  );
};

export default Model;
