import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import CreateActionModal from "./Modals/Create-action.modal";
import EditActionModal from "./Modals/Edit-action.modal";
import DeleteActionModal from "./Modals/Delete-action";
import {
  fetchActionsItem,
  updateActionStatusItem,
} from "../../../data/reducers/referral.reducer";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ToggleStatus from "react-toggle";
import Request from "./Request";
import RewardConfig from "./Reward-Config";
import "./config.css";

//...

export default function Config() {
  const dispatch = useDispatch();
  const [toggleView, setToggleView] = useState("requests");
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const refActions = useSelector((state) => state.referralReducer).refActions;
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await dispatch(fetchActionsItem());
    if (response.payload.isSuccessful) {
      return;
    }
    toast.error(response.payload.message.data.message);
  };
  //rewardConfig
  const handleToggleView = (view) => {
    if (view === "requests") {
      document.getElementById("config").classList.remove("active");
      document.getElementById(view).classList.add("active");
      document.getElementById("rewardConfig").classList.remove("active");
    } else if (view === "config") {
      document.getElementById(view).classList.add("active");
      document.getElementById("requests").classList.remove("active");
      document.getElementById("rewardConfig").classList.remove("active");
      // }
    } else if (view === "rewardConfig") {
      document.getElementById(view).classList.add("active");
      document.getElementById("requests").classList.remove("active");
      document.getElementById("config").classList.remove("active");
    }

    setToggleView(view);
  };
  const handleEditStatus = async (_id, status) => {
    const payload = { _id, status };
    try {
      const response = await dispatch(updateActionStatusItem(payload));
      if (response.payload.isSuccessful) {
        return toast.success("Action status updated successfully!");
      }
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  } else if (
    loggedIn === true &&
    usertype !== "admin" &&
    usertype !== "technical-operation"
  ) {
    return <Redirect to="/"></Redirect>;
  } else if (
    (loggedIn === true && usertype === "admin") ||
    usertype === "technical-operation"
  ) {
    return (
      <div className="container-fluid px-5" style={{ marginTop: "110px" }}>
        <div className="row mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                id="requests"
                className="active nav-link cursor-pointer"
                style={{ color: "black" }}
                onClick={() => {
                  handleToggleView("requests");
                }}
              >
                All Requests
              </a>
            </li>

            <li className="nav-item">
              <a
                id="config"
                className="nav-link cursor-pointer"
                style={{ color: "black" }}
                onClick={() => {
                  handleToggleView("config");
                }}
              >
                Action Config
              </a>
            </li>

            <li className="nav-item">
              <a
                id="rewardConfig"
                className="nav-link  cursor-pointer"
                style={{ color: "black" }}
                onClick={() => {
                  handleToggleView("rewardConfig");
                }}
              >
                Reward Config
              </a>
            </li>
          </ul>
        </div>

        {toggleView === "config" ? (
          <>
            <div className="col d-flex align-items-center justify-content-between my-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">
                Configuration
              </h3>
              <div>
                <CreateActionModal />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="table-responsive mt-2  ">
                  <table className="table table-hover border">
                    <thead className="bg-primary text-white">
                      <tr>
                        <th align="center" scope="col">
                          Action
                        </th>
                        <th align="center" className="text-center" scope="col">
                          Points
                        </th>
                        <th align="center" className="text-center" scope="col">
                          Status
                        </th>
                        <th align="center" className="text-center" scope="col">
                          User Type
                        </th>
                        <th align="center" className="text-center" scope="col">
                          Edit
                        </th>
                        <th align="center" className="text-center" scope="col">
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {refActions.map((item, i) => {
                        return (
                          <tr key={item?._id}>
                            <td align="left">{item.action}</td>
                            <td className="text-center">{item.points}</td>
                            <td className="text-center">
                              <ToggleStatus
                                defaultChecked={item?.status}
                                icons={false}
                                //   onChange={(e) => {
                                //     setStatusState(e.target.checked);
                                //   }}
                                onClick={(e) => {
                                  handleEditStatus(item._id, e.target.checked);
                                }}
                              />
                            </td>
                            <td className="text-center">{item.userType}</td>
                            <td className="text-center">
                              <EditActionModal item={item} index={i} />
                            </td>
                            <td className="text-center">
                              <DeleteActionModal item={item} index={i} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : toggleView === "requests" ? (
          <Request />
        ) : toggleView === "rewardConfig" ? (
          <RewardConfig />
        ) : null}
      </div>
    );
  }
}
