import React, { useEffect, useState } from "react";
import blueLabel from "../../assets/images/bluelabel.png";
import SignupPNG from "../../assets/images/signupbg.png";
import labelWhite from "../../assets/images/labelwhite.png";
import budgeone from "../../assets/images/dtprofile.png";
import { toast } from "react-toastify";
import ReactPlayer from "react-player";
import { studentItem } from "../../data/reducers/student.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Loader from "../../shared/components/Loaders/Loader";
import {
  changeOpenLoginModal,
  hrHireStudentItem,
} from "../../data/reducers/auth.reducer";
import ReactReadMoreReadLess from "react-read-more-read-less";

//..
function StudentProfile() {
  const { id } = useParams();
  const [read, setRead] = useState(false);
  const params = new URLSearchParams(window.location.search);

  localStorage.setItem("CTA", params.get("cta"));
  localStorage.setItem("referredBy", params.get("typId"));
  localStorage.setItem("redirectURL", params.get("redirectURL"));

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  // const [student,setStudent] = useState("");

  const [value, setValue] = useState(true);
  let data;
  data = JSON.parse(localStorage.getItem("particularStudent"));

  // const student = useSelector((state) => state.studentReducer).particularStudent;
  const student = data;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  // if (!loggedIn) {
  //   return <Redirect to="/"></Redirect>;
  // }
  const user = useSelector((state) => state.authReducer).userInfo;
  const usertype = useSelector((state) => state.authReducer).userType;
  const [index1, setIndex1] = useState(null);
  const userId = user._id;

  const dispatch = useDispatch();
  const [hireReq, setHireReq] = useState();
  // let id;
  //   (id = window.location.href.slice(30, window.location.href.length))
  // );
  useEffect(() => {
   
    return id ? localStorage.setItem("linkPreviewId", id) : null;
  }, []);
  const loadData = async () => {
    setLoad(true);

    try {
      let response = await dispatch(studentItem(id));
      // console.log(response);
    } catch (error) {}

    setValue(false);
    setLoading(true);
    setLoad(false);
  };

  const readmore = (i) => {
    setIndex1(i);
    setRead(!read);
  };
  useEffect(() => {
    loadData();
    reqFunc();
  }, [user]);
  // if (value) {
  //   loadData();
  // }
  const reqFunc = () => {
    let req = user?.requested?.map((item) => {
      if (item.student === id) {
        setHireReq(true);
        // setA(false);
        return true;
      } else {
        // setA(false);
        return false;
      }
    });
  };

  const hiredHr = async (id) => {
    const payload = {
      studentId: id,
      hrId: userId,
      hiring: true,
    };
    try {
      let response = await dispatch(hrHireStudentItem(payload));
      if (response.payload.isSuccessful) {
        toast.success(
          "Your request for Hiring has been successfully recorded .\n We will Get Back to you soon"
        );
      }
    } catch (error) {}
    // toast.success("Your Request for Hiring Has been Successfully recorded");
  };

  const [show, setShow] = useState(false);
  if (loggedIn === false) {
    // alert("Please login or signup to view profile")
    // toast.error("Please Login or Signup to view profile");
    dispatch(changeOpenLoginModal({ modalVal: true }));
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true) {
    return (
      <>
        {load && (
          <div className="m-5 p-5">
            <div className="m-5 p-5">
              <Loader />
            </div>
          </div>
        )}
        {loading && (
          <section
            className="  dtpage sc-pd pb-0"
            style={{ marginTop: "110px" }}
          >
            <div className="container">
              <div className="row mb-md-5 mb-4">
                <div className="col-lg-4 col-md-12">
                  <div className="card Candidateprofile fulldetails desbox L_side">
                    {/* <img
                      className="card-img-top"
                      src={student.owner.profilePic}
                      alt="Card image cap"
                    /> */}
                    <div
                      className="card_img"
                      style={{
                        background: `url(${student?.owner?.profilePic})`,
                      }}
                    ></div>
                    <div className="card-body p-md-4 p-3 m-0">
                      <div
                        className="bluelabel zoom"
                        style={{
                          background: `url(${blueLabel}) top center no-repeat`,
                        }}
                      >
                        Level {student?.level?.level || 1}
                      </div>
                      <div className="d-flex align-items-center flex-wrap mb-2">
                        {student?.badges
                          ?.filter(
                            (item) => item.revokedStatus.isActive === false
                          )
                          ?.map((item) => {
                            // console.log('item: ', item);
                            return (
                              <div className="badge-cont border rounded mr-2 mb-2 position-relative">
                                <img
                                  className="card-img-top img-fluid"
                                  src={item.badge.icon}
                                  alt="budgeone"
                                />
                              </div>
                            );
                          })}
                      </div>
                      <h6>
                        TYP-ID :{" "}
                        {userId === id ? student?.owner?.typId : "********"}
                      </h6>
                      <div className="contactinfo">
                        <h5 className="mt-1 mb-4">Contact Info</h5>
                        <ul className="p-0 m-0">
                          {student?.owner?.mobileNumber && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-phone"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Phone</h6>
                                <a className="font-weight-normal">
                                  {userId === id
                                    ? student?.owner?.mobileNumber
                                    : "**********"}
                                </a>
                              </div>
                            </li>
                          )}
                          {student?.owner?.email && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-envelope"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Email</h6>
                                <a
                                  className="font-weight-normal"
                                  // href={`mailto:${student.owner?.email}`}
                                >
                                  {userId === id
                                    ? student.owner?.email
                                    : "*****@gmail.com"}
                                </a>
                              </div>
                            </li>
                          )}
                          {student?.owner?.address && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-map-marker"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Address</h6>
                                <span className="Fweight-500">
                                  {userId === id
                                    ? student.owner?.address
                                    : " *******************"}
                                </span>
                              </div>
                            </li>
                          )}
                          {student?.owner?.dateOfBirth && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-home"></i>
                              </div>
                              <div className="ctbox">
                                <h6>DOB</h6>
                                <span>
                                  {new Date(
                                    student.owner?.dateOfBirth
                                  ).toDateString()}
                                </span>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                      {(student?.personalBlog ||
                        student?.github ||
                        student?.twitter ||
                        student?.linkedin) && (
                        <div className="contactinfo sociallinks">
                          <h5 className="mb-4">Social Links</h5>
                          <ul className="p-0 m-0">
                            {student?.personalBlog && (
                              <li>
                                <div className="iconbox">
                                  <i className="fa fa-blog"></i>
                                </div>
                                <div className="ctbox">
                                  <h6>Personal Blog</h6>
                                  <div className="">
                                    <a
                                      // href={student.personalBlog}
                                      target="_blank"
                                    >
                                      {userId === id
                                        ? student?.personalBlog
                                        : "**********"}
                                    </a>
                                  </div>
                                </div>
                              </li>
                            )}
                            {student?.github && (
                              <li>
                                <div className="iconbox">
                                  <i className="fa fa-github"></i>
                                </div>
                                <div className="ctbox">
                                  <h6>Github</h6>
                                  <div className="">
                                    <span>
                                      <a
                                        //  href={student?.github}
                                        target="_blank"
                                      >
                                        {userId === id
                                          ? student?.github
                                          : "**********"}
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </li>
                            )}
                            {student?.linkedin && (
                              <li>
                                <div className="iconbox">
                                  <i
                                    className="fa fa-linkedin "
                                    style={{ color: "#0077B5" }}
                                  ></i>
                                </div>
                                <div className="ctbox">
                                  <h6>LinkedIn</h6>
                                  <div className="">
                                    <a
                                      //  href={student?.linkedin}
                                      target="_blank"
                                    >
                                      {userId === id
                                        ? student?.linkedin
                                        : "**********"}
                                    </a>
                                  </div>
                                </div>
                              </li>
                            )}
                            {student?.twitter && (
                              <li>
                                <div className="iconbox">
                                  <i
                                    className="fa fa-twitter"
                                    style={{ color: "#1DA1F2" }}
                                  ></i>
                                </div>
                                <div className="ctbox">
                                  <h6>Twitter</h6>
                                  <div className="">
                                    <a
                                      //  href={student?.twitter}
                                      target="_blank"
                                    >
                                      {userId === id
                                        ? student?.twitter
                                        : "**********"}
                                    </a>
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                      {student?.skills?.length != 0 && (
                        <div className="candiprogress border-top mt-1 border-bottom overflow-visible py-1 border-top-0 border-bottom-0">
                          <h5 className="mb-4">Skills</h5>
                          {student?.skills.map((skill, i) => (
                            // <div className="d-flex align-items-center justify-content-between pb-0 borde">
                            // <div className="dropdown cust_notify d-flex">
                            <span
                              className="m-0 mb-1 mr-1 badge text-white"
                              style={{ backgroundColor: "#1d3883" }}
                            >
                              {skill.name}
                            </span>
                            // </div>
                            // </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="rightdetails">
                    <div className="d-flex justify-content-between align-items-md-center align-item-start mb-3 mb-md-0 w-100 flex-md-row flex-column">
                      <div>
                        <h2>
                          {student?.owner.firstName} {student?.owner.lastName}
                        </h2>
                        <p className="font-20 font-weight-normal text-black-50">
                          {student?.owner.currentPosition}
                        </p>
                      </div>
                      {/* <button className="btn text-nowrap font-size-12 btn-secondary bg-success flex-shrink-0">
                        Hire Me 
                      </button> */}
                    </div>

                    {/* <a
                      href={`mailto:${student?.owner.email}`}
                      className="btn btn-warning text-white font-weight-bold"
                    >
                      Contact Me
                    </a> */}
                    {/* <a href="#" className="btn btn-secondary">
                      Download CV
                    </a> */}
                    {usertype === "recruiter" && student?.isHirable === true ? (
                      <>
                        {hireReq ? (
                          <button
                            data-toggle="modal"
                            data-backdrop="static"
                            data-keyboard="false"
                            disabled
                            data-target="#hiremehr"
                            className="btn mx-2 btn-danger w-50"
                          >
                            <img src={labelWhite} alt="" className="pr-2" />
                            {""}
                            Requested : {student?.hirableFor}
                          </button>
                        ) : (
                          <button
                            data-toggle="modal"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-target="#hiremehr"
                            className="btn mx-2 btn-secondary w-50"
                          >
                            <img src={labelWhite} alt="" className="pr-2" />
                            {""}
                            Open For : {student?.hirableFor}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    <div
                      className="modal fade"
                      id="hiremehr"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                      onKeyPress={(e) => {
                        if (e.which == "13") {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="modal-dialog border-0 modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-0 pb-0">
                            <h5
                              className="modal-title border-0"
                              id="exampleModalLabel"
                            />
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-center">
                            <i className="fa fa-4x fa-exclamation-circle text-danger" />
                            <p className=" Fweight-600 h4">
                              Are you sure you want to Hire{" "}
                              {student?.owner?.firstName} who is open for{" "}
                              {student?.hirableFor}.
                            </p>
                          </div>
                          <div className="modal-footer border-0">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              onClick={() => hiredHr(student?._id)}
                              className="btn btn-danger"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="desbox mt-md-5 mt-4">
                      {student?.owner.description && (
                        <div className="leftstar mb-md-5 mb-4 ">
                          <div className=" d-flex align-items-center mb-3">
                            <h4 className="mb-0">Description</h4>
                          </div>
                          <div className="content p-0 m-0 border p-2">
                            <p className="mb-0 p-0">
                              {student?.owner.description}
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="leftstar mb-md-1 mb-4 ">
                        <div className="d-flex align-items-center mb-3">
                          <h4 className="mb-0">Education</h4>
                        </div>
                        <div className="content p-0 m-0 border p-2">
                          {student?.education?.length > 0 ? (
                            student.education.map((education, i) => (
                              <>
                                <p className="mb-2 p-0">
                                  <p style={{ lineHeight: "1.5" }}>
                                    {education.branch} in{" "}
                                    {education.areaOfStudy} at{" "}
                                    {education.institute} from{" "}
                                    {new Date(
                                      education.startDate
                                    ).toLocaleDateString()}{" "}
                                    to {education.endDate}{" "}
                                  </p>
                                </p>
                              </>
                            ))
                          ) : (
                            <p>Data Not Found</p>
                          )}
                        </div>
                      </div>
                      <p className="content"></p>
                      <div className="leftstar mb-md-5 mb-4 ">
                        <div className="d-flex align-items-center mb-3">
                          <h4 className="mb-0">TYP Project</h4>
                        </div>

                        <div className="content p-2 p-0 m-0 border ">
                          {student?.projects?.length > 0 ? (
                            student.projects.map((projects, i) => (
                              <>
                                <ReactPlayer
                                  width="99%"
                                  height="300px"
                                  controls
                                  // url="https://www.youtube.com/watch?v=0GQ63bAaQjM"
                                  url={projects.video}
                                />
                                <p className="content p-0">
                                  <h4 className="mt-2">
                                    Title : {projects.title}
                                  </h4>
                                  <div className=" card card-body">
                                    <ReactReadMoreReadLess
                                      charLimit={100}
                                      readMoreText={
                                        <button className="btn btn-secondary text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold">
                                          Read more
                                        </button>
                                      }
                                      readLessText={
                                        <button className="btn btn-secondary text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold">
                                          Read less
                                        </button>
                                      }
                                      readMoreClassName="read-more-less--more"
                                      readLessClassName="read-more-less--less"
                                    >
                                      {projects?.description}
                                    </ReactReadMoreReadLess>
                                  </div>
                                  <br />
                                  Technology :{" "}
                                  {projects.genre.map((genre) => (
                                    <p
                                      style={{
                                        display: "inline-block",
                                        border: "1px solid #ccc",
                                        margin: "2px",
                                        borderRadius: "5px",
                                        padding: "3px",
                                      }}
                                    >
                                      {" "}
                                      {genre}{" "}
                                    </p>
                                  ))}
                                </p>
                                <a
                                  href={`${projects.url}`}
                                  className="btn btn-primary font-size-14"
                                  target="_blank"
                                >
                                  View Project
                                </a>
                                <hr color="white" />
                              </>
                            ))
                          ) : (
                            <p>Data Not Found</p>
                          )}
                        </div>
                      </div>

                      <div className="leftstar mb-md-5 mb-4 ">
                        <div className="d-flex align-items-center mb-3">
                          <h4 className="mb-0">Work Experiences</h4>
                        </div>
                        <div className="content p-0 m-0 border p-2">
                          {student?.workExperience?.length > 0 ? (
                            student.workExperience.map((work, i) => (
                              <>
                                <p className="content p-0">
                                  {work.projectDescription} {work.workTitle}{" "}
                                  from{" "}
                                  {new Date(
                                    work.startDate
                                  ).toLocaleDateString()}{" "}
                                  to {work.endDate}
                                </p>
                              </>
                            ))
                          ) : (
                            <p>Data Not Found</p>
                          )}
                        </div>
                      </div>
                      <div className="leftstar">
                        <div className="d-flex align-items-center mb-3">
                          <h4 className="mb-0">Certificates</h4>
                        </div>

                        <div className="content p-0 m-0 border p-2">
                          {student?.certificate?.length > 0 ? (
                            student.certificate.map((certificate, i) => (
                              <>
                                <div className="d-flex flex-sm-row flex-column align-items-start mb-md-3 mb-2 justify-content-start">
                                  <div className="Certif-imgCont text-center mr-sm-3 mr-auto mb-2 mb-sm-0 flex-shrink-0">
                                    <img
                                      className="img-fluid"
                                      src={certificate?.imageUrl}
                                      alt="Certificate"
                                    />
                                  </div>
                                  <div className>
                                    <h4 className="m-0">
                                      Title : {certificate?.name}
                                    </h4>
                                    <p className="content p-0 m-0">
                                      {" "}
                                      Certificate Id :{" "}
                                      {certificate?.certificateId} <br />{" "}
                                      Description : {certificate?.description}{" "}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <p>Data Not Found</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <RelatedStudents /> */}
        {!loggedIn && (
          <section
            className="signupsection"
            style={{ background: `url(${SignupPNG}) top center no-repeat` }}
          >
            <div className="container text-center">
              <a href="#" className="btn btn-primary">
                Sign Up Now
              </a>
            </div>
          </section>
        )}
      </>
    );
  }
}
export default StudentProfile;
