import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditProject,
  professionalEditProject,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const Project = (props) => {
  const projectList = props.location.state.projectsList;
  //
  const i = props.location.state.i;
  const id = props.location.state._id;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  //   const id = user._id;

  const [project, setproject] = useState([...projectList]);
  const [proj, setproj] = useState({
    title: "",
    video: "",
    views: "",
    description: "",
    url: "",
    uploadDate: "",
    genre: "",
  });

  useEffect(() => {
    const x = new Array(project[i].genre.map((item) => item)).toString();

    setproj({
      ...proj,
      title: project[i].title,
      video: project[i].video,
      views: project[i].views,
      description: project[i].description,
      url: project[i].url,
      uploadDate: project[i].uploadDate,
      genre: x,
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const payload = { proj, id };
  const submit = async () => {
    // const y = proj.genre.split(",");
    //
    // setproj({ ...proj, ['genre']: y });

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditProject(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditProject(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  const handleprojectInputChange = (e) => {
    const { name, value } = e.target;
    setproj({ ...proj, [name]: value });
  };
  // handle click event of the Remove button

  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>project Details</h3>
      </div>

      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Title</label>
            <input
              type="text"
              name="title"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].title}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Description</label>
            <input
              type="text"
              name="description"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].description}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Views</label>
            <input
              type="text"
              name="views"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].views}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Video</label>
            <input
              type="text"
              name="video"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].video}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">url</label>
            <input
              type="text"
              name="url"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].url}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Upload Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="uploadDate"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={new Date(
                project[i].uploadDate
              ).toLocaleDateString()}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Genre</label>
            <input
              type="text"
              name="genre"
              onChange={(e) => handleprojectInputChange(e)}
              defaultValue={project[i].genre.map((item) => item)}
              className="form-control"
            />
          </div>
        </div>
        <button type="submit" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default Project;
