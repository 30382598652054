import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import RegisteredUser from "./RegisteredUser";
import $ from "jquery";
import {
  checkEmpty,
  checkNumber,
  checkEmail,
} from "../../../shared/Utils/method";

import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../../config";
import event1 from "../../../assets/images/event1.png";
import LoginModal from "../../AuthPages/Login-modal";
import { registerForEventItem } from "../../../data/reducers/events/event-reducer";
import { sendWorksopEventsDataToTelegramItem } from "../../../data/reducers/telegram.reducer";
import { addUserToZohoItem } from "../../../data/reducers/zoho.reducer";

// import "../../shared/styles/custom.css";
import { modal } from "react-bootstrap";
export const redirectUri = constants.redirectUri;
export const redirectUri1 = constants.redirectUri;
export const clientid = constants.clientid;

//*********************************************************************************************************** */
const Model = ({
  data,
  usertype,
  loggedIn,
  studentDetails,
  getEventRegisteredUsersAdminApi,
  setShowRegisteredUser,
  registeredUser,
  skip,
  showRegisteredUser,
}) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const history = useHistory();

  const userInfo = useSelector((state) => state.authReducer).userInfo.owner;
  const [studentDetails1, setStudentDetails] = useState({
    First_Name: userInfo?.firstName,
    Last_Name: userInfo?.lastName,
    Email: userInfo?.email,
    Mobile: userInfo?.mobileNumber,
  });

  const [inputErr, setInputErr] = useState([]);
  useEffect(() => {
    setStudentDetails({
      ...studentDetails1,
      First_Name: userInfo?.firstName,
      Last_Name: userInfo?.lastName,
      Email: userInfo?.email,
      Mobile: userInfo?.mobileNumber,
    });
  }, [userInfo]);
  // var checkLogin = true
  const [load, setLoad] = useState(false);
  const sendDataToTelegram = async (payload) => {
    const data = `Name: ${payload.First_Name} ${payload.Last_Name} \n
Email: ${payload.Email} \n
Phone: ${payload.Mobile}, \n
UserAction: ${payload.UserAction} \n
timeStamp:${new Date(Date.now()).toLocaleString()}`;
    const encoded = encodeURI(data);
    const response = await dispatch(
      sendWorksopEventsDataToTelegramItem(encoded)
    );
  };
  const [isActive, setActive] = useState(false);
  const handleOnEventSubmit = async (e, item) => {
    const res = checkEmpty(studentDetails);
    // console.log('res: ', res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("Please fill all fields");
    }
    if (!checkNumber(studentDetails.Mobile)) {
      return toast.error("Wrong number format .. !");
    }
    if (!checkEmail(studentDetails.Email)) {
      return toast.error("Wrong email format .. !");
    }
    // console.log('item: ', item);
    // console.log({ ...studentDetails, "userAction": `EVENT_${item.eventRecText}` });
    setLoad(true);

    // console.log('zohoResponse.data.data[0].status: ', zohoResponse);
    const typApiResponse = await dispatch(
      registerForEventItem({
        first_Name: studentDetails?.First_Name,
        last_Name: studentDetails?.Last_Name,
        email: studentDetails?.Email,
        mobile: studentDetails?.Mobile,
        eventId: item?._id,
      })
    );
    // console.log("typApiResponse: ", typApiResponse);
    //in case of api sucess
    if (typApiResponse.payload.isSuccessful) {
      sendDataToTelegram({
        ...studentDetails1,
        UserAction: `EVENT_${item.eventRecText}`,
      });
      const zohoResponse = await dispatch(
        addUserToZohoItem({
          payload: {
            ...studentDetails1,
            userId: userInfo._id,
            UserAction: `EVENT_${item.eventRecText}`,
          },
        })
      );
      console.log("zohoResponse: ", zohoResponse);
      if (
        zohoResponse?.payload?.data &&
        zohoResponse?.payload?.data[0]?.status === "success"
      ) {
        setLoad(false);
        // setStudentDetails({
        //   First_Name: "",
        //   Last_Name: "",
        //   Email: "",
        //   Mobile: "",
        // });
        closeModal();
        return toast.success("Registered suessfully");
      }
      setLoad(false);
      toast.error(
        zohoResponse?.payload?.data && zohoResponse?.payload?.data[0]?.message
      );
      return;
    }
    //in case of api err
    else if (!typApiResponse.payload.isSuccessful) {
      setLoad(false);
      toast.error(typApiResponse.payload.message.data.message);
      return;
    }
  };
  Modal.setAppElement("#root");
  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails1, [e.target.name]: e.target.value });
  };
  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <small
        class="text-muted"
        onClick={() => {
          OpenModal();
        }}
      >
        <strong>see more</strong>
      </small>
      <Modal
        isOpen={modalIsOpen}
        scrollable={true}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(225,225,225,0.5)",
            zIndex: 20000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",

            width: "max-content",
            zIndex: 9,
            right: "auto",
            bottom: "auto",
            borderRadius: "6px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",

            padding: "0",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div
          className={isActive ? "flip-card active" : "flip-card"}
          // class=""
        >
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <div
                class="card eventDetail"
                style={{
                  // marginTop:"70px",
                  // borderRadius: "5%",
                  boxShadow:
                    " inset 0rem 0.5rem .5rem rgba(0,0,0,.3),0rem .2rem .2rem rgba(0,0,0,.3)",
                }}
              >
                <img
                  class="card-img-top p-2"
                  style={{ height: "220px" }}
                  src={data?.eventPoster?.url ? data?.eventPoster?.url : event1}
                  alt="Card image cap"
                ></img>
                <div
                  class="card-body"
                  style={{ paddingTop: "0", paddingBottom: "0" }}
                >
                  <h5
                    class="card-title designEventText"
                    style={{ marginBottom: "0" }}
                  >
                    {data.eventTitle}
                  </h5>

                  <div
                    class="card-text designEventText"
                    style={{ color: "#1D3883" }}
                  >
                    <span style={{ color: "#FF8227" }}>Location</span>{" "}
                    <strong style={{ color: "#000" }}>
                      - {data.eventPlace}
                    </strong>
                  </div>
                  <div
                    class="card-text designEventText"
                    style={{ color: "#1D3883" }}
                  >
                    <span style={{ color: "#FF8227" }}>Date</span>{" "}
                    <strong style={{ color: "#000" }}>
                      - {data.eventDate}
                    </strong>
                  </div>
                  <div
                    class="card-text designEventText"
                    style={{ color: "#1D3883" }}
                  >
                    <span style={{ color: "#FF8227" }}>Mode</span>{" "}
                    <strong style={{ color: "#000" }}>
                      - {data.eventMode}{" "}
                    </strong>
                  </div>
                  {/* {usertype == "admin" && ( */}
                    <div
                      class="card-text designEventText"
                      style={{ color: "#1D3883" }}
                    >
                      <span style={{ color: "#FF8227" }}>Recognized as</span>{" "}
                      <strong style={{ color: "#000" }}>
                        - {data.eventRecText}{" "}
                      </strong>
                    </div>
                  {/* )} */}
                  {loggedIn && usertype != "admin" && (
                    <div class="mb-5 mt-3  dots">
                      <button
                        onClick={toggleClass}
                        style={{ background: "#1d3883", color: "#fff" }}
                        class="btn dots"
                      >
                        Register Now
                      </button>
                    </div>
                  )}
                  {loggedIn && usertype == "admin" && (
                    <div className="col-md-12">
                      <RegisteredUser
                        skip={skip}
                        eventId={data?._id}
                        registeredItems={registeredUser}
                        setShowRegisteredUser={setShowRegisteredUser}
                        item={data}
                        getEventRegisteredUsersAdminApi={
                          getEventRegisteredUsersAdminApi
                        }
                      />
                    </div>
                  )}

                  {!loggedIn && <LoginModal purpose="event"></LoginModal>}
                </div>
              </div>
            </div>
            <div class="flip-card-back">
              <div class="mt-3">
                <h4>
                  Hi!{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {studentDetails.First_Name}
                  </span>
                </h4>
                <h6>Indulge yourself</h6>
                <p style={{ color: "#fff" }}>Memories you will never forget.</p>
                <i style={{ fontSize: "5rem" }} class="fas fa-person-booth"></i>
                <p>One step to go</p>
                <h5>Drop Your E-Mail</h5>
                <div className="bgn-wf-row px-3 mb-3">
                  <div className="bgn-wf-field">
                    <input
                      name="Email"
                      value={studentDetails1.Email}
                      className={`form-control form-control-lg   ${
                        inputErr.includes("Email") ? "is-invalid" : ""
                      } 
                                  `}
                      type="text"
                      onChange={handleChange}
                      // valdefaultValueue=""
                      placeholder="email .."
                    />
                  </div>
                </div>
                <button
                  style={{
                    backgroundColor: "white",
                    color: "#1D3883",
                    zIndex: "1000000",
                  }}
                  // disabled={load}
                  className="btn  px-5 mb-1"
                  onClick={(e) => {
                    handleOnEventSubmit(e, data);
                  }}
                >
                  Submit
                </button>
                <div>
                  <button
                    className="btn  px-4 "
                    style={{
                      backgroundColor: "white",
                      color: "#1D3883",
                      zIndex: "1000000",
                    }}
                    onClick={toggleClass}
                    class="btn"
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Model;
