import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequestRewardUserItem } from "../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";

export default function Rewards() {
  const [rewardRecieved, setRewardRecieved] = useState([]);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const refActions = useSelector((state) => state.referralReducer)
    .userRewardReceived;
  // console.log("refActions: ", refActions);
  const userState = useSelector((state) => state.authReducer).userInfo;
  // console.log("userState: ", userState.owner._id);
  const setAction = async () => {
    // const response = await dispatch(fetchActionUserItem({ _id: userState.owner._id, specific: true, action_status: "ACCEPTED" }));
    setLoad(true);
    const response = await dispatch(
      fetchRequestRewardUserItem({
        specific: true,
        request_status: "REWARD_RECIEVED",
      })
    );
    // console.log("response in rewardReciewed : ", response.payload.data);
    if (response.payload.isSuccessful) {
      setLoad(false);
      return setRewardRecieved(response.payload.data);
    }
    toast.error(response.payload.message.data.message);
  };
  useEffect(() => {
    setAction();
  }, []);
  return (
    <div>
      {load ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : rewardRecieved.requests?.length !== 0 ? (
        <table className="table table-hover rwd-table mb-5">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Created At</th>
              <th scope="col">Points Applied</th>
              <th scope="col">Points Approved</th>
              <th scope="col">Status</th>
            </tr>
          </thead>

          <tbody>
            {rewardRecieved.requests?.map((req, indx) => {
              return (
                <tr>
                  <td data-th="#">{indx + 1}</td>
                  <td data-th="Created At">
                    {new Date(req.createdAt).toLocaleString()}
                  </td>
                  <td data-th="Points Applied">
                    {req.pointsRequestedForRedemption}
                  </td>
                  <td data-th="Points Approved">
                    {req.pointsAcceptedForReward}
                  </td>
                  <td data-th="Status">{req.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <h5 className="my-3 text-center">No data to show</h5>
      )}
    </div>
  );
}
