import React from "react";
import { NavLink } from "react-router-dom";

const BulkEmails = () => {
  return (
    <div className="bg-light" style={{ marginTop: "70px" }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-sm-5">
            <div className="bg-white admin-card rounded-lg p-4 d-flex justify-content-center flex-wrap align-items-stretch mb-5 py-5">
              <NavLink
                to={{
                  pathname: "/admin/bulk/email",
                  aboutProps: {
                    comingFrom: "CAMPUS",
                  },
                }}
                className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
              >
                <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                  Campus
                </h4>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/admin/bulk/email",
                  aboutProps: {
                    comingFrom: "EVENT",
                  },
                }}
                className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
              >
                <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                  Events
                </h4>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BulkEmails;
