import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";
import { useDispatch, useSelector } from "react-redux";
import { toggleWantACallbackPool } from "../../../../data/reducers/home.reducer";
import QuickCallbackNFloatingForm from '../../../../shared/components/Forms/QuickCallbackNFloatingForm' ;

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: 1,
    },
    content: {
        color: "#1D3883",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
        flexDirection: "column",
    },
};

const QuickCallback = ({ data, setGetDetailsAfterLogin, page ,pool}) => {

    const dispatch = useDispatch();
    const authState = useSelector(state => state.authReducer).userInfo;
    Modal.setAppElement("#root");
    //Modal functions
    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }


    const userType = useSelector((state) => state.authReducer).userType;

    function closeModal() {
        dispatch(toggleWantACallbackPool({ upcomingPoolId: null }));
        setIsOpen(false);
    }


    return (
        <div>
            <button
                className="btn btn-outline-yellow font-size-14 Fweight-600 px-4"
                onClick={() => {
                    if (userType != "admin" || userType == null || userType == "" || userType == undefined) {
                        openModal();
                    }
                }}>
                Quick Callback
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <QuickCallbackNFloatingForm pool={pool} closeModal={closeModal} openModal={openModal}/>

            </Modal>
        </div>
    );
};

export default QuickCallback;
