import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  sendDataToTelegramAPI,
  sendCampusDataToTelegramAPI,
  sendWorksopEventsDataToTelegramAPI,
} from "../services/telegram.service";

export const sendDataToTelegramItem = createAsyncThunk(
  "/sendDataToTelegram",
  async (payload, thunkAPI) => {
    let response = await sendDataToTelegramAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const sendWorksopEventsDataToTelegramItem = createAsyncThunk(
  "/sendWorksopEventsDataToTelegramItem",
  async (payload, thunkAPI) => {
    let response = await sendWorksopEventsDataToTelegramAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const sendCampusDataToTelegramItem = createAsyncThunk(
  "/sendCampusDataToTelegram",
  async (payload, thunkAPI) => {
    let response = await sendCampusDataToTelegramAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
