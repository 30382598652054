import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchRedeemRequestUserItem } from "../../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";
import {
  sendOTPItem,
  verifyOTPItem,
} from "../../../../data/reducers/auth.reducer";
import {
  createRequestUserItem,
  fetchActionCreatedUserItem,
  fetchActionUserItem,
  fetchRequestHistory,
} from "../../../../data/reducers/referral.reducer";
import { checkEmpty } from "../../../../shared/Utils/method";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    padding: "2%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
  },
};

const RedeemHistoryModal = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [newNumber, setNewNumber] = useState("");
  const [inputErr, setInputErr] = useState([]);
  const [disable, setDisable] = useState(false) ;
  const [upiId, setUpiId] = useState("");
  const [sent, setSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [checked, setChecked] = useState(true);
  // const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sendCode = async (e) => {
    const res = checkEmpty({ newNumber });
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("Fill Required Fields .. !!");
    }
    setLoad(true);
    e.preventDefault();
    try {
      let response = await dispatch(sendOTPItem(newNumber));

      setSent(true);
      toast.success("OTP sent Successfully");
    } catch (error) {
      toast.error(error);
    }
    setLoad(false);
  };

  const setAction = async () => {
    const response = await dispatch(
      fetchActionCreatedUserItem({
        specific: true,
        action_status: "ACTION_CREATED",
      })
    );

    if (response.payload.isSuccessful) {
      return;
    }
    toast.error(response.payload.message.data.message);
  };

  const updateHistoryRequest = async () => {
    const response = await dispatch(fetchRequestHistory({ specific: false }));
    if (response.payload.isSuccessful) {
      return;
    }
  };

  const updateActions = async () => {
    const response = await dispatch(fetchActionUserItem({ specific: false }));

    if (response.payload.isSuccessful) {
      return;
    }
  };

  // const { actions } = useSelector(state => state.authReducer).userInfo.owner;
  const actionsCreated = useSelector((state) => state.referralReducer)
    .userActionCreated;
  const userMobileNumber = useSelector((state) => state.authReducer).userInfo
    .owner?.mobileNumber;

  const createRequest = async () => {
    const res = checkEmpty({ upiId });
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("Fill Required Fields .. !!");
    }
    const response_create_req = await dispatch(
      createRequestUserItem({
        actions: actionsCreated.actions,
        benificiaryUpiId: upiId,
      })
    );
    if (response_create_req.payload.isSuccessful) {
      //add all updating functions here
      updateActions();
      setDisable(false) ;
      setAction();
      updateHistoryRequest();
      toast.success(
        "Your request generated Successfully .. Please wait for Admin Approval .. !"
      );
      closeModal();
    }
  };

  const verifyOTP = async (e) => {
    const res = checkEmpty({ otp });
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("Fill Required Fields .. !!");
    }
    e.preventDefault();

    if (otp.length == 4) {
      setLoad(true);
      try {
        let response = await dispatch(
          verifyOTPItem({ otp, mobileNumber: newNumber })
        );

        if (response.payload.type == "success") {
          return setOtpVerified(true);
        }
        setOtpVerified(false);
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("Please enter otp");
    }
    setOtp("");
    setLoad(false);
  };

  const onAnyChange = () => {
    if (checked) {
      return setNewNumber("");
    }
    setUpiId("");
  };

  return (
    <div>
      <button
        className="btn btn-warning"
        onClick={() => {
          if (actionsCreated.totalSum == 0) {
            return toast.warning("You have no points to redeem ");
          }
          return openModal();
        }}
      >
        Redeem
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h4 className="text-center">Points Reedemption</h4>
        {otpVerified ? (
          <div>
            <input
              className={`form-control ${
                inputErr.includes("upiId") ? "is-invalid" : ""
              }`}
              placeholder="Enter your valid UPI Id"
              onChange={(e) => setUpiId(e.target.value)}
            />
            <button
              className="btn btn-success font-weight-bold btn-block my-2"
              disabled={disable}
              onClick={(e) => {
                createRequest(e);
                setDisable(true) ;
              }}
            >
              Submit UPI ID
            </button>
          </div>
        ) : (
          <div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  onClick={() => {
                    setChecked((preVal) => !preVal);
                    onAnyChange();
                    // console.log("checked : ", checked);
                  }}
                  value={checked}
                  checked={checked}
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  className="form-check-label text-dark"
                  for="flexRadioDefault1"
                >
                  <p>{`Continue with ${userMobileNumber} for UPI verification`}</p>
                </label>
              </div>
              {checked ? (
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("upiId") ? "is-invalid" : ""
                  }`}
                  placeholder="Enter your Valid UPI Id"
                  onChange={(e) => setUpiId(e.target.value)}
                />
              ) : null}
            </div>
            <div>
              <div className="form-check my-3">
                <input
                  className="form-check-input"
                  onClick={() => {
                    setChecked((preVal) => !preVal);
                    onAnyChange();
                    // console.log("checked : ", checked);
                  }}
                  value={!checked}
                  checked={!checked}
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label
                  className="form-check-label text-dark"
                  for="flexRadioDefault2"
                >
                  Change number for redemption
                </label>
              </div>
              {!checked ? (
                sent ? (
                  <input
                    className={`form-control ${
                      inputErr.includes("otp") ? "is-invalid" : ""
                    }`}
                    placeholder="Enter OTP .."
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                ) : (
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text">+91</div>
                    </div>
                    <input
                      type="text"
                      className={`form-control ${
                        inputErr.includes("newNumber") ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setNewNumber(e.target.value)}
                      id="inlineFormInputGroup"
                      placeholder="New Number"
                    />
                  </div>
                )
              ) : // <input className='form-control' placeholder="Enter new number .." } /> :
              null}
            </div>
            {load ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-4">
                {checked ? (
                  //Have to submit UPI ID
                  <button
                    className="btn btn-success btn-block"
                    disabled={disable}
                    onClick={(e) => {
                      createRequest(e);
                      setDisable(true) ;
                    }}
                  >
                    Submit UPI ID
                  </button>
                ) : sent ? (
                  <button
                    className="btn btn-success btn-block"
                    onClick={(e) => {
                      verifyOTP(e);
                    }}
                  >
                    Verify OTP and Proceed for Reedemption
                  </button>
                ) : (
                  //Verify Contact Number
                  <button
                    className="btn btn-success btn-block"
                    onClick={(e) => {
                      sendCode(e);
                    }}
                  >
                    Verify Contact Number
                  </button>
                )}
              </div>
            )}
            <div className="col-auto">
              <label className="sr-only" for="inlineFormInputGroup">
                Username
              </label>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default RedeemHistoryModal;
