import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const Education = (props) => {
  const educationList = props.location.state.educationList;
  //
  const i = props.location.state.i;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const id = user._id;
  const [education, setEducation] = useState([...educationList]);
  const [editEducation, setEditEducation] = useState(education[i]);
  //

  const submit = async () => {
    //
    education[i] = editEducation;
    const payload = { education, id };

    //
    // try {
    //   let response = await dispatch(studentEditTypProfileItem(payload));
    //
    //   if (response.payload.isSuccessful) {
    //
    //     toast.success("Edit Successfully");
    //   }

    //   // toast.error(response.payload.message.data.message);
    // } catch (error) {
    //
    //
    // }
    // return history.push("/studentProfilePage");
  };

  const handleEducationInputChange = (e) => {
    const { name, value } = e.target;
    setEditEducation({ ...editEducation, [name]: value });
  };
  // handle click event of the Remove button

  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>Education Details</h3>
      </div>

      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">School / College / University name</label>
            <input
              type="text"
              name="institute"
              onChange={(e) => handleEducationInputChange(e)}
              defaultValue={education[i].institute}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Start Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="startDate"
              onChange={(e) => handleEducationInputChange(e)}
              defaultValue={education[i].startDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> End Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="endDate"
              onChange={(e) => handleEducationInputChange(e)}
              defaultValue={education[i].endDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Area of Study</label>
            <input
              type="text"
              name="areaOfStudy"
              onChange={(e) => handleEducationInputChange(e)}
              defaultValue={education[i].areaOfStudy}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Branch</label>
            <input
              type="text"
              name="branch"
              onChange={(e) => handleEducationInputChange(e)}
              defaultValue={education[i].branch}
              className="form-control"
            />
          </div>
        </div>
        <button type="submit" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default Education;
