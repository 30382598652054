import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import verifySuccess from "../../assets/images/thanksimg.png";
import {
  sendOTPItem,
  signupItem2,
  verifyOTPItem,
} from "../../data/reducers/auth.reducer";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from "react-otp-input";
import { checkDateSE } from "../../shared/Utils/method";
import RequestCallbackModal from "../Homepage/Components/Modal/Request.Callback";
import { addUserToZohoItem } from "../../data/reducers/zoho.reducer";

const Signup2 = () => {
  const userDetails = useSelector((state) => state.authReducer).userInfo;
  // console.log('userDetails: ', userDetails);
  const history = useHistory();
  const [userData, setUserData] = useState({
    userType: "",
    dateOfBirth: "",
    mobileNumber: "",
    firstName: userDetails.newuser
      ? userDetails.newuser.firstName
      : userDetails.owner
      ? userDetails.owner.firstName
      : "",
    lastName: userDetails.newuser
      ? userDetails.newuser.lastName
      : userDetails.owner
      ? userDetails.owner.lastName
      : "",
    gender: "",
    hqAddress: "",
    companyName: "",
    contactNumber: "",
    email: "",
    incorporationYear: "",
    size: "",
    description: "",
    website: "",
    referredBy: localStorage.getItem("referredBy"),
    CTA: localStorage.getItem("CTA"),
  });
  const [educationList, setEducationList] = useState({
    institute: "",
    startDate: "",
    endDate: "",
    areaOfStudy: "",
    branch: "",
  });
  const [otp, setOtp] = useState("");
  const [openthanks, setOpenthanks] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [extraDetailDone, setExtraDetailDone] = useState(false);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const register2 = useSelector((state) => state.authReducer).register2;

  const verifyOtp = useSelector((state) => state.authReducer).verifyOtp;
  const verified = useSelector((state) => state.authReducer).verified;
  const otpError = useSelector((state) => state.authReducer).otpError;
  const usertype = useSelector((state) => state.authReducer).userType;
  const wantACallback = useSelector((state) => state.authReducer).wantACallback;
  const { reqCallbackPool } = useSelector((state) => state.homeReducer);
  const authState = useSelector((state) => state.authReducer);

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  } else if (register2 === true && usertype === "student") {
    console.log("hey");
    if (localStorage.getItem("redirectURL") != null) {
      console.log("I here");
      return <Redirect to={localStorage.getItem("redirectURL")} />;
    } else if (localStorage.getItem("linkPreviewId")) {
      let id = localStorage.getItem("linkPreviewId");
      let path = `/profile/${id}`;
      console.log("I am here");
      return <Redirect to={path}></Redirect>;
    }
    console.log("I am coming here");
    return <Redirect to="/timeline"></Redirect>;
  } else if (register2 === true && usertype === "admin") {
    return <Redirect to="/admin"></Redirect>;
  } else if (register2 === true && usertype === "recruiter") {
    return <Redirect to="/timeline"></Redirect>;
  } else if (register2 === true && usertype === "professional") {
    return <Redirect to="/timeline"></Redirect>;
  }

  const change = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    //  const result = isVerified({...userData})
  };
  // CHECK VERFICATION
  const isVerified = (data) => {
    // console.log('data: ', data);
    if (userDetails?.mobileNumber == data.mobileNumber) {
      // console.log("inide");
      // console.log('userDetails: ', userDetails);
      return userDetails?.isVerified ? true : false;
    }
    return false;
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };
  const signup2 = async (e) => {
    // console.log("I am in signup2");
    // console.log("userType: ", userType);
    e.preventDefault();

    let signupData;
    if (userType === "student") {
      signupData = {
        firstName,
        lastName,
        dateOfBirth,
        mobileNumber,
        gender,
        userType,
        // education: educationList,
        userType,
        referredBy,
        CTA,
      };
    } else if (userType === "recruiter") {
      signupData = {
        firstName,
        lastName,
        gender,
        mobileNumber,
        designation,
        companyInformation: {
          companyName,
          hqAddress,
          contactNumber,
          email,

          incorporationYear,
          size,
          description,
          website,
        },
        userType,
      };
    } else if (userType === "professional") {
      signupData = {
        firstName,
        lastName,
        gender,
        mobileNumber,
        userType,
        // education: educationList,
        userType,
      };
    }

    if (verified) {
      if (
        (userType === "student" ||
          userType === "recruiter" ||
          userType === "professional") &&
        firstName !== "" &&
        firstName.trim() !== "" &&
        lastName !== "" &&
        lastName.trim() !== ""
      ) {
        if (
          userType === "student" ||
          userType === "professional"
          //  &&
          // educationList.institute.length > 0 &&
          // educationList.areaOfStudy.length > 0 &&
          // educationList.startDate != null &&
          // educationList.endDate != null &&
          // educationList.branch.length > 0
        ) {
          try {
            //
            // if (checkDateSE(educationList.startDate, educationList.endDate)) {
            //   return toast.error("Start date should be less than end date");
            // }
            setLoad(true);

            // console.log('signupdata : ', signupData);

            //Hit zoho api
            const payload = {
              Last_Name: signupData.lastName,
              First_Name: signupData.firstName,
              Mobile: signupData.mobileNumber,
              Email: userDetails.email,
              Customer_Source: "TYP Website",
              "Deal Status": "Deal Not Created",
              //Batch name will be dynamic
              Tag: "batch_name",
            };
            // console.log('payload: ', payload);
            let response_zoho = await dispatch(addUserToZohoItem({ payload }));
            // console.log("response_zoho: ", response_zoho);
            // console.log("Coming till here");
            const zoho_crm_id = response_zoho.payload?.data[0]?.details?.id;
            // console.log("zoho_crm_id: ", zoho_crm_id);

            let response = await dispatch(
              signupItem2({
                ...signupData,
                typId: authState.referralTypId,
                zoho_crm_id,
              })
            );

            // console.log("response: ", response);

            if (response.payload.isSuccessful) {
              const routeTo = localStorage.getItem("campusRoutePage");
              if (routeTo) {
                setLoad(true);
                return history.push(routeTo);
              } else if (localStorage.getItem("redirectTo") === "/events") {
                setLoad(true);
                return history.push(localStorage.getItem("redirectTo"));
              } else if (localStorage.getItem("commonRedirectionAfterLogin")) {
                setLoading(false);
                return history.push(
                  localStorage.getItem("commonRedirectionAfterLogin")
                );
              } else {
                setLoad(true);
                return history.push("/timeline");
              }
            }
            toast.error(response.payload.message.data.message);
          } catch (error) {
            console.log("error: ", error);
          }
        } else if (
          userType === "recruiter" &&
          companyName.length > 0 &&
          hqAddress.length > 0 &&
          contactNumber.length > 9 &&
          email.length > 0 &&
          incorporationYear != null &&
          size.length > 0 &&
          description.length > 0 &&
          website.length > 0
        ) {
          try {
            let response = await dispatch(signupItem2(signupData));

            if (response.payload.isSuccessful) {
              toast.success("Signup Part 2 Successfull");
              const routeTo = localStorage.getItem("campusRoutePage");
              if (routeTo) {
                return history.push(routeTo);
              }
              return;
            }
            toast.error(response.payload.message.data.message);
          } catch (error) {
            toast.error(error);
          }
        } else {
          toast.error("Please Fill All the required fields");
        }
      } else {
        toast.error("All Fields are mandatory");
      }
    } else {
      toast.error("Please Verify Your Contact Number");
    }
    setLoad(false);
  };
  const sendCode = async (e) => {
    setLoad(true);
    e.preventDefault();
    try {
      let response = await dispatch(sendOTPItem(mobileNumber));

      toast.success("OTP sent Successfully");
    } catch (error) {
      toast.error(error);
    }
    setLoad(false);
  };
  const resendCode = async (e) => {
    setLoad(true);
    e.preventDefault();

    try {
      let response = await dispatch(sendOTPItem(mobileNumber));
      toast.success("OTP Resend SuccessFull");
    } catch (error) {
      toast.error(error);
    }
    setLoad(false);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    const { _id } = userDetails;
    if (otp.length == 4) {
      setLoad(true);
      try {
        let response = await dispatch(
          verifyOTPItem({ otp, mobileNumber, _id })
        );

        toast.success(response.payload.message);
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.error("Please enter otp");
    }
    setOtp("");
    setLoad(false);
  };

  const handleEducationInputChange = (e) => {
    const { name, value } = e.target;
    setEducationList({ ...educationList, [name]: value });
  };
  const {
    userType,
    dateOfBirth,
    mobileNumber,
    firstName,
    lastName,
    gender,
    designation,
    companyName,
    hqAddress,
    contactNumber,
    email,
    incorporationYear,
    size,
    description,
    website,
    referredBy,
    CTA,
  } = userData;
  const { institute, startDate, endDate, branch, areaOfStudy } = educationList;

  return (
    <section className="loginsection py-md-5 py-4">
      <div className="container">
        {/* <div className="row tp-row">
          <div className="col-md-3">
            <a href="#" className="btn btn-default">
              <i className="fa  fa-chevron-left "></i> Back
            </a>
          </div>
          <div className="col-md-6 text-center">
            <h3>Register Step 2</h3>
          </div>
        </div> */}
        <h3 className="text-capitalize text-center">Register Step 2</h3>
        <div className="row ">
          <div className="col-md-12">
            <div className="borderbox border border-gray rounded-lg p-sm-5 p-3 mt-3">
              <div className="row ">
                <div className="col-lg-6 rightform order-lg">
                  <div
                    className={
                      isVerified({ ...userData })
                        ? "form-group verified"
                        : "form-group"
                    }
                  >
                    <label className="font-size-18" for="">
                      Mobile number
                    </label>

                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <i className="fa fa-plus font-size-12 pr-1"></i> 91
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="inlineFormInputGroup"
                        placeholder=""
                        name="mobileNumber"
                        value={mobileNumber}
                        onChange={change}
                      />
                    </div>
                    {!isVerified({ ...userData }) &&
                      mobileNumber.length === 10 && (
                        <button
                          className="btn btn-success w-100 mt-2"
                          data-toggle="modal"
                          data-backdrop="static"
                          data-keyboard="false"
                          data-target="#sendotpModal"
                          onClick={sendCode}
                        >
                          Send otp
                        </button>
                      )}
                  </div>
                </div>
                <div className="col-lg-6 leftform">
                  <div className="form-group">
                    <label className="font-size-18" for="">
                      User-Type
                    </label>
                    <select
                      name="userType"
                      className="form-control form-control-lg"
                      id=""
                      onChange={change}
                      value={userType}
                    >
                      {" "}
                      <option value="select">Select</option>
                      <option value="student">Student</option>
                      {/* <option value="recruiter">Recruiter</option> */}
                      <option value="professional">Professional</option>
                    </select>
                  </div>
                </div>
                {userType === "student" && (
                  <div className="col-md-12">
                    <h3 className="mb-4 border-bottom text-left pb-3 border-secondary">
                      Student Details
                    </h3>

                    <form action="">
                      <div className="row text-left">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              First name
                            </label>
                            <input
                              // disabled = {firstName!==""?true:false}
                              type="text"
                              name="firstName"
                              onChange={change}
                              value={firstName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              Last name
                            </label>
                            <input
                              // disabled = {lastName!==""?true:false}
                              type="text"
                              name="lastName"
                              onChange={change}
                              value={lastName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>

                        <hr />
                      </div>
                    </form>
                  </div>
                )}
                {userType === "recruiter" && (
                  <div className="col-md-12">
                    <h3>Recruiter Details</h3>

                    <form action="">
                      <div className="row text-left">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              onChange={change}
                              value={firstName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              onChange={change}
                              value={lastName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18" for="">
                              Select Gender{" "}
                            </label>
                            <select
                              name="gender"
                              value={gender}
                              onChange={change}
                              className="form-control form-control-lg"
                              id=""
                            >
                              {/* 2 */}
                              <option selected>Select</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">Designation</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="designation"
                              onChange={change}
                              value={designation}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">Company Name</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="companyName"
                              onChange={change}
                              value={companyName}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Head Quater Address
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="hqAddress"
                              onChange={change}
                              value={hqAddress}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Company Contact Number
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="contactNumber"
                              onChange={change}
                              value={contactNumber}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Company Email
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="email"
                              onChange={change}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Company Incorporation Year
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="incorporationYear"
                              onChange={change}
                              value={incorporationYear}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">Company size</label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="size"
                              onChange={change}
                              value={size}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Company Description
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="description"
                              onChange={change}
                              value={description}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="font-size-18">
                              Company Website
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              name="website"
                              onChange={change}
                              value={website}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {userType === "professional" && (
                  <div className="px-3 col-md-12 ">
                    <h3 className="border-bottom border-secondary pb-2">
                      Professional Details
                    </h3>

                    <form action="">
                      <div className="row text-left">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              First Name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              onChange={change}
                              value={firstName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group ">
                            <label className="font-size-18" for="">
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              onChange={change}
                              value={lastName}
                              className="form-control form-control-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                <div className="btn-bar col-md-6">
                  {
                    // userType === 'student' && wantACallback ? <RequestCallbackModal data={userData} /> :
                    <button
                      disabled={!isVerified({ ...userData })}
                      className="btn btn-warning w-100 form-control-lg text-white text-uppercase h5 mt-sm-4 mt-3 mb-2"
                      onClick={signup2}
                    >
                      Submit
                    </button>
                  }
                  <br />
                  {load && (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>

                {!isVerified({ ...userData }) ? (
                  <div
                    className="modal fade bd-example-modal-md mbmodal"
                    id="sendotpModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="sendotpModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog  modal-dialog-centered"
                      role="document"
                      style={{ maxWidth: "350px" }}
                    >
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close d-none "
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa fa-close"></i>
                        </button>

                        <div className="modal-body p-sm-5 p-3 text-center">
                          <h5
                            className="modal-title text-center"
                            id="sendotpModalLabel"
                          >
                            We have sent an otp via sms for verification
                            purpose.
                          </h5>
                          <p className="text-center mt-3"> Enter otp here!</p>
                          <div className="digit-group d-flex justify-content-center align-items-center text-center mb-3">
                            <OtpInput
                              value={otp}
                              className="input  digit-group"
                              onChange={handleChange}
                              inputStyle={{
                                border: "none",
                                padding: "10px 5px",
                                width: "45px",
                              }}
                              disabledStyle={true}
                              numInputs={4}

                              // separator={<span>{""} </span>}
                            />
                          </div>
                          <div className="linkbar">
                            <a
                              href="#"
                              className="link text-primary h6 Fweight-600 "
                              onClick={resendCode}
                            >
                              {" "}
                              Resend code
                            </a>
                          </div>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn bg-primary w-100 text-white form-control-lg mt-3"
                          >
                            Add another number ?
                          </button>
                          <button
                            type="button"
                            className="btn bg-primary w-100 text-white form-control-lg mt-3"
                            onClick={verifyOTP}
                          >
                            Verify now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="modal  bd-example-modal-md mbmodal"
                    id="verifyotpModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="verifyotpModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      style={{ maxWidth: "350px" }}
                      role="document"
                    >
                      <div className="modal-content">
                        <button
                          type="button"
                          className="close d-lg-none d-md-none"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <i className="fa fa-close"></i>
                        </button>

                        <div className="modal-body p-sm-5 p-3 text-center">
                          <div className="thanksimg">
                            <img
                              src={verifySuccess}
                              alt="success"
                              className="img-fluid"
                            />
                          </div>
                          <h5
                            className="modal-title text-center font-weight-bold mt-3"
                            id="verifyotpModalLabel"
                          >
                            Your mobile number is verified, please continue!
                          </h5>
                        </div>
                        <button
                          className="btn bg-primary text-white m-2"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup2;
