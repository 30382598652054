export const buttons = [
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "ALL_REQUESTS",
    filterCondition: false,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "REQUEST_PENDING",
    filterCondition: true,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "SUBMITTED_FOR_REWARD",
    filterCondition: true,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "REWARD_PENDING",
    filterCondition: true,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "REWARD_RECIEVED",
    filterCondition: true,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "PARTIALLY_ACCEPTED",
    filterCondition: true,
  },
  {
    className: "dropdown-item",
    type: "button",
    filterStatus: "DECLINE_REDEMPTION",
    filterCondition: true,
  },
];
