import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllRolesAPI,
  CreateUserAdminAPI,
  EditUserAdminAPI,
  deleteUserAdminAPI,
} from "../../../services/admin/roles/roles-admin.service";

//async thunk
//get all badges
export const getAllRolesItem = createAsyncThunk(
  "user/getAllRolesItem",
  async (payload, thunkAPI) => {
    let response = await getAllRolesAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//edit badge
export const EditUserAdminItem = createAsyncThunk(
  "admin/EditUserAdminItem",
  async (payload, thunkAPI) => {
    let response = await EditUserAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
//add badge
export const CreateUserAdminItem = createAsyncThunk(
  "admin/CreateUserAdminItem",
  async (payload, thunkAPI) => {
    let response = await CreateUserAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//toggle badge status
export const deleteUserAdminItem = createAsyncThunk(
  "admin/deleteUserAdminItem",
  async (payload, thunkAPI) => {
    let response = await deleteUserAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//slice
const badgesSlice = createSlice({
  name: "badges-admin",
  initialState: {
    roleUsers: [],
  },
});

export default badgesSlice.reducer;
