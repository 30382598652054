import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentAddProject,
  professionalAddProject,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ProjectAdd = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  //   const projectList = props.location.state.projectList;
  //
  const [newProject, setNewProject] = useState({
    title: "",
    description: "",
    genre: "",
    uploadDate: "",
    url: "",
    video: "",
  });
  //   const [project, setProject] = useState([...projectList]);

  let history = useHistory();
  const handleProjectInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
    //
  };

  const submit = async () => {
    //
    const payload = { newProject };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentAddProject(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalAddProject(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };
  const { title, url, video, description, genre, uploadDate } = newProject;
  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>Project Details</h3>
      </div>
      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Title</label>
            <input
              type="text"
              name="title"
              onChange={(e) => handleProjectInputChange(e)}
              value={title}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Project Url</label>
            <input
              type="text"
              name="url"
              onChange={(e) => handleProjectInputChange(e)}
              value={url}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Video Url</label>
            <input
              type="text"
              name="video"
              onChange={(e) => handleProjectInputChange(e)}
              value={video}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Description</label>
            <input
              type="text"
              name="description"
              onChange={(e) => handleProjectInputChange(e)}
              value={description}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Genre</label>
            <input
              type="text"
              name="genre"
              onChange={(e) => handleProjectInputChange(e)}
              value={genre}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Upload Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="uploadDate"
              onChange={(e) => handleProjectInputChange(e)}
              value={uploadDate}
              className="form-control"
            />
          </div>
        </div>
        <button type="button" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default ProjectAdd;
