import React, { useState, useEffect } from "react";
import { constants } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import logo from "../../../src/assets/images/logo1.png";
import axios from "axios";
import paymentpageposter from "../../assets/images/paymentpageposter1.png";
import { changeOpenLoginModal } from "../../data/reducers/auth.reducer";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const WorkshopProgram = (props) => {
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const userInfo = useSelector((state) => state.authReducer).userInfo;

  useEffect(() => {
    if (localStorage.getItem("commonRedirectionAfterLogin")) {
      setTimeout(() => {
        if (localStorage.getItem("commonRedirectionAfterLogin")) {
          localStorage.removeItem("commonRedirectionAfterLogin");
        }
      }, 10000);
    }
  }, [loggedIn]);

  async function displayRazorpay() {
    // createRazorPayOrderApi();
    const res = await axios.post(`${constants.endpoint}/get/razorpay/orderid`, {
      amount: 499,
    });

    const options = {
      key: constants.razorPayTestKeyId, //enter live key on prod
      currency: res.data.currency,
      amount: res.data.amount.toString(),
      image: { logo },
      notes: {
        //enter these after login
        typ_id: userInfo?.owner?._id,
        first_name: userInfo?.owner?.firstName,
        last_name: userInfo?.owner?.lastName,
        email: userInfo?.owner?.email,
        mobile_number: userInfo?.owner?.mobileNumber,
        user_action: "JAVASCRIPT_PROGRAM",
      },
      order_id: res.data.id,
      handler: function (response) {
        // console.log("response.razorpay: ", response);
        toast.success("Payment successfull");
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.open();
  }

  return (
    <div className="container-fluid" style={{ marginTop: "100px" }}>
      <div className="row">
        <div className="col-12 dots">
          <h4 style={{ textTransform: "uppercase" }}>our Upcoming Programs</h4>
        </div>
      </div>
      <div className="row how_it_work" style={{ paddingTop: "20px" }}>
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <img src={paymentpageposter} className="img-fluid" alt="" />
        </div>
        <div className="col-lg-8 col-md-6 col-sm-12 col-12">
          <h4 style={{ textTransform: "uppercase", color: "#1d3883" }}>
            30 Days JavaScript Program
          </h4>
          <p>
            We welcome you to one of the most refined JS courses out there in
            the industry. You will be mentored over different topics including
            JS, such as HTML, CSS, Git, Github, CLI etc..
          </p>
          <h5 style={{ textTransform: "uppercase", color: "#1d3883" }}>
            Key Features
          </h5>
          <ul className="ulStyle">
            <li className="ulStyle">Live Sessions</li>
            <li className="ulStyle">Certification</li>
            <li className="ulStyle">Supported Projects</li>
            <li className="ulStyle">Personal Branding Sessions</li>
          </ul>
          <button
            className="btn"
            style={{
              textTransform: "uppercase",
              width: "98%",
              position: "absolute",
              bottom: "5px",
              backgroundColor: "#1d3883",
              color: "white",
            }}
            onClick={() => {
              if (!loggedIn) {
                localStorage.setItem(
                  "commonRedirectionAfterLogin",
                  "/workshop/program"
                );
                return dispatch(changeOpenLoginModal({ modalVal: true }));
              }
              displayRazorpay();
            }}
          >
            Register Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkshopProgram;
