import { createAsyncThunk } from "@reduxjs/toolkit";
import { getEventRegisteredUsersAdminAPI } from "../../../services/admin/events/admin-event-service";

export const getEventRegisteredUsersAdminItem = createAsyncThunk(
  "admin/getEventRegisteredUsersAdminItem",
  async (payload, thunkAPI) => {
    let response = await getEventRegisteredUsersAdminAPI(payload);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
