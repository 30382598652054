import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRedeemRequestUserItem } from "../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";
import RedeemRequestActionsModal from "./Modals/RedeemRequestsAction.modal";

export default function RedeemRequest() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const refRequests = useSelector((state) => state.referralReducer)
    .userRefRequests;
  // console.log("refRequests: ", refRequests);

  const setRequest = async () => {
    setLoad(true);
    const response = await dispatch(
      fetchRedeemRequestUserItem({
        specific: true,
        request_status: "REQUEST_PENDING",
      })
    );
    // console.log("response: ", response);
    if (response.payload.isSuccessful) {
      setLoad(false);
      return console.log("Payload is here");
    }
    toast.error(response.payload.message.data.message);
  };
  useEffect(() => {
    setRequest();
  }, []);
  return (
    <div>
      {load ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : refRequests.requests?.length !== 0 ? (
        <table className="table table-hover rwd-table mb-5">
          <thead class="btn-warning">
            <tr>
              <th className="text-white" >
                #
              </th>
              <th className="text-white" >
                Request Date
              </th>
              <th className="text-white" >
                Request Points
              </th>
              <th className="text-white" >
                Approved Points
              </th>
              <th className="text-white" >
                Request Status
              </th>
            </tr>
          </thead>
          <tbody className="center">
            {refRequests.requests?.map((req, index) => {
              return (
                <RedeemRequestActionsModal
                  key={index}
                  actions={req.actions}
                  req={req}
                  index={index + 1}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <h5 className="text-center my-3">No data to Show</h5>
      )}
    </div>
  );
}
