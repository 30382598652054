import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  studentEditTypProfileItem,
  studentDeleteDetails,
  professionalEditTypProfileItem,
  professionalDeleteDetails,
} from "../../../data/reducers/auth.reducer";

import Loader from "../../../shared/components/Loaders/Loader";
import { checkEmpty, checkDateSE } from "../../../shared/Utils/method";
//..
const EducationProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const usertype = useSelector((state) => state.authReducer).userType;
  const id = user._id; //student id
  const [index, setIndex] = useState(null);
  const [education, setEducation] = useState([...user.education]); //init
  const [editEducation, setEditEducation] = useState({});

  // loaders states
  const [educationAdd, setEducationAdd] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [emptyEdit, setEmptyEdit] = useState(true);
  const [educationEdit, setEducationEdit] = useState(false);
  const [educationDelete, setEducationDelete] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  //new education init state
  const [newEducation, setNewEducation] = useState({
    institute: "",
    startDate: "",
    endDate: "",
    branch: "",
    areaOfStudy: "",
  });
  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;
    document.getElementById("txtDate").setAttribute("max", maxDate);
    document.getElementById("txtDate1").setAttribute("max", maxDate);
    document.getElementById("txtDate2").setAttribute("max", maxDate);
    document.getElementById("txtDate3").setAttribute("max", maxDate);
  });

  console.log("editEducation : ", editEducation);

  useEffect(async () => {
    setEducation([...user.education]);
  }, [user]);

  //edit education handle change
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setEditEducation({ ...editEducation, [name]: value });
    // setEmptyEdit(checkEmpty({ ...editEducation, [name]: value }));
  };

  //add education handle change
  const handleNewEducationChange = (e) => {
    const { name, value } = e.target;
    setNewEducation({ ...newEducation, [name]: value });

    // setEmpty(checkEmpty({ ...newEducation, [name]: value }));

    //
  };

  //edit submit
  const submit = async () => {
    const res = checkEmpty(editEducation);
    //
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (checkDateSE(editEducation.startDate, editEducation?.endDate))
      return toast.error("Start date should be less than end date");
    setEducationEdit(true);
    //
    //
    //
    education[index] = editEducation;
    //
    const payload = { education, id };
    //
    //
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setInputErr([]);
          setEditEducation(null);
          setEducationEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditEducation();
        setEducationEdit(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setInputErr([]);
          setEditEducation(null);
          setEducationEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditEducation();
        setEducationEdit(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //set id and field
  const deleteButton = (field, id, i) => {
    //
    //
    localStorage.setItem("field", field);
    localStorage.setItem("deleteId", id);
    localStorage.setItem("i", i);
    // setField(field);
    // setDeleteId(id);
  };

  //delete api
  const deleteFields = async () => {
    setEducationDelete(true);
    //
    //
    const field = localStorage.getItem("field");
    const deleteId = localStorage.getItem("deleteId");
    const i = localStorage.getItem("i");
    // education.splice(i, 1);
    //
    //
    //
    const payload = { field, deleteId };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));
        //
        // window.location.reload();
        if (response.payload.isSuccessful) {
          // window.location.reload();
          //
          setEducationDelete(false);
          return toast.success("Delete Successfully");
        }
        setEducationDelete(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));
        //
        // window.location.reload();
        if (response.payload.isSuccessful) {
          // window.location.reload();
          //
          setEducationDelete(false);
          return toast.success("Delete Successfully");
        }
        setEducationDelete(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //add submit
  const submitNewEducation = async (e) => {
    const res = checkEmpty(newEducation);
    //
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (checkDateSE(newEducation.startDate, newEducation?.endDate))
      return toast.error("Start date should be less than end date");
    setEducationAdd(true);
    //
    //
    education.push(newEducation);
    const payload = { education, id };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setNewEducation({
            institute: "",
            startDate: "",
            endDate: "",
            branch: "",
            areaOfStudy: "",
          });
          setInputErr([]);
          setEducationAdd(false);
          return toast.success("Add Successfully");
        }
        setInputErr([]);
        setNewEducation({
          institute: "",
          startDate: "",
          endDate: "",
          branch: "",
          areaOfStudy: "",
        });
        setEducationAdd(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setNewEducation({
            institute: "",
            startDate: "",
            endDate: "",
            branch: "",
            areaOfStudy: "",
          });
          setInputErr([]);
          setEducationAdd(false);
          return toast.success("Add Successfully");
        }
        setInputErr([]);
        setNewEducation({
          institute: "",
          startDate: "",
          endDate: "",
          branch: "",
          areaOfStudy: "",
        });
        setEducationAdd(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload();
  };

  //
  const [area, setArea] = useState("false");
  const { institute, branch, areaOfStudy, startDate, endDate } = newEducation;
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h4 className="mb-0" >Education</h4>
        <i
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          data-target="#Education"
          className="fa fa-plus-circle font-20 text-success ml-3 cursor-pointer"
        />
        <div className="ml-2">
          {(educationAdd || educationEdit || educationDelete) && (
            <>
              <Loader />
            </>
          )}
        </div>
      </div>
      {education.length > 0 ? (
        <div className="content p-0 m-0 border p-2">
          {education.length > 0 &&
            education.map((edu, i) => (
              <>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown cust_notify">
                    <i
                      className="fa fa-edit  font-20 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-2"
                      role="button"
                      id="dropdownDis"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      aria-haspopup="true"
                      aria-expanded={area}
                      data-target="#EducationEdit"
                      onClick={() => {
                        setIndex(i);
                        //
                        setEmptyEdit(true);
                        setEditEducation(edu);
                      }}
                    />
                  </div>
                  <i
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    onClick={() => {
                      localStorage.setItem("newId", edu._id);
                      deleteButton("education", edu._id, i);
                    }}
                    data-target="#ConfDeleteEducation"
                    className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                  />
                </div>

                <p className="mb-0 p-0">
                  {" "}
                  {edu.branch} {edu.areaOfStudy} at {edu.institute} from
                  {edu.startDate} to {edu.endDate}{" "}
                </p>
              </>
            ))}
        </div>
      ) : null}

      {/* Education add modal */}
      <div
        className="modal fade"
        id="Education"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Education description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    School /College / University name
                  </label>
                  <input
                    type="text"
                    name="institute"
                    value={institute}
                    onChange={handleNewEducationChange}
                    className={`form-control ${
                      inputErr.includes("institute") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Start Date
                  </label>
                  <input
                    id="txtDate"
                    type="date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    name="startDate"
                    value={startDate}
                    onChange={handleNewEducationChange}
                    className={`form-control ${
                      inputErr.includes("startDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                    // max={Date.now()}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    End Date
                  </label>
                  <div className="d-flex">
                    <input
                      id="txtDate1"
                      type="date"
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      //Add end date functionality
                      disabled={
                        newEducation?.endDate != "Present" &&
                        newEducation?.endDate != ""
                          ? true
                          : false
                      }
                      name="endDate"
                      value={endDate}
                      onChange={handleNewEducationChange}
                      className={`form-control col-lg-8 ${
                        inputErr.includes("endDate") ? "is-invalid" : ""
                      }`}
                      placeholder="dd-mm-yy"
                    />
                    <button
                      title="currently working"
                      type="button"
                      className="btn-warning btn text-white mx-2 col-lg-4"
                      disabled={
                        newEducation?.endDate == "Present" ? true : false
                      }
                      onClick={() => {
                        setNewEducation({
                          ...newEducation,
                          endDate: "Present",
                        });
                      }}
                    >
                      Present
                    </button>
                  </div>
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Area of Study
                  </label>
                  <input
                    type="text"
                    name="areaOfStudy"
                    value={areaOfStudy}
                    onChange={handleNewEducationChange}
                    className={`form-control ${
                      inputErr.includes("areaOfStudy") ? "is-invalid" : ""
                    }`}
                    placeholder="Area
                    "
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Branch
                  </label>
                  <input
                    type="text"
                    name="branch"
                    value={branch}
                    onChange={handleNewEducationChange}
                    className={`form-control ${
                      inputErr.includes("branch") ? "is-invalid" : ""
                    }`}
                    placeholder="Branch"
                  />
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => setInputErr([])}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    data-dismiss={
                      checkEmpty(newEducation).length == 0
                        ? checkDateSE(
                            newEducation.startDate,
                            newEducation?.endDate
                          )
                          ? null
                          : "modal"
                        : null
                    }
                    // disabled={empty}
                    onClick={submitNewEducation}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* Education edit modal */}
      <div
        className="modal fade"
        id="EducationEdit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Education description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    School /College / University name
                  </label>
                  <input
                    type="text"
                    name="institute"
                    value={editEducation?.institute}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("institute") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Start Date
                  </label>
                  <input
                    id="txtDate2"
                    type="date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    name="startDate"
                    value={editEducation?.startDate}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("startDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    End Date
                  </label>
                  <div className="d-flex">
                    <input
                      id="txtDate3"
                      type="date"
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      disabled={
                        editEducation?.endDate != "Present" ? true : false
                      }
                      name="endDate"
                      value={editEducation?.endDate}
                      onChange={(e) => handleChange(e, index)}
                      className={`form-control col-lg-8 ${
                        inputErr.includes("endDate") ? "is-invalid" : ""
                      }`}
                      placeholder="dd-mm-yy"
                    />
                    <button
                      title="currently working"
                      type="button"
                      disabled={
                        editEducation?.endDate == "Present" ? true : false
                      }
                      onClick={() => {
                        console.log(editEducation);
                        setEditEducation({
                          ...editEducation,
                          endDate: "Present",
                        });
                      }}
                      className="btn-warning btn text-white mx-2 col-lg-4"
                    >
                      Present
                    </button>
                  </div>
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Area of Study
                  </label>
                  <input
                    type="text"
                    name="areaOfStudy"
                    value={editEducation?.areaOfStudy}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("areaOfStudy") ? "is-invalid" : ""
                    }`}
                    placeholder="Area"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Branch
                  </label>
                  <input
                    type="text"
                    name="branch"
                    value={editEducation?.branch}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("branch") ? "is-invalid" : ""
                    }`}
                    placeholder="Branch"
                  />
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => setInputErr([])}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    data-dismiss={
                      checkEmpty(editEducation).length == 0
                        ? checkDateSE(
                            editEducation?.startDate,
                            editEducation?.endDate
                          )
                          ? null
                          : "modal"
                        : null
                    }
                    // disabled={emptyEdit}
                    onClick={submit}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>
      {/* Delete conformation modal */}
      <div
        className="modal fade"
        id="ConfDeleteEducation"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={deleteFields}
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationProfile;
