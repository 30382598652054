import React from "react";
import DomToIMg from "dom-to-image";
import JsPdf from "jspdf";
import cert from "../../assets/images/certificateborder.png";
import icon from "../../assets/images/logo.png";
//************************************************************ */
const Workshop1 = ({ item }) => {
  // console.log('item: ', item);
  return (
    <div className=" d-flex flex-column align-items-center">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js"></script>

      <div className="cert-container print-m-0" id="content">
        <div id="content2" className="cert">
          {/* <img src={cert} className="cert-bg" alt="" /> */}
          <div className="certificateBorder">
            <div className="cert-content">
              <img
                src={icon}
                style={{ width: "175px", height: "90px" }}
                alt=""
              />
              <h3 className="other-font">CERTIFICATE OF COMPLETION</h3>
              {/* <span style={{ fontSize: "30px" }}>{item?.studentName}</span> */}
              <span style={{ paddingTop: "15px" }}>
                This is to certify that
              </span>
              <br />
              <br />
              <span style={{ fontSize: "30px", marginTop: "20px" }}>
                <b>{item?.studentName.toUpperCase()}</b>
              </span>
              <br />
              {/* <br /> */}

              <span className="other-font">
                <b>
                  has Successfully completed the{" "}
                  <b style={{ fontSize: "25px" }}>{item?.eventTitle}</b>
                </b>
              </span>
              <br />
              <span className="other-font">
                <b>Organized by The Youth Project. </b>
              </span>
              {/*             
            <span style={{ fontSize: "40px" }}>
              <b>{item?.eventTitle}</b>
            </span> */}
              <br />
              <small>(Project Based)</small>
              <br />
              <br />
              <br />
              {/* <small>
              Completion of the Azimuth Check and review of the Azimuth Check
              recommendations in ArmyFit is worth 1.0 hours in the Electronic
            </small> */}
              <div className="bottom-txt">
                <span>
                  Date:{item?.eventDate} <br /> {item?.uniqueId}
                </span>
                <span className="sign">
                  DEVANSH SRIVASTAVA <br />
                  CEO, The Youth Project{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          DomToIMg.toPng(document.getElementById("content")).then((data) => {
            const doc = new JsPdf("l", "mm");
            let width = 700;
            let height = doc.internal.pageSize.getHeight();
            doc.addImage(data, "png", 0, 0, 297, 210);
            doc.save(`${item.eventTitle}.pdf`);
          });
        }}
        className="btn btn-primary m-1"
      >
        Download
      </button>
    </div>
  );
};

export default Workshop1;
