import React from "react";
import DomToIMg from "dom-to-image";
import JsPdf from "jspdf";
import icon from "../../assets/images/logo.png";

//************************************************************ */
const TrainingCertificate = ({ item }) => {
  // console.log('item: ', item);
  return (
    <div className=" d-flex flex-column align-items-center ">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/0.4.1/html2canvas.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.5/jspdf.min.js"></script>

      <div
        className="cert-container print-m-0"
        id="content"
        style={{ marginBottom: "50px" }}
      >
        <div id="content2" className="cert">
          {/* <img src={cert} className="cert-bg" alt="" /> */}
          <div className="certificateBorder" style={{ height: "600px" }}>
            <div className="cert-content" style={{ height: "560px" }}>
              <img
                src={icon}
                style={{ width: "175px", height: "80px" }}
                alt=""
              />
              <h3 className="other-font">CERTIFICATE OF TRAINING</h3>
              {/* <span style={{ fontSize: "30px" }}>{item?.studentName}</span> */}
              <span>This is to certify that</span>
              <br />

              <div style={{ marginTop: "5px" }}>
                <span
                  style={{
                    fontSize: "27px",
                    // paddingBottom:"50px",
                    // paddingBottom:"10px"
                    // marginTop: "20px",
                  }}
                >
                  <b>
                    {/* <WorkshopComp1 item={certificateData} /> */}
                    {item?.studentName.toUpperCase()}
                  </b>
                </span>
              </div>

              {/* <br /> */}
              <div style={{ marginTop: "15px" }}>
                <span className="other-font">
                  has Successfully completed the industrial training on
                  <b> {item?.eventRecText} </b> which{" "}
                  <b>
                    started from{" "}
                    {item?.trainingStartDate.split("-").reverse().join("-")}
                  </b>{" "}
                  and{" "}
                  <b>
                    ended on{" "}
                    {item?.trainingEndDate.split("-").reverse().join("-")}.
                  </b>
                  {/* <b style={{ fontSize: "25px" }}>{item?.eventTitle}</b> */}
                </span>
              </div>

              {/* <br /> */}

              <div style={{ marginTop: "10px" }}>
                <span className="other-font ">
                  They have showed immense capabilities, skillset, commitment to
                  their role as well as curiosity to learn and execute the work
                  given to them in best possible way. We wish them all the best
                  for their future.
                  {/* <b style={{ fontSize: "25px" }}>{item?.eventTitle}</b> */}
                </span>
              </div>

              <br />

              <span className="other-font">
                <b>Organized by The Youth Project. </b>
              </span>

              <div className="bottom-txt" style={{ marginTop: "15px" }}>
                <span>
                  Date:
                  {new Date(item?.certificateIssuedDate)
                    .toLocaleDateString()
                    .split("/")
                    .join("-")}{" "}
                  <br /> {item?.uniqueId}
                </span>
                <span className="">
                  DEVANSH SHRIVASTAVA <br />
                  CEO, The Youth Project{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={() => {
          DomToIMg.toPng(document.getElementById("content")).then((data) => {
            const doc = new JsPdf("l", "mm");
            let width = 700;
            let height = doc.internal.pageSize.getHeight();
            doc.addImage(data, "png", 0, 0, 297, 210);
            doc.save(`${item.eventTitle}.pdf`);
          });
        }}
        className="btn btn-primary m-1"
      >
        Download
      </button>
    </div>
  );
};

export default TrainingCertificate;
