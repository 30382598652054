import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchActionUserItem } from "../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";

const PointsEarned = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);

  const actions = useSelector((state) => state.referralReducer).userRefActions
    .actions;

  const updateActions = async () => {
    setLoad(true);
    const response = await dispatch(fetchActionUserItem({ specific: false }));

    if (response.payload.isSuccessful) {
      setLoad(false);
      return console.log("Got all actions from db .. !");
    }
  };

  useEffect(() => {
    updateActions();
  }, []);

  return (
    <div>
      {
        load ?
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          (actions?.length !== 0 ? (
            <table className="table table-hover rwd-table mb-5" data-aos="zoom-in"
            >
              <thead class="btn-warning">
                <tr>
                  <th className="text-left text-white" >
                    #
              </th>
                  <th className="text-left text-white" >
                    Action
              </th>
                  <th className="text-left text-white" >
                    Description
              </th>
                  <th className="text-left text-white" >
                    Status
              </th>
                  <th className="text-left text-white" >
                    Points Earned
              </th>
                </tr>
              </thead>
              <tbody>
                {actions?.map((action, indx) => {
                  return (
                    <tr key={indx}>
                    
                      <td data-th="#">{indx + 1}</td>
                  <td data-th="Action" className="text-left">{action.actionName}</td>
                  <td data-th="Description">
                    {action.actionStatus.description
                      ? action.actionStatus.description
                      : "---"}
                  </td>
                  <td data-th="Status" className="text-left">{action.actionStatus.status}</td>
                  <td data-th="Points Earned" className="text-left font-weight-bold">{action.actionPoints}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h2 className="text-center text-muted my-3">No data to Show</h2>
          ))}
    </div>
  );
};

export default PointsEarned;
