import React, { useEffect, useState, useMemo } from "react";
import Search from "../../Shared/Search/Search";
import { NavLink, Redirect, useHistory } from "react-router-dom";
// import useFullPageLoader from "hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  studentListAdminItem,
  toggleTYPStudentItem,
} from "../../../../data/reducers/admin.reducer";
import ToggleStudenModelDeactive from "./Modals/ToggleStudent.modal";
import { toast } from "react-toastify";
import SetUserLevelModal from "./Modals/Set-level-modal";

const Students = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const studentList = useSelector((state) => state.adminReducer).studentList;
  // const [student, setStudent] = useState([]);
  const [i, seti] = useState(null);
  const [loader, setLoader] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [noStudent, setNoStudent] = useState(false);
  const [render, setRender] = useState(false);
  const [update, setUpdate] = useState(null);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [click, setClick] = useState("");
  const history = useHistory();
  const ITEMS_PER_PAGE = 50;
  // const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(async () => {
    fetchStudents();
  }, [skip]);

  const fetchStudents = async () => {
    try {
      setLoader(true);
      let response = await dispatch(studentListAdminItem({ limit, skip }));
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        setClick("");
        return setLoader(false);
      }
      setLoader(false);
    } catch (error) {}
  };

  const studentData = useMemo(() => {
    let studentData = studentList?.slice().sort((a, b) => {
      let fa = a?.ownerField?.firstName.toLowerCase(),
        fb = b?.ownerField?.firstName.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    if (studentData.length == 0) {
      setNoStudent(true);
    }
    setNoStudent(false);
    if (search) {
      studentData = studentData.filter(
        (student) =>
          student?.ownerField?.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          student?.ownerField?.email
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          student?.ownerField?.isVerified
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          student?.ownerField?.mobileNumber
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          student?.ownerField?.typId
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    setTotalItems(studentData?.length);
    // curreent pages
    return studentData?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [studentList, currentPage, search]);

  const toggleTYPStudent = async (_id, isTYPStudent) => {
    const response = await dispatch(
      toggleTYPStudentItem({ _id, isTYPStudent, limit, skip })
    );

    if (response.payload.isSuccessful) {
      fetchStudents();
      return toast.success("Student toggled successfully");
    }
    toast.error(response.payload.message.data.message);
  };

  const goPrevPage = () => {
    history.goBack();
  };

  //
  const edit = (i) => {
    seti(i);
    setUpdate(studentList[i]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdate({ ...update, [name]: value });
  };
  const submit = () => {
    //
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (
    loggedIn === true &&
    usertype !== "admin" &&
    usertype !== "technical-operation"
  ) {
    return <Redirect to="/"></Redirect>;
  } else if (
    (loggedIn === true && usertype === "admin") ||
    usertype === "technical-operation"
  ) {
    return (
      <div className="container-fluid">
        <div>
          <div className="row my-3">
            <div className="col d-flex align-items-center justify-content-between mb-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">
                Students List
              </h3>
              <div onClick={goPrevPage} class="btn btn-primary">
                Back
              </div>
            </div>
          </div>
          <div className="text-right pb-3">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>

          <div className="table-responsive">
            <table className="table table-hover text-center">
              <thead className="bg-primary text-white">
                <th>No #</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                {usertype === "admin" && <th>typId</th>}

                <th>referredBy</th>
                <th>CTA</th>
                {usertype === "admin" && <th>Level</th>}
                <th>Verified</th>
                {usertype === "admin" && <th>Edit</th>}

                {/* <th>CRM</th> */}
                <th>Convert to</th>
                <th>Toggle</th>
              </thead>
              <tbody>
                {studentData?.map((student, i) => (
                  <>
                    <tr key={student?._id}>
                      <td className="h5">{skip + i + 1}</td>
                      <td>
                        {student?.ownerField?.firstName}{" "}
                        {student?.ownerField?.lastName}
                      </td>
                      <td>{student?.ownerField?.email}</td>
                      <td>{student?.ownerField?.mobileNumber}</td>
                      {usertype === "admin" && (
                        <td>
                          <NavLink to={`/profile/${student._id}`}>
                            {student?.ownerField?.typId || "-"}
                          </NavLink>
                        </td>
                      )}

                      <td>{student?.ownerField?.referredBy || "-"}</td>
                      <td>{student?.ownerField?.CTA || "-"}</td>
                      {usertype === "admin" && (
                        <SetUserLevelModal
                          limit={limit}
                          skip={skip}
                          student={student}
                        />
                      )}

                      <td>
                        {student?.ownerField?.isVerified === "true" ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-times"></i>
                        )}
                      </td>
                      {usertype === "admin" && (
                        <td>
                          <NavLink
                            to={`/admin/edit/user/profile/${student?._id}/${student?.ownerField?.userType}`}
                          >
                            <button
                              type="button"
                              // onClick={() => edit(i)}
                              // data-toggle="modal"

                              className="btn text-success"
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                          </NavLink>
                        </td>
                      )}
                      {/* <td>
                        <button className="btn btn-warning text-white btn-sm">
                          View CRM
                        </button>
                      </td> */}
                      <td>
                        <button
                          onClick={() => {
                            toggleTYPStudent(
                              student?.ownerField?._id,
                              !student?.ownerField?.isTYPStudent
                            );
                          }}
                          className="btn btn-dark btn-sm"
                        >
                          {student?.ownerField?.isTYPStudent
                            ? "Non TYP Student"
                            : "TYP Student"}
                        </button>
                      </td>
                      <td className="text-center">
                        {/* Add modal over here */}
                        <ToggleStudenModelDeactive
                          skip={skip}
                          limit={limit}
                          _id={student?.ownerField?._id}
                          status={student?.ownerField?.status.isActive}
                        />
                      </td>
                    </tr>
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Edit Student Data
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                name="fistName"
                                defaultValue={student?.ownerField?.firstName}
                                className="form-control"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              onClick={() => seti(null)}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              onClick={submit}
                              className="btn btn-primary"
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </tbody>
            </table>
            <center>
              {noStudent ? (
                <h4 className="text-muted">No Students Found</h4>
              ) : null}
            </center>
          </div>
          <div className="text-center mb-4">
            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={skip === 0}
              onClick={() => {
                setClick("prev");
                setSkip((preVal) => {
                  return preVal - 10;
                });
              }}
            >
              {loader && click === "prev" ? "Prev.." : "Prev"}
              {loader && click === "prev" ? (
                <span
                  id="studentTop"
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>

            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={studentData.length === 0 || studentData.length < 10}
              onClick={() => {
                setClick("next");
                setSkip((preVal) => {
                  return preVal + 10;
                });
              }}
            >
              {loader && click === "next" ? "Next.." : "Next"}
              {loader && click === "next" ? (
                <span
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Students;
