import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllRolesItem } from "../../../data/reducers/admin/roles/roles-admin.reducer";
import { getAllUsertypesItem } from "../../../data/reducers/admin/user-types/usertypes-admin.reducer";
import EditRoleModel from "./Modals/Edit-roles.model";
import CreateRoleModel from "./Modals/Create-roles-model";
import DeleteRoleModel from "./Modals/Delete-roles.model";
// import { toast } from "react-toastify";

//...
const RoleUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const userType = useSelector((state) => state.authReducer).userType;
  const rolesData = useSelector(
    (state) => state.rolesAdminReducer
  ).userTypesData;
  const [roleUsers, setroleUsers] = useState([]);
  const [render, setrender] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      history.push("/theyouthproject/admin/teams");
    } else {
      return userType === "admin"
        ? (setrender(true), fetchAllRoleUsers(), fetchAllRolesConfig())
        : history.push("/");
    }
  }, []);

  const fetchAllRoleUsers = async () => {
    try {
      setloading(true);
      let response = await dispatch(getAllRolesItem());
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        setloading(false);
        return setroleUsers(response?.payload?.data?.users);
      }
      setloading(false);
    } catch (error) {}
  };

  const fetchAllRolesConfig = async () => {
    try {
      let response = await dispatch(getAllUsertypesItem());
      console.log("response: ", response);
      if (response.payload.isSuccessful) {
        return;
      }
    } catch (error) {}
  };

  return (
    <>
      {render && (
        <div style={{ marginTop: "110px" }}>
          <CreateRoleModel
            rolesData={rolesData}
            fetchAllRolesConfig={fetchAllRolesConfig}
            fetchRoles={fetchAllRoleUsers}
          />
        </div>
      )}
      {render && roleUsers?.length > 0 ? (
        <div className="col-md-12">
          {loading && "Loading.."}
          <div className="table-responsive mt-2  ">
            <table className="table table-hover border">
              <thead className="btn-secondary">
                <tr>
                  <th align="center" scope="col">
                    Email
                  </th>
                  <th align="center" scope="col">
                    User-Type
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {roleUsers?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td align="left">{item?.email}</td>
                      <td align="left">{item?.userType}</td>
                      <td align="center">
                        <EditRoleModel
                          rolesData={rolesData}
                          item={item}
                          fetchRoles={fetchAllRoleUsers}
                        />
                      </td>
                      <td align="center">
                        <DeleteRoleModel
                          item={item}
                          fetchRoles={fetchAllRoleUsers}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        "No roles created yet!"
      )}
    </>
  );
};

export default RoleUsers;
