import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { editBatchItem } from "../../../../data/reducers/admin/batches/batches-admin.reducer";
import { toast } from "react-toastify";
import { checkEmpty, checkEditChanged } from "../../../../shared/Utils/method";

//edit batch modal
const Model = ({ batch, _id }) => {
  // console.log("batch : ", batch);
  const dispatch = useDispatch();

  const [batchDetails, setbatchDetails] = useState({
    name: batch?.name,
    duration: batch?.duration,
    startDate: batch?.startDate,
    book_demo_redirect_url: batch?.book_demo_redirect_url,
    mode: batch?.mode,
    timing: batch?.timing,
    duration: batch?.duration,
    track: batch?.track,
  });
  const [modalIsOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [inputErr, setInputErr] = useState([]);

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setDisable(
      checkEditChanged(
        { ...batchDetails, [e.target.name]: e.target.value },
        batch
      )
    );
    setbatchDetails({ ...batchDetails, [e.target.name]: e.target.value });
  };

  const edit = async (details) => {
    const res = checkEmpty(batchDetails);
    // console.log("res: ", res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandartory.. !!");
    }
    const response = await dispatch(editBatchItem({ ...details, _id }));
    if (response.payload.isSuccessful) {
      return toast.success("Batch edited successfully .. !");
    }
    toast.error(response.payload.message.data.message);
  };

  //check empty
  // const checkEmpty = () => (batchDetails.name === "" ? true : false);

  return (
    <div
      className=""
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="mr-2 cursor-pointer text-primary font-size-14"
        onClick={(e) => {
          e.stopPropagation();
          OpenModal();
          //   setbatchDetails({ ...batchDetails, name: name });
        }}
      >
        <i className="fa fa-edit mr-1"></i>Edit
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 10000,
          },
          content: {
            top: "50%",
            left: "50%",
            height:"80vh",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f5f5f5",
            width: "400px",
          },
        }}
      >
        <div className="container-fluid ">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h3 className="text-center">Edit Batch</h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-3"
                >
                  Batch Name
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("name") ? "is-invalid" : ""
                  }`}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="name"
                  value={batchDetails.name}
                  placeholder={"Enter batch name.. "}
                />

                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-3"
                >
                  Batch Start Date
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("startDate") ? "is-invalid" : ""
                  }`}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  // defaultValue={new Date(
                  //   batchDetails?.startDate
                  // ).toLocaleDateString()}
                  defaultValue={batchDetails?.startDate}
                  name="startDate"
                  // value={batchDetails.startDate}
                  placeholder="Batch Start Date"
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Book a demo redirect URL
                </label>
                <input
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("book_demo_redirect_url")
                      ? "is-invalid"
                      : ""
                  }`}
                  onChange={handleChange}
                  name="book_demo_redirect_url"
                  value={batchDetails.book_demo_redirect_url}
                  placeholder="Book a demo link"
                />
                <div className="input-group mt-2">
                  <div className="input-group-prepend">
                    <label
                      className="input-group-text"
                      for="inputGroupSelect01"
                    >
                      Choose
                    </label>
                  </div>
                  <select
                    className="custom-select"
                    name="mode"
                    defaultValue={batchDetails.mode}
                    className={`form-control form-control-lg ${
                      inputErr.includes("mode") ? "is-invalid" : ""
                    }`}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    id="inputGroupSelect01"
                    aria-label="Example select with button addon"
                  >
                    <option disabled selected>
                      Mode...
                    </option>
                    <option value="online">ONLINE</option>
                    <option value="offline">OFFLINE</option>
                  </select>
                </div>
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-3"
                >
                  Batch Start Timings
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("timing") ? "is-invalid" : ""
                  }`}
                  name="timing"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.timing}
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Batch Duration
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("duration") ? "is-invalid" : ""
                  }`}
                  name="duration"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.duration}
                />
                 <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label mt-2"
                >
                  Track
                </label>
                <input
                  className={`form-control form-control-lg ${
                    inputErr.includes("track") ? "is-invalid" : ""
                  }`}
                  name="track"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  defaultValue={batchDetails.track}
                />
              </div>

              <div className="col-12 p-0">
                <button
                  className="btn btn-dark m-1"
                  disabled={disable}
                  onClick={() => {
                    edit(batchDetails);
                    return checkEmpty(batchDetails).length == 0
                      ? closeModal()
                      : null;
                  }}
                >
                  Edit Batch
                </button>

                <button
                  className="btn btn-dark m-1"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
