import React, { useState } from "react";
import Modal from "react-modal";
import { deleteUserAdminItem } from "../../../../data/reducers/admin/roles/roles-admin.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Model = ({ item, fetchRoles }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle submit
  const handleSubmit = async () => {
    const { _id } = item;
    const payload = { _id };
    try {
      setLoader(true);
      const response = await dispatch(deleteUserAdminItem(payload));
      if (response.payload.isSuccessful) {
        setLoader(false);
        fetchRoles();
        closeModal();
        return toast.success("Role deleted successfully!");
      }
      setLoader(false);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  return (
    <div className="container">
      <i
        className="fa fa-trash font-weight-15 text-danger cursor-pointer"
        onClick={() => OpenModal()}
      ></i>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Are you sure ?</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="col-12 p-0">
                <button
                  disabled={loader}
                  className="btn m-1 text-white btn-secondary"
                  // style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Confirm
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
