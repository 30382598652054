import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUpcomingPoolAPI } from "../services/home.service";

export const getUpcomingPoolItem = createAsyncThunk(
  "home/getUpcomingPool",
  async (payload, thunkAPI) => {
    let response = await getUpcomingPoolAPI();

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    upcomingPools: [],
    reqCallbackPool: null,
  },
  reducers: {
    //No reducers here
    toggleWantACallbackPool: (state, action) => {
      state.reqCallbackPool = action.payload.upcomingPoolId;
    },
  },
  extraReducers: {
    [getUpcomingPoolItem.fulfilled]: (state, action) => {
      state.upcomingPools = action.payload.data;
    },
  },
});
export const { toggleWantACallbackPool } = userSlice.actions;
export default userSlice.reducer;
