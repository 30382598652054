import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../shared/Utils/method";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { toggleWantACallbackPool } from "../../../data/reducers/home.reducer";
import QuickCallbackNFloatingForm from "../../../shared/components/Forms/QuickCallbackNFloatingForm";
import telephone from "../../../assets/images/telephone.png";
import whatsapp from "../../../assets/images/whatsapp.svg";
import "../../styles/styles.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
  },
};

const QuickCallback = ({ data, setGetDetailsAfterLogin, page }) => {
  const [active, setActive] = React.useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer).userInfo;
  var toggle = false;
  const handleToggle = () => {
    active ? setActive(false) : setActive(true);
    // setActive(true);
  };

  //Modal functions
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  const userType = useSelector((state) => state.authReducer).userType;

  function closeModal() {
    dispatch(toggleWantACallbackPool({ upcomingPoolId: null }));
    setIsOpen(false);
  }

  // const userType = useSelector((state) => state.authReducer).userType;

  function closeModal() {
    dispatch(toggleWantACallbackPool({ upcomingPoolId: null }));
    setIsOpen(false);
  }
  // const toggleClass = ()=>{
  //   $('.btn-group-fab').on('click', '.btn', function() {
  //     $('.btn-group-fab').toggleClass('active');
  //   });
  //   $('has-tooltip').tooltip();
  // }

  return (
    <div>
      <div
        className={`btn-group-fab ${active ? "active" : ""}`}
        role="group"
        aria-label="FAB Menu"
        onClick={handleToggle}
      >
        <div>
          <button
            type="button"
            style={{background:"#1d3883",color:"white"}}
            class="btn btn-main  has-tooltip"
            data-placement="left"
            title="help"
          >
            {" "}
            <i class="fas fa-headset floatingButton" style={{fontSize:"35px",lineHeight:"normal"}}></i></button>
          <button
            type="button"
            class="btn btn-sub btn-danger has-tooltip"
            data-placement="left"
            title="Enquiry"
            onClick={() => {
              if (
                userType != "admin" ||
                userType == null ||
                userType == "" ||
                userType == undefined
              ) {
                openModal();
              }
            }}
          >
            {" "}
            <i class="fas fa-phone-alt" style={{fontSize:"31px"}}></i>
          </button>
          <a
            type="button"
            href="https://wa.me/919174604453"
            target="_blank"
            class="btn btn-sub btn-success has-tooltip"
            data-placement="left"
            title="chat on whatsapp"
          >
            {" "}
            <i class="fab fa-whatsapp" style={{fontSize:"41px"}}></i>{" "}
          </a>
          <a
            type="button"
            href="https://t.me/typcommunity"
            target="_blank"
            class="btn btn-sub has-tooltip"
            style={{backgroundColor:"#36abe7"}}
            data-placement="left"
            title="chat on telegram"
          >
            {" "}
            <i class="fab fa-telegram-plane" style={{fontSize:"35px",marginTop:"5px",color:"#fff"}}></i>{" "}
          </a>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <QuickCallbackNFloatingForm closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default QuickCallback;
