import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminAddReviewAPI,
  adminFetchReviewAPI,
  adminEditReviewAPI,
  adminEditStatusReviewAPI,
  adminUploadReferenceImageAPI,
  adminUploadPersonPictureAPI,
} from "../../../services/admin/reviews/review.service";

//thunks
//add review
export const adminAddReviewItem = createAsyncThunk(
  "user/adminAddReviewItem",
  async (payload, thunkAPI) => {
    let response = await adminAddReviewAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//fetch review
export const adminFetchReviewItem = createAsyncThunk(
  "user/adminFetchReviewItem",
  async (payload, thunkAPI) => {
    let response = await adminFetchReviewAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//edit review
export const adminEditReviewItem = createAsyncThunk(
  "user/adminEditReviewItem",
  async (payload, thunkAPI) => {
    let response = await adminEditReviewAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//edit status
export const adminEditStatusReviewItem = createAsyncThunk(
  "user/adminEditStatusReviewItem",
  async (payload, thunkAPI) => {
    let response = await adminEditStatusReviewAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//adminUploadPersonPicture
export const adminUploadPersonPictureItem = createAsyncThunk(
  "user/adminUploadPersonPictureItem",
  async (payload, thunkAPI) => {
    let response = await adminUploadPersonPictureAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//adminUploadReferenceImage
export const adminUploadReferenceImageItem = createAsyncThunk(
  "user/adminUploadReferenceImageItem",
  async (payload, thunkAPI) => {
    let response = await adminUploadReferenceImageAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
