import React, { useState, useEffect, useCallback } from "react";
// import ToggleStatus from "react-toggle";
import { Redirect } from "react-router-dom";
import CreateModel from "./Modals/Create-campus-placement-Drive-model";
import LoginModal from "../../AuthPages/Login-modal";
import EditCampusDriveModel from "./Modals/Edit-campus-placement-drive-model";
// import SingleUploadModel from "./Modals/Single-Upload-student-placement-drive-model";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCampusListsItem,
  changeSkip,
  changeTestState,
  fillQuestionArray,
} from "../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";

//******************************************************** */
export const CampusPlacementDrive = () => {
  const dispatch = useDispatch();
  const [campusList, setCampusList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [lastId, setLastId] = useState("");

  const [searchQuery, setSearchQuery] = useState();
  const [click, setClick] = useState("");
  const [loading, setLoading] = useState(false);

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const commonSkip = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).skip;

  const [skip, setSkip] = useState(commonSkip);

  let history = useHistory();

  useEffect(() => {
    dispatch(
      changeTestState({
        passingPercentage: 0,
        testDate: "",
        startTime: "",
        endTime: "",
      })
    );
  }, []);
  

  useEffect(() => {
    if (!loggedIn) {
      return history.push('/');
    } 
    else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    }
     else if (loggedIn && usertype === "admin") {
      fetchCampusListsApi(searchQuery);
    }
  }, [skip]);

  useEffect(() => {
    // console.log("campusList: ", campusList);
    if (campusList.length > 0) {
      const lastElementId = campusList[campusList?.length - 1];
      // console.log("lastElementId: ", lastElementId._id);
      setLastId(lastElementId._id);
    }

    // if (campusList.length > limit) {
    //   setLimit(campusList.length);
    // }
  }, [campusList]);

  useEffect(() => {
    if (searchQuery || searchQuery == "") {
      // console.log("debounce");
      debouncedSave(fetchCampusListsApi, searchQuery);
    }
  }, [searchQuery]);

  const debouncedSave = useCallback(
    debounce(
      (fetchCampusListsApi, $searchQuery) => fetchCampusListsApi($searchQuery),
      1000
    ),
    []
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchCampusListsApi = async (searchQuery) => {
    try {
      setLoading(true);
      const payload = {
        limit,
        lastId: lastId ? lastId : "",
        click,
        skip,
        searchQuery,
      };
      // console.log("payload: ", payload);
      let response = await dispatch(fetchCampusListsItem(payload));

      if (response.payload.isSuccessful) {
        setLoading(false);
        setCampusList(response.payload.data);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };
  // if (!loggedIn) {
  //   console.log("omg");
  //   return <Redirect to="/"></Redirect>;
  // } 
  return (
    <div>
      <div className=" p-5 " style={{ marginTop: "70px" }}>
        <div className="row my-3">
          <div className="col-md-12">
            {" "}
            <h3 className="btn-secondary text-center p-2">
              Campus Placement Drive
            </h3>
          </div>
          <div className="mt-2">
            <CreateModel
              campusList={campusList}
              setCampusList={setCampusList}
              setLimit={setLimit}
              fetchCampusListsApi={fetchCampusListsApi}
            />
            {/* <LoginModal /> */}
          </div>
          <div className="mt-2">
            {" "}
            <input
              className="p-2"
              style={{ width: "350px" }}
              type="text"
              placeholder="search by collegename or campus code"
              // value={searchQuery}
              onChange={(e) => {
                handleSearchChange(e);
              }}
            />
          </div>
        </div>
        <div>
          <div className="col-md-12">
            <div className="table-responsive mt-2  ">
              <table className="table table-hover  ">
                <thead className="btn-secondary">
                  <th align="center" scope="col">
                    No
                  </th>
                  <th align="center" scope="col">
                    Registration link
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Campus
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Campus code
                  </th>
                  <th align="center" className="text-center" scope="col">
                    City
                  </th>{" "}
                  <th align="center" className="text-center" scope="col">
                    TPO Email
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Registration last date
                  </th>
                  <th align="center" className="text-center" scope="col">
                    View Emails
                  </th>
                  {/* <th align="center" className="text-center" scope="col">
                    Status
                  </th> */}
                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Test
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Results
                  </th>
                </thead>

                <tbody>
                  {campusList && campusList.length > 0
                    ? campusList.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td align="left">{skip + index + 1}</td>
                            <td align="left">
                              <span
                                // style={{ cursor: "pointer" }}
                                className="text-primary text-wrap text-break"
                                // onClick={() => {
                                //   if (item?.registrationLink)
                                //     return history.push(item?.registrationLink);
                                // }}
                              >
                                {item?.registrationLink
                                  ? item?.registrationLink
                                  : "--"}
                              </span>
                            </td>
                            <td align="center">{item?.collegeName}</td>
                            <td align="center">
                              {item?.campusCode ? item?.campusCode : "--"}
                            </td>
                            <td align="center">{item?.city}</td>
                            <td align="center">{item?.tpoEmail}</td>
                            <td align="center">{item?.registrationLastDate}</td>
                            <td align="center">
                              <button
                                className="btn btn-gray secondary"
                                onClick={() => {
                                  dispatch(changeSkip({ skip }));
                                  history.push({
                                    pathname:
                                      "/admin/campusPlacementDrive/studentEmails",
                                    state: {
                                      campusId: item?._id,

                                      skip,
                                    },
                                  });
                                }}
                              >
                                View
                              </button>
                            </td>
                            {/* <td align="center">
                              <ToggleStatus
                                defaultChecked={item?.campusStatus.status}
                                icons={false}
                                onClick={(e) => {
                                  // settoggle(true);
                                  // handleEditStatus(item._id, e.target.checked);
                                }}
                              />
                            </td> */}
                            <td align="center">
                              <EditCampusDriveModel
                                item={item}
                                setCampusList={setCampusList}
                                campusList={campusList}
                                fetchCampusListsApi={fetchCampusListsApi}
                              />
                            </td>
                            <td align="center">
                              <button
                                className="btn btn-primary mt-1"
                                onClick={() => {
                                  dispatch(changeSkip({ skip }));
                                  dispatch(
                                    changeTestState({
                                      passingPercentage: item?.passingPercentage
                                        ? item?.passingPercentage
                                        : "",

                                      testDate: item?.testDate
                                        ? item?.testDate
                                        : "",
                                      startTime: item?.startTimeSlotForTest
                                        ?.startTime
                                        ? item?.startTimeSlotForTest?.startTime
                                        : "",
                                      endTime: item?.startTimeSlotForTest
                                        ?.endTime
                                        ? item?.startTimeSlotForTest?.endTime
                                        : "",
                                    })
                                  );

                                  dispatch(
                                    fillQuestionArray({
                                      questionIds: item?.questionIds
                                        ? item?.questionIds
                                        : [],
                                    })
                                  );
                                  history.push({
                                    pathname:
                                      "/admin/campus/placement/drive/create/test",
                                    state: {
                                      campusId: item?._id,
                                      registrationLastDate:
                                        item?.registrationLastDate,

                                      action: "ADD",
                                    },
                                  });
                                }}
                              >
                                {" "}
                                Create test
                              </button>
                            </td>
                            <td align="center">
                              <button
                                className="btn btn-primary mt-1"
                                onClick={() => {
                                  dispatch(changeSkip({ skip }));
                                  history.push({
                                    pathname:
                                      "/admin/campus/placement/drive/results",
                                    state: {
                                      campusId: item?._id,
                                      campusCode: item?.campusCode,
                                    },
                                  });
                                }}
                              >
                                Get results
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : "No data"}
                </tbody>
              </table>
            </div>
          </div>{" "}
          <div class="row mb-2">
            <div class="col-md-12 text-center m-auto">
              <button
                style={{ backgroundColor: "#1D3883" }}
                className="btn text-white m-1"
                disabled={skip === 0}
                onClick={() => {
                  setClick("prev");
                  setSkip((preVal) => {
                    return preVal - limit;
                  });
                }}
              >
                {loading && click === "prev" ? "Prev.." : "Prev"}
                {loading && click === "prev" ? (
                  <span
                    id="studentTop"
                    class="spinner-grow spinner-grow-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>

              <button
                style={{ backgroundColor: "#1D3883" }}
                className="btn text-white m-1"
                disabled={campusList.length === 0 || campusList.length < limit}
                onClick={() => {
                  setClick("next");
                  setSkip((preVal) => {
                    return preVal + limit;
                  });
                }}
              >
                {loading && click === "next" ? "Next.." : "Next"}
                {loading && click === "next" ? (
                  <span
                    class="spinner-grow spinner-grow-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
