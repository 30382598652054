import React, { useState } from "react";
import Modal from "react-modal";
// import { deleteActionItem } from "../../../../data/reducers/referral.reducer";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { projectByIdItem } from "../../../data/reducers/student.reducer";


const Model = ({ _id, endpoint, project }) => {

  //
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [shareLink, setShareLink] = useState(`${endpoint}/project/${_id}`);
  const [copied, setCopied] = useState(false);
  const [loading, setloading] = useState(false);

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <i

        style={{pointerEvents:loading && "none"}}
        data-toggle="tooltip"
        data-placement="top"
        title="Share project"
        // style={{color:"#1D3883"}}
        onClick={async () => {
          try {
            setloading(true);
            const response = await dispatch(projectByIdItem(project._id));
            // console.log('projresponse: ', response);
            if (response.payload.isSuccessful) {
              setloading(false);
              const { isApproved } = response.payload.data;
              return isApproved
                ? OpenModal()
                : toast.warning("Can't share project, pending admin approval!");
            }
            return setloading(false);
          } catch (error) {}
        }}
        className="fa fa-share font-20  ml-3 cursor-pointer text-l-blue"

      />
      {loading ? (
        <span
          class="spinner-border spinner-border-sm ml-1"
          role="status"
          aria-hidden="true"
        ></span>
      ) : null}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
            width: "inherit",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center p-3">
            <div className="col-sm-12">
              <div className="mb-3">
                <input
                  disabled
                  //   onChange={handleAddChange}
                  type="text"
                  className="form-control"
                  name="share"
                  value={shareLink}
                />
              </div>
              <div className="col-sm-12  p-0">
                <button
                  className="btn text-white "
                  style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    closeModal();
                    setCopied(false);
                  }}
                >
                  Cancel
                </button>

                <CopyToClipboard
                  text={shareLink}
                  onCopy={() => setCopied(true)}
                >
                  <button
                    className="btn m-1 text-white"
                    style={{ backgroundColor: "#C82333" }}
                  >
                    {copied ? (
                      <span style={{ color: "white" }}>Copied.</span>
                    ) : (
                      "Copy"
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Model;
