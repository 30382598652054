import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WorkshopComp1 from "./Workshop1";
import { useDispatch } from "react-redux";
import { generateCertificateItem } from "../../data/reducers/student.reducer";
import { toast } from "react-toastify";
import TrainingCertificate from "./Training";

const CommonWorkShopComponent = () => {
  const dispatch = useDispatch();

  const search = useLocation().search;

  //extracting data from query
  const userId = new URLSearchParams(search).get("userId");
  // const dynamicFields = new URLSearchParams(search).get("dynamicFields");
  const eventId = new URLSearchParams(search).get("eventId");
  // const templateName = new URLSearchParams(search).get("templateName");
  // const studentName = new URLSearchParams(search).get("studentName");

  const [certificateData, setCertificateData] = useState();

  useEffect(() => {
    generateCertificate();
  }, [eventId]);

  const generateCertificate = async () => {
    try {
      const payload = {
        userId,
        // dynamicFields,
        eventId,
        // templateName,
        // studentName,
      };

      const response = await dispatch(generateCertificateItem(payload));

      if (response.payload.isSuccessful) {
        setCertificateData(response.payload.data);
        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        return;
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  {
    // <TrainingCertificate item={certificateData} />
  }
  return (
    <div style={{ marginTop: "100px" }}>
      {certificateData?.templateName === "workshop1" ? (
        <WorkshopComp1 item={certificateData} />
      ) : certificateData?.templateName === "Training" ? (
        <TrainingCertificate item={certificateData} />
      ) : (
        "No data"
      )}
    </div>
  );
};

export default CommonWorkShopComponent;
