import {
  api,
  getAuthHeaders,
  post,
  put,
  putFormData,
} from "../../services.common";

//fetch question
export const getQuestionListsAPI = async (payload) => {
  let url = `${api}/admin/questions`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//add question
export const addQuestionAPI = async (payload) => {
  let url = `${api}/admin/question/add`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

//edit question
export const editQuestionAPI = async (payload) => {
  let url = `${api}/admin/question/edit`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};
