import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  studentListAPI,
  studentAPI,
  // editprofileAPI,
  projectAPI,
  studentByOwnerIdAPI,
  // hrHireStudentAPI,
  getProjectByIdAPI,
  generateCertificateAPI,
  createRazorPayOrderAPI,
} from "../services/student.service";

//create order for razor pay
export const createRazorPayOrderItem = createAsyncThunk(
  "student/createRazorPayOrderItem",
  async (payload, thunkAPI) => {
    let response = await createRazorPayOrderAPI(payload);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//gen certificate
export const generateCertificateItem = createAsyncThunk(
  "student/generateCertificateItem",
  async (payload, thunkAPI) => {
    let response = await generateCertificateAPI(payload);
    // console.log('response: ', response.data);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const studentListItem = createAsyncThunk(
  "student/studentListItem",
  async (payload, thunkAPI) => {
    let response = await studentListAPI(payload);
    // console.log('response: ', response.data);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const studentItem = createAsyncThunk(
  "student/studentItem",
  async (payload, thunkAPI) => {
    let response = await studentAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const projectItem = createAsyncThunk(
  "student/projectItem",
  async (payload, thunkAPI) => {
    let response = await projectAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const profileByProjectItem = createAsyncThunk(
  "student/profileByProjectItem",
  async (payload, thunkAPI) => {
    let response = await studentByOwnerIdAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get project by id open api
export const projectByIdItem = createAsyncThunk(
  "student/projectByIdItem",
  async (payload, thunkAPI) => {
    let response = await getProjectByIdAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

const userSlice = createSlice({
  name: "student",
  initialState: {
    studentInfo: [],
    singleStudent: [],
    particularStudent: "",
    studentProfileEdit: [],
    length: "",
    projects: [],
    hireable: null,
    singleProject: {},
    projectSkip: 0,
    studentPageSkip: 0,
  },
  reducers: {
    changeProjectSkip: (state, action) => {
      // console.log("action.payload: ", action.payload);
      state.projectSkip = action?.payload?.skip;
    },
    changeStudentPageSkip: (state, action) => {
      // console.log("action.payload: ", action.payload);
      state.studentPageSkip = action?.payload?.skip;
    },
  },
  extraReducers: {
    [studentListItem.fulfilled]: (state, action) => {
      state.length = action.payload.data.length;
      state.studentInfo = action.payload.data;
    },
    [studentItem.fulfilled]: (state, action) => {
      if (action.payload.data) {
        //
        // state.particularStudent = action.payload;
        // state.singleStudent.push(action.payload);
        localStorage.setItem(
          "particularStudent",
          JSON.stringify(action.payload.data)
        );
        state.particularStudent = JSON.parse(
          localStorage.getItem("particularStudent")
        );
        // console.log(action.payload.data);
      }
    },
    [projectItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      // if (action.payload) {

      state.projects = action.payload.data;

      // } else {
      //
      // }
    },
    [profileByProjectItem.fulfilled]: (state, action) => {
      // if (action.payload) {
      //   // state.projects = action.payload;
      //   //
      // } else {
      //
      // }
    },
    [projectByIdItem.fulfilled]: (state, action) => {
      state.singleProject = action.payload.data;
    },
  },
});
export const { changeProjectSkip, changeStudentPageSkip } = userSlice.actions;
export default userSlice.reducer;
