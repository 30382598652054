import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { revokeBadgeAdminItem } from "../../../../data/reducers/admin/badges/badges-admin.reducer";

const Model = ({ item, editedUser, loadData }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [description, setdescription] = useState("");
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleChange = (e) => {
    setdescription(e.target.value);
  };

  //revoke and unrevoke badge
  const revokeBadge = async (status) => {
    const { _id } = editedUser;
    const { userType } = editedUser.owner;
    const badge = item._id;
    const badgeName = item.badge.name;
    const payload = { _id, userType, badge, description, status, badgeName };
    // console.log("payload: ", payload);

    try {
      setloader(true);
      const response = await dispatch(revokeBadgeAdminItem(payload));
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        loadData();
        setloader(false);
        closeModal();

        return toast.success(
          item.revokedStatus.isActive === false
            ? "Revoked successfully!"
            : "Un-revoked successfully!"
        );
      }
      setloader(false);
      // console.log('response.payload.message: ', response.payload.message);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  return (
    <div className="container">
      {/* "fa fa-times-circle text-danger position-absolute" */}
      <i
        onClick={() => OpenModal()}
        data-toggle="tooltip"
        data-placement="top"
        title={item.revokedStatus.isActive === false ? "Revoke" : "Un-revoked"}
        className={
          item.revokedStatus.isActive === false
            ? "fa fa-times-circle text-danger position-absolute"
            : "fa fa-plus-circle text-danger position-absolute"
        }
      ></i>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center my-4">
                <i
                  style={{ color: "#dc3545" }}
                  className="fa fa-exclamation-circle fa-3x mr-3"
                ></i>
                <h3 className="text-center">
                  {item.revokedStatus.isActive === false
                    ? "Why are you revoking this badge?"
                    : "Why are you un-revoking this badge?"}
                </h3>
              </div>
            </div>
            <div className="col-12">
              <div className="text-center ">
                <textarea
                  className="p-3 border-black"
                  placeholder="Add a small description.."
                  onChange={handleChange}
                  value={description}
                />
              </div>
            </div>
            <div className="col-12 text-center mt-2">
              <button
                disabled={loader || description === ""}
                className="btn btn-danger mr-3 mb-2"
                onClick={() => {
                  {
                    item.revokedStatus.isActive === false
                      ? revokeBadge(true)
                      : revokeBadge(false);
                  }
                }}
              >
                Yes{" "}
                {loader && (
                  <span
                    class="spinner-border ml-1 spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              <button
                className="btn text-white mr-3 mb-2"
                style={{ backgroundColor: "#5A6268" }}
                onClick={closeModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
