import React, { useState } from "react";
import Modal from "react-modal"
import {useDispatch} from 'react-redux' ;
import {deleteBatchItem} from '../../../../data/reducers/admin/batches/batches-admin.reducer' ;
import {toast} from 'react-toastify' ;

//logout modal
const DeleteBatchModal = ({ batchId }) => {

    const dispatch = useDispatch();

    const [modalIsOpen, setIsOpen] = useState(false);
    Modal.setAppElement('#root');

    function OpenModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const removeBatch = async (_id) => {
        const response = await dispatch(deleteBatchItem({ _id }));
        if (response.payload.isSuccessful) {
            return toast.success('Batch deleted successfully');
        }
        toast.error(response.payload.message.data.message);
    }



    return (

        <div className="p-0">
            <div className="text-danger cursor-pointer font-size-14" onClick={(e) => {
                    OpenModal();
                    e.stopPropagation()
                }}>
                <i className='fa fa-trash mr-1'  data-toggle="tooltip" title="Delete Batch" data-placement="bottom"></i>Delete
            </div>
            <Modal
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        zIndex: 1,

                    },
                    content: {
                        color: 'orange',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#f5f5f5',
                        flexDirection: 'column',
                    }

                }}

            >
                <p style={{ fontWeight: 'bold' }}>Are you sure want to Delete this Upcoming Batch ?</p>
                <div  >
                    <button className="btn btn-dark " style={{ margin: '3px' }} name="action" onClick={(e) => { e.stopPropagation(); closeModal(); }}>No
                    </button>
                    <button className="btn btn-dark " style={{ margin: '3px' }} name="action" onClick={(e) => { e.stopPropagation(); removeBatch(batchId); closeModal() }}>Yes
                </button>
                </div>
            </Modal>
        </div>

    );
}

export default DeleteBatchModal;
