import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, NavLink } from "react-router-dom";
import {
  adminSendBulkEmailItem,
  fetchCampusListsForEmailsItem,
} from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import EmailConfirmationModal from "../../CampusPlacementDrive/Modals/Email-confirm.modal";
import ConfirmationModal from "../../../../shared/components/confirmation-modal/Confirmation-modal";
import { toast } from "react-toastify";
import {
  getEventsItem,
  adminSendCustomBulkEventEmailItem,
} from "../../../../data/reducers/admin.reducer";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const CommonEmailTemplate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const initialParam =
    props?.location?.aboutProps?.comingFrom === "CAMPUS"
      ? {
          campusDrive: "",
          subject: "",
          body: EditorState.createEmpty(),
          emailList: "",
          isEmailType: "",
          appLink: "",
        }
      : {
          eventId: "",
          subject: "",
          body: EditorState.createEmpty(),
          emailList: "",
        };
  const [emailData, setemailData] = useState(initialParam);

  const [loading, setLoading] = useState(false);
  const [emailType, setEmailType] = useState("");

  const [campusList, setCampusList] = useState([]);
  const [eventList, setEventList] = useState([]);

  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    if (!loggedIn) {
      return history.push("/");
      // return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      if (props?.location?.aboutProps?.comingFrom === "CAMPUS") {
        fetchCampusListsApi();
      } else {
        fetchEventListsApi();
      }
    }
  }, []);


  useEffect(() => {
    if (props?.location?.aboutProps?.comingFrom === "CAMPUS") {
      if (campusList.length !== 0) {
        setSelectOptions(() => {
          return campusList.map((item) => {
            return { value: item?._id, label: item?.collegeName };
          });
        });
      }
    } else {
      if (eventList.length !== 0) {
        setSelectOptions(() => {
          return eventList.map((item) => {
            return { value: item?._id, label: item?.eventTitle };
          });
        });
      }
    }
  }, [campusList, eventList]);

  var fetchCampusListsApi = async () => {
    try {
      setLoading(true);
      const payload = {};
      // console.log("payload: ", payload);
      let response = await dispatch(fetchCampusListsForEmailsItem(payload));
      // console.log('response =: ', response );

      if (response.payload.isSuccessful) {
        setLoading(false);
        setCampusList(response.payload.data);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  var fetchEventListsApi = async () => {
    try {
      setLoading(true);
      const payload = { callingFor: "BULK_EMAIL" };
      // console.log("payload: ", payload);
      let response = await dispatch(getEventsItem(payload));
      // console.log('response =: ', response );

      if (response.payload.isSuccessful) {
        setLoading(false);
        setEventList(response.payload.data);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setemailData({ ...emailData, [name]: value });
  };

  //send email function
  const adminSendBulkEmailApi = async (closeModal) => {
    try {
      setLoading(true);
      const x = draftToHtml(convertToRaw(emailData?.body.getCurrentContent()));
      const payload =
        props?.location?.aboutProps?.comingFrom === "CAMPUS"
          ? { ...emailData, emailType, body: x }
          : { ...emailData, emailType, body: x };
      console.log("payload: ", payload);

      let response =
        props?.location?.aboutProps?.comingFrom === "CAMPUS"
          ? await dispatch(adminSendBulkEmailItem(payload))
          : await dispatch(adminSendCustomBulkEventEmailItem(payload));
      // console.log('response =: ', response );

      if (response.payload.isSuccessful) {
        toast.success("Sent successfully");
        setLoading(false);
        closeModal();
        return;
      } else if (!response.payload.isSuccessful) {
        setLoading(false);
        toast.error(response.payload && response.payload.message.data.message);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <section style={{ marginTop: "110px" }}>
      <div className="container h-100">
        <div className="row justify-content-center h-100">
          <div className="col-md-10 col-lg-10 h-100 d-flex justify-content-center align-items-center">
            <div className="  col-md-10 col-lg-7 p-3 bg-white  p-md-4 h-100">
              <h3 className="text-center font-weight-bold mb-4 text-dark">
                Send Emails
              </h3>
              <form className="bg-light h-100  border p-5 rounded-lg  ">
                <label
                  for="exampleInputEmail1"
                  className=" text-dark font-weight-bold"
                >
                  <i
                    className="fas fa-tag text-dark mr-1"
                    style={{ fontSize: 14 }}
                  ></i>{" "}
                  Choose email type
                </label>
                <Select
                  // value={selectedOption}
                  onChange={(e) => {
                    setEmailType(e.value);
                  }}
                  options={[
                    {
                      value: "SINGLE_EMAIL",
                      label: "SINGLE EMAIL SEND",
                    },
                    {
                      value: "BULK_EMAIL",
                      label: "BULK EMAIL SEND",
                    },
                  ]}
                />

                {emailType === "SINGLE_EMAIL" && (
                  <div className="form-group mt-4">
                    <label
                      for="exampleInputEmail1"
                      className=" text-dark font-weight-bold"
                    >
                      <i
                        className="fas fa-tag text-dark mr-1"
                        style={{ fontSize: 14 }}
                      ></i>{" "}
                      Emails{" "}
                      <span className="text-muted">
                        *(Comma separated emails)
                      </span>
                    </label>
                    <input
                      type="text"
                      name="emailList"
                      placeholder="abc@example.com,abc2@example.com"
                      onChange={handleChange}
                      className={`form-control`}
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}
                {props?.location?.aboutProps?.comingFrom === "CAMPUS" ? (
                  <>
                    {" "}
                    <label
                      for="exampleInputEmail1"
                      className=" text-dark font-weight-bold mt-4"
                    >
                      <i
                        className="fas fa-tag text-dark mr-1"
                        style={{ fontSize: 14 }}
                      ></i>{" "}
                      Choose campus{" "}
                    </label>
                    <Select
                      // value={selectedOption}
                      onChange={(e) => {
                        setemailData({
                          ...emailData,
                          ["campusDrive"]: e.value,
                        });
                        setSelectedOption({ e });
                      }}
                      options={selectOptions}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <label
                      for="exampleInputEmail1"
                      className=" text-dark font-weight-bold mt-4"
                    >
                      <i
                        className="fas fa-tag text-dark mr-1"
                        style={{ fontSize: 14 }}
                      ></i>{" "}
                      Choose event{" "}
                    </label>
                    <Select
                      // value={selectedOption}
                      onChange={(e) => {
                        setemailData({
                          ...emailData,
                          ["eventId"]: e.value,
                        });
                        setSelectedOption({ e });
                      }}
                      options={selectOptions}
                    />
                  </>
                )}
                {props?.location?.aboutProps?.comingFrom === "CAMPUS" ? (
                  <>
                    <label
                      for="exampleInputEmail1"
                      className=" text-dark font-weight-bold mt-2"
                    >
                      <i
                        className="fas fa-tag text-dark mr-1"
                        style={{ fontSize: 14 }}
                      ></i>{" "}
                      Sending
                    </label>
                    <Select
                      // value={selectedOption}
                      onChange={(e) => {
                        setemailData({
                          ...emailData,
                          ["isEmailType"]: e.value,
                        });
                      }}
                      options={
                        emailType === "BULK_EMAIL"
                          ? [
                              {
                                value: "APP_LINK",
                                label: "APP LINK (REGISTERED USERS)",
                              },

                              {
                                value: "TEST_RESULTS",
                                label: "TEST RESULTS (PASSED STUDENTS)",
                              },
                              {
                                value: "CUSTOM_ALL",
                                label: "CUSTOM (NON-REGISTERED USERS)",
                              },
                              {
                                value: "CUSTOM_REGISTERED",
                                label: "CUSTOM (REGISTERED USERS)",
                              },
                            ]
                          : [
                              {
                                value: "APP_LINK",
                                label: "APP LINK (REGISTERED USERS)",
                              },

                              {
                                value: "CUSTOM_ALL",
                                label: "CUSTOM (NON-REGISTERED USERS)",
                              },
                              {
                                value: "CUSTOM_REGISTERED",
                                label: "CUSTOM (REGISTERED USERS)",
                              },
                            ]
                      }
                    />
                  </>
                ) : (
                  ""
                )}

                <div className="form-group mt-4">
                  <label
                    for="exampleInputEmail1"
                    className=" text-dark font-weight-bold"
                  >
                    <i
                      className="fas fa-tag text-dark mr-1"
                      style={{ fontSize: 14 }}
                    ></i>{" "}
                    Subject{" "}
                  </label>
                  <input
                    type="text"
                    name="subject"
                    onChange={handleChange}
                    className={`form-control `}
                    aria-describedby="emailHelp"
                  />
                </div>
           
                <div className="form-group">
                  <label
                    for="exampleFormControlTextarea1"
                    className=" text-dark font-weight-bold"
                  >
                    <i
                      className="fas fa-comment-alt text-dark mr-1"
                      style={{ fontSize: 14 }}
                    ></i>{" "}
                    Body
                  </label>
                  <Editor
                    editorState={emailData?.body}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) => {
                      setemailData({ ...emailData, body: e });
                    }}
                  />
                </div>
                <div className=" pb-2">
                  <ConfirmationModal
                    commonLoader={loading}
                    callingFunction={adminSendBulkEmailApi}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonEmailTemplate;
