import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteEventItem } from "../../../data/reducers/admin.reducer";
import ToggleStatus from "react-toggle";

const Model = ({ item, getEvents }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [eventStatus, setEventStatus] = useState();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  function OpenModal() {
    setIsOpen(true);
  }
  Modal.setAppElement("#root");
  function closeModal() {
    setIsOpen(false);
  }

  const changeEventStatusaApi = async () => {
    const payload = { eventStatus, eventId: item?._id };
    // console.log("payload: ", payload);
    try {
      setLoader(true);
      const response = await dispatch(deleteEventItem(payload));
      if (response.payload.isSuccessful) {
        setLoader(false);
        getEvents();
        toast.success("Changed Successfully");
        closeModal();
        return;
      } else if (!response.payload.isSuccessful) {
        setLoader(false);
        return toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log("error: ", error);
    }
  };

  return (
    <>
      <ToggleStatus
        onClick={OpenModal}
        checked={item?.eventIsActive}
        icons={false}
        // name="eventIsActive"
        onChange={(e) => {
          setEventStatus(e.target.checked);
        }}
      />

      <div className="container">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 1,
            },
            content: {
              color: "#1D3883",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "between",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              flexDirection: "column",
            },
          }}
        >
          <center>
            <h4>Are you sure ?</h4>
          </center>
          <div className="row w-100 d-flex justify-content-center">
            <div className="col-5 d-flex justify-content-around">
              <button
                className="btn  m-1 btn-primary"
                onClick={changeEventStatusaApi}
              >
                Yes
                {loader && (
                  <span
                    class="spinner-border ml-1 spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              <button className="btn m-1 btn-danger" onClick={closeModal}>
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Model;
