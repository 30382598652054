import React, { useEffect, useState, useMemo, useCallback } from "react";
import { constants } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import RequestCallbackModal from "../Homepage/Components/Modal/Request.Callback";
import {
  profileByProjectItem,
  projectItem,
} from "../../data/reducers/student.reducer";
import { Redirect, useHistory } from "react-router-dom";
import Background from "../../assets/images/abtbg.png";
import { Link } from "react-router-dom";
import Loader from "../../shared/components/Loaders/Loader";
import debounce from "lodash.debounce";
import Search from "../Admin/Shared/Search/Search";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { contestListAdminItem } from "../../data/reducers/admin.reducer";
import { changeProjectSkip } from "../../data/reducers/student.reducer";
import { contestSubmissionItem } from "../../data/reducers/auth.reducer";
import { professionalByProjectItem } from "../../data/reducers/professional.reducer";
import { Element, scroller } from "react-scroll";
import ReactReadMoreReadLess from "react-read-more-read-less";
import noProject from "../../assets/images/search1.jpg";
const endPointReact = constants.endPointFrontend;

//timeline
function Timeline() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const user = useSelector((state) => state.authReducer).userInfo;

  const wantACallbackFlag = useSelector(
    (state) => state.authReducer
  ).wantACallback;

  const userType = useSelector((state) => state.authReducer).userType;
  const [shareLink, setShareLink] = useState("");
  const [searchQuery, setSearchQuery] = useState();
  const [copied, setCopied] = useState(false);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const [totalItems, setTotalItems] = useState(0);
  const contestList = useSelector((state) => state.adminReducer).contestList;

  const [githubUrl, setGithubUrl] = useState("");
  const [contestItem, setContestItem] = useState(null);
  const [image, setImage] = useState("");
  // const [imageContest, setImageContest] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [disabledGithub, setDisabledGithub] = useState(true);
  const [projects, setProjects] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [idd, setIdd] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const ITEMS_PER_PAGE = 6;
  const [read, setread] = useState(false);
  const [index1, setIndex1] = useState(null);
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const projectSkip = useSelector((state) => state.studentReducer).projectSkip;

  const [skip, setSkip] = useState(projectSkip ? projectSkip : 0);
  const [click, setClick] = useState("");
  const [toggleView, setToggleView] = useState("typ");

  useEffect(() => {
    proj();
    // reqFunc();
    contestApi();
    setSubmitDisabled(true);
  }, [user]);

  useEffect(async () => {
    dispatch(changeProjectSkip({ skip }));
    proj(searchQuery);
  }, [toggleView, skip]);

  useEffect(() => {
    if (searchQuery != undefined || searchQuery == "") {
      // console.log("debounce");
      dispatch(changeProjectSkip({ skip }));
      debouncedSave(proj, searchQuery);
    }
  }, [searchQuery]);

  const debouncedSave = useCallback(
    debounce((proj, $searchQuery) => proj($searchQuery), 1000),
    []
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [field, setField] = useState("");

  const handleToggleView = (view) => {
    if (view === "typ") {
      document.getElementById("nontyp").classList.remove("active");
      document.getElementById(view).classList.add("active");
      setSkip(0);
    } else if (view === "nontyp") {
      document.getElementById(view).classList.add("active");
      document.getElementById("typ").classList.remove("active");
      setSkip(0);
    }

    setToggleView(view);
  };
  const share = (field) => {
    setField(field);
    const id = user._id;
    const typId = user.owner.typId;
    const link = `${endPointReact}/timeline/?cta=${field}&typId=${typId}&redirectURL=/timeline`;
    setShareLink(link);
    setCopied(false);
  };

  const contestApi = async () => {
    try {
      // setLoading(true);
      let response = await dispatch(contestListAdminItem());
      // setValue(true);
      // setContests(contest);
    } catch (error) {}
  };

  //fetching all projects
  const proj = async (searchQuery) => {
    setLoader(true);
    try {
      // setLoading(true);
      let response = await dispatch(
        projectItem({ limit, skip, toggleView, searchQuery })
      );
      // console.log("response: ", response);
      if (response.payload.isSuccessful) {
        scroller.scrollTo("test1", {
          duration: 10,
          delay: 0,
          smooth: "linear",
        });
        setLoader(false);
        return setProjects(response.payload.data);
      }

      setLoader(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {}
  };

  //fetching single student profile
  const singleStudent = async (id, usertypee) => {
    // console.log(usertypee);
    if (usertypee === "student") {
      // console.log("i m in student");
      try {
        let response = await dispatch(profileByProjectItem(id));
        setIdd(response.payload.data._id);
        let path = `/profile/` + response.payload.data._id;

        history?.push(path);
      } catch (error) {}
    } else if (usertypee === "professional") {
      try {
        // console.log("i m in professional");
        let response = await dispatch(professionalByProjectItem(id));
        setIdd(response.payload.data._id);
        let path = `/ProfessionalProfile/` + response.payload.data._id;

        history?.push(path);
      } catch (error) {}
    }
    setIdd();
  };

  const participantData = async (contestType) => {
    // const res1 = checkEmail(contestData.email);
    // if (!res1) {
    //   return toast.error("Email Invalid")
    // };
    // const res = checkNumber(contestData.phoneNumber);
    // if (!res) {
    //   return toast.error("Phone Number Invalid")
    // };
    let payload;
    if (contestType == "code") {
      payload = {
        url: githubUrl,
        id: contestItem._id,
        userId: user.owner._id,
      };
    } else {
      payload = {
        url: image,
        id: contestItem._id,
        userId: user.owner._id,
      };
    }
    try {
      let response = await dispatch(contestSubmissionItem(payload));
      // if (response.isSuccessful) {
      toast.success("Contest Submitted SuccessFuly");
      // }
    } catch (error) {}
    // setContestData({
    //   fullName: "",
    //   email: "",
    //   phoneNumber: "",
    //   address: "",
    // });
    setImage("");
    setGithubUrl("");
  };

  // const [arr1, setArr] = useState(user?.owner?.contests?.map((item) => item));
  // console.log("hello");
  const arr1 = user?.owner?.contests?.map((item) => item._id);
  // const arr2 = arr1?.map((item) => item._id);

  const uploadImage = async (e) => {
    // setImageLoader(true);
    setDisabledGithub(true);

    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "typ-image");
    setLoading(true);
    const res = await fetch(
      "	https://api.cloudinary.com/v1_1/projectyouth/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setImage(file.secure_url);
    setLoading(false);
    setSubmitDisabled(false);
    setDisabledGithub(false);
    setShow(true);
  };

  const handleGithubChange = (e) => {
    setGithubUrl(e.target.value);
    setSubmitDisabled(false);
    setShow(true);
    setDisabledGithub(false);
  };
  const filterContest = contestList?.filter((item) => item.isActive === true);
  const filterDate = filterContest?.filter(
    (item) => new Date(item.end_date).toJSON() > new Date().toJSON()
  );
  // console.log(filterDate);
  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <>
      <section
        className="innerbanner "
        style={{
          background: `url(${Background}) top center no-repeat`,
          marginTop: "70px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1 className="display-4 K-font Fweight-500">Student Projects</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/student/list">Student</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Projects
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* {console.log("wantACallback : ", wantACallback)} */}
      </section>
      {
        //add condition for render
        wantACallbackFlag && userType == "student" ? (
          <RequestCallbackModal />
        ) : null
      }
      <section className="projectlist">
        <Element name="test1" className="element" />
        <div className="container">
          <div className="row py-5 mt-lg-5">
            <div className="col-lg-8 pr-lg-5 order-lg-0 order-1">
              <div className="row mb-2">
                <ul className="nav nav-tabs">
                  <li className="nav-item ">
                    <a
                      id="typ"
                      className="active nav-link cursor-pointer mb-0
                      "
                      style={{ color: "black" }}
                      onClick={() => {
                        handleToggleView("typ");
                      }}
                    >
                      TYP-STUDENTS
                    </a>
                  </li>

                  <li className="nav-item ">
                    <a
                      id="nontyp"
                      className="nav-link cursor-pointer mb-0
                      "
                      style={{ color: "black" }}
                      onClick={() => {
                        dispatch(changeProjectSkip({ skip: 0 }));
                        handleToggleView("nontyp");
                      }}
                    >
                      NON TYP-STUDENTS
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex text-center">
                {
                  // loader ? (
                  //   <Loader />
                  // ) :

                  projects?.length == 0 ? (
                    <div
                      data-aos="zoom-out"
                      data-aos-duration="2000"
                      className=""
                    >
                      <img
                        src={noProject}
                        style={{ height: "150px", width: "150px" }}
                      ></img>
                      <h3>No Project Found!</h3>
                    </div>
                  ) : null
                }
              </div>

              <div>
                {projects?.length > 0 &&
                  projects?.map((project, i) => (
                    <div className="card mb-3">
                      <div className="d-flex align-items-center">
                        <>
                          <div
                            className="profile-imgCont rounded-circle  m-2 flex-shrink-0 border"
                            style={{
                              backgroundImage: `url(${project?.ownerField?.profilePic})`,
                            }}
                          ></div>
                        </>
                        <>
                          <div className="w-100">
                            <div className="">
                              <p className="my-auto d-flex flex-column">
                                <Link
                                  to=""
                                  style={{
                                    fontSize: "1.1rem",
                                    fontWeight: "500",
                                  }}
                                  onClick={() => {
                                    singleStudent(
                                      project?.ownerField?._id,
                                      project?.ownerField?.userType
                                    );
                                  }}
                                  className=" text-decoration-none font-weight-bold text-dark text-dark text-capitalize"
                                >
                                  {project?.ownerField?.firstName}{" "}
                                  {project?.ownerField?.lastName}
                                </Link>
                                <p className="font-size-14 mb-0">
                                  {" "}
                                  <i className="fa fa-calendar"></i>{" "}
                                  {new Date(
                                    project.createdAt
                                  ).toLocaleDateString()}
                                </p>
                              </p>
                            </div>
                            {/* {userType === "recruiter" && (
                              <button
                                onClick={() =>
                                  hireme(i, project?.owner?._id,)
                                }
                                className="btn btn-success mr-2"
                                style={{ float: "right" }}
                              >
                                Hire Me!
                              </button>
                            )} */}
                          </div>
                          <br />
                        </>
                      </div>

                      {project?.video ? (
                        // <ReactPlayer
                        //   width="99%"
                        //   controls
                        //   className="card-img-top"
                        //   url={project.video}
                        //   alt="Card image cap"
                        // />
                        <div className="embed-responsive embed-responsive-16by9">
                          <video
                            className="embed-responsive-item"
                            controls
                            autoPlay={false}
                            muted
                            src={project?.video}
                            allowfullscreen
                          ></video>
                        </div>
                      ) : (
                        <p style={{ color: "red" }}>No video uploaded yet!</p>
                      )}
                      <div className="card-body ">
                        <div className="datebar  d-flex justify-content-between mb-3">
                          <span className="leftside text-break">
                            <h4>{project?.title}</h4>
                          </span>
                          {/* <span className="rightside">
                            <i className="fa fa-eye"></i>
                            {project.views}
                          </span> */}
                        </div>

                        {project?.genre.map((gen) => (
                          <span
                            style={{
                              backgroundColor: "lightgray",
                              whiteSpace: "pre-wrap",
                            }}
                            className=" badge  p-2 mr-2 mb-2  text-dark font-weight-bold mb-2 text-break rounded font-size-12 word-break"
                          >
                            {gen}
                          </span>
                        ))}
                        <br />
                        <ReactReadMoreReadLess
                          charLimit={100}
                          readMoreText={
                            <button className="btn btn-warning text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold">
                              Read more
                            </button>
                          }
                          readLessText={
                            <button className="btn btn-warning text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold">
                              Read less
                            </button>
                          }
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          {project?.description}
                        </ReactReadMoreReadLess>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-4 order-lg-1 order-0 mb-3 mb-lg-0">
              <h6 className="border-bottom pb-3 font-weight-bold mb-4">
                Search
              </h6>
              <div className="input-group input-group-lg search">
                <label className="sr-only" for="inlineFormInputGroupUsername">
                  Search
                </label>
                {/* <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control rounded-0 border-0"
                  placeholder="Search by email or project title"
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                />
              </div>
              {userType === "student" && (
                <div>
                  {filterDate?.length > 0 && (
                    <h6 className="border-bottom pb-3 font-weight-bold my-4 mt-xl-5">
                      Active Contests
                    </h6>
                  )}

                  {/* <section className="upcoming_batch"> */}
                  {filterContest?.length > 0
                    ? filterContest?.map((item) => (
                        <>
                          {new Date(item.end_date).toJSON() >
                            new Date().toJSON() && (
                            <div className="col-lg-12 col-md-12 mb-4 mb-lg-4">
                              <div className="card card-hvr shadow">
                                <div
                                  className="card-header"
                                  style={{
                                    background: `url(${item.img}) no-repeat center top  / cover `,
                                    height: "200px",
                                  }}
                                ></div>

                                <div className="card-body">
                                  <h4 className="text-warning online-class text-uppercase font-weight-bold font-size-16 text-secondary mb-0">
                                    {item.title}
                                  </h4>
                                  <h6 className="py-2 text-warning mb-0">
                                    <p className="text-primary pt-1 m-0 font-size-12">
                                      {" "}
                                      Starting From :{" "}
                                      {new Date(item.start_date).toDateString()}
                                    </p>{" "}
                                    <p className="text-primary pt-1 m-0 font-size-12">
                                      Deadline :{" "}
                                      {new Date(item.end_date).toDateString()}
                                    </p>
                                  </h6>
                                  <p className="text-uppercase font-weight-bold font-size-12 text-secondary">
                                    {item?.description?.slice(0, 80)} . . .
                                  </p>

                                  {!arr1?.includes(item._id) ? (
                                    <button
                                      data-toggle="modal"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                      data-target="#Participate"
                                      onClick={() => setContestItem(item)}
                                      className="btn btn-secondary mr-2 text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold mt-2"
                                    >
                                      Participate
                                    </button>
                                  ) : (
                                    <button
                                      // data-toggle="modal"
                                      // data-target="#Participate"
                                      disabled
                                      className="btn btn-danger text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold mt-2 mr-2"
                                    >
                                      Submitted
                                    </button>
                                  )}
                                  <button
                                    data-toggle="modal"
                                    data-target="#sharecontest"
                                    data-backdrop="static"
                                    data-keyboard="false"
                                    className="btn btn-warning text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold mt-2"
                                  >
                                    {" "}
                                    <i className="fas fa-share"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))
                    : ""}
                  {/*share conformation modal */}
                  <div
                    className="modal 0"
                    id="sharecontest"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog border-0 modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                          <h5
                            className="modal-title border-0"
                            id="exampleModalLabel"
                          />
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body text-center d-flex justify-content-around flex-wrap">
                          <button
                            className="btn"
                            onClick={() => share("Whatsapp")}
                          >
                            <i className="fab fa-2x  fa-whatsapp text-success"></i>{" "}
                            <br />
                            whatsapp
                          </button>
                          <button
                            className="btn"
                            onClick={() => share("Instagram")}
                          >
                            <i className="fab fa-2x  fa-instagram text-danger"></i>
                            <br />
                            Instagram
                          </button>
                          <button
                            className="btn"
                            onClick={() => share("Linkedin")}
                          >
                            <i
                              className="fab fa-2x  fa-linkedin"
                              style={{ color: "#0077B5" }}
                            ></i>
                            <br />
                            LinkedIn
                          </button>
                          <button
                            className="btn"
                            onClick={() => share("Twitter")}
                          >
                            <i
                              className="fab fa-2x  fa-twitter"
                              style={{ color: "#1DA1F2" }}
                            ></i>
                            <br />
                            Twitter
                          </button>
                        </div>
                        {shareLink && (
                          <p className="content text-center text-primary h6">
                            {field} Copy Link Given Below
                          </p>
                        )}
                        <p
                          className="content text-center"
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "3px 5px",
                            width: "98%",
                            height: "20%",
                            overflow: "auto",
                            margin: "0 auto 10px",
                          }}
                        >
                          {shareLink}
                        </p>
                        {shareLink && (
                          <CopyToClipboard
                            text={shareLink}
                            onCopy={() => setCopied(true)}
                          >
                            <button className="btn m-2 btn-success mb-4">
                              {copied ? "Copied!" : "Copy Link"}
                            </button>
                          </CopyToClipboard>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* contests modal */}
                  <div
                    className="modal fade"
                    id="Participate"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog border-0 modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                          <h5
                            className="modal-title border-0"
                            id="exampleModalLabel"
                          />
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>

                        <div className="modal-body w-100">
                          <h5 className="text-center mt-0 pt-0">
                            {contestItem?.title}
                          </h5>

                          <br />
                          <div className="">
                            <a
                              href={`${contestItem?.img}`}
                              target="_blank"
                              className="m-0 p-0"
                              download
                            >
                              <img src={contestItem?.img} width="100%" />
                            </a>
                            <div className="w-100">
                              <p className="mt-1 p-0 text-break">
                                {contestItem?.description}
                              </p>
                            </div>
                            <div>
                              <h6 className="text-danger mt-2">
                                Rules For Contests :
                              </h6>
                              1. You can submit the contest only one time .
                              <br />
                              {contestItem?.contestType === "code"
                                ? "2. You Have to push your code to a github repository of your own and paste the link to submit your contest ."
                                : "2. You Have to Upload the image of your contest ."}
                              <br />
                              <hr />
                              <div>
                                {contestItem?.contestType === "image" && (
                                  <div className=" mx-2">
                                    <>
                                      {
                                        <>
                                          <label
                                            className="font-size-18"
                                            htmlFor="upload"
                                            className="btn bg-primary text-light my-3 mx-0"
                                          >
                                            {" "}
                                            Upload image
                                          </label>
                                          {loading && <Loader />}
                                          <input
                                            className=""
                                            type="file"
                                            id="upload"
                                            style={{
                                              display: "none",
                                            }}
                                            onChange={uploadImage}
                                            // accept="image/*"
                                          />
                                          {image?.length > 0 && (
                                            <p className="text-center">
                                              Image Uploaded
                                            </p>
                                          )}
                                        </>
                                      }
                                    </>
                                  </div>
                                )}
                                <div>
                                  {contestItem?.contestType === "code" && (
                                    <input
                                      className="form-control mt-2"
                                      value={githubUrl}
                                      // disabled={disabledGithub}
                                      name="githubUrl"
                                      onChange={handleGithubChange}
                                      placeholder="Github Repository Link"
                                      type="text"
                                    />
                                  )}
                                  {/* {show && (
                                    <div className="mt-4 form-group">
                                      <input
                                        className="form-control"
                                        value={contestData.fullName}
                                        name="fullName"
                                        disabled={disabledGithub}
                                        onChange={handleContestDataChange}
                                        placeholder="Full Name"
                                        type="text"
                                      />
                                      <input
                                        className="form-control mt-2"
                                        value={contestData.email}
                                        name="email"
                                        disabled={disabledGithub}
                                        onChange={handleContestDataChange}
                                        placeholder="Email"
                                        type="email"
                                      />
                                      <input
                                        className="form-control mt-2"
                                        disabled={disabledGithub}
                                        value={contestData.phoneNumber}
                                        name="phoneNumber"
                                        onChange={handleContestDataChange}
                                        placeholder="Contact Number"
                                        type="text"
                                      />
                                      <input
                                        className="form-control mt-2"
                                        disabled={disabledGithub}
                                        value={contestData.address}
                                        name="address"
                                        onChange={handleContestDataChange}
                                        placeholder="Address"
                                        type="text"
                                      />
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer border-0">
                          <button
                            type="button"
                            className="btn btn-secondary text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold mt-2"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={submitDisabled}
                            onClick={() =>
                              participantData(contestItem?.contestType)
                            }
                            data-dismiss="modal"
                            className="btn btn-danger text-white px-4 py-2 rounded border-0 font-size-14 font-weight-bold mt-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* <div class="row mb-2"> */}
            {/* {projects?.length !== 0 && ( */}
              <div class="col-12 order-3 text-center m-auto">
                <button
                  style={{ backgroundColor: "#1D3883" }}
                  className=" btn text-white m-1"
                  disabled={skip === 0}
                  onClick={() => {
                    setClick("prev");
                    setSkip((preVal) => {
                      return preVal - limit;
                    });
                  }}
                >
                  {loader && click === "prev" ? "Prev.." : "Prev"}
                  {loader && click === "prev" ? (
                    <span
                      id="studentTop"
                      class="spinner-grow spinner-grow-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>

                <button
                  style={{ backgroundColor: "#1D3883" }}
                  className=" btn text-white m-1"
                  // data-aos="fade-left"
                  data-aos-duration="2000"
                  disabled={projects?.length === 0 || projects?.length < limit}
                  onClick={() => {
                    setClick("next");
                    setSkip((preVal) => {
                      return preVal + limit;
                    });
                  }}
                >
                  {loader && click === "next" ? "Next.." : "Next"}
                  {loader && click === "next" ? (
                    <span
                      class="spinner-grow spinner-grow-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            {/* )} */}

            {/* </div> */}
            {/* <div className="col-12 order-3">
              <div className="pagination w-100 d-flex justify-content-center">
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Timeline;
