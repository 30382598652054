import React, { useEffect, useState } from "react";
import Image from "../../assets/images/design.png";
import "../../shared/styles/custom.css";
import { useSelector, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
import { useHistory, useParams, useLocation } from "react-router-dom";
import EmailVerificationModal from "./Email-verification-modal";
import { submitCampusRegistrationFormItem } from "../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { toast } from "react-toastify";
import { sendCampusDataToTelegramItem } from "../../data/reducers/telegram.reducer";

//********************************************** */
function CampusRegisterPage() {
  const { campusId, collegeName } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const studentTypAccountId = useSelector((state) => state.authReducer).userInfo
    ?.owner?._id;

  const [emailVerified, setEmailVerfied] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const history = useHistory();

  const [filePdf, setFilePdf] = useState();
  const [loading, setLoading] = useState(false);
  const campusCode = new URLSearchParams(location.search).get("campusCode");
  // console.log("campusCode: ", campusCode);

  const [registrationDetails, setRegistrationDetails] = useState({
    collegeName: collegeName,
    studentCollegeName: "",

    studentCollegeEmailId: "",
    studentCollegeEnrollmentNumber: "",
    studentPassoutYear: "",
    studentBranch: "",
    studentCollegeContactNumber: "",
  });

  useEffect(() => {
    setRegistrationDetails({
      ...registrationDetails,
      ["studentCollegeEmailId"]: userEmail,
    });
  }, [userEmail]);
  useEffect(() => {
    if (loggedIn && usertype === "admin") {
      return history.push("/");
    }
  }, []);

  const sendCampusDataToTelegram = async () => {
    const data = ` studentName: ${
      registrationDetails?.studentCollegeName
    } \n studentEmailId: ${
      registrationDetails?.studentCollegeEmailId
    } \n studentEnrollmentNumber: ${
      registrationDetails?.studentCollegeEnrollmentNumber
    } \n studentBranch: ${
      registrationDetails?.studentBranch
    } \n studentContactNumber: ${
      registrationDetails?.studentCollegeContactNumber
    } \n collegeName: ${
      registrationDetails?.collegeName
    } \n studentTypAccountId: ${studentTypAccountId} \n campusCode: ${new URLSearchParams(
      location.search
    ).get("campusCode")} \n timeStamp:${new Date(Date.now()).toLocaleString()}`;
    const encoded = encodeURI(data);
    const response = await dispatch(sendCampusDataToTelegramItem(encoded));
    console.log("telresponse: ", response);
  };

  //submit registration details
  const handleSubmit = async () => {
    try {
      if (!filePdf) return toast.error("Please upload resume.");
      var bodyFormData = new FormData();

      for (const property in registrationDetails) {
        bodyFormData.append(property, registrationDetails[property]);
      }
      bodyFormData.append("studentTypAccountId", studentTypAccountId);
      bodyFormData.append("campusId", campusId);

      if (filePdf) {
        bodyFormData.append("documents", filePdf);
      }

      setLoading(true);
      const payload = bodyFormData;
      console.log("payload: ", registrationDetails);
      let response = await dispatch(submitCampusRegistrationFormItem(payload));
      console.log("response: ", response);
    
      if (response.payload.isSuccessful) {
        sendCampusDataToTelegram();
        toast.success("Successfully registered.");
        setLoading(false);
        history.push("/");

        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  return (
    <div className="d-flex registerPage">
      <div style={{ marginRight: "0" }} className="row">
        <div className="bg-primary text-white pt-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div
            className="px-4"
            style={{ fontSize: "10px", marginLeft: "70px" }}
          >
            <p style={{ fontSize: "21px" }}>
              One person with courage makes a majority.
            </p>
            <p style={{ fontSize: "16px" }}>-Andrew Jackson</p>
          </div>

          <div className="row">
            <div className="col-1">
              <div
                className="text-primary bg-white px-2 py-3 font-weight-bold position-relative"
                style={{
                  textOrientation: "upright",
                  writingMode: "vertical-lr",
                  borderRadius: "0px 25px 25px 0px",
                  letterSpacing: "6px",
                  bottom: "40px",
                }}
              >
                PLACEMENT DRIVE
              </div>
            </div>
            <div className="col-10">
              <img
                src={Image}
                className=""
                style={{
                  bottom: "0",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="pt-4 col-lg-8 col-md-6 col-sm-12 col-12"
          style={{ borderTopLeftRadius: "5px" }}
        >
          <div className="container-fluid">
            <h5 className="text-primary" style={{ fontSize: "25px" }}>
              {collegeName} COLLEGE CAMPUS.
            </h5>
            {emailVerified && emailVerified ? (
              <div>
                {/* <form> */}
                <div className="row">
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      College Name
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="collegeName"
                      type="text"
                      value={registrationDetails?.collegeName}
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      {" "}
                      Students College Email Id
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentCollegeEmailId"
                      type="text"
                      value={registrationDetails?.studentCollegeEmailId}
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      College Enrollment Number
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentCollegeEnrollmentNumber"
                      type="text"
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      Name
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentCollegeName"
                      type="text"
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      Branch
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentBranch"
                      type="text"
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      Passout Year
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentPassoutYear"
                      type="text"
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      Contact Number
                    </label>
                    <input
                      onChange={(e) =>
                        setRegistrationDetails({
                          ...registrationDetails,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="studentCollegeContactNumber"
                      type="text"
                      className="form-control"
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label for="inputEmail4" style={{ fontSize: "14px" }}>
                      Resume
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          setFilePdf(e.target.files[0]);
                        }
                      }}
                      style={{
                        border: "2px solid rgb(29, 56, 131)",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                </div>
                <div className="mt-4 admin-form-btn">
                  <button
                    disabled={loading}
                    onClick={handleSubmit}
                    className="btn btn-primary px-3 py-0 m-0"
                    style={{ fontSize: "10x", borderRadius: "25px" }}
                  >
                    Lets Start
                    {loading && (
                      <span
                        class="spinner-border ml-1 spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
                {/* </form> */}

                {/* <div
                  className="bg-primary text-white px-4 py-3 font-weight-bold position-relative drive-container"
                  style={{
                    textOrientation: "upright",
                    writingMode: "vertical-lr",
                    borderRadius: "0px 25px 25px 0px",
                    letterSpacing: "4px",
                    bottom: "320px",
                    right: "9px",
                    borderRadius: "20px",
                  }}
                >
                  DRIVE
                </div> */}
              </div>
            ) : (
              <div>
                {/* <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Eius, sequi deleniti neque sint quae, libero animi quam
                  voluptatum vel consequatur hic ab voluptatem. Quia illo in
                  illum velit, deserunt beatae. Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Eius, sequi deleniti neque sint
                  quae, libero animi quam voluptatum vel consequatur hic ab
                  voluptatem. Quia illo in illum velit, deserunt beatae.
                </div> */}
                <div className="mt-4">
                  <EmailVerificationModal
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    setEmailVerfied={setEmailVerfied}
                    campusCode={campusCode}
                  />
                </div>
                {/* <div
                  className="bg-primary text-white px-4 py-3 font-weight-bold position-relative"
                  style={{
                    textOrientation: "upright",
                    writingMode: "vertical-lr",
                    borderRadius: "0px 25px 25px 0px",
                    letterSpacing: "4px",
                    bottom: "140px",
                    right: "109px",
                    borderRadius: "20px",
                  }}
                >
                  DRIVE
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusRegisterPage;
