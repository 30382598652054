import { api, getAuthHeaders, get, post } from "./services.common";

//create order for razor pay
export const createRazorPayOrderAPI = async (payload) => {
  let url = `${api}/get/razorpay/orderid`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//genereate certificate api
export const generateCertificateAPI = async (payload) => {
  const { userId, eventId } = payload;
  //`https://typ-test.herokuapp.com/api/event?userId=${userId}&dynamicFields=${dynamicFields}&eventId=${eventId}&templateName=${templateName}&studentName=${studentName}`
  let url = `${api}/event/generate/certificate?userId=${userId}&eventId=${eventId}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const studentListAPI = async (payload) => {
  const { limit, skip, searchQuery } = payload;
  // console.log('skip: ', skip);

  let url = `${api}/getAllStudents?limit=${limit}&skip=${skip}&searchQuery=${searchQuery}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const studentAPI = async (id) => {
  let url = `${api}/getStudentById/` + id;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
export const projectAPI = async ({ limit, skip, toggleView, searchQuery }) => {
  const isTYPStudent = toggleView === "typ";
  // console.log('isTYPStudent: ', isTYPStudent);
  let url = `${api}/student/allProjects?limit=${limit}&skip=${skip}&isTYPStudent=${isTYPStudent}&searchQuery=${searchQuery}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const studentByOwnerIdAPI = async (id) => {
  let url = `${api}/getStudentByOwnerId/${id}`;

  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const getProjectByIdAPI = async (id) => {
  let url = `${api}/project?id=${id}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
