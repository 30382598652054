import React, { useState, useEffect, useRef } from "react";
import SingleUploadModal from "./Modals/Single-Upload-student-placement-drive-model";
import EditStudentEmailsModal from "./Modals/Edit-studentEmails.model";
import {
  bulkUploadEmailsItem,
  fetchCampusStudentListsItem,
} from "../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import StudentUploadSubmitModal from "./Modals/StudentEmailUpload.model";
//************************************************* */
export const StudentEmails = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const ref = useRef();

  const reset = () => {
    ref.current.value = "";
  };

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const [studentData, setStudentData] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [loader, setloader] = useState(false);

  const [campusId, setcampusId] = useState("");

  useEffect(() => {
    if (!loggedIn) {
      return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      if (campusId !== "") {
        // console.log("calling");
        fetchCampusStudentListsApi();
      }
    }
  }, [campusId]);

  // useEffect(() => {
  //   console.log("calling emails");
  //   fetchCampusStudentListsApi();
  // }, [studentData]);

  useEffect(() => {
    console.log("props: ", props?.location?.state);
    if (props && props?.location?.state?.campusId) {
      setcampusId(props?.location?.state?.campusId);
    }
  }, [props]);

  const fetchCampusStudentListsApi = async () => {
    // console.log("fetch");
    try {
      setLoading(true);
      const payload = {
        campusId,
      };
      // console.log("payload: ", payload);
      let response = await dispatch(fetchCampusStudentListsItem(payload));
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setLoading(false);
        setStudentData(response.payload.data.studentEmailsExcelSheet);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  //excel upload
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      // console.log("d", d);
      setEmailList(d);
    });
  };

  const bulkUploadStudentData = async (closeModal) => {
    try {
      // console.log("called")
      if (emailList.length === 0) return toast.warning("Please choose file");

      setloader(true);
      const payload = { campusId, emailList };
      // console.log('payload: ', payload);
      let response = await dispatch(bulkUploadEmailsItem(payload));

      if (response.payload.isSuccessful) {
        setEmailList([]);
        reset();
        setloader(false);
        setStudentData(response.payload.data.studentEmailsExcelSheet);
        toast.success("Uploaded successfully");
        closeModal();
        return;
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
        return;
      }
    } catch (error) {}
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <div className="p-5 m-2">
        <div className="row my-3">
          <div className="col-md-12">
            {" "}
            <h3 className="btn-secondary text-center p-2">
              Campus Placement Drive
            </h3>
          </div>
          <div className="d-flex col-md-12 justify-content-center ">
            <div className="  border flex-column d-flex justify-content-center align-items-center m-2 ">
              {/* <div className="mt-2">
                <h5 className=""> Uploads</h5>
              </div> */}
              <div className="">
                <SingleUploadModal
                  setStudentData={studentData}
                  studentData={studentData}
                  campusId={campusId}
                  fetchCampusStudentListsApi={fetchCampusStudentListsApi}
                />
              </div>   
              {/* <hr /> */}
              <div className=" flex-column d-flex justify-content-center align-items-center mt-2 ">
                <p className="mt-2 text-muted"> *To upload in bulk , please select file</p>
                <div className="d-flex justify-content-center m-2">
                  <input
                    type="file"
                    ref={ref}
                    className="mt-2 col-md-7"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        const file = e.target.files[0];
                        readExcel(file);
                      }
                    }}
                    accept=".xlsx, .xls"
                  />
               
                  <StudentUploadSubmitModal
                    bulkUploadStudentData={bulkUploadStudentData}
                  />
                  {/* <button
                  className="btn btn-secondary"
                  onClick={bulkUploadStudentData}
                >
                  Submit
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )} */}
                  {/* </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="col-md-12">
            <div className="table-responsive mt-2  ">
              <table className="table table-hover  ">
                <thead className="btn-secondary">
                  {/* <th align="center" className="text-center" scope="col">
                    S.No
                  </th> */}
                  <th align="center" className="text-center" scope="col">
                    Student Name
                  </th>{" "}
                  <th align="center" className="text-center" scope="col">
                    Email ID
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Enrollment Number
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                </thead>
                <tbody>
                  {studentData && studentData?.length > 0
                    ? studentData.map((item) => {
                        return (
                          <tr key={item._id}>
                            {/* <td align="center">1</td> */}
                            <td align="center">
                              {item?.studentName ? item?.studentName : "--"}
                            </td>
                            <td align="center">
                              {item?.emailId ? item?.emailId : "--"}
                            </td>
                            <td align="center">
                              {item?.enrollmentNumber
                                ? item?.enrollmentNumber
                                : "--"}
                            </td>
                            <td align="center">
                              <EditStudentEmailsModal
                                setStudentData={setStudentData}
                                item={item}
                                campusId={campusId}
                              />{" "}
                            </td>
                          </tr>
                        );
                      })
                    : "No data"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
