import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { getQuestionListsItem } from "../../../../data/reducers/admin/campus-placement Drive/question.reducer";
// import Toggle from "./Modals/Confirm-toggle-modal";
import { useSelector, useDispatch } from "react-redux";
import {
  changeQuestionArray,
  changeSkip,
  deleteQuestionId,
} from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";

//********************************* */
export const Questions = (props) => {
  let history = useHistory();
  const [question, setQuestion] = useState([]);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);

  const [click, setClick] = useState("");
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("CODING");
  const [level, setLevel] = useState("EASY");

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const commonSkip = useSelector(
    (state) => state.campusPlacementAdminReducer
  )?.skip;
  const commonCategory = useSelector(
    (state) => state.campusPlacementAdminReducer
  )?.category;
  const commonLevel = useSelector(
    (state) => state.campusPlacementAdminReducer
  )?.level;

  const questionReduxState = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).questionArray;

  const [skip, setSkip] = useState("");

  useEffect(() => {
    setCategory(commonCategory ? commonCategory : "CODING");
    setLevel(commonLevel ? commonLevel : "EASY");
    setSkip(commonSkip);
  }, [commonSkip]);

  // useEffect(() => {
  //   console.log("questionReduxState: ", questionReduxState);
  // }, [questionReduxState]);

  useEffect(() => {
    if (!loggedIn) {
      return history.push('/');
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      fetchQuestionListsApi();
    }
  }, [skip, category, level]);

  const fetchQuestionListsApi = async () => {
    try {
      setLoading(true);
      const payload = { limit, skip, category, level };
      // console.log("payload: ", payload);
      let response = await dispatch(getQuestionListsItem(payload));
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setLoading(false);
        setQuestion(response.payload.data);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div style={{marginTop:"70px"}}>
      <div className=" p-5 m-2">
        <div className="row my-3">
          {props?.location?.state?.comingFrom &&
            props?.location?.state?.comingFrom === "TEST" && (
              <div className="col-md-6">
                {" "}
                <i
                  onClick={() => history.goBack()}
                  className="fa fa-backward btn btn-secondary p-2 m-1"
                >
                  Go back to test
                </i>
                <span className="bg-success p-2 text-white">
                  Total selected questions : {questionReduxState?.length}{" "}
                </span>
              </div>
            )}

          <div className="col-md-12 d-flex justify-content-center">
            {" "}
            <h3 className="btn-secondary text-center p-2 pr-4 pl-4 ">
              Questions
            </h3>
          </div>
          <div className="col-md-12 d-flex   justify-content-center">
            <div className="form-check m-1  ">
              <input
                className="form-check-input"
                defaultChecked={commonCategory === "CODING"}
                type="radio"
                name="FILTER_QUESTIONS"
                id="CODING"
                onClick={() => setCategory("CODING")}
              />
              <label className="form-check-label" htmlFor="CODING">
                CODING
              </label>
            </div>
            <div className="form-check m-1  ">
              <input
                defaultChecked={commonCategory === "APTITUDE"}
                className="form-check-input"
                type="radio"
                name="FILTER_QUESTIONS"
                id="APTITUDE"
                onClick={() => setCategory("APTITUDE")}
              />
              <label className="form-check-label" htmlFor="APTITUDE">
                APTITUDE
              </label>
            </div>
            <div className="form-check m-1  ">
              <input
                defaultChecked={commonCategory === "VERBAL_ABILITY"}
                className="form-check-input"
                type="radio"
                name="FILTER_QUESTIONS"
                id="VERBAL_ABILITY"
                onClick={() => setCategory("VERBAL_ABILITY")}
              />
              <label className="form-check-label" htmlFor="VERBAL_ABILITY">
                VERBAL_ABILITY
              </label>
            </div>
          </div>

          <div className="col-md-12 d-flex  justify-content-center">
            <div className="form-check  m-1">
              <input
                className="form-check-input"
                defaultChecked={commonLevel === "EASY"}
                type="radio"
                name="FILTER_QUESTIONS_LEVEL"
                id="EASY"
                onClick={() => setLevel("EASY")}
              />
              <label className="form-check-label" htmlFor="EASY">
                EASY
              </label>
            </div>
            <div className="form-check  m-1">
              <input
                className="form-check-input"
                defaultChecked={commonLevel === "MEDIUM"}
                type="radio"
                name="FILTER_QUESTIONS_LEVEL"
                id="MEDIUM"
                onClick={() => setLevel("MEDIUM")}
              />
              <label className="form-check-label" htmlFor="MEDIUM">
                MEDIUM
              </label>
            </div>
            <div className="form-check  m-1">
              <input
                className="form-check-input"
                defaultChecked={commonLevel === "HARD"}
                type="radio"
                name="FILTER_QUESTIONS_LEVEL"
                id="HARD"
                onClick={() => setLevel("HARD")}
              />
              <label className="form-check-label" htmlFor="HARD">
                HARD
              </label>
            </div>
          </div>

          <div className="mt-2  col-md-12 d-flex justify-content-end">
            <button
              className="btn btn-secondary align-right"
              onClick={() => {
                dispatch(changeSkip({ skip, level, category }));
                history.push({
                  pathname: "/admin/campusPlacementDrive/Question/Form",
                  state: { data: { page: "Add", item: null } },
                });
              }}
              title="Create"
            >
              Create Question
            </button>
          </div>
        </div>

        <div className="col-12 rounded border">
          <div className="row d-flex align-items-center justify-content-end border-bottom  p-2 ">
            {/* <div className="mx-3 mt-1 ">
              <Toggle />
            </div> */}

            {/* <div>
              <i className="fa fa-trash fa-lg text-danger cursor-pointer mx-3"></i>
            </div> */}
          </div>
          {question && question.length > 0
            ? question?.map((item, index) => {
                return (
                  <div className="row mt-2  ">
                    <div className="col-12">
                      <h5 className="text-left font-weight-bold ">
                        Q.{skip + index + 1} &nbsp; {item?.title}
                      </h5>
                    </div>

                    <div>
                      <i
                        className="fa fa-edit fa-lg text-success cursor-pointer mt-1 mx-3"
                        onClick={() => {
                          dispatch(changeSkip({ skip, level, category }));
                          history.push({
                            pathname:
                              "/admin/campusPlacementDrive/Question/Form",
                            state: {
                              data: { page: "Edit", item },
                            },
                          });
                        }}
                      ></i>
                      {props?.location?.state?.comingFrom &&
                        props?.location?.state?.comingFrom === "TEST" &&
                        questionReduxState && (
                          <button
                            className={`btn ${
                              questionReduxState.includes(item?._id)
                                ? "btn-dark"
                                : "btn-gray"
                            }  font-12 `}
                            onClick={() => {
                              if (!questionReduxState.includes(item?._id)) {
                                dispatch(
                                  changeQuestionArray({ questionId: item?._id })
                                );
                              } else {
                                dispatch(
                                  deleteQuestionId({ questionId: item?._id })
                                );
                              }
                            }}
                          >
                            {questionReduxState.includes(item?._id)
                              ? "SELECTED"
                              : "SELECT"}
                          </button>
                        )}
                    </div>
                    <div className="d-flex  col-12 ">
                      <div className="col-6 ">
                        <div className="row  d-flex flex-column mx-2  ">
                          <div class="form-check  my-1">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              A. {item?.optionA}
                            </label>
                          </div>
                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              B. {item?.optionB}
                            </label>
                          </div>
                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              C. {item?.optionC}
                            </label>
                          </div>

                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              D. {item?.optionD}
                            </label>
                          </div>
                          {/* <p className="m-0 text-muted border-dark">----------------------------</p> */}
                          <div class="form-check my-1">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Correct answer : {item?.correctAnswer}
                            </label>
                          </div>
                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Total marks : {item?.marks}
                            </label>
                          </div>
                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Total time : {item?.time} min
                            </label>
                          </div>
                          <div class="form-check my-1 ">
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Level :{" "}
                              <span class="badge rounded-pill bg-light p-1">
                                {item?.level}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {item?.picture && (
                        <div className="col-6 row d-flex justify-content-end">
                          <img
                            src={item?.picture}
                            width="200"
                            height="200"
                            alt="coding"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : "No data"}

          <div class="row mb-2">
            <div class="col-md-12 text-center m-auto">
              <button
                style={{ backgroundColor: "#1D3883" }}
                className="btn text-white m-1"
                disabled={skip === 0}
                onClick={() => {
                  setClick("prev");
                  setSkip((preVal) => {
                    return preVal - limit;
                  });
                }}
              >
                {loading && click === "prev" ? "Prev.." : "Prev"}
                {loading && click === "prev" ? (
                  <span
                    id="studentTop"
                    class="spinner-grow spinner-grow-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>

              <button
                style={{ backgroundColor: "#1D3883" }}
                className="btn text-white m-1"
                disabled={question.length === 0 || question.length < limit}
                onClick={() => {
                  setClick("next");
                  setSkip((preVal) => {
                    return preVal + limit;
                  });
                }}
              >
                {loading && click === "next" ? "Next.." : "Next"}
                {loading && click === "next" ? (
                  <span
                    class="spinner-grow spinner-grow-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
