import { api, getAuthHeaders, get, post, patch } from "../../services.common";

//add User
export const CreateUsertypeAdminAPI = async (payload) => {
  const { roleName } = payload;
  let url = `${api}/admin/create/role`;
  let authHeader = getAuthHeaders();
  return await post(url, { roleName }, { ...authHeader });
};

//get all usertypes user
export const getAllUsertypesAPI = async () => {
  let url = `${api}/admin/get/roles`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//edit Usertype(role)
export const EditUsertypeAdminAPI = async (payload) => {
  const { _id, roleName } = payload;
  let url = `${api}/admin/edit/role?roleId=${_id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { roleName }, { ...authHeader });
};
