import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PaginationComponent from "../Shared/Pagination/Pagination";
import {
  participantListAdminItem,
  toggleApproveAdminItem,
} from "../../../data/reducers/admin.reducer";
import Search from "../Shared/Search/Search";
import Loader from "../../../shared/components/Loaders/Loader";

const ContestParticipant = (props) => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState();
  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;
  const [imageModal, setimageModal] = useState("");
  const [status, setStatus] = useState("");
  const [id, setId] = useState(null);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const participants = useSelector((state) => state.adminReducer)
    .participantList;
  const idContest = props.location.state?.id;

  const [participant, setParticipant] = useState([...participants]);

  const [participantList, setParticipantList] = useState(
    participant.filter((item) => item?.contestId?._id === idContest)
  );
  const dispatch = useDispatch();

  const approve = async () => {
    if (true) {
      const payload = {
        status: status,
        id,
      };

      try {
        let response = await dispatch(toggleApproveAdminItem(payload));
      } catch (error) {}
    }
  };

  useEffect(async () => {
    setLoading(true);
    try {
      let response = await dispatch(participantListAdminItem());

      setParticipant(participants);
    } catch (error) {}

    // const data = participants?.filter(
    //   (item) => item?.contestId?._id === idContest
    // );
    //
    // setParticipantList(data);
    setLoading(false);
  }, []);

  const participantData = useMemo(() => {
    let participantData = participants.filter(
      (item) => item?.contestId?._id === idContest
    );

    if (search) {
      participantData = participantData?.filter(
        (item) => item?.fullName?.toLowerCase().includes(search.toLowerCase())
        // item?.contestId?.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(participantData?.length);
    // curreent pages
    return participantData?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [participants, currentPage, search]);
  const handleChangeOpen = (e) => {
    const { name, value } = e.target;
    setStatus({ [name]: value });
    // setDisable(false);
  };
  // console.log(participantData);
  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <>
      {!loading ? (
        <div>
          {participantList?.length > 0 ? (
            <div className="p-5 m-5">
              <div
                className="m-5"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <PaginationComponent
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
              <table className="table table-responsive">
                <thead>
                  <th>#</th>
                  <th>Contest Title</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>phoneNumber</th>
                  {/* <th>Address</th> */}
                  <th>URL's</th>
                  <th>Status</th>
                </thead>
                <tbody>
                  {participantData?.map((item, i) => (
                    <tr>
                      <td style={{ width: "5%" }}>{i + 1}</td>
                      <td style={{ width: "15%" }}>
                        {" "}
                        {item?.contestId?.title}
                      </td>
                    
                      <td style={{ width: "8%" }}>{item?.userId?.firstName} {item?.userId?.lastName}</td>
                      <td style={{ width: "15%" }}>{item?.userId?.email}</td>
                      <td style={{ width: "10%" }}>{item?.userId?.mobileNumber}</td>
                      {/* <td style={{ width: "5%" }}>{item?.userId?.address}</td> */}

                      <td style={{ width: "10%" }}>
                        {item?.url?.search("github") >= 0 && (
                          <a href={`${item.url}`}>
                            <i className="fa fa-github fa-2x"></i>
                          </a>
                        )}
                        {item?.url?.search("cloudinary") > 0 && (
                          <button
                            data-toggle="modal"
                            data-backdrop="static" data-keyboard="false"
                            data-target="#image"
                            onClick={() => setimageModal(item.url)}
                            className="btn btn-success"
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                        )}
                      </td>
                      <td>
                        <button
                          data-toggle="modal"
                          data-backdrop="static" data-keyboard="false"
                          data-target="#status"
                          className="btn"
                          onClick={() => {
                            setId(item._id);
                            setStatus(item?.status);
                          }}
                        >
                          {item?.status === "winner" && <button className="btn btn-success">Winner</button>}
                          {item?.status === "runnerup" && <button className="btn btn-secondary">Runner up</button>}
                          {item?.status === "looser" && <button className="btn btn-danger">Looser</button>}
                          {item?.status === "submitted" && <button className="btn btn-warning">submitted</button>}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h1 className="btn-secondary text-center p-5 m-5">
              Sorry , No Submissions for this Contest
            </h1>
          )}

          <div
            className="modal fade"
            id="status"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex align-items-center text-center justify-content-center">
                    <div className="form-group d-flex text-center m-2 align-items-center">
                      <input
                        type="radio"
                        onChange={handleChangeOpen}
                        id="male"
                        className="mt-1 mr-1"
                        name="status"
                        value="winner"
                      />
                      <label for="male" className="mb-0 mt-1">
                        Winner
                      </label>
                    </div>
                    <div className="form-group d-flex text-center m-2 align-items-center">
                      <input
                        type="radio"
                        onChange={handleChangeOpen}
                        id="other"
                        className="mt-1 mr-1"
                        name="status"
                        value="runnerup"
                      />
                      <label for="other" className="mb-0 mt-1">
                        Runner Up
                      </label>
                    </div>
                    <div className="form-group d-flex text-center m-2 align-items-center">
                      <input
                        type="radio"
                        onChange={handleChangeOpen}
                        className="mt-1 mr-1"
                        id="female"
                        name="status"
                        value="looser"
                      />
                      <label for="female" className="mb-0 mt-1">
                        Looser
                      </label>
                    </div>
                    <br />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={approve}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="image"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <img
                    className="img-thumbnail ml-1 mr-1"
                    src={imageModal}
                    alt="img"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ContestParticipant;
