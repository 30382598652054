import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUpcomingBatchesAPI,
  addUpcomingBatchAPI,
  deleteUpcomingBatchAPI,
  editUpcomingBatchAPI,
  uploadImageUpcomingBatchAPI,
} from "../../../services/admin/batches/batches.service";

export const getBatchesItem = createAsyncThunk(
  "batch/getBatches",
  async (payload, thunkAPI) => {
    const response = await getUpcomingBatchesAPI();
    console.log(
      "🚀 ~ file: batches-admin.reducer.js ~ line 11 ~ async ~ response",
      response
    );
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const addBatchItem = createAsyncThunk(
  "batch/addBatches",
  async (payload, thunkAPI) => {
    const response = await addUpcomingBatchAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const deleteBatchItem = createAsyncThunk(
  "batch/deleteBatch",
  async (payload, thunkAPI) => {
    const response = await deleteUpcomingBatchAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const editBatchItem = createAsyncThunk(
  "batch/editBatch",
  async (payload, thunkAPI) => {
    const response = await editUpcomingBatchAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const uploadUpcomingBatchImageItem = createAsyncThunk(
  "batch/uploadUpcomingBatchImage",
  async (payload, thunkAPI) => {
    const response = await uploadImageUpcomingBatchAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

const batchSlice = createSlice({
  name: "batch",
  initialState: {
    batches: [],
  },
  reducers: {},
  extraReducers: {
    [getBatchesItem.fulfilled]: (state, action) => {
      //   console.log("action : ", action);
      state.batches = action?.payload?.data;
    },
    [addBatchItem.fulfilled]: (state, action) => {
      state.batches = action?.payload?.data;
    },
    [deleteBatchItem.fulfilled]: (state, action) => {
      //   console.log("Coming here in deletebatchitem.ful");
      state.batches = action?.payload?.data;
    },
    [editBatchItem.fulfilled]: (state, action) => {
      state.batches = action?.payload?.data;
    },
    [uploadUpcomingBatchImageItem.fulfilled]: (state, action) => {
      state.batches = action?.payload?.data;
    },
  },
});

export default batchSlice.reducer;
