import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createActionAPI,
  fetchActionsAPI,
  updateActionAPI,
  updateActionStatusAPI,
  deleteActionAPI,
  fetchAllRequestsAPI,
  fetchActionsUserAPI,
  fetchRedeemRequestUserAPI,
  fetchRequestsUserAPI,
  fetchActionsCreatedUserAPI,
  toggleRedeemRequestAPI,
  validateActionInRedeemRequestAPI,
  createRequestUserAPI,
  sendRewardAPI,
  proceedPaymentAPI,
} from "../services/referral.service";

//Admin Action Creators Start ----------------------

//creating action thunk
export const createActionItem = createAsyncThunk(
  "admin/createAction",
  async (payload, thunkAPI) => {
    let response = await createActionAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//fetching action thunk
export const fetchActionsItem = createAsyncThunk(
  "admin/fetchActions",
  async (payload, thunkAPI) => {
    let response = await fetchActionsAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//fetching reqs thunk
export const fetchAllRequestsItem = createAsyncThunk(
  "admin/fetchAllRequests",
  async (payload, thunkAPI) => {
    let response = await fetchAllRequestsAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//updating action thunk
export const updateActionItem = createAsyncThunk(
  "admin/updateAction",
  async (payload, thunkAPI) => {
    let response = await updateActionAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//updating action status thunk
export const updateActionStatusItem = createAsyncThunk(
  "admin/updateActionStatus",
  async (payload, thunkAPI) => {
    let response = await updateActionStatusAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//deleting action  thunk
export const deleteActionItem = createAsyncThunk(
  "admin/deleteAction",
  async (payload, thunkAPI) => {
    let response = await deleteActionAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//toggle reddem req  thunk
export const toggleRedeemRequestItem = createAsyncThunk(
  "admin/toggleRedeemRequest",
  async (payload, thunkAPI) => {
    let response = await toggleRedeemRequestAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//validateActionInRedeemRequest  thunk
export const validateActionInRedeemRequestItem = createAsyncThunk(
  "admin/validateActionInRedeemRequest",
  async (payload, thunkAPI) => {
    let response = await validateActionInRedeemRequestAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//create request for actions  thunk
export const createRequestUserItem = createAsyncThunk(
  "admin/createRequest",
  async (payload, thunkAPI) => {
    let response = await createRequestUserAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);
//validateActionInRedeemRequest  thunk
export const sendRewardItem = createAsyncThunk(
  "admin/sendReward",
  async (payload, thunkAPI) => {
    let response = await sendRewardAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//proceed pymnt  thunk
export const proceedPaymentItem = createAsyncThunk(
  "admin/proceedPayment",
  async (payload, thunkAPI) => {
    let response = await proceedPaymentAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);
//Admin Action Creators End ----------------------

let initialStateActions = {
  actions: [],
  totalSum: 0,
};
let initialStateRequests = {
  requests: [],
  totalSum: 0,
};

//User Action Creators Start ----------------------

export const fetchActionUserItem = createAsyncThunk(
  "/getAllActions",
  async (payload, thunkAPI) => {
    let response = await fetchActionsUserAPI(payload);
    //
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const fetchActionCreatedUserItem = createAsyncThunk(
  "/getCreatedActions",
  async (payload, thunkAPI) => {
    //
    let response = await fetchActionsCreatedUserAPI(payload);
    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const fetchRedeemRequestUserItem = createAsyncThunk(
  "/getPendingRequests",
  async (payload, thunkAPI) => {
    let response = await fetchRedeemRequestUserAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const fetchRequestRewardUserItem = createAsyncThunk(
  "/getRewardRequests",
  async (payload, thunkAPI) => {
    let response = await fetchRequestsUserAPI(payload);
    //

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

export const fetchRequestHistory = createAsyncThunk(
  "/getAllRequests",
  async (payload, thunkAPI) => {
    // let response = await fetchRedeemRequestUserAPI(payload);
    let response = await fetchRequestsUserAPI(payload);

    if (response.isSuccessful) {
      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);

//User Action Creators End ----------------------

//slice
const referralSlice = createSlice({
  name: "referral",
  initialState: {
    refActions: [], // Admin purpose
    userRefActions: [], // All actions - Points earned
    userActionCreated: [], // actions of status request_created - We'll add this points to header for redeemable points
    userRewardReceived: [], // reward reveived requests
    userRefRequests: [], // pending requests
    userReqHistory: [], // All Requests
    rewardRequest : null
  },
  reducers : {
    rewardRequest : (state, action) => {
      state.rewardRequest = action.payload.request ;
    }
  },
  extraReducers: {
    [createActionItem.fulfilled]: (state, action) => {
      state.refActions = action.payload.data;
    },
    [fetchActionsItem.fulfilled]: (state, action) => {
      state.refActions = action.payload.data;
    },
    [updateActionItem.fulfilled]: (state, action) => {
      state.refActions = action.payload.data;
    },
    [updateActionStatusItem.fulfilled]: (state, action) => {
      state.refActions = action.payload.data;
    },
    [deleteActionItem.fulfilled]: (state, action) => {
      state.refActions = action.payload.data;
    },
    [fetchAllRequestsItem.fulfilled]: (state, action) => {
      state.allRequests = action.payload.data;
    },
    [fetchActionUserItem.fulfilled]: (state, action) => {
      state.userRefActions = action.payload.data;
    },
    [fetchRedeemRequestUserItem.fulfilled]: (state, action) => {
      state.userRefRequests = action.payload.data;
    },
    [fetchRequestRewardUserItem.fulfilled]: (state, action) => {
      state.userRewardReceived = action.payload.data;
    },
    [fetchRequestHistory.fulfilled]: (state, action) => {
      state.userReqHistory = action.payload.data;
    },
    [fetchActionCreatedUserItem.fulfilled]: (state, action) => {
      state.userActionCreated = action.payload.data;
    },
    [toggleRedeemRequestItem.fulfilled]: (state, action) => {
      state.allRequests = action.payload.data;
    },
    [validateActionInRedeemRequestItem.fulfilled]: (state, action) => {
      state.allRequests = action.payload.data;
    },
    [createRequestUserItem.fulfilled]: (state, action) => {},
    [sendRewardItem.fulfilled]: (state, action) => {
      state.allRequests = action.payload.data;
    },
    [proceedPaymentItem.fulfilled]: (state, action) => {
      state.allRequests = action.payload.data;
    },
  },
});

export const {rewardRequest} = referralSlice.actions ;
export default referralSlice.reducer;
