import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  checkConnectionAPI,
  signupAPI,
  loginAPI,
  signupAPI2,
  sendOTPAPI,
  verifyOTPAPI,
  editprofileAPI,
  linkedinLoginAPI,
  linkedinSignUpAPI,
  AdminloginAPI,
  editTypProfileAPI,
  editProjectAPI,
  addProjectAPI,
  deleteStudentProfileDetailsAPI,
  forgotPassowrdAPI,
  confirmationAPI,
  hrHireStudentAPI,
  studentHirableAPI,
  editprofileAPIProfessional,
  contestSubmissionAPI,
  editProTypProfileAPI,
  editProProjectAPI,
  deleteProfessionalProfileDetailsAPI,
  addProProjectAPI,
  addStudentToUpcomingPoolAPI,
  updateStudentToUpcomingPoolAPI,
  professionalHirableAPI,
  hrHireProfessionalAPI,
  getActiveEventsAPI,
  uploadProfilePicAPI,
  uploadProfilePicAPIProfessional,
} from "../services/auth.service";
import { professionalAPI } from "../services/professional.service";
import { studentAPI } from "../services/student.service";

export const checkConnection = createAsyncThunk(
  "checkConnection",
  async (payload, thunkAPI) => {
    return await checkConnectionAPI(payload);
  }
);
export const forgotPassworditem = createAsyncThunk(
  "user/forgotPassworditem",
  async (payload, thunkAPI) => {
    let response = await forgotPassowrdAPI(payload);

    if (response.isSuccessful === true) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const confirmationItem = createAsyncThunk(
  "user/confirmationItem",
  async (payload, thunkAPI) => {
    let response = await confirmationAPI(payload);

    if (response.isSuccessful === true) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const loginItem = createAsyncThunk(
  "user/loginItem",
  async (payload, thunkAPI) => {
    let response = await loginAPI(payload);

    if (response.isSuccessful === true) {
      // localStorage.setItem("userdata", response.data);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("userId", response.data.data.owner._id);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userType", response.data.data.owner.userType);
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const AdminLoginItem = createAsyncThunk(
  "user/AdminLoginItem",
  async (payload, thunkAPI) => {
    let response = await AdminloginAPI(payload);

    if (response.isSuccessful) {
      localStorage.setItem("userdata", JSON.stringify(response.data));
      localStorage.setItem("user", JSON.stringify(response.data.admin));
      localStorage.setItem("userId", response.data.admin._id);
      localStorage.setItem("token", response.data.token);

      return response;
    }
    return thunkAPI.rejectWithValue(response);
  }
);
export const signupItem = createAsyncThunk(
  "user/signupItem",
  async (payload, thunkAPI) => {
    let response = await signupAPI(payload);

    if (response.isSuccessful === true) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userId", response.data.userId);
      localStorage.setItem("token", response.data.token);
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const signupItem2 = createAsyncThunk(
  "user/signupItem2",
  async (payload, thunkAPI) => {
    let response = await signupAPI2(payload);

    if (response.isSuccessful === true) {
      localStorage.setItem("user", JSON.stringify(response.data.user));
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const sendOTPItem = createAsyncThunk(
  "user/sendOTPItem",
  async (payload, thunkAPI) => { 
    let response = await sendOTPAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const verifyOTPItem = createAsyncThunk(
  "user/verifyOTPItem",
  async (payload, thunkAPI) => {
    let response = await verifyOTPAPI(payload);
    // console.log("response: ", response); 

    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const linkedinSignUpItem = createAsyncThunk(
  "user/linkedinSignUpItem",
  async (payload, thunkAPI) => {
    let response = await linkedinSignUpAPI(payload);

    if (response.isSuccessful) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userId", response.data.newuser._id);
      localStorage.setItem("userInfo", response.data.newuser);

      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const linkedinLoginItem = createAsyncThunk(
  "user/linkedinLoginItem",
  async (payload, thunkAPI) => {
    let response = await linkedinLoginAPI(payload);

    if (response.isSuccessful) {
      localStorage.setItem("userdata", response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      localStorage.setItem("userId", response.data.data.owner._id);
      localStorage.setItem("userInfo", response.data.data);
      localStorage.setItem("userType", response.data.data.owner.userType);

      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const studentEditProfileItem = createAsyncThunk(
  "student/studentEditProfileItem",
  async (payload, thunkAPI) => {
    let response = await editprofileAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const studentUploadProfilePicItem = createAsyncThunk(
  "student/studentUploadProfilePicItem",
  async (payload, thunkAPI) => {
    let response = await uploadProfilePicAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const professionalEditProfileItem = createAsyncThunk(
  "professional/professionalEditProfileItem",
  async (payload, thunkAPI) => {
    let response = await editprofileAPIProfessional(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const professionalUploadProfilePicItem = createAsyncThunk(
  "professional/professionalUploadProfilePicItem",
  async (payload, thunkAPI) => {
    let response = await uploadProfilePicAPIProfessional(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const studentEditTypProfileItem = createAsyncThunk(
  "student/studentEditTypProfileItem",
  async (payload, thunkAPI) => {
    let response = await editTypProfileAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const professionalEditTypProfileItem = createAsyncThunk(
  "professional/professionalEditTypProfileItem",
  async (payload, thunkAPI) => {
    let response = await editProTypProfileAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

//edit project
export const studentEditProject = createAsyncThunk(
  "student/studentEditProject",
  async (payload, thunkAPI) => {
    let response = await editProjectAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const professionalEditProject = createAsyncThunk(
  "professional/professionalEditProject",
  async (payload, thunkAPI) => {
    let response = await editProProjectAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

//add project
export const studentAddProject = createAsyncThunk(
  "student/studentAddProject",
  async (payload, thunkAPI) => {
    let response = await addProjectAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const professionalAddProject = createAsyncThunk(
  "professional/professionalAddProject",
  async (payload, thunkAPI) => {
    let response = await addProProjectAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

//delte
export const studentDeleteDetails = createAsyncThunk(
  "student/studentDeleteDetails",
  async (payload, thunkAPI) => {
    let response = await deleteStudentProfileDetailsAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const professionalDeleteDetails = createAsyncThunk(
  "professional/professionalDeleteDetails",
  async (payload, thunkAPI) => {
    let response = await deleteProfessionalProfileDetailsAPI(payload);

    if (response.isSuccessful) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const hrHireStudentItem = createAsyncThunk(
  "student/hrHireStudentItem",
  async (payload, thunkAPI) => {
    let response = await hrHireStudentAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const hrHireProfessionalItem = createAsyncThunk(
  "student/hrHireProfessionalItem",
  async (payload, thunkAPI) => {
    let response = await hrHireProfessionalAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const studentHireableItem = createAsyncThunk(
  "student/studentHireableItem",
  async (payload, thunkAPI) => {
    let response = await studentHirableAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const professionalHireableItem = createAsyncThunk(
  "student/professionalHireableItem",
  async (payload, thunkAPI) => {
    let response = await professionalHirableAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const contestSubmissionItem = createAsyncThunk(
  "user/contestSubmissionItem",
  async (payload, thunkAPI) => {
    let response = await contestSubmissionAPI(payload);

    if (response.isSuccessful === true) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const addStudentToUpcomingPoolItem = createAsyncThunk(
  "user/addStudentToUpcomingPool",
  async (payload, thunkAPI) => {
    // console.log("hitting addstudentotupcoming api");
    let response = await addStudentToUpcomingPoolAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const updateStudentToUpcomingPoolItem = createAsyncThunk(
  "user/updateStudentToUpcomingPool",
  async (payload, thunkAPI) => {
    let response = await updateStudentToUpcomingPoolAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);

export const getActiveEventsItem = createAsyncThunk(
  "user/getActiveEvents",
  async (payload, thunkAPI) => {
    let response = await getActiveEventsAPI(payload);
    // console.log("response in active events : ", response);
    if (response.isSuccessful === true) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  }
);
export const studentItem1 = createAsyncThunk(
  "student/studentItem1",
  async (payload, thunkAPI) => {
    let response = await studentAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else thunkAPI.rejectWithValue(response);
  }
);
export const professionalItem1 = createAsyncThunk(
  "student/professionalItem1",
  async (payload, thunkAPI) => {
    let response = await professionalAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else thunkAPI.rejectWithValue(response);
  }
);
const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: [],
    loggedIn: false,
    token: "",
    userType: "",
    register2: false,
    verifyOtp: false,
    verified: false,
    otpError: false,
    hireReq: null,
    wantACallback: false,
    referralTypId: null,
    activeEvents: [],
    openLoginModal: false,
  },
  reducers: {
    signup2: (state, action) => {
      state.referralTypId = action.payload.typId;
    },
    changeOpenLoginModal: (state, action) => {
      state.openLoginModal = action.payload.modalVal;
    },
    logoutFunction: (state, action) => {
      state.userInfo = "";
      let updateConnector = false;
      state.loggedIn = updateConnector;
      localStorage.removeItem("userData");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userType");
      localStorage.removeItem("CTA");
      localStorage.removeItem("referredBy");
      localStorage.removeItem("redirectURL");
      localStorage.clear();
      state.userType = "";
      state.verified = false;
      state.register2 = false;
      state.verifyOtp = false;
    },
    wantACallback: (state, action) => {
      // console.log("action for want a callback : ", action);

      state.wantACallback = action.payload.wantACallback;
    },
  },
  extraReducers: {
    [loginItem.fulfilled]: (state, action) => {
      if (action.payload.data.token) {
        state.userInfo = action.payload.data.data;

        let updateConnector = true;
        state.loggedIn = updateConnector;
        state.userType = action.payload.data.data.owner.userType;
      } else {
        state.loggedIn = false;
      }
    },
    [AdminLoginItem.fulfilled]: (state, action) => {
      if (action.payload.data.token) {
        state.userInfo = action.payload.data;
        let updateConnector = true;
        state.loggedIn = updateConnector;
        state.userType = action.payload.data.admin.userType;
      } else {
        state.loggedIn = false;
      }
    },
    [signupItem.fulfilled]: (state, action) => {
      if (action.payload.data.token) {
        state.userInfo = action.payload.data.user;

        let updateConnector = true;
        state.loggedIn = updateConnector;
        state.token = action.payload.token;
      } else {
        state.loggedIn = false;
      }
    },
    [signupItem2.fulfilled]: (state, action) => {
      if (localStorage.getItem("token")) {
        state.userInfo = action.payload.data;
        let updateConnector = true;
        state.register2 = updateConnector;
        state.userType = action.payload.data.owner.userType;
      }
    },
    [addStudentToUpcomingPoolItem.fulfilled]: (state, action) => {
      if (localStorage.getItem("token")) {
        return;
      }
    },
    [updateStudentToUpcomingPoolItem.fulfilled]: (state, action) => {
      if (localStorage.getItem("token")) {
        return;
      }
    },
    [sendOTPItem.fulfilled]: (state, action) => {
      if (action.payload.type === "success") {
        state.verifyOtp = true;
      } else {
      }
    },
    [verifyOTPItem.fulfilled]: (state, action) => {
      if (action.payload.type === "success") {
        state.verified = true;
        state.userInfo = action.payload.data;
        state.verifyOtp = false;
      } else {
        state.otpError = true;
      }
    },
    [linkedinSignUpItem.fulfilled]: (state, action) => {
      if (localStorage.getItem("token")) {
        state.userInfo = JSON.parse(localStorage.getItem("user"));
        let updateConnector = true;
        state.loggedIn = updateConnector;
        state.token = localStorage.getItem("token");
        state.userType = localStorage.getItem("userType");
      } else {
        state.loggedIn = false;
      }
    },
    [linkedinLoginItem.fulfilled]: (state, action) => {
      if (localStorage.getItem("token")) {
        state.userInfo = JSON.parse(localStorage.getItem("user"));
        let updateConnector = true;
        state.loggedIn = updateConnector;
        state.token = localStorage.getItem("token");
        state.userType = localStorage.getItem("userType");
      } else {
        state.loggedIn = false;
      }
    },
    [studentEditProfileItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentUploadProfilePicItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalUploadProfilePicItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalEditProfileItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentEditTypProfileItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalEditTypProfileItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentEditProject.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalEditProject.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentAddProject.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalAddProject.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentDeleteDetails.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalDeleteDetails.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [studentHireableItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload.data;
      }
    },
    [professionalHireableItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload.data;
      }
    },
    [hrHireStudentItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [hrHireProfessionalItem.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [getActiveEventsItem.fulfilled]: (state, action) => {
      state.activeEvents = action.payload.data;
    },
    [contestSubmissionItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload;
      }
    },
    [studentItem1.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
    [professionalItem1.fulfilled]: (state, action) => {
      state.userInfo = action.payload.data;
    },
  },
});
export const { logoutFunction, wantACallback, signup2, changeOpenLoginModal } =
  userSlice.actions;
export default userSlice.reducer;
