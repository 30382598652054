import React, { useState, useEffect,useCallback } from "react";
import { Link } from "react-scroll";
import { useHistory } from "react-router-dom";
// owl carousel
import OwlCarousel from "react-owl-carousel";
// images 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
// import  {  } from "react";
import bannerImg from "../../assets/images/banner-img.png";
import { Helmet } from "react-helmet";
import itforme from "../../assets/images/it-for-me-img.svg";
import img1 from "../../assets/images/Img-1.png";
import img2 from "../../assets/images/Img-2.png";
import img3 from "../../assets/images/Img-3.png";
import certification from "../../assets/images/certification_showcase.svg";
import assesment from "../../assets/images/assessment.svg";
import reporting from "../../assets/images/reporting.svg";
import project from "../../assets/images/project-work.svg";
import mentorship from "../../assets/images/mentorship.svg";
import enrollment from "../../assets/images/enrollment.svg";
import iosImg1 from "../../assets/images/ios-img-1.svg";
import iosApp from "../../assets/images/iosapp.svg";
import RequestACallbackModal from "./Components/Modal/Request.Callback";
import QuickCallbackModal from "./Components/Modal/QuickCallback.modal";
import { useSelector, useDispatch } from "react-redux";

import sliderImg1 from "../../assets/images/students/himanshu-bhardwaj-post.png";
import proImg1 from "../../assets/images/students/himanshu-bhardwaj.jpg";

import sliderImg2 from "../../assets/images/students/prasheet-pathak-post.png";
import proImg2 from "../../assets/images/students/prasheet-pathak.jpg";

import sliderImg3 from "../../assets/images/students/shubhangi-khare-post.png";
import proImg3 from "../../assets/images/students/shubhangi-khare.jpg";

import sliderBackGround from "../../assets/images/slider-background.png";
import LoginModal from "../AuthPages/Login-modal";
import { toast } from "react-toastify";
import { wantACallback } from "../../data/reducers/auth.reducer";
import {
  getUpcomingPoolItem,
  toggleWantACallbackPool,
} from "../../data/reducers/home.reducer";

import "../../../src/shared/styles/styles.css";
import { getBatchesItem } from "../../data/reducers/admin/batches/batches-admin.reducer";
import discussImage from "../../assets/images/discuss.png";
import image1 from "../../assets/images/image3.png";
import image2 from "../../assets/images/image4.png";
import image3 from "../../assets/images/image5.png";
import "../../shared/styles/styles.css";
import recruitmentImage from "../../assets/images/recruitment.png";
import c1 from "../../assets/images/c1.png";
import homepage from "../../assets/images/homepage1.png"
import c2 from "../../assets/images/c2.png";
import c3 from "../../assets/images/c3.png";
import c4 from "../../assets/images/c4.png";
import c5 from "../../assets/images/c5.png";
import c6 from "../../assets/images/c6.png";
import c7 from "../../assets/images/c7.png";
import c8 from "../../assets/images/c8.png";
import c9 from "../../assets/images/c9.png";
// import greyOrange from "../../assets/images/greyimage.png";
// import cueMath from "../../assets/images/cuemath.png";
// import simpiLearn from "../../assets/images/simpilearn.png";
// import bharatPea from "../../assets/images/bharatpea.png";
// import shareChat from "../../assets/images/sharechat.png";
// import ajio from "../../assets/images/ajio.png";
import groupImage from "../../assets/images/group1.jpeg";
import bghome from "../../assets/images/homepage.png"
const Homepage = () => {
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const first = [
    'Full-Stack', 'Java', 'Assured'
]
const second = [
  'Fellowship', 'Script', 'Placement'
]
const third = [
  'Program', 'Workshop', 'Program'
]
const [newFirst, setnewFirst] = useState("Full-Stack");
const [newsec, setnewsec] = useState("Fellowship");
const [newThird, setnewthird] = useState("Program");
  const history = useHistory();
  const dispatch = useDispatch();
  const [getDetailsAfterLogin, setGetDetailsAfterLogin] = useState();
  const [upcomingPool, setUpcomingPool] = useState();
  const authState = useSelector((state) => state.authReducer);
  const register2 = useSelector((state) => state.authReducer).register2;
  const userType = useSelector((state) => state.authReducer).userType;
  const upcomingBatches = useSelector((state) => state.batchAdminReducer)
    .batches;
    let i=0;
    const shuffle = useCallback(() => {
      if(i==3){
        i=0;
      }
      const index = i
      setnewFirst(first[index]);
      setnewsec(second[index]);
      setnewthird(third[index]);
      i++;
  }, []);

  useEffect(() => {
      const intervalID = setInterval(shuffle, 2000);
      return () => clearInterval(intervalID);
  }, [shuffle])
  const responsive = {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 2,
      nav: false,
    },
    800: {
      items: 2,
      nav: false,
    },
    1281: {
      items: 3,
      nav: true,
      loop: true,
    },
  };
  const getUpcoimgPools = async () => {
    const response = await dispatch(getBatchesItem());
    console.log("response: ", response);
    if (response?.payload?.isSuccessful) {
      return;
    }
    toast.error(response?.payload?.message?.data?.message);
  };

  const checkLogin = (_id, pool) => {
    if (!authState.loggedIn) {
      dispatch(toggleWantACallbackPool({ upcomingPoolId: _id }));
      dispatch(wantACallback({ wantACallback: true }));
      if (register2) {
        return history.push("/signup/part2");
      }
      // history.push("/signup");
      return;
    }
    setUpcomingPool(pool?.name);
    return setGetDetailsAfterLogin(true);
  };

  useEffect(() => {
    getUpcoimgPools();
  }, []);

  return (
    <>
    
      <div id="homepage_content">
     
        <section className="homePage" >
       {/*  <ul className="shapes">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
       </ul> */}
       {/* <ul className="box_area">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul> */}
          <div className="">
            
            <img  data-aos="fade-down"
            data-aos-duration="500" src={bghome} alt=""/>
          </div>
          <span data-aos="fade-left"
            data-aos-duration="500" className="mainHeading"> {newFirst} <br/>        
         <span style={{color:"#1D3883"}}> {newsec} </span><br/>
         <span style={{color:"#0AB3DE"}}>  {newThird} </span> </span>
         
          <span className="subheading">
            Is An Initiative To Fill-In The Gap Between Current Technology
            Education At Universities And Actual Market Requirements
          </span>
          <span className="subHeading1"> 
            Do You Feel The Your Current Education Is Not Enough To Land You
            Your Dream Job In Tier-| Technology Companies With Handsome
            Packages?
          </span>
          <Link to="contactUs">
          <button  className="btn yesHelp">Yes! Help Me Out.</button>
          </Link>
         
        </section>
        <section className="homepage1">
            <div className="row">
              <div className="col-12">
              <img
                src={homepage}
                className=" " width="100%" ></img>
              </div>
              <div className="col-12">
                <h1  className="company_name p-2"> 
                  <span  style={{color:"#FF8227"}}>{newFirst}</span> <br/><span style={{color:"#1D3883"}}>{newsec}</span> <br/><span style={{color:"#0AB3DE"}}>{newThird}</span>
                </h1>
               
              </div>
              <div className="col-12">
              <h4 className="subheading px-3">
                Is An Initiative To Fill-In The Gap Between Current Technology
            Education At Universities And Actual Market Requirements
                </h4>
                <h5 className="subHeading1 p-3">
                Do You Feel The Your Current Education Is Not Enough To Land You
            Your Dream Job In Tier-| Technology Companies With Handsome
            Packages?
                </h5>
              </div>
              <div className="col-12">
              <Link to="contactUs" className="p-3">
          <button  className="btn yesHelp">Yes! Help Me Out.</button>
          </Link>
              </div>
            </div>
        </section>
        {/* <section>
     
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="bg-primary"
            id="blue-curve"
          >
            <img src={image3} className="imageresize1" width=""></img>
          </div>
       
        </section> */}
        {/* <section>
          <div className="row container1">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2 p-2 section1">
             
              <div className="row">
                <div className="col-12">
                  <h1
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    className="company_name p-2"
                  >
                    The Youth Project
                  </h1>
                </div>
              </div>
              <div className="row recImagesbox">
                <div className="col-12">
                  <h4
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    className="textStyle p-2"
                  >
                    Is an initiative to fill-in the gap between current
                    technology education at univerisities and actual market
                    requirements
                  </h4>
                  <button
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    style={{
                      backgroundColor: "#1d3883",
                      border: "#1d3883",
                      borderRadius: "25px",
                      color: "white",
                      height: "50px",
                      width: "220px",
                    }}
                    className="p-2 m-3"
                  >
                    <Link to="contactUs">YES ! HELP ME OUT</Link>
                  </button>
                </div>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              className="col-lg-6 col-md-6 col-sm-12 col-12"
            >
              <img
                src={discussImage}
                className=" imageresize"
                style={{ marginTop: "-25px" }}
              ></img>
            </div>
          </div>
        </section> */}

        {/* <Helmet>
          <meta
            name="description"
            content="TheYouthProject exists to bridge gap between the current technical education and actual market requirements. Join us to know more how you can score tier-1 jobs in IT field."
          />
          <title>The Youth Project</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <h1 className="text-white line-H-1 mt-md-5 mt-4 display-2 font-weight-bold K-font">
                  The Youth Project
                </h1>
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-light K-font">
                <h3 className="Fweight-500 ">
                  is an initiative to fill-in the gap between current technology
                  education at univerisities and actual market requirements
                </h3>

                <p className="font-size-20 font-weight-normal py-md-4 py-3">
                  Do you feel the your current education is not enough to
                  <br />
                  land you your dream job in Tier-1 technology companies <br />
                  with handsome packages?
                </p>
                <div className="">
                  <Link
                    className="btn btn-white mr-3 text-uppercase font-size-14 K-font Fweight-500"
                    to="/contact"
                  >
                    yes help me out!
                  </Link>
                  <a
                    className="btn btn-outline-primary text-uppercase font-size-14 K-font Fweight-500"
                    href="#howitwork"
                  >
                    how does it work?
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="banner-img text-center mx-auto mt-md-0 mt-4">
                <img className="img-fluid" src={bannerImg} alt="img" />
              </div>
            </div>
          </div>
        </div> */}

        <section className="how_it_work " id="howitwork">
          <div className="container">
            {/* <div className="row">
            <div className="col-lg-7">
              <div className="title font-weight-bold">
                <h3 className="">AND...HOW DOES IT WORK? </h3>
                <h6 className="font-size-20 text-gray">
                  TYP. exists to carve out the professional inside you.
                </h6>
                <p className="font-size-20 text-dark-gray py-md-3 py-2">
                  It does not only focus on teaching the right skills, but the
                  students are not certified until and unless they show their
                  perfect ability to use and present those skills by creating a
                  presentable solution.
                </p>
              </div>
            </div>
          </div> */}

            <div className="row">
              <div
                data-aos="fade-up-right"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={enrollment} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#1. Enrolment</h6>
                  <p className="font-size-14 mb-0 overflow-hidden">
                    You join TYP community and fellowship program
                  </p>
                </div>
              </div>

              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={mentorship} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#2 . Mentorship</h6>
                  <p className="font-size-14 mb-0 overflow-hidden">
                    As part of TYP community, you attend internal community
                    events with students like you who have the hunger to learn.
                    As part of fellowship program, you are taught LIVE by
                    mentors with proven experience in tech industry with Tier-1
                    companies on our online platform.
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-up-left"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={project} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#3 . Project Work</h6>
                  <p className="font-size-14 mb-0 overflow-auto">
                    You are assited by mentors to complete a presentable project
                    in team, also learning about tech collaboration and planning
                    tools like GitHub projects, DevOps and OSS contribution.
                  </p>
                </div>
              </div>

              <div
                data-aos="fade-up-right"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={reporting} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#4 . Reporting</h6>
                  <p className="font-size-14 mb-0 overflow-auto">
                    You create presentation and execution report just like in
                    real tech companies about your work and submit it for
                    assessment. These project informations are also shared to
                    the companies as your work portfolio.
                  </p>
                </div>
              </div>

              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={assesment} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#5 . Assessment</h6>
                  <p className="font-size-14 mb-0 overflow-auto">
                    A TYP mentor assess your report, helps you make it better
                    and approves you for certification once satisfied.
                  </p>
                </div>
              </div>

              <div
                data-aos="fade-up-left"
                data-aos-duration="1000"
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <div className="card card-hvr shadow">
                  <div className="card-img">
                    <img src={certification} alt="img" />
                  </div>
                  <h6 className="py-3 mb-0">#6 . Certification & Showcase</h6>
                  <p className="font-size-14 mb-0 overflow-auto">
                    You earn a certificate mentioning your work, remark from
                    mentors and your profile is showcased to our employer
                    network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="it_for_me bg-primary pt-5 pb-sm-5 pb-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="title text-center">
                  <h3
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    className="pt-md-4 text-white pt-2"
                  >
                    IS IT FOR ME?
                  </h3>
                  <h5
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    className="text-light pb-sm-5 pb-2 pt-2"
                  >
                    TYP. is for anyone who is a part of the groups below,
                  </h5>
                </div>
              </div>

              <div
                data-aos="flip-left"
                data-aos-duration="1000"
                className="col-lg-6 col-md-6 col-sm-12 col-12"
              >
                <div className="it_for_me_img_box text-center mb-4 mb-0">
                  <img className="img-fluid" src={itforme} alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-hvr shadow d-flex justify-content-center align-items-start">
                      <h4
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-warning"
                      >
                        Students
                      </h4>
                      <p
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-light font-size-14"
                      >
                        {" "}
                        who want to upskill and earn more than what the campus
                        placements are capable to offer to them
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-hvr shadow d-flex justify-content-center align-items-start">
                      <h4
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-warning"
                      >
                        Professionals
                      </h4>
                      <p
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-light font-size-14"
                      >
                        who are not finding the right opportunities in the
                        market to grow
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-hvr shadow d-flex justify-content-center align-items-start">
                      <h4
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-warning"
                      >
                        Individuals
                      </h4>
                      <p
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-light font-size-14"
                      >
                        who want to start their freelancing / consulting /
                        remote work career
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card card-hvr shadow d-flex justify-content-center align-items-start">
                      <h4
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-warning"
                      >
                        People
                      </h4>
                      <p
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        className="text-light font-size-14"
                      >
                        who want to start with programming but don't know how to
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {getDetailsAfterLogin && userType != "admin" ? (
          <RequestACallbackModal
            pool={upcomingPool}
            page="homepage"
            setGetDetailsAfterLogin={setGetDetailsAfterLogin}
          />
        ) : null}

        {/* ios application start */}
        {/* <section className="ios_application">
        <div className="container">
          <div className="row">
            <div className="col-7 col-md-12">
              <h3>STUDENT WORK</h3>
              <h2>ParkWith i0S Application</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look
                believable.
              </p>
              <a className="btn btn-warning" href="#">
                View case study
              </a>
              <div className="ios_app-img-box">
                <img className="img-fluid" src={iosImg1} alt="img" />
              </div>
            </div>
            <div className="col-5 col-md-12 text-center">
              <img className="img-fluid" src={iosApp} alt="img" />
            </div>
          </div>
        </div>
      </section> */}
        {/* ios application close */}

        {/* upcoming batch start */}
        <section
          id="upcomingbatch"
          className="upcoming_batch pt-5 pb-sm-5 pb-4"
        >
          <div className="container">
            <div className="row align-items-center justify-content-start">
              <div className="col-md-12">
                <div className="title text-center">
                  <h3
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    className="pt-md-4 pt-2"
                  >
                    UPCOMING BATCH DATES
                  </h3>
                  <h5
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    className="text-gray pb-sm-4 pb-2 pt-2 line-H-12"
                  >
                    We conduct offline and online classes, and updates for it
                    are regularly posted.
                    <br /> Keep an eye on this section.
                  </h5>
                </div>
              </div>

              {
                //have to map items
                upcomingBatches?.map((pool) => (
                  <div
                    data-aos="flip-right"
                    data-aos-duration="1000"
                    className="col-lg-4 col-md-6 mb-3 mb-lg-0"
                  >
                    <div className="card card-hvr shadow">
                      <div
                        className="card-header"
                        style={{
                          background: `url(${pool?.imageURI}) no-repeat top center / cover `,
                        }}
                      ></div>

                      <div className="card-body">
                        <p className="online-class text-uppercase font-weight-bold font-size-12 text-secondary mb-0">
                          Mode: {pool?.mode} class
                        </p>
                        {/* <h4 className="py-1 text-warning mb-0">{new Date(pool.startDate)}</h4> */}
                        <h4 className="py-1 text-warning mb-0">
                          START DATE: {pool?.startDate}
                        </h4>
                        <p className="dis_H text-uppercase font-weight-bold font-size-14 text-secondary">
                          Batch: {pool?.name} <br />
                          (duration {pool?.duration} months)
                        </p>
                        {/* Need to have time and am/pm check */}
                        <p className="text-uppercase font-weight-bold font-size-14 text-gray">
                          Time: {pool?.timing}
                        </p>
                        <p className="text-uppercase font-weight-bold font-size-14 text-gray">
                          Track: {pool?.track}
                        </p>
                        <div className="row">
                          <div className="col">
                            {loggedIn && (
                              <a
                                className="btn btn-outline-yellow btn-block font-size-14 Fweight-600 px-4"
                                onClick={() => {
                                  //Only student is implemented for request a callback
                                  if (
                                    userType != "admin" ||
                                    userType == null ||
                                    userType == "" ||
                                    userType == undefined
                                  ) {
                                    checkLogin(pool?._id, pool);
                                  }
                                }}
                                href="#"
                              >
                                ENROLL NOW
                              </a>
                            )}
                            {!loggedIn && (
                              <LoginModal purpose="enroll"></LoginModal>
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col  d-flex justify-content-between ">
                            {/* //add quick callback */}
                            <div className="mr-2">
                              <QuickCallbackModal pool={pool} />
                            </div>
                            <a
                              className="btn btn-outline-yellow font-size-14 Fweight-600 px-4"
                              // Enter a book a demo link of calendly
                              target="_blank"
                              href={pool?.book_demo_redirect_url}
                            >
                              Book a Demo
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        {/* <div className="col-4 col-md-7">
              <div className="card card-hvr shadow">
                <div className="card-header" style={{background: `url(${img1}) top center no-repeat`,}}>
                </div>
                
                <div className="card-body">
                  <p className="online-class text-uppercase font-weight-bold font-size-12 text-secondary mb-0">online class</p>
                  <h4 className="py-1 text-warning mb-0">March 10, 2021</h4>
                  <p className="text-uppercase font-weight-bold font-size-14 text-secondary">Javascript full Stack Development <br />(duration 2 months)</p>
                  <p className="text-uppercase font-weight-bold font-size-14 text-gray">07:30 PM onwards</p>
                  <a className="btn btn-outline-yellow font-size-14 Fweight-600 px-4" href="/contact">Request a call back</a>
                </div>
              </div>
            </div>
            <div className="col-4 col-md-7">
              <div className="card card-hvr shadow">
                <div className="card-header" style={{background: `url(${img1}) top center no-repeat`,}}>
                </div>
                
                <div className="card-body">
                  <p className="online-class text-uppercase font-weight-bold font-size-12 text-secondary mb-0">online class</p>
                  <h4 className="py-1 text-warning mb-0">March 10, 2021</h4>
                  <p className="text-uppercase font-weight-bold font-size-14 text-secondary">MERN Stack Development <br />(duration 2 months)</p>
                  <p className="text-uppercase font-weight-bold font-size-14 text-gray">07:30 PM onwards</p>
                  <a className="btn btn-outline-yellow font-size-14 Fweight-600 px-4" href="/contact">Request a call back</a>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      {/* upcoming batch close */}

        {/* enroll now start */}

        <section className="enroll_now  ">
          <div className="bg">
            <div className="container">
              <div className="row">
                {/* <div className="col-12">
            <div className="title text-center">
                <h3 className="">ENROLL NOW!</h3>
                <h5 className="text-gray pb-sm-4 pb-2 pt-2 line-H-12 px-5 mx-5">
                  The Youth Project is created by a group of high skilled
                  engineers who have worked as team leaders, CXO's and
                  professional developers, and have felt the need to do
                  something about the problem with the current workforce.
                </h5>
              </div>
            </div> */}
                <div className="col-12">
                  <h2
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    className="text-primary text-center mt-4 mb-4 font-weight-800"
                  >
                    {" "}
                    OUR REVIEWS
                  </h2>

                  {/* <div>
                  <img
                    src={sliderBackGround}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div> */}

                  <OwlCarousel
                    loop
                    data-aos="zoom-out"
                    data-aos-duration="1000"
                    margin={30}
                    center
                    responsiveClass
                    responsive={responsive}
                    nav
                    className="owl-theme"
                    style={{ marginTop: "55px" }}
                  >
                    <div>
                      <div
                        className=" scales shadow  pt-4 pb-3 px-2"
                        style={{
                          border: "3px solid rgb(251 253 253)",
                          borderRadius: "30px",

                          // width: "330px",
                        }}
                      >
                        <div className="d-flex justify-content-around mt-3">
                          {/* <div className="proimg"> */}
                          <div>
                            <img
                              src={proImg1}
                              alt=""
                              style={{
                                width: "70px",
                                height: "66px",
                                borderRadius: "35px",
                              }}
                            />
                            <p
                              className="text-center text-warning mb-0"
                              style={{
                                // width: "85px",
                                fontSize: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Himanshu <br /> Bhardwaj
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "172px",
                              margin: "0",
                            }}
                          >
                            Hi My name is Himanshu Bhardwaj and I’m pursuing
                            B.tech from poornima University, Jaipur. Currently
                            I’m in 7th semester.
                            {/* I heard about TYP but was not */}
                            {/* aware how can It push */}
                            {/*  me on a high level in my
                            career. I met Devansh sir who guided me on how I can
                            enter in web development and what are its
                            advantages. The first impression from him literally
                            clarified my all doubts about how dedicated and
                            knowledgeable he is in this field. 
                            That made me
                            confident enough to be a part of this project." */}
                          </p>
                        </div>
                        {/* <p
                            className="text-center text-warning mb-0"
                            style={{
                              width: "85px",
                              fontSize: "8px",
                              fontWeight: "bold",
                              
                            }}
                          >
                            Himanshu <br /> Bhardwaj
                          </p> */}
                      </div>
                    </div>
                    <div>
                      <div
                        className="scales shadow pt-4 pb-3 px-2"
                        style={{
                          backgroundColor: "#fff",
                          border: "3px solid rgb(251 253 253)",

                          borderRadius: "30px",
                          // width: "330px",
                        }}
                      >
                        <div className="d-flex justify-content-around mt-3">
                          {/* <div className="proimg"> */}
                          <div>
                            <img
                              src={proImg2}
                              alt=""
                              style={{
                                width: "70px",
                                height: "66px",
                                borderRadius: "35px",
                              }}
                            />
                            <p
                              className="text-center text-warning mb-0 scales"
                              style={{
                                // width: "85px",
                                fontSize: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Prasheet <br /> Pathak
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "172px",
                              margin: "0",
                            }}
                          >
                            Hello I am Prasheet Pathak and I’m from Indore. I am
                            a 3rd year student of B.tech in computer science. I
                            enrolled into the TYP{" "}
                            {/*a  few months ago and it was
                            an enthralling experience as I got to witness and
                            learn all the updated technologies in the market not
                            only empowered my mission but also enhanced my skill
                            set. Currently I am working on different
                            technologies and receiving number of job offers and
                            I just don’t feel */}
                            {/* like its impossible as TYP already made me familiar with
                        the job culture environment. I would like to thank all
                        the faculties and team members of TYP for bringing out
                        best in me." */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className=" shadow  pt-4 pb-3 px-2 scales"
                        style={{
                          border: "3px solid rgb(251 253 253)",
                          borderRadius: "30px",

                          // width: "330px",
                        }}
                      >
                        <div className="d-flex justify-content-around mt-3">
                          <div>
                            <img
                              src={proImg3}
                              alt=""
                              style={{
                                width: "70px",
                                height: "66px",
                                borderRadius: "35px",
                              }}
                            />
                            <p
                              className="text-center text-warning mb-0"
                              style={{
                                // width: "85px",
                                fontSize: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Shubhangi <br /> Khare
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "172px",
                              margin: "0",
                            }}
                          >
                            Hello, I am Shubhangi Khare. Few month ago when I
                            was promoted to 7th semester of my B.tech in
                            computer science,
                            {/*} I was very confused regarding my
                            placements. And then I heard about TYP, without a
                            second thought I enrolled myself with TYP. After
                            studying at TYP for more than 2 months, I certainly
                            felt that the environment which TYP provides I
                            wonderful for students. Everyone is friendly and
                            co-operative. I have never seen such type of
                            environment anywhere else. All the mentees are ready
                            to help students anytime. The training helped me to
                            receive multiple job offers. I feel grateful for
                            being a part of TYP family. I heartily thank TYP for
                            their tremendous support.*/}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="shadow pt-4 pb-3 px-2 scales"
                        style={{
                          backgroundColor: "#fff",
                          border: "3px solid rgb(251 253 253)",

                          borderRadius: "30px",
                          // width: "330px",
                        }}
                      >
                        <div className="d-flex justify-content-around mt-3">
                          <div>
                            <img
                              src={proImg2}
                              alt=""
                              style={{
                                width: "70px",
                                height: "66px",
                                borderRadius: "35px",
                              }}
                            />
                            <p
                              className="text-center text-warning mb-0"
                              style={{
                                // width: "85px",
                                fontSize: "8px",
                                fontWeight: "bold",
                              }}
                            >
                              Prasheet <br /> Pathak
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              width: "172px",
                              margin: "0",
                            }}
                          >
                            Hello I am Prasheet Pathak and I’m from Indore. I am
                            a 3rd year student of B.tech in computer science. I
                            enrolled into the TYP{" "}
                            {/*a  few months ago and it was
                            an enthralling experience as I got to witness and
                            learn all the updated technologies in the market not
                            only empowered my mission but also enhanced my skill
                            set. Currently I am working on different
                            technologies and receiving number of job offers and
                            I just don’t feel */}
                            {/* like its impossible as TYP already made me familiar with
                        the job culture environment. I would like to thank all
                        the faculties and team members of TYP for bringing out
                        best in me." */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-5 recruiters">
          <h2
            data-aos="fade-left"
            data-aos-duration="1000"
            style={{ color: "#1d3883" }}
            className="text-center"
          >
            OUR STUDENTS PLACED IN
          </h2>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img
                data-aos="flip-left"
                data-aos-duration="1000"
                src={recruitmentImage}
                className="imageresize px -4"
              ></img>
            </div>
            <div className="recImagesbox col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="row px-5">
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4 circle"
                >
                  {/* <img src={greyOrange} className="imageresize p-2"></img> */}
                  <img src={c1} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={cueMath} className="imageresize p-2" ></img> */}
                  <img src={c2} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={cueMath} className="imageresize p-2" ></img> */}
                  <img src={c3} className="imageresize p-2"></img>
                </div>
              </div>
              <div className="row px-5">
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={simpiLearn} className="imageresize p-2" ></img> */}
                  <img src={c4} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={bharatPea} className="imageresize p-2" ></img> */}
                  <img src={c5} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={cueMath} className="imageresize p-2" ></img> */}
                  <img src={c6} className="imageresize p-2"></img>
                </div>
              </div>
              <div className="row px-5">
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={shareChat} className="imageresize p-2" ></img> */}
                  <img src={c7} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={ajio} className="imageresize p-2"></img> */}
                  <img src={c8} className="imageresize p-2"></img>
                </div>
                <div
                  data-aos="flip-right"
                  data-aos-duration="1000"
                  className="col-4"
                >
                  {/* <img src={cueMath} className="imageresize p-2" ></img> */}
                  <img src={c9} className="imageresize p-2"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="row count">
            <div data-aos="zoom-in" data-aos-duration="1000" className="col-12">
              <img src={groupImage} className=""></img>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Homepage;
