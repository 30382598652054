import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { linkedinLoginItem } from "../../data/reducers/auth.reducer";
import { AdminLoginItem } from "../../data/reducers/auth.reducer";
import loginrightimg from "../../assets/images/loginrightimg.png";
import LinkedIn from "react-linkedin-login-oauth2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../config";

export const redirectUri = constants.redirectUri;
export const clientid = constants.clientid;

const AdminLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  let [userData, setUserdata] = useState({ email: "", password: "" });
  const change = (event) => {
    const { name, value } = event.target;
    setUserdata({ ...userData, [name]: value });
  };
  const handleSuccess = async (data) => {
    const payload = {
      data,
      redirectUri,
    };
    setLoad(true);
    try {
      let response = await dispatch(linkedinLoginItem(payload));
      if (response.payload.isSuccessful) {
        setLoad(false);
        return toast.success("Login Successfull");
      }
      setLoad(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFailure = (error) => {
    toast.error(error);
  };
  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { name, value } = event.target;
    try {
      let response = await dispatch(AdminLoginItem(userData));
      if (response.payload.isSuccessful) {
        history.push("/admin");
        setLoading(false);
        return toast.success("Login Successfull");
      }
      setLoading(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  if (
    (loggedIn === true && usertype === "admin") ||
    (loggedIn === true && usertype === "technical-operation")
  ) {
    return <Redirect to="/admin" />;
  }
  const { email, password } = userData;
  return (
    <>
      <section className="loginsection">
        <div className="container">
          <div className="row tp-row pt-4">
            <div className="col-md-3"></div>
            <div className="col-md-6 text-center">
              <h3> Admin Login</h3>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="borderbox">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-lg-1">
                    <img src={loginrightimg} alt="" className="img-fluid" />
                  </div>
                  <div className="col-lg-6 leftform">
                    <div className="form-group">
                      <label for="">Email</label>

                      <input
                        type="email"
                        name="email"
                        value={email}
                        className="form-control form-control-lg"
                        onChange={change}
                      />
                    </div>
                    <div className="form-group">
                      <label for="">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg"
                        value={password}
                        onChange={change}
                      />
                    </div>

                    <div className="text-center">
                      <a
                        href="javascrit:void(0);"
                        className="link"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-target="#exampleModal"
                      >
                        Forgot Password ?
                      </a>
                    </div>

                    <div className="btn-bar">
                      <button
                        disabled={loading}
                        className="btn btn-warning w-100 form-control-lg text-white text-uppercase h5 mt-sm-4 mt-3 mb-2"
                        onClick={submit}
                      >
                        Log In
                      </button>
                    </div>
                    <br />
                    {loading && (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="modal fade bd-example-modal-lg"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog  modal-lg modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <button
                        type="button"
                        className="close d-lg-none d-md-none"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa fa-close"></i>
                      </button>

                      <div className="modal-body">
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Forgot Password ?
                        </h5>
                        <p className="text-center">
                          {" "}
                          Lorem ipsum, or lipsum as it is sometimes known, is
                          dummy text used in laying out print, graphic or web
                          designs.
                        </p>

                        <label for="">Email</label>

                        <input type="email" className="form-control" />

                        <button type="button" className="btn btn-primary">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
