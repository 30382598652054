import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  studentEditTypProfileItem,
  studentDeleteDetails,
  professionalEditTypProfileItem,
  professionalDeleteDetails,
} from "../../../data/reducers/auth.reducer";
import Loader from "../../../shared/components/Loaders/Loader";
// import { studentItem } from "../../../data/reducers/student.reducer";
import { checkEmpty, checkDateSE } from "../../../shared/Utils/method";

//..
const WorkProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;

  const [deleteApprove, setDeleteApprove] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [empty_edit, setEmptyEdit] = useState(true);
  const [field, setField] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [index, setIndex] = useState(null);
  const [work, setWork] = useState([...user.workExperience]);
  const [editWork, setEditWork] = useState();
  const [workAdd, setWorkAdd] = useState(false);
  const [workEdit, setWorkEdit] = useState(false);
  const [workDelete, setWorkDelete] = useState(false);

  const [newWork, setNewWork] = useState({
    workTitle: "",
    projectDescription: "",
    startDate: "",
    endDate: "",
  });

  //edit education handle change
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setEditWork({ ...editWork, [name]: value });

    //check empty for fields
    setEmptyEdit(checkEmpty({ ...editWork, [name]: value }));
  };

  //add education handle change
  const handlenewWorkChange = (e) => {
    const { name, value } = e.target;
    setNewWork({ ...newWork, [name]: value });
    //To add checkEmpty
    // setEmpty(checkEmpty({ ...newWork, [name]: value }));
  };
  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;
    document.getElementById("workDate").setAttribute("max", maxDate);
    document.getElementById("workDate1").setAttribute("max", maxDate);
    document.getElementById("workDate2").setAttribute("max", maxDate);
    document.getElementById("workDate3").setAttribute("max", maxDate);
  });
  useEffect(() => {
    setWork([...user.workExperience]);
  }, [user]);

  //edit submit
  const submit = async (e) => {
    e.preventDefault();
    const res = checkEmpty(editWork);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (checkDateSE(editWork.startDate, editWork.endDate))
      return toast.error("Start date should be less than End date");
    setWorkEdit(true);
    //
    work[index] = editWork;
    //
    const workExperience = work;
    const payload = { workExperience, id };
    //
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setInputErr([]);
          setEditWork();
          setWorkEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditWork();
        setWorkEdit(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setInputErr([]);
          setEditWork();
          setWorkEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditWork();
        setWorkEdit(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}
    // window.location.reload()
    // return history.push("/studentFullProfile");
  };

  //set id and field
  const deleteButton = (field, id, i) => {
    localStorage.setItem("field", field);
    localStorage.setItem("deleteId", id);
    localStorage.setItem("iW", i);
    // setField(field);
    // setDeleteId(id);
  };

  //delete api
  const deleteFields = async () => {
    setWorkDelete(true);
    const field = localStorage.getItem("field");
    const deleteId = localStorage.getItem("deleteId");
    const iW = localStorage.getItem("iW");
    // work.splice(iW, 1);
    //
    //
    const payload = { field, deleteId };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setWorkDelete(false);
          return toast.success("Delete Successfully");
        }
        setWorkDelete(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setWorkDelete(false);
          return toast.success("Delete Successfully");
        }
        setWorkDelete(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload()
    // return history.push("/studentFullProfile");
  };

  //add submit
  const submitnewWork = async (e) => {
    e.preventDefault();
    const res = checkEmpty(newWork);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (checkDateSE(newWork.startDate, newWork.endDate))
      return toast.error("Start date should be less than End date");
    setWorkAdd(true);
    work.push(newWork);
    const workExperience = work;
    const payload = { workExperience, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setInputErr([]);
          setNewWork({
            workTitle: "",
            projectDescription: "",
            startDate: "",
            endDate: "",
          });
          setWorkAdd(false);
          return toast.success("Add Successfully");
        }
        setNewWork({
          workTitle: "",
          projectDescription: "",
          startDate: "",
          endDate: "",
        });
        setWorkAdd(false);
        setInputErr([]);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setInputErr([]);
          setNewWork({
            workTitle: "",
            projectDescription: "",
            startDate: "",
            endDate: "",
          });
          setWorkAdd(false);
          return toast.success("Add Successfully");
        }
        setNewWork({
          workTitle: "",
          projectDescription: "",
          startDate: "",
          endDate: "",
        });
        setWorkAdd(false);
        setInputErr([]);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload()
  };

  //
  const [area, setArea] = useState("false");
  const { workTitle, projectDescription, startDate, endDate } = newWork;
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h4 className="mb-0" >Work Experiences</h4>
        <i
          data-target="#Work_Experiences"
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          className="fa fa-plus-circle font-20 text-success ml-3 cursor-pointer"
        />
        <div className="ml-2">
          {(workAdd || workEdit || workDelete) && (
            <>
              <Loader />
            </>
          )}
        </div>
      </div>
      {work.length > 0 ? (
        <div className="content p-0 m-0 border p-2">
          {work.length > 0 &&
            work.map((work, i) => (
              <>
                <div className="d-flex align-items-center justify-content-end ">
                  <div className="dropdown cust_notify">
                    <i
                      className="fa fa-edit  font-20 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-2"
                      role="button"
                      id="dropdownDis"
                      data-toggle="modal"
                      data-target="#Work_Experiences_Edit"
                      aria-haspopup="true"
                      data-backdrop="static"
                      data-keyboard="false"
                      aria-expanded={area}
                      onClick={() => {
                        setIndex(i);
                        //
                        setEmptyEdit(true);
                        setEditWork(work);
                      }}
                    />
                  </div>
                  <i
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    onClick={() => deleteButton("workExperience", work._id, i)}
                    data-target="#ConfDeleteWork"
                    className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                  />
                </div>
                <p className="content p-0">
                  {work.projectDescription} {work.workTitle} from{" "}
                  {work.startDate} to {work.endDate}
                </p>
              </>
            ))}
        </div>
      ) : null}

      {/* work add modal */}
      <div
        className="modal fade"
        id="Work_Experiences"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Work description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Title
                  </label>
                  <input
                    type="text"
                    name="workTitle"
                    value={workTitle}
                    onChange={handlenewWorkChange}
                    className={`form-control ${
                      inputErr.includes("workTitle") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    work description
                  </label>
                  <textarea
                    name="projectDescription"
                    value={projectDescription}
                    onChange={handlenewWorkChange}
                    className={`form-control ${
                      inputErr.includes("projectDescription")
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Description"
                  ></textarea>
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    start date
                  </label>
                  <input
                    id="workDate"
                    type="date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    name="startDate"
                    value={startDate}
                    onChange={handlenewWorkChange}
                    className={`form-control ${
                      inputErr.includes("startDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    End Date
                  </label>
                  <div className="d-flex">
                    <input
                      id="workDate1"
                      type="date"
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      disabled={
                        newWork?.endDate != "Present" && newWork?.endDate != ""
                          ? true
                          : false
                      }
                      name="endDate"
                      value={endDate}
                      onChange={handlenewWorkChange}
                      className={`form-control col-lg-8 ${
                        inputErr.includes("endDate") ? "is-invalid" : ""
                      }`}
                      placeholder="dd-mm-yy"
                    />
                    {/* <p></p> */}
                    <button
                      title="currently working"
                      type="button"
                      className="btn-warning btn text-white mx-2 col-lg-4"
                      disabled={newWork?.endDate == "Present" ? true : false}
                      onClick={() => {
                        setNewWork({ ...newWork, endDate: "Present" });
                      }}
                    >
                      Present
                    </button>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    href="#"
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => setInputErr([])}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    data-dismiss={
                      checkEmpty(newWork).length == 0 ? "modal" : null
                    }
                    // disabled={empty}
                    onClick={submitnewWork}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* work edit modal */}
      <div
        className="modal fade"
        id="Work_Experiences_Edit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Work description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Title
                  </label>
                  <input
                    type="text"
                    name="workTitle"
                    value={editWork?.workTitle}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("workTitle") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Work description
                  </label>
                  <textarea
                    name="projectDescription"
                    value={editWork?.projectDescription}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("projectDescription")
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Description"
                  ></textarea>
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    start date
                  </label>
                  <input
                    id="workDate2"
                    type="date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    name="startDate"
                    value={editWork?.startDate}
                    onChange={(e) => handleChange(e, index)}
                    className={`form-control ${
                      inputErr.includes("startDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    End Date
                  </label>
                  <div className="d-flex">
                    <input
                      id="workDate3"
                      type="date"
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      disabled={editWork?.endDate != "Present" ? true : false}
                      name="endDate"
                      value={editWork?.endDate}
                      onChange={(e) => handleChange(e, index)}
                      className={`form-control  ${
                        inputErr.includes("endDate") ? "is-invalid" : ""
                      }`}
                      placeholder="dd-mm-yy"
                    />
                    <button
                      title="currently working"
                      type="button"
                      className="btn-warning btn text-white mx-2 col-lg-4"
                      disabled={editWork?.endDate == "Present" ? true : false}
                      onClick={() => {
                        setEditWork({ ...editWork, endDate: "Present" });
                      }}
                    >
                      Present
                    </button>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    href="#"
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => setInputErr([])}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    // disabled={empty_edit}
                    data-dismiss={
                      checkEmpty(editWork).length == 0 ? "modal" : null
                    }
                    onClick={submit}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>
      {/* Delete conformation modal */}
      <div
        className="modal fade"
        id="ConfDeleteWork"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={deleteFields}
                data-dismiss="modal"
                type="button"
                className="btn btn-danger"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkProfile;
