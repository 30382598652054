import React, { useState } from "react";
import Modal from "react-modal";
import { deleteRewardConfigItem } from "../../../../data/reducers/admin.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Model = ({ item, index }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    Modal.setAppElement("#root");

    function OpenModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //handle submit
    const handleSubmit = async () => {
        try {
            const response = await dispatch(deleteRewardConfigItem({ _id: item._id }));
            if (response.payload.isSuccessful) {
                return toast.success("Action deleted successfully!");
            }
            toast.error(response.payload.message.data.message);
        } catch (err) { }
    };
    return (
        <div className="container">
            <i
                className="fa fa-trash font-weight-15 text-danger cursor-pointer"
                onClick={() => OpenModal()}
            ></i>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0,0,0,0.4)",
                        zIndex: 1,
                    },
                    content: {
                        color: "#1D3883",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f5f5f5",
                        flexDirection: "column",
                    },
                }}
            >
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <h5 className="text-center font-weight-bold">Delete Reward ?</h5>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center p-3">
                        <div className="col-12">
                            <div className="col-12 p-0">
                                <button
                                    className="btn m-1 text-white btn-secondary"
                                    // style={{ backgroundColor: "#C82333" }}
                                    onClick={() => {
                                        closeModal();
                                        handleSubmit();
                                    }}
                                >
                                    Confirm
                </button>
                                <button
                                    className="btn text-white m-1 btn-danger"
                                    // style={{ backgroundColor: "#5A6268" }}
                                    onClick={closeModal}
                                >
                                    Cancel
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Model;
