import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { editRewardConfigItem } from "../../../../data/reducers/admin.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";

const Model = ({ item, index }) => {
    //
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [inputErr, setInputErr] = useState([]);
    //init state of rewardName form
    const [editrewardName, setEditrewardName] = useState({
        _id: item._id,
        rewardName: item.rewardName,
        rewardPoints: item.rewardPoints,
        type: item?.type,
        measure: item.measure,
    });

    Modal.setAppElement("#root");

    function OpenModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //handle add change
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditrewardName({ ...editrewardName, [name]: value });
    };

    //handle submit
    const handleSubmit = async () => {
        if (editrewardName?.type === "CASH" || editrewardName?.type === "FREE") {
              console.log("in cash free");
            var res = checkEmpty(editrewardName, "rewardPoints");
            var dupeditrewardName = {
                rewardName: editrewardName.rewardName,
                type: editrewardName?.type,
                measure: editrewardName.measure,
            };
            setEditrewardName() ;
        } else {
            //   console.log("in poingts");
            var res = checkEmpty(editrewardName);
            var dupeditrewardName = {
                rewardName: editrewardName.rewardName,
                type: editrewardName?.type,
                rewardPoints: editrewardName.rewardPoints,
                measure: editrewardName.measure,
            };
        }
        
        setInputErr(res);
        if (res.length != 0) {
            return toast.warning("All fields are mandatory!");
        }

        

        const _id = item._id;
        let payload ;
        if(editrewardName?.type === "CASH" || editrewardName?.type === "FREE")
            payload = {_id, editrewardName:{...editrewardName, "rewardPoints" : " -- "}}
        else
            payload = { _id, editrewardName };
        
        console.log('payload: ', payload);
        try {
            console.log("Coming here before response");
            const response = await dispatch(editRewardConfigItem(payload));

            if (response.payload.isSuccessful) {
                setInputErr([]);
                return toast.success("Reward updated successfully!");
            }
            setInputErr([]);
            toast.error(response.payload.message.data.message);
        } catch (err) { }
    };

    return (
        <div className="container">
            <i
                className="fa fa-edit font-size-15 text-success cursor-pointer"
                onClick={() => {
                    OpenModal();
                    setEditrewardName({
                        ...editrewardName,
                        ["rewardName"]: item.rewardName,
                        ["rewardPoints"]: item.rewardPoints,
                        ["type"]: item?.type,
                        ["measure"]: item.measure,
                    });
                    setInputErr([]);
                }}
            ></i>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0,0,0,0.4)",
                        zIndex: 1,
                    },
                    content: {
                        color: "#1D3883",
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        flexDirection: "column",
                    },
                }}
            >
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <h5 className="text-center font-weight-bold">Edit reward</h5>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center p-3">
                        <div className="col-12">
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label font-weight-bold text-dark"
                                >
                                    Name
                </label>
                                <input
                                    onChange={handleEditChange}
                                    type="text"
                                    className={`form-control ${inputErr.includes("rewardName") ? "is-invalid" : ""
                                        }`}
                                    name="rewardName"
                                    value={editrewardName?.rewardName}
                                />
                            </div>
                            <div className="form-group">
                                <label className="test-dark">
                                    Reward Type
                        </label>
                                <select name="type" value={editrewardName?.type} onChange={handleEditChange} className='custom-select'>
                                    <option value="">
                                        Reward Type
                            </option>
                                    <option value="GOODIE">
                                        Goodie
                            </option>
                                    <option value="CASH">
                                        Cash
                            </option>
                                    <option value="FREE">
                                        Free
                            </option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label className='test-dark'>
                                    Measure of Reward
                        </label>
                                <select name="measure" value={editrewardName?.measure} onChange={handleEditChange} className='custom-select'>
                                    <option value="">
                                        Measure
                            </option>
                                    <option value="AMOUNT">
                                        Amount
                            </option>
                                    <option value="QUANTITY">
                                        Quantity
                            </option>
                                </select>
                            </div>             
                            
                            {editrewardName?.type !== "CASH" && editrewardName?.type !== "FREE" ? (
                                <div className="form-group">
                                    <label className="text-dark">Reward Points</label>
                                    <input
                                        onChange={handleEditChange}
                                        placeholder="Enter Reward Points"
                                        value={editrewardName?.rewardPoints}
                                        type="number"
                                        className={`form-control ${inputErr.includes("rewardPoints") ? "is-invalid" : ""
                                            }`}
                                        name="rewardPoints"
                                    />
                                </div>
                            ) : null}
                            <div className="col-12 p-0">
                                <button
                                    // disabled= checkEmpty(editrewardName).length === 0 ? true : false}
                                    className="btn m-1 text-white btn-secondary"
                                    // style={{ backgroundColor: "#C82333" }}
                                    onClick={() => {
                                        handleSubmit();
                                        return checkEmpty(editrewardName).length === 0
                                            ? closeModal()
                                            : null;
                                    }}
                                >
                                    Edit
                </button>
                                <button
                                    className="btn text-white m-1 btn-danger"
                                    onClick={() => {
                                        closeModal();
                                        setInputErr([]);
                                    }}
                                >
                                    Cancel
                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Model;
