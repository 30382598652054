import { api, get, post, put } from "./services.common";

export const addUserToZohoService = async (data) => {
  console.log("data in addUserToZohoService frontend: ", data);
  return await post(`${api}/zoho/crm/add`, data);
};

export const updateUserToZohoService = async (data) => {
  const { _id, updated_data, First_Name, Last_Name, Email, } = data;
  return await put(`${api}/zoho/crm/update/${_id}`, {
    updated_data,
    First_Name,
    Last_Name,
    Email,
  });
};
