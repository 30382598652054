import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SendTemplateLinkModal from "./Send-template-link.modal";
import { adminSendTemplateLinkItem } from "../../../data/reducers/admin.reducer";
import { getEventRegisteredUsersAdminItem } from "../../../data/reducers/admin/events/admin-event-reducer";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../../config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ConfirmationModal from "../../../shared/components/confirmation-modal/Confirmation-modal";

export const redirectUri = constants.redirectUri;
export const redirectUri1 = constants.redirectUri;
export const clientid = constants.clientid;

//*********************************************************************************************************** */
const Model = ({ skip, eventId, item }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(2);
  const [registeredUser, setRegisteredUsers] = useState([]);
  const [templateLinks, setTemplateLinks] = useState([]);
  const [showRegisteredUser, setShowRegisteredUser] = useState(false);
  const [copied, setCopied] = useState(false);
  const [linkIndex, setLinkIndex] = useState();

  const getEventRegisteredUsersAdminApi = async (eventId) => {
    // console.log("eventId: ", eventId);
    setLoader(true);
    const response = await dispatch(
      getEventRegisteredUsersAdminItem({ eventId, skip, limit })
    );
    // console.log("response: ", response);
    setLoader(true);
    if (response.payload.isSuccessful) {
      setLoader(false);
      setRegisteredUsers(response.payload?.data?.registeredusers);
      setTemplateLinks(response.payload?.data?.templateIds);
      return;
    } else if (!response.payload.isSuccessful) {
      setLoader(false);
      toast.error(response.payload.message.data.message);
      return;
    }
  };
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const sendSingleLink = async (closeModal, item) => {
    const payload = {
      userEmail: item?.registeredUsers?.email,
      userId: item?.registeredUsers?._id,
      eventId: item?._id,
      firstName: item?.registeredUsers?.firstName,
      sendSingleLink: true,
      templateLink: templateLinks.find(
        (subItem) => subItem?.userId === item?.registeredUsers?._id
      )?.templateLink,
    };
    // console.log('payload : ', payload );
    setLoader(true);
    const response = await dispatch(adminSendTemplateLinkItem(payload));

    if (response.payload.isSuccessful) {
      closeModal();
      toast.success("Sent Successfully");
      setLoader(false);
      return;
    } else {
      setLoader(false);
      toast.error(response.payload.message.data.message);
    }
  };

  return (
    <>
      <button
        disabled={loader}
        onClick={() => {
          setShowRegisteredUser(true);
          getEventRegisteredUsersAdminApi(item?._id);
          OpenModal();
        }}
        className="btn btn-primary mt-3 mb-4"
      >
        Show registered users
        {loader ? (
          <span
            id="studentTop"
            class="spinner-grow spinner-grow-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        ) : null}
      </button>
      {/* <small
        class="text-muted"
        onClick={() => {
          OpenModal();
        }}
      >
        <strong>see more</strong>
      </small> */}
      <Modal
        isOpen={modalIsOpen}
        scrollable={true}
        class="registerModal"
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(225,225,225,0.5)",
            zIndex: 20000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            height: "80vh",
            // width: "100%",
            zIndex: 9,
            right: "auto",
            bottom: "auto",
            borderRadius: "6px",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // overflow: "auto",

            padding: "0",
            backgroundColor: "#fff",
            // flexDirection: "column",
          },
        }}
      >
        {registeredUser && registeredUser.length > 0 ? (
          <SendTemplateLinkModal eventId={eventId} />
        ) : null}

        <div
          style={{
            position: "relative",
            height: "500px",
            overflow: "auto",
            display: "block",
          }}
        >
          <table className="table table-responsive">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">First name</th>
                <th scope="col">Last name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile number</th>
                <th scope="col">Certificate link</th>
                <th scope="col">Send link</th>
              </tr>
            </thead>
            <tbody
            // style={{
            //   height: "auto",
            //   maxHeight: "200px",
            //   overflowX: "hidden",
            // }}
            >
              {registeredUser && registeredUser.length > 0
                ? registeredUser?.map((item, index) => {
                    return (
                      <tr key={item?._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.registeredUsers?.firstName}</td>
                        <td>{item?.registeredUsers?.lastName}</td>
                        <td>{item?.registeredUsers?.email}</td>
                        <td>{item?.registeredUsers?.mobileNumber}</td>
                        <td style={{ width: "0.5rem" }}>
                          {templateLinks.find(
                            (subItem) =>
                              subItem?.userId === item?.registeredUsers?._id
                          )?.templateLink ? (
                            <CopyToClipboard
                              text={`${constants?.endPointFrontend}${
                                templateLinks.find(
                                  (subItem) =>
                                    subItem?.userId ===
                                    item?.registeredUsers?._id
                                )?.templateLink
                              }`}
                              onCopy={() => setCopied(true)}
                            >
                              <button
                                className="btn m-2 btn-secondary mb-4"
                                onClick={() => setLinkIndex(index)}
                              >
                                {copied && linkIndex === index
                                  ? "Copied!"
                                  : "Copy Link"}
                              </button>
                            </CopyToClipboard>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {/* {templateLinks.find(
                            (subItem) =>
                              subItem?.userId === item?.registeredUsers?._id
                          )?.templateLink ? ( */}
                          {/* // <button className="btn btn-secondary">Send</button> */}
                          <ConfirmationModal
                            commonLoader={loader}
                            callingFunction={sendSingleLink}
                            buttonText={"Send"}
                            item={item}
                          />
                          {/* // ) : (
                          //   "--"
                          // )} */}
                        </td>
                      </tr>
                    );
                  })
                : "No data"}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default Model;
