import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../../../config";
import {
  studentEditTypProfileItem,
  studentDeleteDetails,
  professionalEditTypProfileItem,
  professionalDeleteDetails,
} from "../../../data/reducers/auth.reducer";
import Loader from "../../../shared/components/Loaders/Loader";
import { checkEmpty, emptyFileChoosen } from "../../../shared/Utils/method";

//..
const CertificateProfile = () => {
  const {
    cloudinaryImageLink,
    uploadImagePreset,
  } = constants;
  const fileRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const usertype = useSelector((state) => state.authReducer).userType;
  //
  const id = user._id;

  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [emptyEdit, setEmptyEdit] = useState(true);
  const [imgUrl, setImgUrl] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [inputErr, setInputErr] = useState([]);
  const [index, setIndex] = useState(null);
  const [certificate, setCertificate] = useState([...user.certificate]);
  const [editCertificate, setEditCertificate] = useState();
  // loaders states
  const [certificateAdd, setCertificateAdd] = useState(false);
  const [certificateEdit, setCertificateEdit] = useState(false);
  const [certificateDelete, setCertificateDelete] = useState(false);

  const [newCertificate, setNewCertificate] = useState({
    name: "",
    imageUrl: "",
    certificateId: "",
    description: "",
  });
  useEffect(async () => {
    setCertificate([...user.certificate]);
  }, [user]);
  //edit education handle change
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setEditCertificate({ ...editCertificate, [name]: value });
    // setEmptyEdit(checkEmpty({ ...editCertificate, [name]: value }));
  };

  //add education handle change
  const handlenewCertificateChange = (e) => {
    const { name, value } = e.target;
    setNewCertificate({ ...newCertificate, [name]: value });
    //
    // setEmpty(checkEmpty({ ...newCertificate, [name]: value }));
  };

  //edit submit
  const submit = async () => {
    const res = checkEmpty(editCertificate);
    //
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    setCertificateEdit(true);
    //
    certificate[index] = editCertificate;
    //
    const payload = { certificate, id };
    //
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setInputErr([]);
          setEditCertificate(null);
          setCertificateEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditCertificate();
        setCertificateEdit(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          //
          setInputErr([]);
          setEditCertificate(null);
          setCertificateEdit(false);
          return toast.success("Edit Successfully");
        }
        setInputErr([]);
        setEditCertificate();
        setCertificateEdit(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //set id and field
  const deleteButton = (field, id, i) => {
    localStorage.setItem("field", field);
    localStorage.setItem("deleteId", id);
    localStorage.setItem("iC", i);
    // setField(field);
    // setDeleteId(id);
  };

  //delete api
  const deleteFields = async () => {
    setCertificateDelete(true);
    const field = localStorage.getItem("field");
    const deleteId = localStorage.getItem("deleteId");
    const iC = localStorage.getItem("iC");
    // certificate.splice(iC, 1);
    //
    //
    const payload = { field, deleteId };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setCertificateDelete(false);
          return toast.success("Delete Successfully");
        }

        setCertificateDelete(false);
        return toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setCertificateDelete(false);
          return toast.success("Delete Successfully");
        }

        setCertificateDelete(false);
        return toast.error(response.payload.message.data.message);
      }
    } catch (error) {}
    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //add submit
  const submitnewCertificate = async (e) => {
    if (newCertificate.imageUrl === "") {
      return toast.error("Please upload the image !");
    }
    const res = checkEmpty(newCertificate);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }

    setCertificateAdd(true);
    certificate.push(newCertificate);
    //
    const payload = { certificate, id };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setNewCertificate({
            name: "",
            imageUrl: "",
            certificateId: "",
            description: "",
          });
          setInputErr([]);
          setCertificateAdd(false);
          fileRef.current.value = "";
          return toast.success("Add Successfully");
        }
        setInputErr([]);
        setNewCertificate({
          name: "",
          imageUrl: "",
          certificateId: "",
          description: "",
        });
        setCertificateAdd(false);
        fileRef.current.value = "";
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setNewCertificate({
            name: "",
            imageUrl: "",
            certificateId: "",
            description: "",
          });
          setInputErr([]);
          setCertificateAdd(false);
          fileRef.current.value = "";
          return toast.success("Add Successfully");
        }
        setInputErr([]);
        setNewCertificate({
          name: "",
          imageUrl: "",
          certificateId: "",
          description: "",
        });
        setCertificateAdd(false);
        fileRef.current.value = "";
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      toast.error(error);
    }

    // window.location.reload();
  };

  //edit upload
  const editUpload = async (e) => {
    const files = e.target.files;
    if (!files[0]) {
      return toast.error("No file Selected");
    }
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", uploadImagePreset);
    setLoading(true);

    const res = await fetch(
     cloudinaryImageLink,
      {
        method: "POST",
        body: data,
      }
    );

    const file = await res.json();

    setImgUrl(file.secure_url);
    setEditCertificate({ ...editCertificate, ["imageUrl"]: file.secure_url });
    // setNewCertificate({ ...newCertificate, ["imageUrl"]: file.secure_url });
    // setImage(file.secure_url);
    setLoading(false);

    setEmptyEdit(
      checkEmpty({ ...editCertificate, ["imageUrl"]: file.secure_url })
    );
    if (file.secure_url !== "") {
      toast.success("Image Uploaded");
    } else {
      toast.error("Image Not Uploaded");
    }
  };
  //add upload
  const upload = async (e) => {
    const files = e.target.files;
    if (!files[0]) {
      return toast.error("No file Selected");
    }

    const data = new FormData();
    data.append("file", files[0]);

    data.append("upload_preset", uploadImagePreset);
    setLoading(true);

    const res = await fetch(
      cloudinaryImageLink,
      {
        method: "POST",
        body: data,
      }
    );

    const file = await res.json();

    //
    setNewCertificate({ ...newCertificate, ["imageUrl"]: file.secure_url });
    console.log('file.secure_url: ', file.secure_url);
    // setImage(file.secure_url);
    setLoading(false);
    if (file.secure_url !== "") {
      toast.success("Image Uploaded");
    } else {
      toast.error("Image Not Uploaded");
    }
  };

  //
  const [area, setArea] = useState("false");
  const { name, imageUrl, certificateId, description } = newCertificate;
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h4 className="mb-0">Certificates</h4>
        <i
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          data-target="#Certificates"
          className="fa fa-plus-circle font-20 text-success ml-3 cursor-pointer"
        />
        <div className="ml-2">
          {(certificateAdd || certificateEdit || certificateDelete) && (
            <>
              <Loader />
            </>
          )}
        </div>
      </div>
      {certificate.length > 0 ? (
        <div className="content p-0 m-0 border p-2">
          {certificate.length > 0 &&
            certificate.map((certificate, i) => (
              <>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown cust_notify">
                    <i
                      className="fa fa-edit  font-20 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-2"
                      role="button"
                      id="dropdownDis"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      aria-haspopup="true"
                      data-target="#CertificateEdit"
                      aria-expanded={area}
                      onClick={() => {
                        setIndex(i);
                        //
                        setEmptyEdit(true);

                        setEditCertificate(certificate);
                      }}
                    />
                  </div>
                  <i
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    onClick={() =>
                      deleteButton("certificate", certificate._id, i)
                    }
                    data-target="#ConfDeleteCertificate"
                    className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                  />
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-start mb-2 mb-md-3 ">
                  <div className="Certif-imgCont text-center mr-sm-3 mb-2 flex-shrink-0 mr-auto">
                    <img
                      src={certificate?.imageUrl}
                      alt="Certificate"
                      className="img-fluid"
                    />
                  </div>
                  <div>
                    <h5 className="m-0">Title : {certificate?.name}</h5>
                    <p className="content p-0 mb-0">
                      {" "}
                      Certificate Id : {certificate?.certificateId} <br />{" "}
                      Description : {certificate?.description}{" "}
                    </p>
                  </div>
                </div>
              </>
            ))}
        </div>
      ) : null}

      {/* certificate add modal */}
      <div
        className="modal fade"
        id="Certificates"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Certificate description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    disabled={loading}
                    onChange={handlenewCertificateChange}
                    className={`form-control ${
                      inputErr.includes("name") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Upload Image
                  </label>

                  <input
                    type="file"
                    className="form-control pt-1"
                    accept="image/*"
                    disabled={loading}
                    name="file"
                    ref={fileRef}
                    placeholder="Upload an Image"
                    onChange={upload}
                  ></input>
                  {loading ? (
                    <p className="text-danger h6 mb-0 pt-1">Please Wait!</p>
                  ) : null}
                  {/* <input
                    type="text"
                    name="imageUrl"
                    value={imageUrl}
                    // onChange={handlenewCertificateChange}
                    className="form-control"
                    placeholder="imageUrl"
                  /> */}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Certificate Id
                  </label>
                  <input
                    type="text"
                    name="certificateId"
                    value={certificateId}
                    disabled={loading}
                    onChange={handlenewCertificateChange}
                    className={`form-control ${
                      inputErr.includes("certificateId") ? "is-invalid" : ""
                    }`}
                    placeholder="certificateId"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={description}
                    disabled={loading}
                    onChange={handlenewCertificateChange}
                    className={`form-control ${
                      inputErr.includes("description") ? "is-invalid" : ""
                    }`}
                    placeholder="description"
                  />
                </div>
                <div className="text-right">
                  <button
                    disabled={loading ? true : false}
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => {
                      emptyFileChoosen(fileRef);
                      setInputErr([]);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    disabled={loading}
                    data-dismiss={
                      checkEmpty(newCertificate).length == 0 ? "modal" : null
                    }
                    onClick={submitnewCertificate}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* Delete conformation modal */}
      <div
        className="modal fade"
        id="ConfDeleteCertificate"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={deleteFields}
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* certificate edit modal */}
      <div
        className="modal fade"
        id="CertificateEdit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize mb-3 d-block text-dark Fweight-600 h5">
                  Certificate description
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={editCertificate?.name}
                    disabled={loading}
                    onChange={handleChange}
                    className={`form-control ${
                      inputErr.includes("name") ? "is-invalid" : ""
                    }`}
                    placeholder="name"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Upload Image
                  </label>

                  <input
                    type="file"
                    className="form-control pt-1"
                    accept="image/*"
                    disabled={loading}
                    name="file"
                    ref={fileRef}
                    placeholder="Upload an Image"
                    onChange={editUpload}
                  ></input>
                  {loading ? (
                    <p className="text-danger h6 mb-0 pt-1">Please Wait!</p>
                  ) : null}
                  {/* <input
                    type="text"
                    name="imageUrl"
                    value={imageUrl}
                    // onChange={handleChange}
                    className="form-control"
                    placeholder="imageUrl"
                  /> */}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Certificate Id
                  </label>
                  <input
                    type="text"
                    name="certificateId"
                    value={editCertificate?.certificateId}
                    disabled={loading}
                    onChange={handleChange}
                    className={`form-control ${
                      inputErr.includes("certificateId") ? "is-invalid" : ""
                    }`}
                    placeholder="certificateId"
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={editCertificate?.description}
                    disabled={loading}
                    onChange={handleChange}
                    className={`form-control ${
                      inputErr.includes("description") ? "is-invalid" : ""
                    }`}
                    placeholder="description"
                  />
                </div>
                <div className="text-right">
                  <button
                    disabled={loading}
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => {
                      fileRef.current.value = "";
                      setInputErr([]);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    disabled={loading}
                    data-dismiss={
                      checkEmpty(editCertificate).length == 0 ? "modal" : null
                    }
                    onClick={submit}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateProfile;
