import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCampusAPI,
  fetchCampusListsAPI,
  editCampusAPI,
  fetchCampusStudentListsAPI,
  singleUploadEmailAPI,
  updateStudentAPI,
  bulkUploadEmailsAPI,
  checkEmailExistenceAPI,
  submitCampusRegistrationFormAPI,
  createCampusTestAPI,
  getcampusResultAPI,
  fetchCampusListsForEmailsAPI,
  adminSendBulkEmailAPI,
} from "../../../services/admin/campus-placement Drive/campus-placement-drive.services";

//thunks

//send bulk email function
export const adminSendBulkEmailItem = createAsyncThunk(
  "user/adminSendBulkEmailItem",
  async (payload, thunkAPI) => {
    let response = await adminSendBulkEmailAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get campus
export const fetchCampusListsForEmailsItem = createAsyncThunk(
  "user/fetchCampusListsForEmailsItem",
  async (payload, thunkAPI) => {
    let response = await fetchCampusListsForEmailsAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get campus
export const fetchCampusListsItem = createAsyncThunk(
  "user/fetchCampusListsItem",
  async (payload, thunkAPI) => {
    let response = await fetchCampusListsAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//fetch campus student lists
export const fetchCampusStudentListsItem = createAsyncThunk(
  "user/fetchCampusStudentListsItem",
  async (payload, thunkAPI) => {
    let response = await fetchCampusStudentListsAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get campus
export const addCampusItem = createAsyncThunk(
  "user/addCampusItem",
  async (payload, thunkAPI) => {
    let response = await addCampusAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get campus
export const editCampusItem = createAsyncThunk(
  "user/editCampusItem",
  async (payload, thunkAPI) => {
    let response = await editCampusAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//single upload
export const singleUploadEmailItem = createAsyncThunk(
  "user/singleUploadEmailItem",
  async (payload, thunkAPI) => {
    let response = await singleUploadEmailAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//update studnt
export const updateStudentItem = createAsyncThunk(
  "user/updateStudentItem",
  async (payload, thunkAPI) => {
    let response = await updateStudentAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//bulk upload
export const bulkUploadEmailsItem = createAsyncThunk(
  "user/bulkUploadEmailsItem",
  async (payload, thunkAPI) => {
    let response = await bulkUploadEmailsAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//checkEmail
export const checkEmailExistenceItem = createAsyncThunk(
  "user/checkEmailExistenceItem",
  async (payload, thunkAPI) => {
    let response = await checkEmailExistenceAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//submit campus registration form
export const submitCampusRegistrationFormItem = createAsyncThunk(
  "user/submitCampusRegistrationFormItem",
  async (payload, thunkAPI) => {
    let response = await submitCampusRegistrationFormAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//create test
export const createCampusTestItem = createAsyncThunk(
  "user/createCampusTestItem",
  async (payload, thunkAPI) => {
    let response = await createCampusTestAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//getcampusResult
export const getcampusResultItem = createAsyncThunk(
  "user/getcampusResultItem",
  async (payload, thunkAPI) => {
    let response = await getcampusResultAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//************************************************* */
const CampusPlacementDriveSlice = createSlice({
  name: "CampusPlacementDrive",
  initialState: {
    category: "",
    level: "",
    skip: 0,
    // searchQuery: "",
    emailVerified: false, //used for email verification in user campus registration form
    testInitialState: {
      // totalAllowedTimeForTest: { totalHours: "", totalMinutes: "" },
      questionArray: [],
      passingPercentage: 0,
      testDate: "",
      startTimeSlotForTest: { startTime: "", endTime: "" },
    },
    questionArray: [],
    emailOtp: "",
    otpTimeOut: 0,
  },
  reducers: {
    emptyEmailOtp: (state, action) => {
      // console.log("action.payload: ", action.payload);
      state.emailOtp = "";
      state.otpTimeOut = ""
    },
  
    changeSkip: (state, action) => {
      // console.log("action.payload: ", action.payload);
      state.skip = action?.payload?.skip;
      state.level = action?.payload?.level;
      state.category = action?.payload?.category;
    },
    changeEmailVerified: (state, action) => {
      // console.log("action.payload: ", action.payload);
      state.emailVerified = action.payload.emailVerified;
    },
    changeTestState: (state, action) => {
      console.log("action.payload: ", action.payload);
      state.testInitialState = {
        // totalAllowedTimeForTest: {
        //   totalHours: action?.payload?.totalHours,
        //   totalMinutes: action?.payload?.totalMinutes,
        // },
        testDate: action?.payload?.testDate,
        passingPercentage: action?.payload?.passingPercentage,
        startTimeSlotForTest: {
          startTime: action?.payload?.startTime,
          endTime: action?.payload?.endTime,
        },
      };
    },
    fillQuestionArray: (state, action) => {
      // state.questionArray=[];
      state.questionArray = action?.payload?.questionIds;
    },
    changeQuestionArray: (state, action) => {
      // state.questionArray=[];
      if (!state.questionArray.find((item) => item?.questionId)) {
        state.questionArray.push(action?.payload?.questionId);
      }
    },
    deleteQuestionId: (state, action) => {
      // state.questionArray = action.payload.questionId;
      state.questionArray = state.questionArray.filter(
        (item) => item !== action.payload.questionId
      );
    },
    emptyQuestionArray: (state, action) => {
      state.questionArray = [];
    },
    emptyTestInitialState: (state, action) => {
      state.testInitialState = {
        // totalAllowedTimeForTest: { totalHours: "", totalMinutes: "" },
        questionArray: [],
        passingPercentage: 0,
        testDate: "",
        startTimeSlotForTest: { startTime: "", endTime: "" },
      };
    },
  },
  extraReducers: {
    [checkEmailExistenceItem.fulfilled]: (state, action) => {
      state.emailOtp = action.payload.data.OTP;
      // console.log('action.payload: ', action.payload);
      // console.log("otptime");
      state.otpTimeOut = `${new Date(Date.now()).getHours()}:${
        new Date(Date.now()).getMinutes() < 10
          ? "0" + new Date(Date.now()).getMinutes()
          : new Date(Date.now()).getMinutes() + 2
      }`;
    },
  },
});
export const {
  changeSkip,
  changeEmailVerified,
  changeTestState,
  changeQuestionArray,
  deleteQuestionId,
  emptyQuestionArray,
  emptyTestInitialState,
  fillQuestionArray,
  emptyEmailOtp,
} = CampusPlacementDriveSlice.actions;
export default CampusPlacementDriveSlice.reducer;
