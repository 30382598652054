import React, { useState } from "react";
import Modal from "react-modal";
// import { deleteActionItem } from "../../../../data/reducers/referral.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Model = ({
  addAcceptedDeclinedArray,
  _id,
  declinedIdsState,
  actionPoints,
}) => {
  //
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [declineDescription, setDeclineDescription] = useState("");
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle add change
  const handleAddChange = async (e) => {
    setDeclineDescription(e.target.value);
  };

  return (
    <>
      <button
        className="btn btn-secondary"
        disabled={
          declinedIdsState.map((state) => state._id).includes(_id)
            ? true
            : false
        }
        onClick={() => {
          OpenModal();
        }}
      >
        Decline
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h3 className="text-center">
                Please add Action decline description!
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Description
                </label>
                <textarea
                  onChange={handleAddChange}
                  className="form-control"
                  name="description"
                />
              </div>
              <div className="col-12  p-0">
                <button
                  className="btn text-white m-1"
                  style={{ backgroundColor: "#5A6268" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  disabled={
                    declineDescription === "" ||
                    declineDescription.trim() === ""
                      ? true
                      : false
                  }
                  className="btn m-1 text-white"
                  style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    //
                    closeModal();
                    addAcceptedDeclinedArray(
                      "decline",
                      _id,
                      declineDescription,
                      actionPoints
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Model;
