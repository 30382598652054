import React, { useEffect, useState, useMemo, useCallback } from "react";
import abtbg from "../../assets/images/abtbg.png";
import lock from "../../assets/images/lock.png";
import debounce from "lodash.debounce";
import bluelabel from "../../assets/images/bluelabel.png";
import { useDispatch, useSelector } from "react-redux";
import { Element, scroller } from "react-scroll";
import LoginModal from "../AuthPages/Login-modal";

import {
  studentListItem,
  studentItem,
  changeStudentPageSkip,
} from "../../data/reducers/student.reducer";
import { useHistory, Link } from "react-router-dom";
import Search from "../Admin/Shared/Search/Search";
import { toast } from "react-toastify";
import noData from "../../assets/images/empty_data_set.png";
// import Search from "../../assets/images/search.jpg";
const StudentList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  //check here
  const studentInfo = useSelector((state) => state.studentReducer).studentInfo;
  // console.log('studentInfo: ', studentInfo);
  const user = useSelector((state) => state.authReducer).userInfo;
  const userId = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const length = useSelector((state) => state.studentReducer).length;
  const student = useSelector((state) => state.studentReducer).singleStudent;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const studentPageSkip = useSelector(
    (state) => state.studentReducer
  ).studentPageSkip;

  const [skip, setSkip] = useState(studentPageSkip ? studentPageSkip : 0);
  const [click, setClick] = useState("");

  useEffect(async () => {
    dispatch(changeStudentPageSkip({ skip }));
    fetchStudents();
  }, [skip]);

  useEffect(() => {
    if (searchQuery != undefined || searchQuery == "") {
      // console.log("debounce");
      dispatch(changeStudentPageSkip({ skip }));
      debouncedSave(fetchStudents, searchQuery);
    }
  }, [searchQuery]);

  const debouncedSave = useCallback(
    debounce(
      (fetchStudents, $searchQuery) => fetchStudents($searchQuery),
      1000
    ),
    []
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchStudents = async (searchQuery) => {
    // console.log("calling");
    try {
      setLoading(true);
      let response = await dispatch(
        studentListItem({ limit, skip, searchQuery })
      );
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        setClick("");
        scroller.scrollTo("test1", {
          duration: 10,
          delay: 0,
          smooth: "linear",
        });
        setActive(true);

        return setLoading(false);
      } else {
        toast.error(response.payload.message.data.message);
        setActive(true);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {}
  };

  const singleStudent = async (e, id, usertypee) => {
    e.preventDefault();
    // setLoading(true);

    try {
      let response = await dispatch(studentItem(id));
    } catch (error) {}

    // setLoading(false);
    let path = `/profile/` + id;
    history.push(path);
  };

  return (
    <>
      <section
        className="innerbanner"
        style={{
          background: `url(${abtbg}) top center no-repeat`,
          marginTop: "70px",
        }}
      >
        <div className="container">
          <a href="#studentTop" hidden />
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1 className="display-4 K-font Fweight-500">
                Featured Profiles
              </h1>

              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Featured Profiles
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="Candidatelist sc-pd">
        <Element name="test1" className="element" />
        <div className="container">
          <div className="text-left my-4">
            {/* {loading && (
              <div class="spinner-border text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )} */}
            <div className="d-flex justify-content-between align-items-center flex-md-row flex-column">
              {/* <div>
                <h5>
                  Showing {studentInfo.length} of {studentInfo.length} students
                </h5>
              </div> */}
              <div className="col-lg-4 col-md-6 px-0 ">
                <div
                  className="input-group input-group-lg search"

                  // data-aos-easing="ease-in-sine"
                >
                  <label className="sr-only" for="inlineFormInputGroupUsername">
                    Search
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-0 border-0"
                    placeholder="Search by name or email "
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            {studentInfo.length === 0 && (
              <div className="container col-12 dots">
                <img src={noData} class="imageresize1"></img>
              </div>

              // <img src = {Search}></img>
              // <Modal show={true}>
              //   <ModalHeader>
              //     <ModalTitle>No Students Yet!</ModalTitle>
              //   </ModalHeader>
              // </Modal>
            )}
            {studentInfo?.map((item) => (
              <div class="col-xl-3 col-lg-4 col-md-6">
                {/* give a lock classname here */}
                <div class="card mb-5 Candidateprofile">
                  <div
                    className="card_img border"
                    style={{
                      background: `url(${item?.ownerField?.profilePic})`,
                    }}
                  >
                    {usertype === "recruiter" && item?.isHirable === true && (
                      <>
                        {item?.hireRequest?.includes(userId) === true ? (
                          <p className="p-0 hiremelabel bg-danger">Requested</p>
                        ) : (
                          <p className="p-0 hiremelabel">Hire me</p>
                        )}
                      </>
                    )}
                  </div>

                  <span class="lockimg">
                    <img src={lock} alt="" class="img-fluid" />
                  </span>
                  <div class=" card-body">
                    <div
                      class="bluelabel"
                      style={{
                        background: `url(${bluelabel}) top center no-repeat`,
                      }}
                    >
                      Level {item?.level?.level || 1}
                    </div>
                    <div className="student-list d-flex align-items-start justify-content-between">
                      <div>
                        <h6 class="card-title">
                          {item?.ownerField?.firstName}{" "}
                          {item?.ownerField?.lastName}
                        </h6>
                        <p class="card-text small mb-2">
                          {item?.ownerField?.currentPosition}
                        </p>
                      </div>
                      <div className="favlabel">
                        <img
                          src={active}
                          alt=""
                          className="img-fluid img1 d-block"
                        />
                      </div>
                    </div>
                    {/* <p class="card-text small mb-1" >{item.ownerField.description}</p> */}

                    <div class="btnbar text-center">
                      {loggedIn && (
                        <a
                          style={{ cursor: "pointer" }}
                          // data-toggle={!loggedIn ? "modal" : ""}
                          // data-target={!loggedIn ? "" : ""}
                          activeClassName={!loggedIn ? "active" : ""}
                          class="link text-primary text-decoration-none d-flex align-items-center justify-content-center h6"
                          onClick={(e) => {
                            if (loggedIn) {
                              singleStudent(
                                e,
                                item?._id,
                                item?.ownerField?.userType
                              );
                            }
                          }}
                        >
                          View Profile <i class="fa fa-chevron-right ml-2"></i>
                        </a>
                      )}

                      {!loggedIn && <LoginModal purpose={"View Profile"} />}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* {studentInfo.length !== 0 && ( */}
            <div class="row mb-2">
              <div class="col-md-12 text-center m-auto">
                <button
                  style={{ backgroundColor: "#1D3883" }}
                  className="btn text-white m-1"
                  disabled={skip === 0}
                  onClick={() => {
                    setClick("prev");
                    setSkip((preVal) => {
                      return preVal - limit;
                    });
                  }}
                >
                  {loading && click === "prev" ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Prev"
                  )}
                  {loading && click === "prev" ? (
                    <span
                      id="studentTop"
                      class="spinner-grow spinner-grow-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>

                <button
                  style={{ backgroundColor: "#1D3883" }}
                  className="btn text-white m-1 "
                  disabled={
                    studentInfo?.length === 0 || studentInfo?.length < limit
                  }
                  onClick={() => {
                    setClick("next");
                    setSkip((preVal) => {
                      return preVal + limit;
                    });
                  }}
                >
                  {loading && click === "next" ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Next"
                  )}
                  {loading && click === "next" ? (
                    <span
                      class="spinner-grow spinner-grow-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </div>
            </div>
          {/* )} */}
        </div>
      </section>
    </>
  );
};

export default StudentList;
