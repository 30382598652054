import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserLevelItem } from "../../../../../data/reducers/admin.reducer";

//...
const Model = ({ student, limit, skip }) => {
  // console.log('student: ', student);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [level, setlevel] = useState();
  const [loader, setloader] = useState(false);
  const [description, setdescription] = useState("");

  const [minlevel, setminlevel] = useState(1);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");
  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleChange = (e) => {
    setdescription(e.target.value);
  };
  //level handlechange
  const handleLevelChange = (e) => {
    const { name, value } = e.target;
    setlevel(value);
  };

  //set user level
  const setUserLevelApi = async () => {
    const { _id } = student;
    const oldLevel = student?.level?.level;
    const { userType } = student.ownerField;
    const payload = {
      _id,
      level,
      userType,
      limit,
      skip,
      oldLevel,
      description,
    };
    // console.log("payload: ", payload);
    try {
      if (description === "" || description == null) {
        return toast.error("Please add reason!");
      }

      setloader(true);
      const response = await dispatch(setUserLevelItem(payload));
      // console.log("response: ", response);
      if (response.payload.isSuccessful) {
        setloader(false);
        closeModal();
        toast.success("Added succesfully");
        return;
      }
      setloader(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {}
  };

  return (
    // <div className="container-fluid">
    <>
      <td
        data-toggle="tooltip"
        data-placement="top"
        title="Set level"
        onClick={() => {
          OpenModal();
          setlevel(student?.level?.level);
          setdescription(student?.level?.description);
        }}
        className="cursor-pointer"
      >
        {student?.level?.level || "-"}
      </td>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "370px",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="row d-flex justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <h5 className="font-weight-bold">Set user level</h5>
          </div>
        </div>
        <div className="row d-flex justify-content-center p-3">
          <div className="col-12">
            <div className="mb-3">
              <input
                disabled
                onChange={handleLevelChange}
                type="number"
                className={`form-control text-center `}
                name="level"
                value={level}
              />
            </div>
            <div className="mb-3">
              <button
                className="btn mx-2 text-white btn-secondary "
                onClick={() => {
                  level === minlevel
                    ? toast.warning("Can't set below zero!")
                    : setlevel(level - 1);
                }}
              >
                DEC
              </button>
              <button
                className="btn mx-2 text-white btn-secondary "
                onClick={() => {
                  setlevel(level + 1);
                }}
              >
                INC
              </button>
            </div>
            <div className="mb-3 ">
              <label>Reason</label>
              <textarea
                className="p-3 border-black"
                placeholder="Add reason.."
                onChange={handleChange}
                value={description}
              />
            </div>
            <div className="col-12 p-0">
              <button
                className="btn mx-2 text-white btn-secondary"
                disabled={loader}
                onClick={() => {
                  setUserLevelApi();
                }}
              >
                Yes
                {loader && (
                  <span
                    class="spinner-border ml-1 spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              <button
                className="btn text-white mx-1 btn-warning"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
    // </div>
  );
};

export default Model;
