import React, { useState } from "react";
import Modal from "react-modal";
import { createActionItem } from "../../../../data/reducers/referral.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";
//...
const Model = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  //init state of action form
  const [actionState, setActionState] = useState({
    action: "",
    points: null,
    userType: "",
  });

  //handle add change
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setActionState({ ...actionState, [name]: value });
  };

  //handle submit
  const handleSubmit = async () => {
    const res = checkEmpty(actionState);
    //
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    //
    try {
      const response = await dispatch(createActionItem(actionState));
      //
      if (response.payload.isSuccessful) {
        setActionState({
          ...actionState,
          ["action"]: "",
          ["points"]: "",
          ["userType"]: "",
        });
        setInputErr([]);

        return toast.success("Action created successfully!");
      }
      setActionState({
        ...actionState,
        ["action"]: "",
        ["points"]: "",
        ["userType"]: "",
      });
      setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container-fluid">
      <button
        className="cursor-pointer text-white btn btn-warning m-0 p-2"
        onClick={() => {
          OpenModal();
          setInputErr([]);
        }}
      >
        <i className="fas fa-plus text-white"></i> Create Action
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <>
          {/* <div className="container"> */}

          <div className="row d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <h5 className="font-weight-bold">Create Action</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="font-weight-bold form-label text-dark"
                >
                  Action Name
                </label>
                <input
                  placeholder="e.g. SIGN_UP"
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("action") ? "is-invalid" : ""
                  }`}
                  name="action"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Points
                </label>
                <input
                  onChange={handleAddChange}
                  type="Number"
                  className={`form-control form-control-lg ${
                    inputErr.includes("points") ? "is-invalid" : ""
                  }`}
                  name="points"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  User Type
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("userType") ? "is-invalid" : ""
                  }`}
                  name="userType"
                />
              </div>

              <div className="col-12 p-0">
                <button
                  // disabled={
                  //   actionState.action !== "" &&
                  //   actionState.points !== "" &&
                  //   actionState.userType !== ""
                  //     ? false
                  //     : true
                  // }
                  className="btn mx-2 text-white btn-secondary"
                  // style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    handleSubmit();
                    return checkEmpty(actionState).length === 0
                      ? closeModal()
                      : null;
                  }}
                >
                  Create
                </button>
                <button
                  className="btn text-white mx-1 btn-warning"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    setActionState({
                      ...actionState,
                      ["action"]: "",
                      ["points"]: "",
                      ["userType"]: "",
                    });
                    closeModal();
                    setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default Model;
