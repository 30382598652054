import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { EditUserAdminItem } from "../../../../data/reducers/admin/roles/roles-admin.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";

const Model = ({ item, fetchRoles, rolesData }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [loader, setloader] = useState(false);

  //init state of  form
  const [editRole, setEditRole] = useState({
    email: item?.email,
    password: "",
    userType: item?.userType,
    confirmPassword: "",
  });

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle add change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditRole({ ...editRole, [name]: value });
  };

  // handle submit
  const handleSubmit = async () => {
    const res = checkEmpty(editRole, ["password", "confirmPassword"]);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    if (editRole.password !== editRole.confirmPassword) {
      return toast.error("Password doesn't match!");
    }
    const _id = item?._id;
    const { email, password, userType } = editRole;
    const payload = { _id, email, userType, password };
    // console.log("payload: ", payload);

    try {
      setloader(true);
      const response = await dispatch(EditUserAdminItem(payload));
      if (response.payload.isSuccessful) {
        fetchRoles();
        setloader(false);
        closeModal();
        setInputErr([]);

        return toast.success("Edited successfully!");
      }
      setloader(false);
      setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  return (
    <div className="container">
      <i
        className="fa fa-edit font-size-15 text-success cursor-pointer"
        onClick={() => {
          OpenModal();
          setEditRole({
            ...editRole,
            ["email"]: item?.email,
            ["userType"]: item?.userType,
          });
          setInputErr([]);
        }}
      ></i>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Edit Role</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Email
                </label>
                <input
                  onChange={handleEditChange}
                  type="email"
                  className={`form-control ${
                    inputErr.includes("email") ? "is-invalid" : ""
                  }`}
                  name="email"
                  value={editRole?.email}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Password <span className="text-muted">(Optional if you are aren't updating)</span>
                 
                </label>
                <input
                  disabled={loader}
                  onChange={handleEditChange}
                  type="password"
                  className={`form-control ${
                    inputErr.includes("password") ? "is-invalid" : ""
                  }`}
                  name="password"
                  value={editRole?.password}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Confirm password
                </label>
                <input
                  disabled={loader}
                  onChange={handleEditChange}
                  type="password"
                  className={`form-control ${
                    inputErr.includes("confirmPassword") ? "is-invalid" : ""
                  }`}
                  name="confirmPassword"
                  value={editRole?.confirmPassword}
                />
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {editRole?.userType === ""
                    ? "Choose role"
                    : editRole?.userType}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {rolesData?.map((role) => {
                    return (
                      <span key={role?._id}
                        onClick={() => {
                          setEditRole({
                            ...editRole,
                            ["userType"]: role?.roleName,
                          });
                        }}
                        style={{ cursor: "pointer" }}
                        className={`form-control dropdown-item ${
                          inputErr.includes("userType") ? "is-invalid" : ""
                        }`}
                      >
                        {role?.roleName}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="col-12 p-0">
                <button
                  disabled={loader}
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    return checkEmpty(editRole).length === 0
                      ? closeModal()
                      : null;
                  }}
                >
                  Yes
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  disabled={loader}
                  className="btn text-white m-1 btn-danger"
                  onClick={() => {
                    closeModal();
                    setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
