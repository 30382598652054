import {
  api,
  getAuthHeaders,
  get,
  post,
  patch,
  del,
} from "../../services.common";

//get all roles user
export const getAllRolesAPI = async () => {
  let url = `${api}/admin/read/users`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//edit User
export const EditUserAdminAPI = async (payload) => {
  const { _id, password, email, userType } = payload;
  let url = `${api}/admin/edit/user?userId=${_id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { password, email, userType }, { ...authHeader });
};

//add User
export const CreateUserAdminAPI = async (payload) => {
  const { email, password, userType } = payload;
  let url = `${api}/admin/create/user`;
  let authHeader = getAuthHeaders();
  return await post(url, { email, password, userType }, { ...authHeader });
};

//delete User
export const deleteUserAdminAPI = async (payload) => {
  const { _id } = payload;
  let url = `${api}/admin/remove/user?userId=${_id}`;
  let authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};
