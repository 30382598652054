import React, { useState, useEffect, useRef } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { constants } from "../../config";
import {
  studentEditTypProfileItem,
  studentDeleteDetails,
  professionalEditTypProfileItem,
  professionalDeleteDetails,
  professionalHireableItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//imahes

import EditProfileModal from "../StudentProfileEdit/EditProfileModal";
import bluelabel from "../../assets/images/bluelabel.png";
import signupbg from "../../assets/images/signupbg.png";
import CertificateProfile from "./StudentProfileComponents/CertificateProfile";
import EducationProfile from "./StudentProfileComponents/EducationProfile";
import ProjectProfile from "./StudentProfileComponents/ProjectProfile";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WorkExperienceProfile from "./StudentProfileComponents/WorkExperienceProfile";
import Loader from "../../shared/components/Loaders/Loader";
import budgeone from "../../assets/images/dtprofile.png";
import {
  checkEmpty,
  checkSkillsEmpty,
  checkSocialLinks,
  checkModalSocialLinks,
} from "../../shared/Utils/method";
import {
  studentHireableItem,
  studentItem1,
  professionalItem1,
} from "../../data/reducers/auth.reducer";

const endPointReact = constants.endPointFrontend;

//main profile component
const StudentFullProfileNew = () => {
  const [copied, setCopied] = useState(false);
  const [empty, setEmpty] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  let user = useSelector((state) => state.authReducer).userInfo;

  if (!loggedIn) {
    return <Redirect to="/"></Redirect>;
  }

  useEffect(() => {
    if (!loggedIn) {
      return history.push("/");
    } else if (
      loggedIn &&
      usertype === "admin" &&
      usertype === "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (usertype === "student") {
      fetchStudent();
    } else if (usertype === "professional") {
      fetchProf();
    }
  }, []);

  const childRef = useRef();

  // console.log("user: ", user);

  let hiring = useSelector((state) => state.studentReducer).hireable;

  const userId = user?._id;
  const [editSkills, setEditSkills] = useState();
  const [index, setIndex] = useState(null);
  const [openHire, setOpenHire] = useState({
    open: "",
  });

  //add social links initials add state
  const [socialLinks, setSocialLinks] = useState({
    linkedin: user?.linkedin,
    github: user?.github,
    twitter: user?.twitter,
    personalBlog: user?.personalBlog,
  });

  //edit social links initials add state
  const [socialLink, setSocialLink] = useState({
    linkedin: user?.linkedin,
    github: user?.github,
    twitter: user?.twitter,
    personalBlog: user?.personalBlog,
  });

  const [hireableStudent, setHireableStudent] = useState(user?.isHirable);
  //add skill init state
  const [newSkills, setNewSkills] = useState({
    name: "",
  });

  //real skills
  const [skills, setSkills] = useState([...user?.skills]);
  const [shareLink, setShareLink] = useState("");
  // loaders states
  const [skillAdd, setSkillAdd] = useState(false);
  const [skillEdit, setSkillEdit] = useState(false);
  const [skillDelete, setSkillDelete] = useState(false);

  const [disable, setDisable] = useState(true);
  //
  const [linkAdd, setLinkAdd] = useState(false);
  const [linkEdit, setLinkEdit] = useState(false);
  const [linkDelete, setLinkDelete] = useState(false);
  const [inValidLinks, setInValidLInks] = useState([]);

  const fetchStudent = async () => {
    // console.log("calling stud");
    const response = await dispatch(studentItem1(userId));
    if (response.payload.isSuccessful) {
      return;
    }

    return;
  };
  const fetchProf = async () => {
    // console.log("calling prof");
    const response = await dispatch(professionalItem1(userId));
    if (response.payload.isSuccessful) {
      return;
    }

    return;
  };

  useEffect(async () => {
    setSkills([...user?.skills]);
    setSocialLinks({
      ...socialLinks,
      ["linkedin"]: user?.linkedin,
      ["github"]: user?.github,
      ["twitter"]: user?.twitter,
      ["personalBlog"]: user?.personalBlog,
    });
    setSocialLink({
      ...socialLink,
      ["linkedin"]: user?.linkedin,
      ["github"]: user?.github,
      ["twitter"]: user?.twitter,
      ["personalBlog"]: user?.personalBlog,
    });
    setHireableStudent(user?.isHirable);
  }, [user]);

  //add socialLinks handle change
  const handleAddsocialLinks = (e) => {
    const { name, value } = e.target;
    //

    setSocialLinks({
      ...socialLinks,
      [name]: value,
    });
  };

  //handle add skill
  const handleSkillsInputChange = (e) => {
    const { name, value } = e.target;

    setNewSkills({ ...newSkills, [name]: value });

    //Check empty function is here
    setEmpty(checkSkillsEmpty({ ...newSkills, [name]: value }));
  };

  const checkEmptySocialLinks = (data) => {
    // console.log("data: ", data);
    for (let single_data in data) {
      if (
        socialLinks[single_data] != "" &&
        socialLinks[single_data] != undefined
      ) {
        return false;
      }
    }
    return true;
  };

  const [hiremeLoader, setHireMeLoader] = useState(false);
  //edit skills handle change
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setEditSkills({ ...editSkills, [name]: value });

    setEmpty(checkSkillsEmpty({ ...editSkills, [name]: value }));
  };

  //handle edit link
  const handleEditSocialLinks = (e) => {
    const { name, value } = e.target;

    if (name === "github") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        ["linkedin"]: user?.linkedin,
        ["twitter"]: user?.twitter,
        ["personalBlog"]: user?.personalBlog,
      });
    } else if (name === "linkedin") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        ["github"]: user?.github,
        ["twitter"]: user?.twitter,
        ["personalBlog"]: user?.personalBlog,
      });
    } else if (name === "twitter") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        ["github"]: user?.github,
        ["linkedin"]: user?.linkedin,
        ["personalBlog"]: user?.personalBlog,
      });
    } else if (name === "personalBlog") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        ["github"]: user?.github,
        ["twitter"]: user?.twitter,
        ["linkedin"]: user?.linkedin,
      });
    } else {
      setSocialLink({
        ...socialLink,
        ["personalBlog"]: user?.personalBlog,
        ["github"]: user?.github,
        ["twitter"]: user?.twitter,
        ["linkedin"]: user?.linkedin,
      });
    }
  };

  //add social links API
  const addsocialLinks = async (e) => {
    // console.log("calling links");
    e.preventDefault();
    const result = checkSocialLinks(socialLinks);
    // console.log("result: ", result);
    setInValidLInks(result);
    if (result.length > 0) {
      // setInValidLInks([]);
      return toast.error("Please provide valid url!");
    }
    setLinkAdd(true);
    const id = user?._id;

    const payload = { socialLinks, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          // window.location.reload();
          setInValidLInks([]);
          setLinkAdd(false);
          return toast.success("Add Successfully");
        }
        setLinkAdd(false);
        setInValidLInks([]);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));
        //
        if (response.payload.isSuccessful) {
          // window.location.reload();
          setInValidLInks([]);
          setLinkAdd(false);
          return toast.success("Add Successfully");
        }
        setInValidLInks([]);
        setLinkAdd(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      // window.location.reload();
    }
    // window.location.reload();
    // return history.push("/studentProfilePage");
  };

  //edit submit skills
  const submit = async () => {
    setSkillEdit(true);
    const id = user?._id;
    //
    skills[index] = editSkills;

    const payload = { skills, id };
    //

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setEditSkills(null);
          setSkillEdit(false);
          return toast.success("Edit Successfully");
        }
        setEditSkills(null);
        setSkillEdit(false);

        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setEditSkills(null);
          setSkillEdit(false);
          return toast.success("Edit Successfully");
        }
        setEditSkills(null);
        setSkillEdit(false);

        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}
    // setDisplay(true);
    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //edit social links api
  const editSocialLink = async () => {
    setLinkEdit(true);
    const id = user?._id;

    const payload = { socialLink, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setLinkEdit(false);
          return toast.success("Edit Successfully");
        }
        setLinkEdit(false);
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          setLinkEdit(false);
          return toast.success("Edit Successfully");
        }
        setLinkEdit(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}
    // window.location.reload();
    // return history.push("/studentProfilePage");
  };

  //add skills api
  const addSkills = async () => {
    setSkillAdd(true);
    const id = user?._id;
    //
    skills.push(newSkills);
    const payload = { skills, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          //
          setNewSkills({ ...newSkills, ["name"]: "" });
          setSkillAdd(false);

          return toast.success("Add Successfully");
        }
        setSkillAdd(false);

        setNewSkills({ ...newSkills, ["name"]: "" });
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          //
          setNewSkills({ ...newSkills, ["name"]: "" });
          setSkillAdd(false);

          return toast.success("Add Successfully");
        }
        setSkillAdd(false);

        setNewSkills({ ...newSkills, ["name"]: "" });
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}
    // window.location.reload();
    // <Redirect to="/studentFullProfile"/>
    // return history.push("/studentFullProfile");
  };

  //set id and field social links
  const deleteButton = (field, id, i) => {
    localStorage.setItem("field", field);
    localStorage.setItem("deleteId", id);
    if (field === "skills") {
      localStorage.setItem("i", i);
    }

    // setField(field);
    // setDeleteId(id);
  };

  //delete api social links/skills
  const deleteFields = async () => {
    const field = localStorage.getItem("field");
    const deleteId = localStorage.getItem("deleteId");
    if (field === "skills") {
      setSkillDelete(true);
    } else {
      setLinkDelete(true);
    }
    //
    //
    const payload = { field, deleteId };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));
        //
        // if (response.payload.isSuccessful) {
        //
        if (field === "skills") {
          if (response.payload.isSuccessful) {
            const i = localStorage.getItem("i");
            //
            // skills.splice(i, 1);
            setSkillDelete(false);
            setNewSkills({ ...newSkills, ["name"]: "" });
            return toast.success("Delete Successfully");
          }
          setSkillDelete(false);
          setNewSkills({ ...newSkills, ["name"]: "" });
          toast.error(response.payload.message.data.message);
        } else {
          if (response.payload.isSuccessful) {
            setLinkDelete(false);
            return toast.success("Delete Successfully");
          }
          setLinkDelete(false);
          toast.error(response.payload.message.data.message);
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));
        //
        // if (response.payload.isSuccessful) {
        //
        if (field === "skills") {
          if (response.payload.isSuccessful) {
            const i = localStorage.getItem("i");
            //
            // skills.splice(i, 1);
            setSkillDelete(false);
            setNewSkills({ ...newSkills, ["name"]: "" });
            return toast.success("Delete Successfully");
          }
          setSkillDelete(false);
          setNewSkills({ ...newSkills, ["name"]: "" });
          toast.error(response.payload.message.data.message);
        } else {
          if (response.payload.isSuccessful) {
            setLinkDelete(false);
            return toast.success("Delete Successfully");
          }
          setLinkDelete(false);
          toast.error(response.payload.message.data.message);
        }
      }

      // }
    } catch (error) {}
    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  const [field, setField] = useState("");
  const share = (field) => {
    setField(field);
    const id = user._id;
    const typId = user.owner.typId;
    if (usertype === "student") {
      const link = `${endPointReact}/profile/${id}/?cta=${field}&typId=${typId}&redirectURL=/profile/${id}`;

      setShareLink(link);
    } else {
      const link = `${endPointReact}/ProfessionalProfile/${id}/?cta=${field}&typId=${typId}&redirectURL=/ProfessionalProfile/${id}`;

      setShareLink(link);
    }
    setCopied(false);
  };

  const { name } = newSkills;
  if (loggedIn === false) {
    toast.error("Please Login or Signup");
    return <Redirect to="/signup"></Redirect>;
  }

  // if (openHire.open === "job" || openHire.open === "internship") {
  //   setDisable(true);
  // }
  const hireableFunc = async (e) => {
    // e.preventDefault();
    // setHireableStudent(true);
    setHireMeLoader(true);

    // const hiringStatus = hireableStudent;
    const payload = {
      userId,
      hiringFor: openHire.open,
      hiringStatus: !hireableStudent,
    };

    if (openHire.open != null) {
      if (usertype === "student") {
        try {
          let response = await dispatch(studentHireableItem(payload));
          if (hireableStudent === true) {
            toast.error("You are not Hireable");
          } else {
            toast.success("You are Hireable");
          }
          setHireableStudent(user.isHirable);
        } catch (error) {
          toast.error(error);
        }
      } else {
        try {
          let response = await dispatch(professionalHireableItem(payload));
          if (hireableStudent === true) {
            toast.error("You are not Hireable");
          } else {
            toast.success("You are Hireable");
          }
          setHireableStudent(user.isHirable);
        } catch (error) {
          toast.error(error);
        }
      }
    }
    setHireMeLoader(false);
  };
  const hiremeFalse = async (e) => {
    // e.preventDefault();
    // setHireableStudent(true);
    setHireMeLoader(true);

    // const hiringStatus = hireableStudent;
    const payload = {
      userId,
      hiringFor: null,
      hiringStatus: !hireableStudent,
    };

    if (usertype === "student") {
      try {
        let response = await dispatch(studentHireableItem(payload));
        if (hireableStudent === true) {
          toast.error("You are not Hireable");
        } else {
          toast.success("You are Hireable");
        }
        setHireableStudent(user.isHirable);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        let response = await dispatch(professionalHireableItem(payload));
        if (hireableStudent === true) {
          toast.error("You are not Hireable");
        } else {
          toast.success("You are Hireable");
        }
        setHireableStudent(user.isHirable);
      } catch (error) {
        toast.error(error);
      }
    }
    setHireMeLoader(false);
  };
  const handleChangeOpen = (e) => {
    const { name, value } = e.target;
    setOpenHire({ ...openHire, [name]: value });
    setDisable(false);
  };

  //render
  return (
    <div>
      <section
        className="dtpage sc-pd py-md-5 pt-5"
        style={{ marginTop: "70px" }}
      >
        <div className="container">
          <div className="row mb-md-5 mb-4">
            <div className="col-lg-4 col-md-6">
              <div className="card Candidateprofile fulldetails desbox L_side">
                <div
                  className="card_img"
                  style={{
                    background: `url(${user.owner.profilePic})`,
                  }}
                ></div>
                {/* <img
                  className="card-img-top"
                  src={user.owner.profilePic}
                  alt="Card image cap"
                /> */}
                <div className="card-body p-lg-4 px-md-4 p-3 mr-0 p-sm-4">
                  <div
                    className="bluelabel zoom"
                    style={{
                      background: `url(${bluelabel}) top center no-repeat`,
                    }}
                  >
                    Level {user?.level?.level || 1}
                  </div>
                  {/* <div className="d-flex align-items-center flex-wrap mb-2">
                    {user?.badges
                      ?.filter((item) => item.revokedStatus.isActive === false)
                      ?.map((item) => {
                        // console.log('item: ', item);
                        return (
                          <div className="badge-cont border rounded mr-2 mb-2 position-relative">
                            <img
                              className="card-img-top img-fluid"
                              src={item?.badge?.icon}
                              alt="budgeone"
                            />
                          </div>
                        );
                      })}
                  </div> */}
                  <h6>TYP - ID : {user?.owner.typId}</h6>
                  <div className="contactinfo">
                    <div className="d-flex justify-content-between">
                      <h5 className="mb-md-4 py-0 mt-md-0">Contact Info</h5>
                      <EditProfileModal purpose="editProfile"></EditProfileModal>
                    </div>
                    {/* {user?.badges
                    ?.filter((item) => item.revokedStatus.isActive === false)
                    ?.map((item) => {
                      // console.log('item: ', item);
                      return (
                       
                        <div className="badge-cont border rounded mr-2 mb-2 position-relative">
                          <img
                            className="card-img-top img-fluid"
                            src={item?.badge?.icon}
                            alt="budgeone"
                          />
                        </div>
                     
                      );
                    })} */}
                  </div>
                  {/* <h6 >TYP - ID : {user?.owner.typId}</h6> */}
                  <div className="contactinfo">
                    {/* <h5 className="mb-md-4 py-0 mt-md-0" >Contact Info</h5> */}
                    <ul className="m-0 p-0">
                      <li>
                        <div className="iconbox flex-shrink-0">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="ctbox w-100">
                          <h6>Phone</h6>
                          <a
                            className="font-weight-normal"
                            href={`tel:${user.owner.mobileNumber}`}
                          >
                            {user.owner.mobileNumber
                              ? user.owner.mobileNumber
                              : "Number not added yet"}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="iconbox flex-shrink-0">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="ctbox w-100">
                          <h6>Email</h6>
                          <a
                            className="font-weight-normal"
                            href={`mailto:${user.owner.email}`}
                          >
                            {user.owner.email
                              ? user.owner.email
                              : "Email not added yet"}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="iconbox flex-shrink-0">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="ctbox w-100">
                          <h6>Address</h6>
                          <span className="Fweight-500">
                            {user.owner.address
                              ? user.owner.address
                              : "Address not added yet"}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="iconbox flex-shrink-0">
                          <i className="fa fa-home" />
                        </div>
                        <div className="ctbox w-100">
                          <h6>DOB</h6>
                          <span className="Fweight-500">
                            {user.owner.dateOfBirth
                              ? user.owner.dateOfBirth
                              : "Dob not added yet"}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="contactinfo sociallinks">
                    {/* <SocialLinksProfile /> */}
                    <h5 className="mb-4 d-flex">
                      Social Links
                      {user.linkedin &&
                      user.github &&
                      user.personalBlog &&
                      user.twitter ? (
                        ""
                      ) : (
                        <>
                          <i
                            data-toggle="modal"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-target="#SocialLinks"
                            onClick={() => {
                              setInValidLInks([]);
                              setSocialLinks({
                                linkedin: user?.linkedin,
                                github: user?.github,
                                twitter: user?.twitter,
                                personalBlog: user?.personalBlog,
                              });
                            }}
                            className="fa fa-plus-circle font-20 text-success ml-3 cursor-pointer"
                          />
                          <div className="ml-2">
                            {(linkAdd || linkEdit || linkDelete) && (
                              <>
                                <Loader />
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </h5>
                    <ul className="m-0 p-0">
                      {user.personalBlog && (
                        <li>
                          <div className="iconbox flex-shrink-0">
                            <i className="fa fa-blog" />
                          </div>
                          <div className="ctbox w-100">
                            <h6>Personal Blog</h6>
                            <div className="d-flex align-items-start justify-content-between w-100">
                              <a href={user.personalBlog} target="_blank">
                                {user.personalBlog}
                              </a>
                              <div className="dropdown cust_notify d-flex align-items-center">
                                <i
                                  className="fa fa-edit  font-18 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-1 ml-2 "
                                  role="button"
                                  id="dropdownDis"
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#PersonalBlog"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                />
                                <i
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#ConfDelete1"
                                  className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                                  onClick={() =>
                                    deleteButton("personalBlog", user._id)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                      {user.github && (
                        <li>
                          <div className="iconbox flex-shrink-0">
                            <i className="fa fa-github" />
                          </div>
                          <div className="ctbox w-100">
                            <h6>Github</h6>
                            <div className="d-flex align-items-start justify-content-between w-100">
                              <a href={user.github} target="_blank">
                                {user.github}
                              </a>
                              <div className="dropdown cust_notify d-flex align-items-center">
                                <i
                                  className="fa fa-edit  font-18 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-1 ml-2 "
                                  role="button"
                                  id="dropdownDis"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-toggle="modal"
                                  data-target="#Github"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                />
                                <i
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#ConfDelete1"
                                  onClick={() =>
                                    deleteButton("github", user._id)
                                  }
                                  className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                      {user.linkedin && (
                        <li>
                          <div className="iconbox flex-shrink-0">
                            <i
                              className="fa fa-linkedin"
                              style={{ color: "#0077B5" }}
                            />
                          </div>
                          <div className="ctbox w-100">
                            <h6>Linkedin</h6>
                            <div className="d-flex align-items-start justify-content-between w-100">
                              <a href={user.linkedin} target="_blank">
                                {user.linkedin}
                              </a>
                              <div className="dropdown cust_notify d-flex align-items-center">
                                <i
                                  className="fa fa-edit  font-18 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-1 ml-2 "
                                  role="button"
                                  id="dropdownDis"
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#Linkedin"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                />
                                <i
                                  onClick={() =>
                                    deleteButton("linkedin", user._id)
                                  }
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#ConfDelete1"
                                  className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                      {user.twitter && (
                        <li>
                          <div className="iconbox flex-shrink-0">
                            <i
                              className="fa fa-twitter"
                              style={{ color: "#1DA1F2" }}
                            />
                          </div>
                          <div className="ctbox w-100">
                            <h6>Twitter</h6>
                            <div className="d-flex align-items-start justify-content-between w-100">
                              <a href={user.twitter} target="_blank">
                                {user.twitter}
                              </a>
                              <div className="dropdown cust_notify d-flex align-items-center">
                                <i
                                  className="fa fa-edit  font-18 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-1 ml-2 "
                                  role="button"
                                  id="dropdownDis"
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#Twitter"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                />
                                <i
                                  onClick={() =>
                                    deleteButton("twitter", user._id)
                                  }
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#ConfDelete1"
                                  className="fa fa-trash font-20 text-danger ml-3 cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="candiprogress border-top mt-2 border-bottom overflow-visible py-2 border-top-0 border-bottom-0">
                    {/* <SkillsProfile ref={childRef} /> */}
                    <h5 className="m-0 mb-3 d-flex">
                      Skills{" "}
                      <i
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-target="#skills"
                        className="fa fa-plus-circle font-20 text-success ml-2 cursor-pointer"
                      />
                      <div className="ml-2">
                        {(skillAdd || skillEdit || skillDelete) && (
                          <>
                            <Loader />
                          </>
                        )}
                      </div>
                    </h5>
                    <div className="w-100">
                      <label htmlFor>
                        {/* <div className="d-flex align-items-center justify-content-between pb-1"> */}
                        {skills.length >= 0 &&
                          skills.map((skill, i) => (
                            <div className="dropdown cust_notify d-flex align-items-start justify-content-between mb-1">
                              <h6 className="mx-1 badge badge-primary">
                                <a>{skill.name}</a>
                              </h6>
                              <div className="flex-shrink-0">
                                <i
                                  className="fa fa-edit  font-18 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-1 ml-2 "
                                  role="button"
                                  id="dropdownDis"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-toggle="modal"
                                  data-target="#SkillsEdit"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={() => {
                                    setIndex(i);
                                    //
                                    setEditSkills(skill);
                                    // setDisplay(false);
                                  }}
                                />
                                <i
                                  data-toggle="modal"
                                  data-backdrop="static"
                                  data-keyboard="false"
                                  data-target="#ConfDeleteSkills"
                                  onClick={() =>
                                    deleteButton("skills", skill._id, i)
                                  }
                                  className="fa fa-trash font-20 text-danger ml-2 cursor-pointer"
                                />
                              </div>

                              {/* <div
                                  className="dropdown-menu p-4"
                                  aria-labelledby="dropdownDis"
                                  id={`skills${i}`}
                                >
                                  <form className="text-left">
                                    <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                                      Skill details
                                    </span>
                                    <div className="form-group ">
                                      <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                                        Name
                                      </label>
                                      <input
                                        type="text"
                                        name="name"
                                        defaultValue={skill.name}
                                        onChange={(e) => handleChange(e, i)}
                                        className="form-control"
                                        placeholder="Skill"
                                      />
                                    </div>
                                    <div className="text-right">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          document
                                            .getElementById(`skills${i}`)
                                            .classList.remove("show");
                                        }}
                                        className="btn btn-danger close-drop mx-0 text-capitalize px-4 py-2 mr-2"
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        // href="#"
                                        type="button"
                                        onClick={() => {
                                          document
                                            .getElementById(`skills${i}`)
                                            .classList.remove("show");
                                          submit();
                                        }}
                                        className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </form>
                                </div> */}
                            </div>
                          ))}
                        {/* <span>70%</span> */}
                        {/* </div> */}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="rightdetails">
                <div className="d-flex justify-content-between align-items-md-center align-item-start mb-3 mb-md-0 w-100 flex-md-row flex-column">
                  <div>
                    <h2>
                      {user.owner.firstName} {user.owner.lastName}
                    </h2>
                  </div>
                  {/* {hireableStudent ? (
                    <>
                      <button
                        className="btn text-nowrap font-size-15 text-white font-weight-bold  btn-warning flex-shrink-0"
                        data-toggle="modal"
                        // style={{"backgroundColor":"yellow"}}
                        data-target="#hiremefalse"
                        title="Don't Want To get Hired"
                      >
                        Ready For Hire : {user?.hirableFor}{" "}
                        {hiremeLoader && <Loader />}
                      </button>

                    
                    </>
                  ) : (
                    <button
                      className="btn text-nowrap btn-secondary btn-sm font-weight-bold flex-shrink-0"
                      data-toggle="modal"
                      title="Want to Get Hired ?"
                      data-target="#hireme"
                    >
                      Hire Me {hiremeLoader && <Loader />}
                    </button>
                  )} */}
                </div>

                <div
                  className="modal fade"
                  id="hireme"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  onKeyPress={(e) => {
                    if (e.which == "13") {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="modal-dialog border-0 modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 pb-0">
                        <h5
                          className="modal-title border-0"
                          id="exampleModalLabel"
                        />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body text-center">
                        <i className="fa fa-4x fa-exclamation-circle text-danger" />
                        <p className=" Fweight-600 h4 px-xl-5 mt-2">
                          Please Select For What you are Open for Hire .
                        </p>
                        <div className="d-flex align-items-center text-center justify-content-center">
                          <div className="form-group d-flex text-center m-2 align-items-center">
                            <input
                              type="radio"
                              onChange={handleChangeOpen}
                              id="male"
                              className="mt-1 mr-1"
                              name="open"
                              value="job"
                            />
                            <label for="male" className="mb-0 mt-1">
                              Job
                            </label>
                          </div>
                          <div className="form-group d-flex text-center m-2 align-items-center">
                            <input
                              type="radio"
                              onChange={handleChangeOpen}
                              className="mt-1 mr-1"
                              id="female"
                              name="open"
                              value="internship"
                            />
                            <label for="female" className="mb-0 mt-1">
                              Internship
                            </label>
                          </div>
                          <br />
                        </div>
                      </div>
                      <div className="modal-footer border-0">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          disabled={disable}
                          // onClick={hiremeFalse}
                          onClick={hireableFunc}
                          data-dismiss="modal"
                          className="btn btn-danger"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade"
                  id="hiremefalse"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  onKeyPress={(e) => {
                    if (e.which == "13") {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="modal-dialog border-0 modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header border-0 pb-0">
                        <h5
                          className="modal-title border-0"
                          id="exampleModalLabel"
                        />
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body text-center">
                        <i className="fa fa-4x fa-exclamation-circle text-danger" />
                        <p className=" Fweight-600 h4">
                          Are you sure you Don't want to be Hireable !
                        </p>
                      </div>
                      <div className="modal-footer border-0">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={hiremeFalse}
                          type="button"
                          data-dismiss="modal"
                          className="btn btn-danger"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="font-18 font-weight-normal text-black-50">
                  {user.owner.currentPosition
                    ? user.owner.currentPosition
                    : "Current position not added yet"}
                </p>
                <div className="pb-md-4">
                  <button
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    data-target="#Share"
                    className="btn btn-warning text-white font-weight-bold"
                  >
                    Share Profile <i className="fas fa-share-alt"></i>
                  </button>
                  {hireableStudent ? (
                    <>
                      <button
                        className="btn text-nowrap font-size-15 text-white font-weight-bold  btn-danger mx-2 flex-shrink-0"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        // style={{"backgroundColor":"yellow"}}
                        data-target="#hiremefalse"
                        title="Don't Want To get Hired"
                      >
                        Ready For Hire : {user?.hirableFor}{" "}
                        {/* {hiremeLoader && <Loader />} */}
                        {hiremeLoader && (
                          <span
                            className="ml-1 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>

                      {/* <label>DOnt't Want to get Hired ?</label> */}
                    </>
                  ) : (
                    <button
                      className="btn text-nowrap btn-secondary ml-3 font-weight-bold flex-shrink-0"
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      title="Want to Get Hired ?"
                      data-target="#hireme"
                    >
                      Hire Me{" "}
                      {hiremeLoader && (
                        <span
                          className="ml-1 spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  )}
                </div>
                {user?.owner?.profilePic ===
                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" ? (
                  <div className=" mb-md-5 mb-4 mt-2 " id="profilePic">
                    <span className="text-danger">
                      *Please upload profile picture to see your profile in
                      students section.
                    </span>
                  </div>
                ) : null}

                <div className="desbox mt-md-5 mt-4 ">
                  <div className="leftstar mb-md-5 mb-4 ">
                    <div className=" d-flex align-items-center mb-3">
                      <h4 className="mb-0">Description</h4>
                    </div>
                    {user?.owner?.description && (
                      <div className="content p-0 m-0 border p-2">
                        <p className="mb-0 p-0 ">{user?.owner?.description}</p>
                      </div>
                    )}
                  </div>
                  <div className="leftstar mb-md-5 mb-4">
                    <EducationProfile user={user} edu={user.education} />
                  </div>
                  <div className="leftstar mb-md-5 mb-4">
                    <ProjectProfile />
                  </div>
                  <div className="leftstar mb-md-5 mb-4 ">
                    <WorkExperienceProfile />
                  </div>
                  <div className="leftstar  ">
                    <CertificateProfile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!loggedIn && (
        <section
          className="signupsection"
          style={{ background: `url(${signupbg}) top center no-repeat` }}
        >
          <div className="container text-center">
            <a href="#" className="btn btn-primary">
              Sign Up Now
            </a>
          </div>
        </section>
      )}
      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}

      {/* addSociallinks  modal */}
      <div
        className="modal fade"
        id="SocialLinks"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setInValidLInks([]);
                  setSocialLinks({
                    linkedin: "",
                    github: "",
                    twitter: "",
                    personalBlog: "",
                  });
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Add social links
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Linkedin
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidLinks.includes("linkedin") ? "is-invalid" : ""
                    }`}
                    name="linkedin"
                    onChange={handleAddsocialLinks}
                    value={socialLinks?.linkedin}
                    placeholder=""
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Github
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidLinks.includes("github") ? "is-invalid" : ""
                    }`}
                    name="github"
                    onChange={handleAddsocialLinks}
                    value={socialLinks?.github}
                    placeholder=""
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Twitter
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidLinks.includes("twitter") ? "is-invalid" : ""
                    }`}
                    name="twitter"
                    onChange={handleAddsocialLinks}
                    value={socialLinks?.twitter}
                    placeholder=""
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Personal Blog
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidLinks.includes("personalBlog") ? "is-invalid" : ""
                    }`}
                    name="personalBlog"
                    onChange={handleAddsocialLinks}
                    value={socialLinks?.personalBlog}
                    placeholder=""
                  />
                </div>
                <div className="text-right">
                  <button
                    // href="#"
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                    onClick={() => {
                      setSocialLinks({
                        linkedin: "",
                        github: "",
                        twitter: "",
                        personalBlog: "",
                      });
                      setInValidLInks([]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={checkEmptySocialLinks(socialLinks)}
                    type="button"
                    data-dismiss={
                      checkModalSocialLinks(socialLinks).length == 0
                        ? "modal"
                        : null
                    }
                    onClick={(e) => {
                      addsocialLinks(e);
                    }}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* add linkedin  modal */}
      <div
        className="modal fade"
        id="Linkedin"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Edit social link
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Linkedin
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="linkedin"
                    onChange={handleEditSocialLinks}
                    value={socialLink.linkedin}
                    placeholder=""
                  />
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      editSocialLink();
                    }}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* add github  modal */}
      <div
        className="modal fade"
        id="Github"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Edit social link
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Github
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="github"
                    onChange={handleEditSocialLinks}
                    value={socialLink.github}
                    placeholder=""
                  />
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      editSocialLink();
                    }}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* add twitter  modal */}
      <div
        className="modal fade"
        id="Twitter"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Edit social link
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Twitter
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="twitter"
                    onChange={handleEditSocialLinks}
                    value={socialLink.twitter}
                    placeholder=""
                  />
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      editSocialLink();
                    }}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* add blog  modal */}
      <div
        className="modal fade"
        id="PersonalBlog"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Edit social link
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Personal Blog
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="personalBlog"
                    onChange={handleEditSocialLinks}
                    value={socialLink.personalBlog}
                    placeholder=""
                  />
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    data-dismiss="modal"
                    onClick={() => {
                      editSocialLink();
                    }}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Edit
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/*socail links Delete conformation modal */}
      <div
        className="modal 0"
        id="ConfDelete1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                onClick={deleteFields}
                className="btn btn-danger"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*share conformation modal */}
      <div
        className="modal 0"
        id="Share"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center d-flex justify-content-around flex-wrap">
              <button className="btn" onClick={() => share("Whatsapp")}>
                <i className="fab fa-2x  fa-whatsapp text-success"></i> <br />
                whatsapp
              </button>
              <button className="btn" onClick={() => share("Instagram")}>
                <i className="fab fa-2x  fa-instagram text-danger"></i>
                <br />
                Instagram
              </button>
              <button className="btn" onClick={() => share("Linkedin")}>
                <i
                  className="fab fa-2x  fa-linkedin"
                  style={{ color: "#0077B5" }}
                ></i>
                <br />
                LinkedIn
              </button>
              <button className="btn" onClick={() => share("Twitter")}>
                <i
                  className="fab fa-2x  fa-twitter"
                  style={{ color: "#1DA1F2" }}
                ></i>
                <br />
                Twitter
              </button>
            </div>
            {shareLink && (
              <p className="content text-center text-primary h6">
                {field} Copy Link Given Below
              </p>
            )}
            <p
              className="content text-center"
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "3px 5px",
                width: "98%",
                height: "20%",
                overflow: "auto",
                margin: "0 auto 10px",
              }}
            >
              {shareLink}
            </p>
            {shareLink && (
              <CopyToClipboard text={shareLink} onCopy={() => setCopied(true)}>
                <button className="btn m-2 btn-success mb-4">
                  {copied ? "Copied!" : "Copy Link"}
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>
      </div>

      {/* Skills add  modal */}
      <div
        className="modal fade"
        id="skills"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Skills details
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => handleSkillsInputChange(e)}
                    className="form-control"
                    value={name}
                    placeholder="Skill"
                  />
                </div>
                <div className="text-right">
                  <button
                    // href="#"

                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={addSkills}
                    data-dismiss="modal"
                    // href="#"
                    disabled={empty}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* Skills edit  modal */}
      <div
        className="modal fade"
        id="SkillsEdit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <span className="text-capitalize d-block w-100 mb-3 d-block text-dark Fweight-600 h5">
                  Skills details
                </span>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => handleChange(e, index)}
                    className="form-control"
                    value={editSkills?.name}
                    placeholder="Skill"
                  />
                </div>
                <div className="text-right">
                  <button
                    // href="#"

                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={submit}
                    data-dismiss="modal"
                    // href="#"
                    disabled={empty}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/*skills Delete conformation modal */}
      <div
        className="modal fade"
        id="ConfDeleteSkills"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  deleteFields();
                  // childRef.current.delLocalSkills();
                }}
                data-dismiss="modal"
                className="btn btn-danger"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentFullProfileNew;
