import React from "react";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import ContestParticipant from "./contestParticipant";
import { useSelector } from "react-redux";

const ContestPage = () => {
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }
  const goPrevPage = () => {
    history.goBack();
  };
  return (
    <div className="bg-light">
      <div className="container-fluid">
        <div className="row px-sm-5">
          <div className="col-12 d-flex align-items-center justify-content-between my-4">
            <h3 className="text-primary Fweight-600 text-center mt-3">
              Contests
            </h3>
            <div onClick={goPrevPage} class="btn btn-primary">
              Back
            </div>
          </div>
          <div className="col-12 bg-white admin-card rounded-lg p-4 d-flex justify-content-center flex-wrap align-items-stretch mb-5 py-5">
            <NavLink
              to="/admin/contests"
              className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
            >
              <div className="">
                <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                  CRUD Contests
                </h4>
              </div>
            </NavLink>
            <NavLink
              to="/admin/contest/participantList"
              className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
            >
              <div className="">
                <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                  Contest Participant List
                </h4>
              </div>
            </NavLink>
            {/* 
          <NavLink to="/admin/contest/participantList" className="text-white text-decoration-none">
            Reward List for Contests
             </NavLink>
        */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestPage;
