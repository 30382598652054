import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const WorkExperience = (props) => {
  const workExperienceList = props.location.state.workExperienceList;
  //
  const i = props.location.state.i;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const id = user._id;
  const [workExperience, setWorkExperience] = useState([...workExperienceList]);
  const [editWorkExperience, setEditWorkExperience] = useState(
    workExperience[i]
  );

  //

  const submit = async () => {
    workExperience[i] = editWorkExperience;

    const payload = { workExperience, id };
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  const handleworkExperienceInputChange = (e) => {
    const { name, value } = e.target;

    setEditWorkExperience({ ...editWorkExperience, [name]: value });
  };
  // handle click event of the Remove button

  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>workExperience Details</h3>
      </div>

      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Work Title</label>
            <input
              type="text"
              name="workTitle"
              onChange={(e) => handleworkExperienceInputChange(e)}
              defaultValue={workExperience[i].workTitle}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Start Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="startDate"
              onChange={(e) => handleworkExperienceInputChange(e)}
              defaultValue={workExperience[i].startDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> End Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="endDate"
              onChange={(e) => handleworkExperienceInputChange(e)}
              defaultValue={workExperience[i].endDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Description</label>
            <input
              type="text"
              name="projectDescription"
              onChange={(e) => handleworkExperienceInputChange(e)}
              defaultValue={workExperience[i].projectDescription}
              className="form-control"
            />
          </div>
        </div>
        <button type="submit" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default WorkExperience;
