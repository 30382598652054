import React, { useState } from "react";
import Modal from "react-modal";
import { updateActionItem } from "../../../../data/reducers/referral.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";

const Model = ({ item, index }) => {
  //
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  //init state of action form
  const [editAction, setEditAction] = useState({
    action: item.action,
    points: item.points,
    userType: item.userType,
  });

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle add change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditAction({ ...editAction, [name]: value });
  };

  //handle submit
  const handleSubmit = async () => {
    const res = checkEmpty(editAction);
    //
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }

    const _id = item._id;
    const payload = { _id, editAction };
    try {
      const response = await dispatch(updateActionItem(payload));
      if (response.payload.isSuccessful) {
        // setEditAction({
        //   ...editAction,
        //   ["action"]: "",
        //   ["points"]: "",
        //   ["userType"]: "",
        // });
        setInputErr([]);
        return toast.success("Action updated successfully!");
      }
      // setEditAction({
      //   ...editAction,
      //   ["action"]: "",
      //   ["points"]: "",
      //   ["userType"]: "",
      // });
      setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };
  return (
    <div className="container">
      <i
        className="fa fa-edit font-size-15 text-success cursor-pointer"
        onClick={() => {
          OpenModal();
          setEditAction({
            ...editAction,
            ["action"]: item.action,
            ["points"]: item.points,
            ["userType"]: item.userType,
          });
          setInputErr([]);
        }}
      ></i>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Edit Action</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Name
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("action") ? "is-invalid" : ""
                  }`}
                  name="action"
                  value={editAction?.action}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Points
                </label>
                <input
                  onChange={handleEditChange}
                  type="number"
                  className={`form-control form-control-lg ${
                    inputErr.includes("points") ? "is-invalid" : ""
                  }`}
                  name="points"
                  value={editAction?.points}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  User Type
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("userType") ? "is-invalid" : ""
                  }`}
                  name="userType"
                  value={editAction?.userType}
                />
              </div>
              <div className="col-12 p-0">
                <button
                  // disabled= checkEmpty(editAction).length === 0 ? true : false}
                  className="btn m-1 text-white btn-secondary"
                  // style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    handleSubmit();
                    return checkEmpty(editAction).length === 0
                      ? closeModal()
                      : null;
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    closeModal();
                    setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
