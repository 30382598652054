import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setBadgeAdminItem } from "../../../../data/reducers/admin/badges/badges-admin.reducer";

const Model = ({ item, editedUser, loadData }) => {
  // console.log("editedUser: ", editedUser.badges);
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //set badge
  const setBadge = async () => {
    const { _id } = editedUser;
    const { userType } = editedUser.owner;
    const badge = item._id;
    const badgeName = item.name;
    const payload = { _id, userType, badge, badgeName };
    // console.log("payload: ", payload);

    try {
      setloader(true);
      const response = await dispatch(setBadgeAdminItem(payload));
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        loadData();
        setloader(false);
        closeModal();

        return toast.success("Assigned successfully!");
      }
      setloader(false);
      // console.log('response.payload.message: ', response.payload.message);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  return (
    <div className="container">
      <span
        onClick={() => OpenModal()}
        className="dropdown-item mb-0 px-2 user cursor-pointer"
      >
        {item.name}
      </span>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center my-4">
                <i
                  style={{ color: "#dc3545" }}
                  className="fa fa-exclamation-circle fa-3x mr-3"
                ></i>
                <h3 className="text-center">Are you sure ?</h3>
              </div>
            </div>
            <div className="col-12 text-center mt-2">
              <button
                disabled={loader}
                onClick={() => {
                  setBadge();
                }}
                className="btn btn-danger mr-3 mb-2"
              >
                Yes{" "}
                {loader && (
                  <span
                    class="spinner-border ml-1 spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              <button
                className="btn text-white mr-3 mb-2"
                style={{ backgroundColor: "#5A6268" }}
                onClick={closeModal}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
