import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchActionCreatedUserItem } from "../../../data/reducers/referral.reducer";
import RedeemHistoryModal from ".//Modals/RedeemHistory.modal";
import RedeemPointsModal from "./Modals/RedeemPoints.modal";

export default function RewardHeader() {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const refUserAction = useSelector((state) => state.referralReducer)
    .userActionCreated;

  const userState = useSelector((state) => state.authReducer).userInfo;

  const setAction = async () => {
    setLoad(true);
    const response = await dispatch(
      fetchActionCreatedUserItem({
        specific: true,
        action_status: "ACTION_CREATED",
      })
    );

    if (response.payload.isSuccessful) {
      setLoad(false);
      return console.log("Successful");
    }
    toast.error(response.payload.message.data.message);
  };

  useEffect(async () => {
    setAction();
  }, []);
  return (
    <div className="bg-primary text-white p-2 px-3 rounded"   data-aos="flip-right" data-aos-duration="1000">
      <div className="row ">
        <div className="col-md-12">
          <div className="d-flex flex-sm-row justify-content-between align-items-center flex-column flex-wrap">
            <div className="d-flex align-items-center">
              <span className="mb-0 mr-3 h6">
                Your Points : {load ?
                  <span className="text-center ml-2 ">
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </span> :
                  refUserAction.totalSum}
              </span>
            </div>
            <div className="col">
              <i className="far fa-question-circle fa-x text-white"></i>
            </div>
            {/* <div className=" py-2 py-sm-0">
            <h4 className="text-center mb-0 font-weight-bold">Rewards</h4>    
            </div> */}
            <div className="d-flex justify-content-between align-items-center">
            <div className="mr-3">
             <RedeemPointsModal  />
             </div>
            <RedeemHistoryModal />
            </div>
            </div>
          </div>
        </div>
    </div>
    
  );
}
