import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllUsertypesAPI,
  CreateUsertypeAdminAPI,
  EditUsertypeAdminAPI,
} from "../../../services/admin/user-types/usertypes-admin.service";

//async thunk
//get all
export const getAllUsertypesItem = createAsyncThunk(
  "user/getAllUsertypesItem",
  async (payload, thunkAPI) => {
    let response = await getAllUsertypesAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//edit
export const CreateUsertypeAdminItem = createAsyncThunk(
  "admin/CreateUsertypeAdminItem",
  async (payload, thunkAPI) => {
    let response = await CreateUsertypeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
//add
export const EditUsertypeAdminItem = createAsyncThunk(
  "admin/EditUsertypeAdminItem",
  async (payload, thunkAPI) => {
    let response = await EditUsertypeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//slice
const userTypesSlice = createSlice({
  name: "userTypes-admin",
  initialState: {
    userTypesData: [],
  },
  extraReducers: {
    [getAllUsertypesItem.fulfilled]: (state, action) => {
      console.log("in action ");
      state.userTypesData = action.payload.data;
    },
  },
});

export default userTypesSlice.reducer;
