import React, { useEffect, useState, useMemo } from "react";
import PaginationComponent from "../../Shared/Pagination/Pagination";
import Search from "../../Shared/Search/Search";
import Spinner from "react-bootstrap/Spinner";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
// import useFullPageLoader from "hooks/useFullPageLoader";
import { useDispatch, useSelector } from "react-redux";
import { hrListAdminItem } from "../../../../data/reducers/admin.reducer";
import Requestlist from "./Requestlist";

const Hr = () => {
  const dispatch = useDispatch();
  const hrList = useSelector((state) => state.adminReducer).hrList;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const hrLength = useSelector((state) => state.adminReducer).hrLength;

  const [hr, setSHr] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 50;
  const history = useHistory();

  const request = (i, request, firstName, lastName) => {
    history.push({
      pathname: "/admin/hr/requestlist",
      state: { request: request, first: firstName, last: lastName },
    });
  };
  // const [loader, showLoader, hideLoader] = useFullPageLoader();
  useEffect(async () => {
    setLoader(true);

    try {
      // setLoading(true);
      let response = await dispatch(hrListAdminItem());

      setSHr(hrList);
    } catch (error) {}
    setLoader(false);
  }, []);
  const goPrevPage = () => {
    history.goBack();
  };
  const hrData = useMemo(() => {
    let hrData = hr;

    if (search) {
      hrData = hrData.filter(
        (hr) =>
          hr.owner.firstName.toLowerCase().includes(search.toLowerCase()) ||
          hr.owner.email.toLowerCase().includes(search.toLowerCase()) ||
          hr.owner.isVerified.toLowerCase().includes(search.toLowerCase()) ||
          hr.owner.mobileNumber.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(hrData.length);
    // curreent pages
    return hrData.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [hr, currentPage, search]);

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (loggedIn === true && usertype !== "admin" && usertype !== "technical-operation") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin" || usertype === "technical-operation") {
    return (
      <div className="container-fluid">
        <div>
          <div className="row mt-3">
            <div className="col d-flex align-items-center justify-content-between mb-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">
                Hr List
              </h3>
              <div onClick={goPrevPage} class="btn btn-primary">
                Back
              </div>
            </div>
          </div>
          <div className="text-right pb-3">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>

          <div className="table-responsive">
            {loader && (
              <p className="text-center">
                <Spinner
                  className="text-center"
                  animation="border"
                  variant="info"
                />
              </p>
            )}
            <table className="table table-hover">
              <thead className="bg-primary text-white">
                <th>No #</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>Request List</th>
              </thead>
              <tbody>
                {hrData.map((hr, i) => (
                  <tr key={hr?._id}>
                    <td className="h5">{i + 1}</td>
                    <td>
                      {hr.owner.firstName} {hr.owner.lastName}
                    </td>
                    <td>{hr.owner.email}</td>
                    <td>{hr.owner.mobileNumber}</td>
                    <td>
                      <button
                        className="btn btn-secondary"
                        onClick={() =>
                          request(
                            i,
                            hr.requested,
                            hr.owner.firstName,
                            hr.owner.lastName
                          )
                        }
                      >
                        Requests
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    );
  }
};

export default Hr;
