import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  checkNumber,
  checkEmail,
  checkEmpty,
  checkString,
} from "../../Utils/method";
import { addUserToZohoItem } from "../../../data/reducers/zoho.reducer";
import { useState } from "react";
import { sendDataToTelegramItem } from "../../../data/reducers/telegram.reducer";
import ContactUsVerifyModal from "./Verify-modal";

const Footer = () => {
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  // const Contact = ()=>{
  const dispatch = useDispatch();
  const [inputErr, setInputErr] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const sendDataToTelegram = async (payload) => {
    const data = `Name: ${payload.First_Name} ${payload.Last_Name} \n
Email: ${payload.Email} \n
Subject: ${payload.Subject} \n
Phone: ${payload.Phone}, \n
Message: ${payload.Message}, \n
UserAction: ${payload.UserAction} \n 
timeStamp:${new Date(Date.now()).toLocaleString()} `;
    const encoded = encodeURI(data);
    const response = await dispatch(sendDataToTelegramItem(encoded));
    // console.log("response telegram: ", response);
    // if (response.payload.status == "success") {
    //   return toast.success("Data Sent!!");
    // }
    // console.log("payloaf", response.payload.status);
  };
  const submit = async (closeModal) => {
    // console.log("Something is here");
    // const res = checkEmpty(userData);
    // console.log("res: ", res);
    // setInputErr(res);
    // if (res.length != 0) {
    //   return toast.error("All fields are mandatory");
    // }

    // if (!checkNumber(userData.phone) || !checkEmail(userData.email)) {
    //   return toast.error("Please check Email ID / Contact Number format");
    // }

    // if (!checkString(userData.name)) {
    //   return toast.error("Please check name format");
    // }

    let [First_Name, Last_Name] = userData.name.split(" ");
    if (!Last_Name) Last_Name = " -- ";
    console.log("First_Name, Last_Name: ", First_Name, Last_Name);
    const payload = {
      First_Name,
      Last_Name,
      Email: userData.email,
      Subject: userData.subject,
      Phone: userData.phone,
      Message: userData.message,
      UserAction: "CONTACT_US",
    };
    sendDataToTelegram(payload);
    const response = await dispatch(addUserToZohoItem({ payload }));
    // console.log("response: ", response);
    if (response.payload.data[0].status == "success") {
      setUserData({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
      closeModal();
      return toast.success("We'll get back to you soon ... !!");
    }
    toast.error(response.payload.data[0].message);
  };

  const { name, email, subject, phone, message } = userData;
  return (
    <>
      <footer className="footer" id="contactUs">
        <div className="container-fluid row" id="foot">
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 contact-form">
            <h2 className="h1-responsive my-3" id="form-heading">
              Contact us
            </h2>
            <div className="row" id="responsive-contact-row">
              <div className="col-md-12 mb-5">
                <form
                  id="contact-form"
                  name="contact-form"
                  action="mail.php"
                  method="POST"
                >
                  <div className="row" id="input-fields">
                    <div className="col-md-12">
                      <div className="md-form mb-4" id="input-name">
                        {/* <label htmlFor="name" className="myclass">Your name</label> */}
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={handleChange}
                          value={name}
                          className={`form-control my-5 shadow-none ${
                            inputErr.includes("name") ? "is-invalid" : ""
                          }`}
                          //  className="form-control my-5"
                          placeholder="Your Name"
                        />
                      </div>

                      <div className="md-form mb-0">
                        {/* <label htmlFor="email" className>Your email</label> */}
                        <input
                          type="text"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={email}
                          className={`form-control shadow-none ${
                            inputErr.includes("email") ? "is-invalid" : ""
                          }`}
                          // className="form-control"
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="md-form mb-0">
                        {/* <label htmlFor="subject" className>Subject</label> */}
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          onChange={handleChange}
                          value={subject}
                          className="form-control my-3 shadow-none"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="md-form mb-0">
                        {/* <label htmlFor="subject" className>Subject</label> */}
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          onChange={handleChange}
                          value={phone}
                          className={`form-control my-3 shadow-none ${
                            inputErr.includes("phone") ? "is-invalid" : ""
                          }`}
                          // className="form-control my-3"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="md-form">
                        {/* <label htmlFor="message">Your message</label> */}
                        {/* <label style={{color: 'grey'}}>Your message</label> */}
                        <textarea
                          className="text-white"
                          id="white-placeholder"
                          type="text"
                          id="message"
                          name="message"
                          rows={2}
                          onChange={handleChange}
                          value={message}
                          className={`form-control md-textarea my-3 shadow-none ${
                            inputErr.includes("message") ? "is-invalid" : ""
                          }`}
                          // className="form-control md-textarea my-3"
                          placeholder="Type Your message here!!"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-center" id="contact-submit">
                  {/* <button onClick={submit}>SUBMIT</button>
                   */}
                  <ContactUsVerifyModal
                    userData={userData}
                    setInputErr={setInputErr}
                    submit={submit}
                  />
                </div>
                {/* <div className="status" /> */}
              </div>
            </div>
          </div>
          {/* <div className="contact-form col-md-4">
            <section className="mb-4">
             
            </section>
          </div> */}
          <div className="col-md-12 col-lg-8 col-sm-12 col-12 contact-form">
            <div className="row" id="footer-text">
              <div className="col-md-12 text-center" id="footer-cntnt">
                <div className="title my-md-4 my-4 pt-md-3">
                  <h2 className="text-white  mb-4 text-uppercase">
                    Experience the <span className="text-warning">new</span> way
                    of <span className="text-warning">learning</span>
                  </h2>
                  <p
                    className="text-gray Fweight-600 text-uppercase"
                    id="footer-cntnt-sub-heading"
                  >
                    It’s never too late. Join our army and become the future of
                    design.
                  </p>
                  {/* <navlink
                    className="btn text-uppercase btn-dark-warning"
                    to="/contact"
                  >
                    Yes, Help me out!
                  </navlink> */}
                </div>
                <div className="footer-contnt">
                  {/* <div className="social_cont d-flex flex-md-row flex-column justify-content-between align-items-center py-md-2 pt-0 pb-3" id="footer-icons"> */}
                  <div
                    className="social_cont col-md-6 col-sm-1 col-1 m-auto"
                    id="footer-icons"
                  >
                    {/* <ul className="d-flex col-md-6 col-sm-1 col-1 justify-content-center justify-content-md-start "> */}
                    <div>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/TheYouthProjectIndia"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/theyouthproject_in/"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href="https://linkedin.com/company/theyouthproject"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </div>
                    <div>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCbpLgFV3aMgaV7LFo-AvnRg"
                        style={{ textDecoration: "none" }}
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </div>
                  </div>
                  <div style={{ marginTop: "8em" }} id="footer-cntnt-ul">
                    {/* <Link className="nav-link active" to="/about" >Home</Link> */}
                    <ul className="navfooter">
                      <li className=" contact-nav-item">
                        <Link
                          className="nav-link active"
                          to="/"
                          style={{ color: "white" }}
                        >
                          Home
                        </Link>
                        <span className="sr-only">(current)</span>
                      </li>
                      <li className=" contact-nav-item">
                        <Link
                          className="nav-link"
                          to="/about"
                          style={{ color: "white" }}
                        >
                          About us
                        </Link>
                      </li>
                      {/* {'{'}loggedIn &amp;&amp; (
                          <li className="contact-nav-item">
                          <link className="nav-link" to="/timeline" />
                      Timeline
                      </li>
                       ){'}'} */}
                      <li className=" contact-nav-item">
                        <Link
                          className="nav-link"
                          to="/student/list"
                          style={{ color: "white" }}
                        >
                          Students
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="dots1">
                  <a
                    href="tel: +917999549783"
                    className="text-center text-white"
                  >
                    +91 - 79995-49783
                  </a>{" "}
                  |
                  <a
                    href="mailto: contact@theyouthproject.in"
                    className="text-white text-center"
                  >
                    contact@theyouthproject.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mb-0 text-center text-white py-4 border-top border-secondary font-size-14">
          <i class="fas fa-copyright"></i> Copyright.The Youth Project All
          Rights Reserved.
          {/* {'{'}new Date().getFullYear(){'}'} */}
        </p>
      </footer>
    </>
  );
  // }
};

export default Footer;
