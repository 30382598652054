import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import loginrightimg from "../../assets/images/loginrightimg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../config";
import { confirmationItem } from "../../data/reducers/auth.reducer";

function Confirmation() {
  const history = useHistory() ;
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  let [userData, setUserdata] = useState({ password: "", confirmPassword: "" });
  const change = (event) => {
    const { name, value } = event.target;
    setUserdata({ ...userData, [name]: value });
  };
  const submit = async (event) => {
    event.preventDefault();
    const params = new URLSearchParams(window.location.search);
    let token = params.get("token");
    let payload = {
      password: password,
      token: token,
    };
    setLoading(true);
    if (password === confirmPassword) {
      if (userData.password === userData.confirmPassword)
        try {
          let response = await dispatch(confirmationItem(payload));

          if (response.payload === true) {
            setLoading(false);
            //Add userhistory here
            history.push('/login') ;
            return toast.success("Password Reset kindly log in");
          } else if (response.payload.isSuccessful === false) {
            toast.error(response.payload.message.data.message);
          }
        } catch (error) {
          toast.error(error);
        }
    } else {
      toast.error("passwords dont match");
    }
    setLoading(false);
  };
  const { password, confirmPassword } = userData;
  return (
    <>
      <section className="loginsection">
        <div className="container">
          <div className="row tp-row">
            <div className="col-md-3">
              <NavLink to="/" className="btn btn-default">
                <i className="fa  fa-chevron-left "></i> Back
              </NavLink>
            </div>
            <div className="col-md-6 text-center">
              <h3>Reset Password</h3>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="borderbox">
                <div className="row align-items-center">
                  <div className="col-lg-6 order-lg-1">
                    <img src={loginrightimg} alt="" className="img-fluid" />
                  </div>
                  <div className="col-lg-6 leftform">
                    <div className="form-group">
                      <label for="">Password</label>
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        value={password}
                        onChange={change}
                      />
                    </div>
                    <div className="form-group">
                      <label for="">Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        value={confirmPassword}
                        onChange={change}
                      />
                    </div>

                    <div className="btn-bar">
                      <button className="btn btn-primary" onClick={submit}>
                        Reset Password
                      </button>
                    </div>
                    <br />
                    {loading && (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Confirmation;
