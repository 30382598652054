import React from "react";
import { NavLink, Redirect } from "react-router-dom";

import { Link } from "react-scroll";
import  { useState, useEffect } from "react";

//logo
import logo from "../../../assets/images/logo.png";
import chaticon from "../../../assets/images/chaticon.svg";
import proImg from "../../../assets/images/Proimg.png";
import alarm from "../../../assets/images/alarm.svg";
import menu from "../../../assets/images/Menu.png";
import { useSelector } from "react-redux";
import { logoutFunction } from "../../../data/reducers/auth.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Signup from "../../../pages/AuthPages/Signup";
import Login from "../../../pages/AuthPages/Login";
import "../../styles/custom.css";
import LoginModal from "../../../pages/AuthPages/Login-modal";

const Header = () => {
  const [isActive, setActive] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const toggleClass = () => {
    setActive(!isActive);
  };
  const toggleClass1 = () => {
    setActive();
  };
  const logout = async (e) => {
    e.preventDefault();

    history.push("/");
    let response = await dispatch(logoutFunction());
toggleClass()
      toast.success("Logout Successfully");
  };

  // if (loggedIn === true && usertype === "student") {
  //   return (
  //     <>
  //       <header className="bg-primary">
  //         <div className="container-fluid">
  //           <div className="row">
  //             <div className="col-12">
  //               <nav className="navbar bg-primary fixed-top navbar-expand-lg  navbar-dark px-0">
  //                 <NavLink className="navbar-brand" to="/">
  //                   <img src={logo} alt="logo" />
  //                 </NavLink>
  //                 <button
  //                   className="navbar-toggler border"
  //                   type="button"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   aria-controls="navbarSupportedContent"
  //                   aria-expanded="false"
  //                   aria-label="Toggle navigation"
  //                 >
  //                   <span className="navbar-toggler-icon text"></span>
  //                 </button>

  //                 <div
  //                   className="collapse navbar-collapse"
  //                   id="navbarSupportedContent"
  //                 >
  //                   <ul className="navbar-nav ml-auto align-items-center">

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         to="/about"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         About us
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/student/list"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Students
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/events"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Events
  //                       </NavLink>
  //                     </li>

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/timeline"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Timeline
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/FullProfile"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Profile
  //                       </NavLink>
  //                     </li>

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/rewards"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Rewards
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <a
  //                         style={{ cursor: "pointer" }}
  //                         data-toggle="modal"
  //                         data-target="#Logout"
  //                         data-backdrop="static"

  //                         data-keyboard="false"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         <i className="fa fa-sign-out fa-2x"></i>
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </nav>
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           className="modal fade"
  //           id="Logout"
  //           tabIndex={-1}
  //           aria-labelledby="exampleModalLabel"
  //           aria-hidden="true"
  //         >
  //           <div className="modal-dialog border-0 modal-dialog-centered">
  //             <div className="modal-content">
  //               <div className="modal-header border-0 pb-0">
  //                 <h5 className="modal-title border-0" id="exampleModalLabel" />
  //                 <button
  //                   type="button"
  //                   className="close"
  //                   data-dismiss="modal"
  //                   aria-label="Close"
  //                 >
  //                   <span aria-hidden="true">×</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body text-center">
  //                 <i className="fa fa-4x fa-exclamation-circle text-danger" />
  //                 <p className=" Fweight-600 h4">
  //                   Are you sure you want to Logout
  //                 </p>
  //               </div>
  //               <div className="modal-footer border-0">
  //                 <button
  //                   type="button"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   className="btn btn-secondary"
  //                   data-dismiss="modal"
  //                 >
  //                   Cancel
  //                 </button>
  //                 <button
  //                   type="button"
  //                   data-dismiss="modal"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   onClick={logout}
  //                   className="btn btn-danger"
  //                 >
  //                   Confirm
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </header>
  //     </>
  //   );
  // } else
  // if (
  //   (loggedIn === true && usertype === "admin") ||
  //   (loggedIn === true && usertype === "technical-operation")
  // ) {
  //   return (
  //     <>
  //       <header className="bg-primary">
  //         <div className="container-fluid">
  //           <div className="row">
  //             <div className="col-12">
  //               <nav className="navbar bg-primary fixed-top navbar-expand-lg ">
  //                 <NavLink className="navbar-brand" to="/">
  //                   <img src={logo} alt="logo" />
  //                 </NavLink>
  //                 <button
  //                   className="navbar-toggler"
  //                   type="button"
  //                   style={{
  //                     background: `url(${menu}) center center no-repeat`,
  //                   }}
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   aria-controls="navbarSupportedContent"
  //                   aria-expanded="false"
  //                   aria-label="Toggle navigation"
  //                 >
  //                   <span className="navbar-toggler-icon text"></span>
  //                 </button>

  //                 <div
  //                   className="collapse navbar-collapse"
  //                   id="navbarSupportedContent"
  //                 >
  //                   <ul className="navbar-nav ml-auto align-items-center">
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Home <span className="sr-only">(current)</span>
  //                       </NavLink>
  //                     </li>

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/admin"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Admin
  //                       </NavLink>
  //                     </li>
  //                     {usertype === "admin" && (
  //                       <li className="nav-item">
  //                         <NavLink
  //                           exact
  //                           to="/events"
  //                           activeClassName="active"
  //                           data-toggle="collapse"
  //                           data-target="#navbarSupportedContent"
  //                           className="nav-link"
  //                           activeClassName="active"
  //                         >
  //                           Events
  //                         </NavLink>
  //                       </li>
  //                     )}
  //                     {usertype === "admin" && (
  //                       <li className="nav-item">
  //                         <NavLink
  //                           exact
  //                           to="/upcomingBatches"
  //                           className="nav-link"
  //                           activeClassName="active"
  //                           data-toggle="collapse"
  //                           data-target="#navbarSupportedContent"
  //                           activeClassName="active"
  //                         >
  //                           Batches
  //                         </NavLink>
  //                       </li>
  //                     )}

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/timeline"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                       >
  //                         Timeline
  //                       </NavLink>
  //                     </li>
  //                     {usertype === "admin" && (
  //                       <li className="nav-item">
  //                         <NavLink
  //                           exact
  //                           to="/config"
  //                           className="nav-link"
  //                           activeClassName="active"
  //                           activeClassName="active"
  //                           data-toggle="collapse"
  //                           data-target="#navbarSupportedContent"
  //                         >
  //                           Referral
  //                         </NavLink>
  //                       </li>
  //                     )}

  //                     <li className="nav-item">
  //                       <a
  //                         style={{ cursor: "pointer" }}
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="modal"
  //                         data-backdrop="static"
  //                         data-keyboard="false"
  //                         data-target="#AdminLogout"
  //                       >
  //                         <i className="fa fa-sign-out fa-2x"></i>
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </nav>
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           className="modal fade"
  //           id="AdminLogout"
  //           tabIndex={-1}
  //           aria-labelledby="exampleModalLabel"
  //           aria-hidden="true"
  //         >
  //           <div className="modal-dialog border-0 modal-dialog-centered">
  //             <div className="modal-content">
  //               <div className="modal-header border-0 pb-0">
  //                 <h5 className="modal-title border-0" id="exampleModalLabel" />
  //                 <button
  //                   type="button"
  //                   className="close"
  //                   data-dismiss="modal"
  //                   aria-label="Close"
  //                 >
  //                   <span aria-hidden="true">×</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body text-center">
  //                 <i className="fa fa-4x fa-exclamation-circle text-danger" />
  //                 <p className=" Fweight-600 h4">
  //                   Are you sure you want to Logout
  //                 </p>
  //               </div>
  //               <div className="modal-footer border-0">
  //                 <button
  //                   type="button"
  //                   className="btn btn-secondary"
  //                   data-dismiss="modal"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                 >
  //                   Cancel
  //                 </button>
  //                 <button
  //                   type="button"
  //                   onClick={logout}
  //                   data-dismiss="modal"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   className="btn btn-danger"
  //                 >
  //                   Confirm
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </header>
  //     </>
  //   );
  // } else
  // if (loggedIn === true && usertype === "professional") {
  //   return (
  //     <>
  //       <header className="bg-primary">
  //         <div className="container-fluid">
  //           <div className="row">
  //             <div className="col-12">
  //               <nav className="navbar bg-primary fixed-top navbar-expand-lg ">
  //                 <NavLink className="navbar-brand" to="/">
  //                   <img src={logo} alt="logo" />
  //                 </NavLink>
  //                 <button
  //                   className="navbar-toggler"
  //                   type="button"
  //                   style={{
  //                     background: `url(${menu}) top center no-repeat`,
  //                   }}
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   aria-controls="navbarSupportedContent"
  //                   aria-expanded="false"
  //                   aria-label="Toggle navigation"
  //                 >
  //                   <span className="navbar-toggler-icon text"></span>
  //                 </button>

  //                 <div
  //                   className="collapse navbar-collapse"
  //                   id="navbarSupportedContent"
  //                 >
  //                   <ul className="navbar-nav ml-auto align-items-center">
                 
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/about"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         About
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/student/list"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Students
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         to="/events"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Events
  //                       </NavLink>
  //                     </li>

  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/FullProfile"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Profile
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/timeline"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Timeline
  //                       </NavLink>
  //                     </li>
                     
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/rewards"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Rewards
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <a
  //                         style={{ cursor: "pointer" }}
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="modal"
  //                         data-backdrop="static"
  //                         data-keyboard="false"
  //                         data-target="#Logout"
  //                       >
  //                         <i className="fa fa-sign-out fa-2x"></i>
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </nav>
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           className="modal fade"
  //           id="Logout"
  //           tabIndex={-1}
  //           aria-labelledby="exampleModalLabel"
  //           aria-hidden="true"
  //         >
  //           <div className="modal-dialog border-0 modal-dialog-centered">
  //             <div className="modal-content">
  //               <div className="modal-header border-0 pb-0">
  //                 <h5 className="modal-title border-0" id="exampleModalLabel" />
  //                 <button
  //                   type="button"
  //                   className="close"
  //                   data-dismiss="modal"
  //                   aria-label="Close"
  //                 >
  //                   <span aria-hidden="true">×</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body text-center">
  //                 <i className="fa fa-4x fa-exclamation-circle text-danger" />
  //                 <p className=" Fweight-600 h4">
  //                   Are you sure you want to Logout
  //                 </p>
  //               </div>
  //               <div className="modal-footer border-0">
  //                 <button
  //                   type="button"
  //                   className="btn btn-secondary"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   data-dismiss="modal"
  //                 >
  //                   Cancel
  //                 </button>
  //                 <button
  //                   type="button"
  //                   onClick={logout}
  //                   data-dismiss="modal"
  //                   className="btn btn-danger"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                 >
  //                   Confirm
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </header>
  //     </>
  //   );
  // } else
  //  if (loggedIn === true && usertype === "recruiter") {
  //   return (
  //     <>
  //       <header className="bg-primary">
  //         <div className="container-fluid">
  //           <div className="row">
  //             <div className="col-12">
  //               <nav className="navbar bg-primary fixed-top navbar-expand-lg ">
  //                 <NavLink className="navbar-brand" to="/">
  //                   <img src={logo} alt="logo" />
  //                 </NavLink>
  //                 <button
  //                   className="navbar-toggler"
  //                   type="button"
  //                   style={{
  //                     background: `url(${menu}) top center no-repeat`,
  //                   }}
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   aria-controls="navbarSupportedContent"
  //                   aria-expanded="false"
  //                   aria-label="Toggle navigation"
  //                 >
  //                   <span className="navbar-toggler-icon text"></span>
  //                 </button>

  //                 <div
  //                   className="collapse navbar-collapse"
  //                   id="navbarSupportedContent"
  //                 >
  //                   <ul className="navbar-nav ml-auto align-items-center">
                   
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/student/list"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         activeClassName="active"
  //                       >
  //                         Students
  //                       </NavLink>
  //                     </li>
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/events"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Events
  //                       </NavLink>
  //                     </li>

                    
  //                     <li className="nav-item">
  //                       <NavLink
  //                         exact
  //                         to="/timeline"
  //                         activeClassName="active"
  //                         data-toggle="collapse"
  //                         data-target="#navbarSupportedContent"
  //                         className="nav-link"
  //                         activeClassName="active"
  //                       >
  //                         Timeline
  //                       </NavLink>
  //                     </li>

  //                     <li className="nav-item">
  //                       <a
  //                         style={{ cursor: "pointer" }}
  //                         data-toggle="modal"
  //                         data-target="#Logout"
  //                         className="nav-link"
  //                         data-backdrop="static"
  //                         data-keyboard="false"
  //                         activeClassName="active"
  //                       >
  //                         <i className="fa fa-sign-out fa-2x"></i>
  //                       </a>
  //                     </li>
  //                   </ul>
  //                 </div>
  //               </nav>
  //             </div>
  //           </div>
  //         </div>
  //         <div
  //           className="modal fade"
  //           id="Logout"
  //           tabIndex={-1}
  //           aria-labelledby="exampleModalLabel"
  //           aria-hidden="true"
  //         >
  //           <div className="modal-dialog border-0 modal-dialog-centered">
  //             <div className="modal-content">
  //               <div className="modal-header border-0 pb-0">
  //                 <h5 className="modal-title border-0" id="exampleModalLabel" />
  //                 <button
  //                   type="button"
  //                   className="close"
  //                   data-dismiss="modal"
  //                   aria-label="Close"
  //                 >
  //                   <span aria-hidden="true">×</span>
  //                 </button>
  //               </div>
  //               <div className="modal-body text-center">
  //                 <i className="fa fa-4x fa-exclamation-circle text-danger" />
  //                 <p className=" Fweight-600 h4">
  //                   Are you sure you want to Logout
  //                 </p>
  //               </div>
  //               <div className="modal-footer border-0">
  //                 <button
  //                   type="button"
  //                   className="btn btn-secondary"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   data-dismiss="modal"
  //                 >
  //                   Cancel
  //                 </button>
  //                 <button
  //                   type="button"
  //                   onClick={logout}
  //                   data-dismiss="modal"
  //                   activeClassName="active"
  //                   data-toggle="collapse"
  //                   data-target="#navbarSupportedContent"
  //                   className="btn btn-danger"
  //                 >
  //                   Confirm
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </header>
  //     </>
  //   );
  // }
  return (
    <>
      <header className=" ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <nav className="navbar  navbar-expand-lg fixed-top">
                <NavLink className="navbar-brand" to="/">
                  <img src={logo} alt="logo" />
                </NavLink>
                <button
                  className="navbar-toggler"
                  onClick={toggleClass}
                  // className={isActive ? "" : "navbar-toggler collapsed "}
                  type="button"
                  style={{
                    // background: `url(${menu}) top center no-repeat`,
                  }}
                  // data-toggle="collapse"
                  // data-target="#navbarSupportedContent"
                  // aria-controls="navbarSupportedContent"
                  // aria-expanded="false"
                  // aria-label="Toggle navigation"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>
                  {/* <span className="navbar-toggler-icon text"></span> */}
                </button>

                <div
                className={isActive ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
                  // className=""
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ml-auto align-items-center">
                    {((loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "admin" ||
                        usertype === "technical-operation" ||
                        usertype === "professional" ||
                        usertype === "recruiter")) ||  (loggedIn===false)) && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            // aria-controls="navbarSupportedContent"
                            // aria-expanded="false"
                            // aria-label="Toggle navigation"
                          >
                            Home <span className="sr-only">(current)</span>
                          </NavLink>
                        </li>
                      )}
                    {((loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "professional")) || (loggedIn===false)) && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/about"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            // aria-label="Toggle navigation"
                          >
                            About
                          </NavLink>
                        </li>
                      )}

                    {loggedIn === true &&
                      (usertype === "admin" ||
                        usertype === "technical-operation") && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/admin"
                            onClick={toggleClass}
                            activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            className="nav-link"
                            // activeClassName="active"
                          >
                            Admin
                          </NavLink>
                        </li>
                      )}
                    {((loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "professional" ||
                        usertype === "recruiter")) || (loggedIn===false))&& (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/student/list"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            // aria-label="Toggle navigation"
                          >
                            Students
                          </NavLink>
                        </li>
                      )}
                    {((loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "admin" ||
                        usertype === "technical-operation" ||
                        usertype === "professional" ||
                        usertype === "recruiter") ) ||  (loggedIn===false))&& (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/events"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            // aria-label="Toggle navigation"
                          >
                            Events
                          </NavLink>
                        </li>
                      )}
                    {loggedIn === true && usertype === "admin" && (
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/upcomingBatches"
                          className="nav-link"
                          onClick={toggleClass}
                          // activeClassName="active"
                          // data-toggle="collapse"
                          // data-target="#navbarSupportedContent"
                          // activeClassName="active"
                        >
                          Batches
                        </NavLink>
                      </li>
                    )}
                    {loggedIn === true &&
                      (usertype === "admin" ||
                        usertype === "technical-operation" || usertype === "student"||
                        usertype === "professional"|| usertype === "recruiter") && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/timeline"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                          >
                            Timeline
                          </NavLink>
                        </li>
                      )}
                    {loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "professional") && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/FullProfile"
                            activeClassName="active"
                            onClick={toggleClass}
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            className="nav-link"
                            // activeClassName="active"
                          >
                            Profile
                          </NavLink>
                        </li>
                      )}
                    {loggedIn === true && usertype === "admin" && (
                      <li className="nav-item">
                        <NavLink
                          exact
                          to="/config"
                          className="nav-link"
                          onClick={toggleClass}
                          // activeClassName="active"
                          // activeClassName="active"
                          // data-toggle="collapse"
                          // data-target="#navbarSupportedContent"
                        >
                          Referral
                        </NavLink>
                      </li>
                    )}
                    {loggedIn === true &&
                      (usertype === "student" ||
                        usertype === "professional") && (
                        <li className="nav-item">
                          <NavLink
                            exact
                            to="/rewards"
                            className="nav-link"
                            onClick={toggleClass}
                            // activeClassName="active"
                            // data-toggle="collapse"
                            // data-target="#navbarSupportedContent"
                            // activeClassName="active"
                          >
                            Rewards
                          </NavLink>
                        </li>
                      )}
                    {loggedIn === false && (
                      <li className="nav-item">
                        <Link
                          style={{ cursor: "pointer" }}
                          // exact
                          to="contactUs"
                          className="nav-link"
                          onClick={toggleClass}
                          // activeClassName="active"
                          // data-toggle="collapse"
                          // data-target="#navbarSupportedContent"
                          // aria-controls="navbarSupportedContent"
                          // aria-expanded="false"
                          // aria-label="Toggle navigation"
                        >
                          Contact us
                        </Link>
                      </li>
                    )}
                  </ul>
                  <ul  className="navbar-nav ml-auto align-items-center pr-3">
                    <LoginModal purpose={"Sign"} toggleClass={toggleClass} />

                    {/* signup model */}
                    <div
                      class="modal  fade"
                      id="signUpModal"
                      tabindex="-1"
                      aria-labelledby="signUpModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog  modal-dialog-centered login-modal">
                        <div
                          class="modal-content"
                          style={{ borderRadius: "25px" }}
                        >
                          <div class="modal-body px-0">
                            <Signup />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="modal fade"
                      rol="dialog"
                      id="loginModal"
                      tabindex="-1"
                      aria-labelledby="loginModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered login-modal">
                        <div
                          class="modal-content"
                          style={{ borderRadius: "25px" }}
                        >
                          <div class="modal-body px-0">
                            <Login />
                          </div>
                        </div>
                      </div>
                    </div>
                    {loggedIn == true && (
                      <li className="nav-item">
                        <a
                          style={{ cursor: "pointer" }}
                          className="nav-link"
                          // activeClassName="active"
                          data-toggle="modal"
                          
                          data-backdrop="static"
                          data-keyboard="false"
                          data-target="#AdminLogout"
                        >
                          <i className="fa fa-sign-out fa-2x"></i>
                        </a>
                      </li>
                    )}
                    <div
                      className="modal fade"
                      id="AdminLogout"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog border-0 modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header border-0 pb-0">
                            <h5
                              className="modal-title border-0"
                              id="exampleModalLabel"
                            />
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body text-center">
                            <i className="fa fa-4x fa-exclamation-circle text-danger" />
                            <p className=" Fweight-600 h4">
                              Are you sure you want to Logout
                            </p>
                          </div>
                          <div className="modal-footer border-0">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                              // activeClassName="active"
                              // data-toggle="collapse"
                              // data-target="#navbarSupportedContent"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              onClick={logout}
                              data-dismiss="modal"
                              // activeClassName="active"
                              // data-toggle="collapse"
                              // data-target="#navbarSupportedContent"
                              className="btn btn-danger"
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
