import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBadgesAdminItem,
  toggleBadgesStatusAdminItem,
} from "../../../data/reducers/admin/badges/badges-admin.reducer";
import EditBadgesModel from "./Modals/Edit-badges.model";
import CreateBadgesModel from "./Modals/Create-badges-model";
import DeleteBadgesModel from "./Modals/Delete-badges.model";
import ToggleStatus from "react-toggle";
import { toast } from "react-toastify";
import "../Referral/config.css"; //this is the css that toggle button needs

//...
const Badges = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const userType = useSelector((state) => state.authReducer).userType;
  const badgesData = useSelector((state) => state.badgesAdminReducer).badges;
  // console.log("badgesData: ", badgesData);
  const [render, setrender] = useState(false);
  const [toggle, settoggle] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      history.push("/theyouthproject/admin/teams");
    } else {
      return userType === "admin"
        ? (setrender(true), fetchAllBadges())
        : history.push("/");
    }
  }, []);

  const fetchAllBadges = useCallback(async () => {
    try {
      let response = await dispatch(getAllBadgesAdminItem());
      if (response.payload.isSuccessful) {
        return;
      }
    } catch (error) {}
  }, [render]);

  const handleEditStatus = useCallback(
    async (_id, status) => {
      const payload = { _id, status };
      try {
        const response = await dispatch(toggleBadgesStatusAdminItem(payload));
        if (response.payload.isSuccessful) {
          return toast.success("Badge status updated successfully!");
        }
        toast.error(response.payload.message.data.message);
      } catch (err) {}
    },
    [toggle]
  );

  return (
    <>
      {render && (
        <div style={{ marginTop: "110px" }}>
          <CreateBadgesModel />
        </div>
      )}
      {render && badgesData?.length > 0 ? (
        <div className="col-md-12">
          <div className="table-responsive mt-2  ">
            <table className="table table-hover border">
              <thead className="btn-secondary">
                <tr>
                  <th align="center" scope="col">
                    Name
                  </th>
                  <th align="center" scope="col">
                    Icon
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Points
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Status
                  </th>

                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {badgesData?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td align="left">{item.name}</td>
                      <td align="left">
                        {item.icon === "" ? (
                          "--"
                        ) : (
                          <img
                            width="60px"
                            height="50px"
                            src={`${item.icon}`}
                          />
                        )}
                      </td>
                      <td align="center">{item.points || "--"}</td>
                      <td align="center" className="">
                        <ToggleStatus
                          defaultChecked={item.status.isActive}
                          icons={false}
                          onClick={(e) => {
                            settoggle(true);
                            handleEditStatus(item._id, e.target.checked);
                          }}
                        />
                      </td>
                      <td align="center">
                        <EditBadgesModel item={item} />
                      </td>
                      <td align="center">
                        <DeleteBadgesModel item={item} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        "No badges created yet!"
      )}
    </>
  );
};

export default Badges;
