import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRequestsItem } from "../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";
import ReqActionModal from "./Modals/Req-action.modal";
import { buttons } from "./Reusable-data/button";

const Request = () => {
  const dispatch = useDispatch();
  const allRequests = useSelector((state) => state.referralReducer).allRequests;
  console.log('allRequests: ', allRequests);
  //
  const [reqStatus, setReqStatus] = useState("Status");
  const [buttonArray, setbuttonArray] = useState(buttons);
  const [compensation, setCompensation] = useState("") ;
  const [sortCondition, setSortCondition] = useState("Sort");
  useEffect(() => {
    //
    getReqByStatus(false, "");
  }, []);

  const getReqByStatus = async (specific, reqCondition) => {
    const payload = {
      specific,
      reqCondition,
    };

    const response = await dispatch(fetchAllRequestsItem(payload));
    if (response.payload.isSuccessful) {
      // setAllReqState(response.payload.data);
      return reqCondition === ""
        ? setReqStatus("Status")
        : setReqStatus(reqCondition);
      //
    }
    toast.error(response.payload.message.data.message);
  };

  const sort = (sortCond) => {
    setSortCondition(sortCond);
  };

  return (
    <div className="">
      <h3 className="text-primary Fweight-600 text-center my-3">Requests</h3>
      <div className="table-responsive mt-2 ">
        <table className="table table-hover text-center">
          <thead className="bg-primary text-white">
            <tr>
              <th className="text-center" scope="col">
                <th
                  className="text-center border-0 dropdown-toggle p-0 py-1"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {sortCondition}
                </th>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button
                    className="dropdown-item"
                    onClick={() => sort("Oldest")}
                    type="button"
                  >
                    Oldest
                  </button>
                  <button
                    className="dropdown-item"
                    onClick={() => sort("Latest ")}
                    type="button"
                  >
                    Latest
                  </button>
                </div>
              </th>
              <th className="text-center" scope="col">
                Created Date
              </th>
              <th className="text-center" scope="col">
                Created Time
              </th>
              <th className="text-center" scope="col">
                Name
              </th>

              <th className="text-center" scope="col">
                Total Points
              </th>
              {/* <th className="text-center"  scope="col">
                Status
              </th> */}
              <th className="text-center">
                <th
                  className="border-0 cursor-pointer text-center m-auto dropdown-toggle p-0"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {reqStatus}
                </th>

                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {buttonArray.map((item) => {
                    return (
                      <button key={item?._id}
                        className={item.className}
                        onClick={() =>
                          getReqByStatus(
                            item.filterCondition,
                            item.filterStatus
                          )
                        }
                        type={item.type}
                      >
                        {item.filterStatus}
                      </button>
                    );
                  })}
                </div>
              </th>
              <th className="text-center" scope="col">
                Reward Type
              </th>
              <th className="text-center" scope="col">
                Accepted
              </th>
              <th className="text-center" scope="col">
                Action
              </th>
            </tr>
          </thead>

          {!allRequests ? (
            "No req"
          ) : (
            <>
              {sortCondition === "Latest "
                ? allRequests
                  .slice(0)
                  .reverse()
                  .map((item, index) => {
                    return (
                      <>
                        <ReqActionModal
                          key={index}
                          item={item}
                          index={index + 1}
                          
                        />
                      </>
                    );
                  })
                : allRequests.map((item, index) => {
                  return (
                    <ReqActionModal
                      key={index}
                      item={item}
                      index={index + 1}
                    />
                  );
                })}
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default Request;
