import React, { useState } from "react";
import Modal from "react-modal";
import { createRewardConfigItem } from "../../../../data/reducers/admin.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";
//...
const Model = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  //init state of action form
  const [rewardConfig, setRewardConfig] = useState({
    rewardName: "",
    rewardPoints: "",
    type: "",
    measure: "",
  });

  //handle add change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRewardConfig({ ...rewardConfig, [name]: value });
    // console.log("{ ...rewardConfig, [name]: value }: ", {
    //   ...rewardConfig,
    //   [name]: value,
    // });
  };

  //handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("rewardConfig: ", rewardConfig);
    //rewardConfig.type ==="CASH"? checkEmpty(rewardConfig):checkEmpty(rewardConfig);
    if (rewardConfig.type === "CASH" || rewardConfig.type === "FREE") {
      //   console.log("in cash free");
      var res = checkEmpty(rewardConfig, "rewardPoints");
      var dupRewardConfig = {
        rewardName: rewardConfig.rewardName,
        type: rewardConfig.type,
        measure: rewardConfig.measure,
      };
    } else {
      //   console.log("in poingts");
      var res = checkEmpty(rewardConfig);
      var dupRewardConfig = {
        rewardName: rewardConfig.rewardName,
        type: rewardConfig.type,
        rewardPoints: rewardConfig.rewardPoints,
        measure: rewardConfig.measure,
      };
    }
    // console.log("dupRewardConfig: ", dupRewardConfig);
    console.log("res: ", res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }

    try {
      const response = await dispatch(createRewardConfigItem(dupRewardConfig));
      //
      if (response.payload.isSuccessful) {
        setRewardConfig({
          ["rewardName"]: "",
          ["rewardPoints"]: "",
          ["type"]: "",
          ["measure"]: "",
        });
        setInputErr([]);
        closeModal();
        return toast.success("Action created successfully!");
      }
      setRewardConfig({
        ["rewardName"]: "",
        ["rewardPoints"]: "",
        ["type"]: "",
        ["measure"]: "",
      });
      setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (err) { }
  };
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container-fluid">
      <button
        className="cursor-pointer text-white btn btn-warning m-0 p-2"
        onClick={() => {
          OpenModal();
          setInputErr([]);
        }}
      >
        <i className="fas fa-plus text-white"></i> Create Reward
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <center>
          <h4>New Reward</h4>
        </center>
        <form>
          <div className="form-group">
            <label className="text-dark">Reward Name</label>
            <input
              onChange={handleChange}
              placeholder="Enter Reward Name"
              className={`form-control ${inputErr.includes("rewardName") ? "is-invalid" : ""
                }`}
              name="rewardName"
            />
          </div>

          <div className="form-group">
            <label className="test-dark">Reward Type</label>
            <select
              name="type"
              onChange={handleChange}
              className={`custom-select ${inputErr.includes("type") ? "is-invalid" : ""
                }`}
            >
              <option value="">Reward Type</option>
              <option value="GOODIE">Goodie</option>
              <option value="CASH">Cash</option>
              <option value="FREE">Free</option>
            </select>
          </div>

          <div className="form-group">
            <label className="test-dark">Measure of Reward</label>
            <select
              name="measure"
              onChange={handleChange}
              className={`custom-select ${inputErr.includes("measure") ? "is-invalid" : ""
                }`}
            >
              <option value="">Measure</option>
              <option value="AMOUNT">Amount</option>
              <option value="QUANTITY">Quantity</option>
            </select>
          </div>
          {rewardConfig.type !== "CASH" && rewardConfig.type !== "FREE" ? (
            <div className="form-group">
              <label className="text-dark">Reward Points</label>
              <input
                onChange={handleChange}
                placeholder="Enter Reward Points"
                type="number"
                className={`form-control ${inputErr.includes("rewardPoints") ? "is-invalid" : ""
                  }`}
                name="rewardPoints"
              />
            </div>
          ) : null}
          <div className="row">
            <div classsName='col w-100 d-flex justify-content-between'>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Create Reward
              </button>
              <button className="btn btn-danger" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Model;