import React, { useEffect, useState, useMemo } from "react";
import Search from "../Shared/Search/Search";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  projectListAdminItem,
  setProjectSkip,
  setProjectView,
} from "../../../data/reducers/admin.reducer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const ProjectsApproval = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const projectList = useSelector((state) => state.adminReducer).projectList;
  const projectSkip = useSelector((state) => state.adminReducer).projectSkip;
  // const projectView = useSelector((state) => state.adminReducer)
  //   .projectToggleView;
  // console.log("projectView: ", projectView);

  // console.log('projectList: ', projectList);
  const [loader, setLoader] = useState(false);
  let history = useHistory();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [click, setClick] = useState("");
  const [toggleView, setToggleView] = useState("approved");

  //fetching all projects
  useEffect(async () => {
    // console.log("calling");
    // console.log(" projectSkip1 : ", projectSkip);
    // console.log("toggleView: ", toggleView);
    setSkip(projectSkip ? projectSkip : skip);
    fetchProjects();

    handleToggleView();
  }, [skip, toggleView]);

  useEffect(() => {}, []);
  //fetch all projects
  const fetchProjects = async () => {
    const status = toggleView === "approved" ? true : false;
    try {
      const payload = { limit, skip, status };
      // console.log("payload: ", payload);
      setLoader(true);
      let response = await dispatch(
        projectListAdminItem({ limit, skip, status })
      );
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setLoader(false);
        return response.payload.data.length === 0
          ? toast.warning("There are no more projects!")
          : null;
      }
      toast.error(response.payload.message.data.message);
      setLoader(false);
    } catch (error) {}
  };
  const goPrevPage = () => {
    history.goBack();
  };

  const handleToggleView = () => {
    const view = localStorage.getItem("projectView");
    // console.log('view: ', view);
    if (view === "approved") {
      // console.log("22");
      dispatch(setProjectSkip(projectSkip));
      document.getElementById("unapproved").classList.remove("active");
      document.getElementById(view).classList.add("active");
    } else if (view === "unapproved") {
      // console.log("22");
      dispatch(setProjectSkip(projectSkip));
      document.getElementById(view).classList.add("active");
      document.getElementById("approved").classList.remove("active");
    } else {
      dispatch(setProjectSkip(projectSkip));
      document.getElementById("unapproved")?.classList.remove("active");
      document.getElementById("approved")?.classList.add("active");
      return  setToggleView("approved");
    }

    setToggleView(view);
    // setZero()
  };

  const setZero = () => {
    dispatch(setProjectSkip(0));
    setSkip(0);
  };
  //implementing search algo
  let projectData = useMemo(() => {
    let projectData = projectList;

    if (search) {
      return (projectData = projectData.filter(
        (project) =>
          project.owner.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          project.owner.lastName.toLowerCase().includes(search.toLowerCase())
      ));
    }
    return projectData;
  }, [projectList, search]);

  const openModal = (
    link,
    id,
    isActive,
    isApproved,
    firstName,
    lastName,
    email,
    title,
    project
  ) => {
    history.push({
      pathname: "/admin/projectsApproval",
      state: {
        link: link,
        id: id,
        isActive: isActive,
        isApproved: isApproved,
        firstName: firstName,
        lastName: lastName,
        email: email,
        title: title,
        project,
      },
    });
  };

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  } else if (
    loggedIn === true &&
    usertype !== "admin" &&
    usertype !== "technical-operation"
  ) {
    return <Redirect to="/"></Redirect>;
  } else if (
    (loggedIn === true && usertype === "admin") ||
    usertype === "technical-operation"
  ) {
    return (
      <div className="continer p-5 m-5">
        <div className="row my-3">
          <div className="col d-flex align-items-center container">
            <div onClick={goPrevPage} class="btn btn-primary">
              Back
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-12">
            {" "}
            <h3 className="btn-secondary text-center p-2">Projects List</h3>
          </div>
        </div>
        <div className="row my-3">
          {/* <div className="col-md-3">
            {" "}
            <h3 className="btn-secondary  mr-2 text-center p-2">
              Page {skip/10 +1}
            </h3>
          </div> */}
          {projectList.length === 0 ? (
            <div className="col-md-3">
              <button
                className="btn-secondary  mr-2 text-center p-2"
                onClick={() => {
                  setZero();
                }}
              >
                Go to page-1
              </button>
            </div>
          ) : null}
        </div>
        {/* <div className=""> */}
        <div className="row my-3 d-flex justify-content-start">
          <div className=" col-md-3  mb-2">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>

          <div className="row mb-2">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  id="approved"
                  className="active nav-link cursor-pointer"
                  style={{ color: "black" }}
                  onClick={() => {
                    localStorage.setItem("projectView", "approved");
                    handleToggleView();
                  }}
                >
                  APPROVED
                </a>
              </li>

              <li className="nav-item">
                <a
                  id="unapproved"
                  className="nav-link cursor-pointer"
                  style={{ color: "black" }}
                  onClick={() => {
                    localStorage.setItem("projectView", "unapproved");
                    handleToggleView();
                  }}
                >
                  UN-APPROVED
                </a>
              </li>
            </ul>
          </div>
        </div>

        {projectData?.length > 0 ? (
          <div>
            <div className="row my-3">
              <table className="table table-hover table-esponsive">
                <thead>
                  <th>No #</th>
                  <th>Name</th>

                  <th>Views</th>
                  <th>APPROVAL</th>
                  <th>Active</th>
                  <th>project</th>
                </thead>
                <tbody>
                  {projectData?.map((project, i) => (
                    <tr key={project?._id}>
                      <td style={{ width: "5%" }}>{skip + i + 1}</td>
                      <td style={{ width: "5%" }}>
                        {project.owner.firstName} {project.owner.lastName}
                      </td>

                      <td style={{ width: "5%" }}>{project.views}</td>
                      <td style={{ width: "5%" }}>
                        {project.isApproved ? "Approved" : "Not Approved"}
                      </td>
                      <td style={{ width: "5%" }}>
                        {project.isActive ? "Active" : "Not Active"}
                      </td>
                      <td style={{ width: "5%" }}>
                        <button
                          className="btn btn-success"
                          onClick={() => {
                            dispatch(setProjectSkip(skip));
                            localStorage.setItem("projectView", toggleView);
                            // console.log('toggleView: ', toggleView);
                            openModal(
                              project.video,
                              project._id,
                              project.isActive,
                              project.isApproved,
                              project.owner.firstName,
                              project.owner.lastName,
                              project.owner.email,
                              project.title,
                              project
                            );
                          }}
                        >
                          View Project
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr size="2px" />
            </div>
          </div>
        ) : (
          <p className="text-center">No projects to show!</p>
        )}

        <div
          className="m-1"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            style={{ backgroundColor: "#1D3883" }}
            className=" btn text-white m-1"
            disabled={skip === 0}
            onClick={() => {
              setClick("prev");
              setSkip((preVal) => {
                // console.log("preVal: ", preVal);
                dispatch(setProjectSkip(preVal - limit));
                return preVal - limit;
              });
            }}
          >
            {loader && click === "prev" ? "Prev.." : "Prev"}
            {loader && click === "prev" ? (
              <span
                id="studentTop"
                class="spinner-grow spinner-grow-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>

          <button
            style={{ backgroundColor: "#1D3883" }}
            className=" btn text-white m-1"
            disabled={projectData?.length === 0 || projectData?.length < limit}
            onClick={() => {
              setClick("next");
              setSkip((preVal) => {
                // console.log("preVal: ", preVal);
                dispatch(setProjectSkip(preVal + limit));
                return preVal + limit;
              });
            }}
          >
            {loader && click === "next" ? "Next.." : "Next"}
            {loader && click === "next" ? (
              <span
                class="spinner-grow spinner-grow-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>
        </div>
        {/* </div> */}
      </div>
    );
  }
};

export default ProjectsApproval;
