import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { checkEmpty } from "../../../shared/Utils/method";
import { toast } from "react-toastify";
import ToggleStatus from "react-toggle";
import "../../Admin/Referral/config.css";
import { editEventItem } from "../../../data/reducers/admin.reducer";
import ReactSelect from "react-select";
import moment from "moment";

const Model = ({ item, getEvents }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [editEventDetails, setEditEventDetails] = useState({
    eventTitle: item?.eventTitle,
    eventPoster: item?.eventPoster,
    eventDate: item?.eventDate,
    eventPlace: item?.eventPlace,
    eventRecText: item?.eventRecText,
    eventMode: item?.eventMode,
    eventIsActive: item?.eventIsActive,
    emailContent: item?.emailContent,
    emailSubject: item?.emailSubject,
    trainingStartDate: item?.trainingStartDate,
    trainingEndDate: item?.trainingEndDate,
    certificateIssuedDate: new Date(item?.certificateIssuedDate)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-"),
    registrationLastDate: new Date(item?.registrationLastDate)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-"),

    grantCertificate: item?.grantCertificate,
    isSpecialEvent: item?.isSpecialEvent,
    certificationConfig: {
      templateName: item?.certificationConfig?.templateName,
      signature: item?.certificationConfig?.signature,
      dynamicFields: item?.certificationConfig?.dynamicFields,
    },
  });
  // console.log(
  //   "regLast",
  //   new Date(editEventDetails?.registrationLastDate)
  //     .toLocaleDateString()
  //     .split("/")
  //     .reverse()
  //     .join("-")
  // );
  const [inputErr, setInputErr] = useState([]);
  const dispatch = useDispatch();

  const [fileImage, setFileImage] = useState();
  const [displayImage, setDisplayImage] = useState(item?.eventPoster?.url);

  const ref = useRef();

  // const uploadImage = async (e) => {
  //   const files = e.target.files;
  //   const data = new FormData();
  //   data.append("file", files[0]);
  //   data.append("upload_preset", "typ-image");

  //   const res = await fetch(
  //     "	https://api.cloudinary.com/v1_1/projectyouth/image/upload",
  //     {
  //       method: "POST",
  //       body: data,
  //     }
  //   );
  //   const file = await res.json();

  //   setEditEventDetails({ ...editEventDetails, eventPoster: file.secure_url });
  // };
  Modal.setAppElement("#root");
  const selectOptions = [
    { label: "YES", value: true },
    { label: "NO", value: false },
  ];

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    // const old_val = JSON.stringify(item);
    // console.log('old_val: ', old_val);
    // const new_val = JSON.stringify(editEventDetails);
    // console.log('new_val: ', new_val);
    // if (old_val == new_val) {
    //     return setDisable(true);
    // }

    setEditEventDetails({
      ...editEventDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    let A = moment(editEventDetails.eventDate);
    let B = moment(editEventDetails?.registrationLastDate);

    let A1 = moment(editEventDetails.trainingEndDate);
    let B1 = moment(editEventDetails?.trainingStartDate);

    if (A.diff(B, "days") < 0 || A.diff(B, "days") === 0) {
      return toast.error(
        "Event date should be greater than registration date."
      );
    }

    if (A1.diff(B1, "days") < 0 || A1.diff(B1, "days") === 0) {
      return toast.error(
        "Training start date should be greater than training end date."
      );
    }

    // const res = checkEmpty(editEventDetails, [

    //   "emailSubject",
    //   "emailContent",
    //   "certificationConfig",
    //   "dynamicFields",
    //   "eventPoster",
    // ]);
    // setInputErr(res);
    // if (res.length !== 0) {
    //   return toast.error("Something is missing .. !!");
    // }

    if (!displayImage) {
      return toast.error("Please choose event image.");
    }
    try {
      setloader(true);
      var bodyFormData = new FormData();

      for (const property in editEventDetails) {
        // console.log("property: ", editEventDetails[property]);

        bodyFormData.append(property, editEventDetails[property]);
      }
      bodyFormData.delete("certificationConfig");
      bodyFormData.delete("eventPoster");

      if (fileImage) {
        bodyFormData.append("images", fileImage);
      }
      const isImageChanged =
        item?.eventPoster?.url === displayImage ? false : true;

      bodyFormData.append("isImageChanged", isImageChanged);
      bodyFormData.append(
        "certificationConfig",
        JSON.stringify(editEventDetails.certificationConfig)
      );
      bodyFormData.append(
        "eventPoster",
        JSON.stringify(editEventDetails.eventPoster)
      );

      const payload = {
        _id: item._id,
        bodyFormData,
      };
      // console.log("payload: ", payload);
      const response = await dispatch(editEventItem(payload));
      // console.log("response: ", response);
      if (response.payload.isSuccessful) {
        setloader(false);
        getEvents();
        closeModal();
        return toast.success("Event edited Successfully");
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      setloader(false);
      console.log("error: ", error);
    }
  };

  return (
    <>
      <i
        className="fa fa-edit font-size-15 text-success cursor-pointer"
        onClick={OpenModal}
      ></i>

      <div className="container">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 10000,
            },
            content: {
              color: "#1D3883",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",

              backgroundColor: "#f5f5f5",
              height: "80vh",
            },
          }}
        >
          <div className="row d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <h5 className="font-weight-bold">Edit Event</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="font-weight-bold form-label text-dark"
                >
                  Event Title
                </label>
                <input
                  placeholder="event title .. "
                  onChange={handleChange}
                  defaultValue={item?.eventTitle}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventTitle") ? "is-invalid" : ""
                  }`}
                  name="eventTitle"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Event Poster
                </label>
                {/* Add cloudinary */}
                <input
                  type="file"
                  ref={ref}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setFileImage(e.target.files[0]);
                      // console.log('e.target.files[0]: ', e.target.files[0]);
                      const reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = () => {
                        // console.log("reader.result: ", reader.result);
                        setDisplayImage(reader.result);
                      };
                    }
                  }}
                  accept="image/png,  image/jpeg"
                  className={`form-control form-control-lg `}
                  name="eventPoster"
                />
                <img
                  src={displayImage}
                  alt="display_image"
                  width="400px"
                  height="400px"
                />
                {displayImage && (
                  <i
                    className="fa fa-close"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDisplayImage(undefined)}
                  ></i>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Event Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  defaultValue={item?.eventDate}
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventDate") ? "is-invalid" : ""
                  }`}
                  name="eventDate"
                />
              </div>{" "}
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Registration last date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("registrationLastDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="registrationLastDate"
                  value={editEventDetails?.registrationLastDate}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Training Start Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  defaultValue={item?.trainingStartDate}
                  className={`form-control form-control-lg ${
                    inputErr.includes("trainingStartDate") ? "is-invalid" : ""
                  }`}
                  name="trainingStartDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Training End Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  defaultValue={item?.trainingEndDate}
                  className={`form-control form-control-lg ${
                    inputErr.includes("trainingEndDate") ? "is-invalid" : ""
                  }`}
                  name="trainingEndDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Certificate Issued Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  defaultValue={new Date(item?.certificateIssuedDate)
                    .toLocaleDateString()
                    .split("/")
                    .reverse()
                    .join("-")}
                  className={`form-control form-control-lg ${
                    inputErr.includes("certificateIssuedDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="certificateIssuedDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Event Recognized As
                </label>
                <input
                  onChange={handleChange}
                  placeholder="Event Recognition name .."
                  type="text"
                  defaultValue={item?.eventRecText}
                  className={`form-control form-control-lg `}
                  name="eventRecText"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Email Subject
                </label>
                <textarea
                  onChange={handleChange}
                  defaultValue={item?.emailSubject}
                  placeholder="Event content.."
                  className={`form-control form-control-lg `}
                  name="emailSubject"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Email Content
                </label>
                <textarea
                  onChange={handleChange}
                  placeholder="Event content.."
                  className={`form-control form-control-lg `}
                  name="emailContent"
                  defaultValue={item?.emailContent}
                />
              </div>
              <div className="mb-3">
                <span
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Activate/Deactivate
                </span>
                <ToggleStatus
                  defaultChecked={item?.eventIsActive}
                  icons={false}
                  onChange={(e) => {
                    setEditEventDetails({
                      ...editEventDetails,
                      eventIsActive: e.target.checked,
                    });
                  }}
                  name="eventIsActive"
                />
              </div>
              {/* Dropdown event mode */}
              <div className="form-group">
                <label className="test-dark">Mode</label>
                <select
                  onChange={handleChange}
                  defaultValue={item.eventMode}
                  name="eventMode"
                  className="custom-select custom-select-lg"
                >
                  <option value="">mode</option>
                  <option value="ONLINE">Online</option>
                  <option value="OFFLINE">Offline</option>
                </select>
              </div>
              <div className="form-group">
                <label
                  for="exampleInputEmail1"
                  className=" text-dark font-weight-bold mt-4"
                >
                  Grant certification{" "}
                </label>
                <ReactSelect
                  value={[
                    {
                      label: editEventDetails?.grantCertificate ? "YES" : "NO",
                    },
                  ]}
                  onChange={(e) => {
                    setEditEventDetails({
                      ...editEventDetails,
                      grantCertificate: e.value,
                    });
                  }}
                  options={selectOptions}
                />
              </div>
              <div className="form-group">
                <label
                  for="exampleInputEmail1"
                  className=" text-dark font-weight-bold mt-4"
                >
                  Special Event
                </label>
                <ReactSelect
                  value={[
                    {
                      label: editEventDetails?.isSpecialEvent ? "YES" : "NO",
                    },
                  ]}
                  onChange={(e) => {
                    setEditEventDetails({
                      ...editEventDetails,
                      isSpecialEvent: e.value,
                    });
                  }}
                  options={selectOptions}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Template name
                </label>
                <input
                  onChange={(e) =>
                    setEditEventDetails({
                      ...editEventDetails,
                      certificationConfig: {
                        ...editEventDetails?.certificationConfig,
                        templateName: e.target.value,
                      },
                    })
                  }
                  placeholder="Template name.."
                  type="text"
                  className={`form-control form-control-lg `}
                  name="templateName"
                  value={editEventDetails?.certificationConfig?.templateName}
                />
              </div>{" "}
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Dynamic template fields{" "}
                  <span className="text-muted">
                    *(Comma separated camelcase)
                  </span>
                </label>
                <input
                  onChange={(e) =>
                    setEditEventDetails({
                      ...editEventDetails,
                      certificationConfig: {
                        ...editEventDetails?.certificationConfig,
                        dynamicFields: e.target.value,
                      },
                    })
                  }
                  placeholder="eventTitle,templateName...."
                  type="text"
                  className={`form-control form-control-lg `}
                  name="dynamicFields"
                  value={editEventDetails?.certificationConfig?.dynamicFields}
                />
              </div>
              <div className="col-12 p-0">
                <button
                  disabled={loader}
                  className="btn mx-2 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(editEventDetails, [
                    //   "emailSubject",
                    //   "emailContent",
                    //   "certificationConfig",
                    // ]).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Save
                </button>
                <button
                  className="btn text-white mx-1 btn-warning"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    setEditEventDetails({
                      eventTitle: "",
                      // eventPoster: "",
                      eventDate: "",
                      eventPlace: "",
                      eventIFrameLink: "",
                      eventMode: "",
                      emailContent: "",
                      emailSubject: "",
                    });
                    closeModal();
                    setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          {/* <div className="row w-100 d-flex justify-content-center">
                        <div className="col-5 d-flex justify-content-around">
                            <button className="btn btn-success">Save</button>
                            <button className='btn btn-danger' onClick={closeModal}>Cancel</button>
                        </div>
                    </div> */}
        </Modal>
      </div>
    </>
  );
};

export default Model;
