import React from "react";
import LeftSideBar from "../../shared/components/leftSideBar/leftSideBar";
import proIMG from "../../assets/images/Proimg.png";
import clock from "../../assets/images/clock.svg";
import send from "../../assets/images/send.svg";

function Message(){
    return(
        <>
        <section className="midcontentsection">
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 col-lg-3 pl-0">
            <LeftSideBar/>
        </div>
        <div className="col-xl-10 col-lg-9">
          <div className="chatside">
            <h2>My Messages</h2>
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="chatleftbox">
                  <div className="fixedheader">
                    <h6>Chat</h6>
                    <form className="form-inline ">
                      <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                      <button className="btn btn-outline-success my-2 my-sm-0 p-0" type="submit"><i
                          className="fa fa-search"></i></button>
                    </form>
                  </div>

                  <ul className="chatlist">
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in Hello, tomorrow in Hello, tomorrow in</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in Hello, tomorrow in Hello, tomorrow in</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in Hello, tomorrow in Hello, tomorrow in</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in…</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in…</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in…</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>

                    <li>
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <h6>Jeffrey Brock</h6>
                        <p>Hello, tomorrow in…</p>

                        <span className="badge">5m</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="chatrightbox">
                  <h6 className="text-center">Chat</h6>
                  <ul>
                    <li className="lt1">
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <p>So this is pretty great, even
                          the bubbles are vectorized...</p>
                        <span className="badge"><img src={clock} alt="" />Thursday 8:01 AM</span>
                      </div>
                    </li>
                    <li className="rt1">
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <p>So this is prfoetty great, even
                          the bubbles are vectorized...</p>
                        <span className="badge"><img src={clock} alt="" />Thursday 8:01 AM</span>
                      </div>
                    </li>
                    <li className="rt1">
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <p>So this is pretty great, even
                          the bubbles are vectorized...</p>
                        <span className="badge"><img src={clock} alt="" />Thursday 8:01 AM</span>
                      </div>
                    </li>
                    <li className="lt1">
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <p>So this is pretty great, even
                          the bubbles are vectorized...</p>
                        <span className="badge"><img src={clock} alt="" />Thursday 8:01 AM</span>
                      </div>
                    </li><li className="lt1">
                      <div className="userimg">
                        <img src={proIMG} alt="" className="img-fluid" />
                      </div>
                      <div className="userdt">
                        <p>So this is pretty great, even
                          the bubbles are vectorized...</p>
                        <span className="badge"><img src={clock} alt="" />Thursday 8:01 AM</span>
                      </div>
                    </li>

                    
                    <div className="clearfix"></div>
                  </ul>
                  <div className="chatfoot">
                    <h5 className="currentmsg">
                      <span>Ethan Benson</span> is typing…
                    </h5>

                    <div className="msgsendbar">

                      <div className="filebutton">
                        <label for=""></label>
                        <input type="file" className="form-control pt-1" className="custom-file-input" />
                    </div>

                      <form className="form-inline ">
                        <input className="form-control mr-sm-2" type="text"  aria-label="Search" />
                        <button className="btn btn-outline-success my-2 my-sm-0 p-0" type="submit">
                          <img src={send} alt="" />
                        </button>
                      </form>
                    </div>

                  </div>
                  
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </section>
        </>
    )
}

export default Message;