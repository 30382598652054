import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  projectListAdminItem,
  adminCountDocumentsForHomePageItem,
} from "../../data/reducers/admin.reducer";

import { NavLink, Redirect } from "react-router-dom";
import { changeSkip } from "../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";

//...
function Admin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const projectList = useSelector((state) => state.adminReducer).projectList;
  const studentList = useSelector((state) => state.adminReducer).studentList;
  const hrList = useSelector((state) => state.adminReducer).hrList;
  const profList = useSelector((state) => state.adminReducer).professionalList;
  const badges = useSelector((state) => state.badgesAdminReducer).badges;
  const totalCountsForHomePage = useSelector(
    (state) => state.adminReducer
  )?.totalCountsForHomePage;
  // console.log("totalCountsForHomePage: ", totalCountsForHomePage);
  useEffect(() => {
    fetchCountsProjects();
  }, []);

  const fetchCountsProjects = async () => {
    try {
      let response = await dispatch(adminCountDocumentsForHomePageItem({}));
      // console.log("response: ", response);
      if (response.payload.isSuccessful) {
        return;
      }
    } catch (error) {}
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (usertype !== "admin" && usertype !== "technical-operation") {
    return <Redirect to="/"></Redirect>;
  } else if (
    (loggedIn === true && usertype === "admin") ||
    (loggedIn === true && usertype === "technical-operation")
  ) {
    return (
      <>
        <div className="bg-light" style={{ marginTop: "70px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 px-sm-5">
                <h4 className="p-0 text-left text-dark pb-3 pt-4 Fweight-600">
                  Welcome Admin{" "}
                </h4>
                <div className="bg-white admin-card rounded-lg p-4 d-flex justify-content-center flex-wrap align-items-stretch mb-5 py-5">
                  <NavLink
                    to="/admin/users"
                    className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                  >
                    <div className="">
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Users Panel
                      </h4>
                      <p className="d-flex justify-content-between">
                        <span>Student:</span>{" "}
                        <span className="text-primary col-2 pr-0">
                          {totalCountsForHomePage &&
                            totalCountsForHomePage !== "" &&
                            totalCountsForHomePage?.totalUsersCount?.length !==
                              0 &&
                            totalCountsForHomePage?.totalUsersCount[0]
                              ?.typStudents +
                              totalCountsForHomePage?.totalUsersCount[0]
                                ?.nonTypStudents}
                        </span>
                      </p>
                      <p className="d-flex justify-content-between">
                        <span>Professional:</span>{" "}
                        <span className="text-primary col-2 pr-0">
                          {totalCountsForHomePage?.totalProfessionalCount}
                        </span>
                      </p>
                      <p className="d-flex justify-content-between">
                        <span>Recruiter:</span>{" "}
                        <span className="text-primary col-2 pr-0">0</span>
                      </p>
                      <p className="d-flex justify-content-between border-top mb-0 pt-3">
                        <span>Total users :</span>{" "}
                        <span className="text-primary col-2 pr-0">
                          {totalCountsForHomePage &&
                            totalCountsForHomePage !== "" &&
                            totalCountsForHomePage?.totalUsersCount.length !==
                              0 &&
                            totalCountsForHomePage?.totalUsersCount[0]
                              ?.typStudents +
                              totalCountsForHomePage?.totalUsersCount[0]
                                ?.nonTypStudents +
                              totalCountsForHomePage?.totalProfessionalCount}
                        </span>
                      </p>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/admin/projects-approval"
                    className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                  >
                    <div className="">
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Project Panel
                      </h4>
                      <p className="d-flex justify-content-between">
                        <span> Approved :</span>{" "}
                        <span className="text-primary col-2 pr-0">
                          {totalCountsForHomePage &&
                            totalCountsForHomePage !== "" &&
                            totalCountsForHomePage?.totalProjectsCount
                              .length !== 0 &&
                            totalCountsForHomePage?.totalProjectsCount[0]
                              .approved}
                        </span>
                      </p>
                      <p className="d-flex justify-content-between">
                        <span> Unapproved:</span>{" "}
                        <span className="text-primary col-2 pr-0">
                          {totalCountsForHomePage &&
                            totalCountsForHomePage !== "" &&
                            totalCountsForHomePage?.totalProjectsCount
                              .length !== 0 &&
                            totalCountsForHomePage?.totalProjectsCount[0]
                              .unApproved}
                        </span>
                      </p>
                    </div>
                  </NavLink>
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/contestPage"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <div className="">
                        <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                          Contest Panel
                        </h4>
                      </div>
                    </NavLink>
                  ) : null}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/badges"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Badges
                      </h4>
                      <div className=" d-flex align-items-center justify-content-center h-100">
                        <p className="d-flex flex-column align-items-center">
                          <span className="display-4 text-primary font-weight-bold">
                            {totalCountsForHomePage?.totalBadgeCount}
                          </span>
                          Total
                        </p>
                      </div>
                    </NavLink>
                  ) : null}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/roles"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        User Roles
                      </h4>
                      {/* <div className=" d-flex align-items-center justify-content-center h-100">
                        <p className="d-flex flex-column align-items-center">
                          <span className="display-4 text-primary font-weight-bold">
                            ....
                          </span>
                          Total
                        </p>
                      </div> */}
                    </NavLink>
                  ) : null}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/roles/config"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Roles Config
                      </h4>
                      {/* <div className=" d-flex align-items-center justify-content-center h-100">
                        <p className="d-flex flex-column align-items-center">
                          <span className="display-4 text-primary font-weight-bold">
                            ....
                          </span>
                          Total
                        </p>
                      </div> */}
                    </NavLink>
                  ) : null}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/campusPlacementDrive/Homepage"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4
                        className="border-bottom pb-3 pt-2 mb-3 text-center"
                        onClick={() => {
                          dispatch(changeSkip({ skip: 0 }));
                        }}
                      >
                        Campus Placement Drive
                      </h4>
                      {/* <div className=" d-flex align-items-center justify-content-center h-100">
                        <p className="d-flex flex-column align-items-center">
                          <span className="display-4 text-primary font-weight-bold">
                            ....
                          </span>
                          Total
                        </p>
                      </div> */}
                    </NavLink>
                  ) : null}{" "}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/admin/bulk/emails"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Bulk emails
                      </h4>
                    </NavLink>
                  ) : null}
                  {usertype === "admin" ? (
                    <NavLink
                      to="/api/reviews"
                      className="card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                    >
                      <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                        Homepage Reviews
                      </h4>
                    </NavLink>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
