import {
  api,
  getAuthHeaders,
  get,
  post,
  put,
  del,
  patch,
  putFormData,
} from "./services.common";

//send custom email to registered users in events
export const adminSendCustomBulkEventEmailAPI = async (payload) => {
  let url = `${api}/admin/send/event/bulk/email`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//sned template links to event registered users
export const adminSendTemplateLinkAPI = async (payload) => {
  let url = `${api}/admin/send/template/link`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//get counts for students users project badges
export const adminCountDocumentsForHomePageAPI = async () => {
  let url = `${api}/admin/get/counts`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const studentListAdminAPI = async ({ limit, skip }) => {
  let url = `${api}/admin/getAllStudents?limit=${limit}&skip=${skip}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const professionalListAdminAPI = async ({ limit, skip }) => {
  let url = `${api}/admin/getAllProfessional?limit=${limit}&skip=${skip}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const hrListAdminAPI = async () => {
  let url = `${api}/admin/getAllhr`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const projectListAdminAPI = async ({ limit, skip, status }) => {
  // console.log("status: ", status);

  if (status == undefined) {
    // console.log("statusif");
    var isApproved = "undefined";
  } else {
    // console.log("statuseklse");

    var isApproved = status;
  }
  let url = `${api}/admin/getAllProjects?limit=${limit}&skip=${skip}&isApproved=${isApproved}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const projectApprovalAdminAPI = async (payload) => {
  let url = `${api}/admin/Approval`;
  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const createContestAdminAPI = async (payload) => {
  let url = `${api}/admin/createContest`;
  let authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};

export const editContestAdminAPI = async (payload) => {
  let url = `${api}/admin/editContest`;
  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const contestListAdminAPI = async () => {
  let url = `${api}/admin/getAllContest`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const contestDeleteAdminAPI = async (payload) => {
  let url = `${api}/admin/deleteContest`;
  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const participantListAdminAPI = async () => {
  let url = `${api}/admin/getAllSubmittedContests`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const toggleApproveAdminAPI = async (payload) => {
  let url = `${api}/admin/toggleSubmittedContest/${payload.id}`;
  let authHeader = getAuthHeaders();
  const status = payload.status;
  return await patch(url, status, { ...authHeader });
};

export const toggleStudentAPI = async (payload) => {
  // console.log("payload: ", payload);
  const { _id, isActive, description, limit, skip } = payload;
  let url = `${api}/admin/toggleStudent/${_id}?limit=${limit}&skip=${skip}`;
  const authHeader = getAuthHeaders();
  return await patch(url, { isActive, description }, { ...authHeader });
};

export const getEventsAPI = async (payload) => {
  // console.log('payload: ', payload);
  let url = `${api}/admin/get/events`;
  // console.log("something is here");
  let authHeader = getAuthHeaders();
  console.log("authHeader: ", authHeader);
  return await post(url, { ...payload }, { ...authHeader });
};

export const createEventAPI = async (payload) => {
  const { bodyFormData } = payload;
  let url = `${api}/admin/create/event`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, bodyFormData, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

export const getRewardConfigAPI = async () => {
  let url = `${api}/admin/getRewards`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const createRewardConfigAPI = async (payload) => {
  let url = `${api}/admin/reward/add`;
  const authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};

export const deletEventAPI = async (payload) => {
  const { eventId, eventStatus } = payload;
  let url = `${api}/admin/delete/event/${eventId}`;
  const authHeader = getAuthHeaders();
  return await post(url, { eventStatus }, { ...authHeader });
};

//edit event admin side
export const editEventAPI = async (payload) => {
  const { _id, bodyFormData } = payload;
  let url = `${api}/admin/edit/event?eventId=${_id}`;
  const authHeader = getAuthHeaders();
  return await putFormData(url, bodyFormData, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

export const editRewardConfigAPI = async (payload) => {
  console.log("payload: ", payload);
  let url = `${api}/admin/reward/edit`;
  const authHeader = getAuthHeaders();
  return await put(url, payload, {
    ...authHeader,
  });
};

export const deleteRewardConfigAPI = async (payload) => {
  console.log("payload in delete rewards : ", payload);
  const { _id } = payload;
  let url = `${api}/admin/reward/delete/${_id}`;
  const authHeader = getAuthHeaders();
  return await del(url, authHeader);
};

export const toggleRewardConfigAPI = async (payload) => {
  const { _id, status } = payload;
  console.log("payload: ", payload);
  let url = `${api}/admin/reward/toggle`;
  const authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const createRewardAPI = async (payload) => {
  const { _id, rewards } = payload;
  let url = `${api}/admin/createReward/${_id}`;
  const authHeader = getAuthHeaders();
  return await post(url, { rewards }, { ...authHeader });
};

export const toggleTYPStudentAPI = async (payload) => {
  const { _id, isTYPStudent, limit, skip } = payload;
  let url = `${api}/admin/toggleTYPStudent/${_id}?limit=${limit}&skip=${skip}`;
  const authHeader = getAuthHeaders();
  return await patch(url, { isTYPStudent }, { ...authHeader });
};

//set user level
export const setUserLevelAPI = async (payload) => {
  const { _id, level, userType, limit, skip, oldLevel, description } = payload;
  let url = `${api}/admin/user/setLevel?_id=${_id}&level=${level}&userType=${userType}&limit=${limit}&skip=${skip}`;
  const authHeader = getAuthHeaders();
  return await patch(url, { oldLevel, description }, { ...authHeader });
};
