import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  adminFetchReviewItem,
  adminUploadPersonPictureItem,
  adminUploadReferenceImageItem,
} from "../../../data/reducers/admin/reviews/review.reducer";
import AddReviewModal from "./Modals/Add-review.modal";
import EditReviewModal from "./Modals/Edit-review-modal";
import ChangeReviewStatusModal from "./Modals/Change-status.modal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../shared/components/confirmation-modal/Confirmation-modal";

//************************************************ */
const Reviews = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fileImage, setFileImage] = useState();
  const [fileImage2, setFileImage2] = useState();

  const [imageViewText, setImageViewText] = useState(true);
  const [click, setClick] = useState("");

  const [cardId, setCardId] = useState();

  useEffect(() => {
    if (!loggedIn) {
      return history.push("/");
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      fetchReviewsApi();
    }
  }, []);

  useEffect(() => {
    console.log("imageViewText: ", imageViewText);
  }, [cardId, imageViewText]);

  const uploadPersonProfilePic = async (closeModal, item) => {
    try {
      if (!fileImage) {
        return toast.error("Please choose a file");
      }
      var bodyFormData = new FormData();

      bodyFormData.append("personPicture", fileImage);
      bodyFormData.append("reviewId", item?._id);
      setLoading(true);
      const payload = bodyFormData;
      let response = await dispatch(adminUploadPersonPictureItem(payload));
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setFileImage(null);
        setLoading(false);
        fetchReviewsApi();
        closeModal();
        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const uploadReferenceImage = async (closeModal, item) => {
    try {
      if (!fileImage2) {
        return toast.error("Please choose a file");
      }
      var bodyFormData = new FormData();

      bodyFormData.append("referenceImage", fileImage2);
      bodyFormData.append("reviewId", item?._id);
      setLoading(true);
      const payload = bodyFormData;
      let response = await dispatch(adminUploadReferenceImageItem(payload));
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setFileImage2(null);
        setLoading(false);
        fetchReviewsApi();
        closeModal();
        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const fetchReviewsApi = async () => {
    try {
      setLoading(true);
      const payload = {
        limit,
        skip,
      };
      // console.log("payload: ", payload);
      let response = await dispatch(adminFetchReviewItem(payload));

      if (response.payload.isSuccessful) {
        setLoading(false);
        setReviews(response.payload.data);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{ marginTop: "100px", flexDirection: "column" }}
      >
        <h1 className="text-center">Reviews</h1>
        <div className="mt-2">
          <AddReviewModal fetchReviewsApi={fetchReviewsApi} />
        </div>
        <div className="row m-3">
          {reviews && reviews?.length > 0
            ? reviews?.map((item, index) => {
                return (
                  <div className="col-lg-12 col-md-6 col-sm-12 col-12 mt-3">
                    <div className="card">
                      <div className="card-header">
                        <span
                          className="d-flex justify-content-between"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <EditReviewModal
                            item={item}
                            fetchReviewsApi={fetchReviewsApi}
                          />
                          <ChangeReviewStatusModal
                            item={item}
                            fetchReviewsApi={fetchReviewsApi}
                          />
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="d-flex flex-column">
                          {" "}
                          <img
                            className="card-img-top"
                            style={{
                              height: "270px",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            src={
                              item?.personPicture?.url
                                ? item?.personPicture?.url
                                : "https://cdn.pixabay.com/photo/2016/03/21/20/05/image-1271454_960_720.png"
                            }
                            alt="Card image cap"
                          />
                          <span>Profile picture</span>
                        </div>
                        <div className="d-flex flex-column">
                          <img
                            className="card-img-top"
                            style={{
                              height: "270px",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            src={
                              item?.referenceImage?.url
                                ? item?.referenceImage?.url
                                : "https://cdn.pixabay.com/photo/2016/03/21/20/05/image-1271454_960_720.png"
                            }
                            alt="Card image cap"
                          />
                          <span>Reference image</span>
                        </div>
                      </div>
                      {/* <button
                        className="btn btn-primary mx-4"
                        onClick={(e) => {
                          e.preventDefault();
                          setImageViewText(!imageViewText);
                          setCardId(index);
                        }}
                      >
                        { cardId === index &&  imageViewText
                          ? "See reference image"
                          : "See profile image"}
                      </button> */}
                      <div className="card-body">
                        <h5 className="card-title">{item?.personName}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                          {item?.personReview}
                        </h6>
                        <div className="card-text">
                        

                          <div
                            className="file-upload-wrapper d-flex"
                            data-text="Select your file!"
                          >
                            <input
                              name="file-upload-field"
                              className="file-upload-field"
                              type="file"
                              id="file-input"
                              onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                  setFileImage(e.target.files[0]);
                                }
                              }}
                              accept="image/png,  image/jpeg"
                            />
                            <label
                              className="file-input__label"
                              for="file-input"
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="upload"
                                className="svg-inline--fa fa-upload fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                ></path>
                              </svg>
                              <span>Profile</span>
                            </label>
                            <ConfirmationModal
                              commonLoader={loading}
                              callingFunction={uploadPersonProfilePic}
                              buttonText={"Upload"}
                              item={item}
                            />
                          </div>
                          

                          <div
                            className="file-upload-wrapper d-flex"
                            data-text="Select your file!"
                          >
                            <input
                              name="file-upload-field"
                              className="file-upload-field"
                              type="file"
                              id="file-input1"
                              onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                  setFileImage2(e.target.files[0]);
                                }
                              }}
                              accept="image/png,  image/jpeg"
                            />
                            <label
                              className="file-input__label"
                              for="file-input1"
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="upload"
                                className="svg-inline--fa fa-upload fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                ></path>
                              </svg>
                              <span>Reference</span>
                            </label>
                            <ConfirmationModal
                              commonLoader={loading}
                              callingFunction={uploadReferenceImage}
                              buttonText={"Upload"}
                              item={item}
                            />
                          </div>
                        </div>
                        <a
                          target={item?.clickAction ? "_blank" : ""}
                          style={{
                            color: "black",
                            cursor: item?.clickAction ? "pointer" : "default",
                          }}
                          className=""
                          href={item?.clickAction ? item?.clickAction : "#"}
                          className="card-link"
                        >
                          Click Action
                        </a>
                        {/* <a href="#" className="card-link">Another link</a> */}
                      </div>
                    </div>
                  </div>
                );
              })
            : "No data"}
        </div>
        <div class="row mb-2">
          <div class="col-md-12 text-center m-auto">
            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={skip === 0}
              onClick={() => {
                setClick("prev");
                setSkip((preVal) => {
                  return preVal - limit;
                });
              }}
            >
              {loading && click === "prev" ? "Prev.." : "Prev"}
              {loading && click === "prev" ? (
                <span
                  id="studentTop"
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>

            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={reviews.length === 0 || reviews.length < limit}
              onClick={() => {
                setClick("next");
                setSkip((preVal) => {
                  return preVal + limit;
                });
              }}
            >
              {loading && click === "next" ? "Next.." : "Next"}
              {loading && click === "next" ? (
                <span
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
