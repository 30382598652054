import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  projectApprovalAdminItem,
  setProjectSkip,
} from "../../../data/reducers/admin.reducer";
import { constants } from "../../../config";

const adminPassword = constants.adminPassword;
const ProjectApproval = (props) => {
  // console.log('props: ', props);
  const dispatch = useDispatch();
  let history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const projectSkip = useSelector((state) => state.adminReducer).projectSkip;
  // console.log(' projectSkip : ',  projectSkip );
  const link = props.location.state?.link;
  let isActive = props.location.state?.isActive;
  const isApproved = props.location.state?.isApproved;
  const firstName = props.location.state?.firstName;
  const lastName = props.location.state?.lastName;
  const email = props.location.state?.email;
  const title = props.location.state?.title;
  const project = props.location.state?.project;

  const id = props.location.state?.id;

  const [active, setActive] = useState(isActive);
  const [approved, setApproved] = useState(isApproved);

  const isActivebutton = () => {
    const response = prompt("Enter the Password to Active the project");
    if (response === adminPassword) {
      toast.success("Access Given");
      setActive(!active);
    } else {
      toast.warning("You dont Have Access to Active the Project");
    }
  };
  const goPrevPage = () => {
    history.goBack();
  };
  const isApprovedbutton = () => {
    const response = prompt("Enter the Password to Approve Project");
    if (response === "theyouthproject") {
      setApproved(!approved);
    } else {
      toast.warning("You dont Have Access to Approve the Project");
    }
  };

  const submit = async () => {
    const payload = {
      id,
      isApproved: approved,
      isActive: active,
      firstName: firstName,
      lastName: lastName,
      email: email,
      title: title,
    };
    try {
      // console.log("hittijnh");
      let response = await dispatch(projectApprovalAdminItem(payload));
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        return history.push("/admin/projects-approval");
      }
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype !== "admin" && usertype !== "technical-operation") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin" || usertype === "technical-operation") {
    return (
      <div className="m-5 p-5">
        <div className="row my-3">
          <div className="col d-flex align-items-center container">
            <div onClick={goPrevPage} class="btn btn-primary">
              Back
            </div>
          </div>
        </div>
        <h5 className="text-start btn-secondary p-2">
          Title : {project?.title}
        </h5>
        <h5 className="text-start p-2">
          Description : <br /> {project?.description}
        </h5>
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ReactPlayer url={link} controls />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div className="form-group">
            <label>Is Active</label>
            <button
              onClick={isActivebutton}
              className={active ? "btn btn-success m-2" : "btn btn-danger m-2"}
            >
              {active === true ? "Active" : "Not Active"}
            </button>
          </div>
          <br />
          <div className="form-group">
            <label>Is Approved</label>
            <button
              onClick={isApprovedbutton}
              className={
                approved ? "btn btn-success m-2" : "btn btn-danger m-2"
              }
            >
              {approved === true ? "Approved" : "Not Aproved"}
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <button onClick={submit} className="btn btn-secondary">
            Submit
          </button>
        </div>
      </div>
    );
  }
};
export default ProjectApproval;
