import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../shared/Utils/method";
import { useDispatch, useSelector } from "react-redux";
import { updateStudentToUpcomingPoolItem } from "../../../../data/reducers/auth.reducer";
import { wantACallback } from "../../../../data/reducers/auth.reducer";
import { toggleWantACallbackPool } from "../../../../data/reducers/home.reducer";
import { checkNumber } from "../../../../shared/Utils/method";
import {
  updateUserToZohoItem,
  addUserToZohoItem,
} from "../../../../data/reducers/zoho.reducer";
import { sendDataToTelegramItem } from "../../../../data/reducers/telegram.reducer";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
  },
};

const RequestCallback = ({ data, setGetDetailsAfterLogin, page, pool }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer).userInfo;
  const zoho_crm_id = authState.owner.zoho_crm_id;

  //All states declared here
  const [student_details, setstudent_details] = useState({
    Phone: "",
    Description: "",
    Batch: pool,
    UserAction: "ENROLL_NOW",
  });
  // console.log("student_details: ", student_details);
  const [inputErr, setInputErr] = useState([]);
  const [load, setLoad] = useState(false);

  //Modal functions
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    dispatch(toggleWantACallbackPool({ upcomingPoolId: null }));
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setstudent_details({ ...student_details, [e.target.name]: e.target.value });
  };
  const sendDataToTelegram = async (
    student_details,
    First_Name,
    Last_Name,
    Email
  ) => {
    const data = `Name: ${First_Name} ${Last_Name}\n Email: ${Email}\n Phone: ${
      student_details?.Phone
    } \n Description: ${student_details?.Description} \n Batch: ${
      student_details?.Batch
    }, \n UserAction: ${student_details?.UserAction} \n timeStamp:${new Date(
      Date.now()
    ).toLocaleString()} `;
    const encoded = encodeURI(data);
    const response = await dispatch(sendDataToTelegramItem(encoded));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const errArray = checkEmpty(student_details);
    // console.log('errArray: ', errArray);
    setInputErr(errArray);
    if (errArray.length != 0) {
      return toast.warning("All Fields are Mandatory");
    }

    if (!checkNumber(student_details.Phone)) {
      return toast.error("Please check number format");
    }
    setLoad(true);
    const payload = {
      Last_Name: authState?.owner?.lastName,
      First_Name: authState?.owner?.firstName,
      Mobile: authState?.owner?.mobileNumber,
      Email: authState?.owner?.email,
      Customer_Source: "TYP Website",
      "Deal Status": "Deal Not Created",
      //Batch name will be dynamic
      Tag: "batch_name",
    };
    sendDataToTelegram(
      student_details,
      payload.First_Name,
      payload.Last_Name,
      payload.Email
    );
    if (!zoho_crm_id) {
      //Hit zoho api

      let response_zoho = await dispatch(
        addUserToZohoItem({ payload, student_details })
      );
      // console.log("response_zoho: ", response_zoho);
      if (
        response_zoho.payload &&
        response_zoho.payload.data[0].status == "success"
      ) {
        var addedZohoId = response_zoho.payload?.data[0]?.details?.id;
        // console.log('addedZohoId: ', addedZohoId);
      }
    }

    const response = await dispatch(
      updateUserToZohoItem({
        _id: zoho_crm_id || addedZohoId,
        updated_data: student_details,
        First_Name: payload.First_Name,
        Last_Name: payload.Last_Name,
        Email: payload.Email,
      })
    );

    // console.log("updateresponse: ", response);
    if (response.payload && response.payload.data[0].status == "success") {
      setLoad(false);
      closeModal();
      toast.success("We'll get back to you very soon .. !!!");
      if (page == "homepage") {
        setGetDetailsAfterLogin(undefined);
      }
      return dispatch(wantACallback({ wantACallback: false }));
    }
    setLoad(false);
    toast.warning("Something went wrong.. !!");
  };

  useEffect(() => {
    openModal();
  }, []);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <h2
          style={{
            fontWeight: "400",
          }}
          className="mb-3"
        >
          Taking some details for better connection .. !!
        </h2>
        <form>
          <div className="row form-group">
            <div className="col-md">
              <label className="text-dark">WhatsApp Number : </label>
              <input
                placeholder="WhatsApp"
                value={student_details.Phone}
                onChange={handleChange}
                name="Phone"
                className={`form-control ${
                  inputErr.includes("Phone") ? "is-invalid" : ""
                }`}
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md">
              <label className="text-dark">Description : </label>
              <textarea
                className={`form-control ${
                  inputErr.includes("Description") ? "is-invalid" : ""
                }`}
                value={student_details.Description}
                onChange={handleChange}
                name="Description"
                rows="3"
                placeholder="Why you want to join TYP-The Youth Project ?"
              />
            </div>
          </div>
          <div className="row form-group d-flex justify-content-center">
            <div className="col-md-6 d-flex justify-content-around">
              <button
                disabled={load}
                className="btn btn-success px-4 py-2"
                onClick={(e) => {
                  onSubmit(e);
                }}
              >
                {load ? (
                  <>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="ml-2">Submit</span>
                  </>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                disabled={load}
                className="btn btn-danger px-4 py-2"
                onClick={(e) => {
                  if (page == "homepage") {
                    setGetDetailsAfterLogin(undefined);
                  }
                  return dispatch(wantACallback({ wantACallback: false }));
                  closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default RequestCallback;
