import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { updateStudentItem } from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

//************************************* */
const Model = ({ item, setStudentData, campusId }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);

  const [editStudentEmails, setEditStudentEmails] = useState({
    studentName: "",
    emailId: "",
    enrollmentNumber: "",
  });

  useEffect(() => {
    if (item) {
      setEditStudentEmails({
        ...editStudentEmails,
        studentName: item?.studentName,
        emailId: item?.emailId,
        enrollmentNumber: item?.enrollmentNumber,
      });
    }
  }, []);

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditStudentEmails({ ...editStudentEmails, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setloader(true);
      const payload = { ...editStudentEmails, studentId: item._id };
      // console.log('payload: ', payload);
      let response = await dispatch(updateStudentItem(payload));

      if (response.payload.isSuccessful) {
        closeModal();
        setloader(false);
        setStudentData(response.payload.data.studentEmailsExcelSheet);
        toast.success("Edited successfully");
        return;
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
        return;
      }
      closeModal();
    } catch (error) {}
  };

  return (
    <div className="container">
      <i
        className="fa fa-edit font-size-15 text-success cursor-pointer"
        onClick={() => {
          OpenModal();
        }}
      ></i>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">
                Edit Student Details
              </h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Student Name
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="studentName"
                  value={editStudentEmails?.studentName}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Email ID
                </label>
                <input
                  onChange={handleEditChange}
                  type="email"
                  className={`form-control form-control-lg `}
                  name="emailId"
                  value={editStudentEmails?.emailId}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Enrollment Number
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="enrollmentNumber"
                  value={editStudentEmails?.enrollmentNumber}
                />
              </div>

              <div className="col-12 p-0">
                <button
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(editBadges).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Yes
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  onClick={() => {
                    closeModal();
                    // setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
