export const constants = {
  //url will go under here for e.g.
  endpoint: "https://typ-prod.herokuapp.com/api",
  CRM_ENDPOINT: "https://typ-batch-management-prod.herokuapp.com/api",
  redirectUri: "https://theyouthproject.in/linkedin",
  clientid: "864uplm0edp5gz",
  adminPassword: "theyouthproject",
  endPointFrontend: "https://theyouthproject.in",
  cloudinaryImageLink: "https://api.cloudinary.com/v1_1/typ/image/upload",
  cloudinaryVideoLink: "https://api.cloudinary.com/v1_1/typ/video/upload",
  uploadImagePreset: "typ-prod-image",
  uploadVideoPreset: "typ-prod-video",
  botToken: "2095393436:AAEcyQ9W6XggDLpV_CMWtEzXXaw6YnIHl4U",
  chat_Id: "-1001652096454",
  campusBotToken: "2042444344:AAFyY7SeFaqoa_i8Pb3nnkwf5VDl3WHBma8",
  campusChatId: "-1001510301311",
  razorPayTestKeyId: "rzp_test_LzHMLza2FsMwjT",
  razorPayTestSecretKeyId: "YZfA4hF1Kp5ut6H0JoXtxVzS",
  workshopBotToken: "5066563747:AAFI1cZUhS_ioY7TgK6tyXl5drPyfqZS7PA",
  workshopChat_Id: "-1001603015610",
};
