import React, { useState, useEffect } from "react";
import {
  linkedinSignUpItem,
  signupItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import loginrightimg from "../../assets/images/loginrightimg.png";
import LinkedIn from "react-linkedin-login-oauth2";
import { signup2 } from "../../data/reducers/auth.reducer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinkedInImage from "../../assets/images/linkedin-image.png";

import { constants } from "../../config";
import TermsCondition from "./TermsCondition";
import linkedinImage from "../../assets/images/linkedin.svg";
import {checkNumber, checkEmail, checkEmpty } from "../../shared/Utils/method"
export const redirectUri = constants.redirectUri;
export const clientid = constants.clientid;

const Signup = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [passType, setpassType] = useState(false);
  const [hasReferral, setHasReferral] = useState(false);
  const [haveRedirectReferral, setHaveRedirectReferral] = useState(false);
  const [typId, setTypId] = useState(null);
  const [passType1, setpassType1] = useState(false);
  const [checkbox, setCheckbox] = useState("");
  const [i_agree, SetI_agree] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const [userData, setUserdata] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [modalClose, setModalClose] = useState("");
  const change = (event) => {
    const { name, value } = event.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleChangeCheckbox = () => {
    SetI_agree(!i_agree);
  };
  //linkedin login
  const handleSuccess = async (data) => {
    const payload = {
      data,
      redirectUri,
    };
    setLoad(true);
    try {
      let response = await dispatch(linkedinSignUpItem(payload));

      if (response.payload.isSuccessful) {
        setLoad(false);
        return toast.success("Signup Successfull");
      }
      setLoad(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  // console.log(
  //   "typId inquery : ",
  //   new URLSearchParams(location.search).get("typId")
  // );

  const handleFailure = (error) => {
    toast.error(error);
  };

  // const handleKeyPress = (e)=>{
  //   if (e.keyCode === 13) {
  //     submit();
  //   }  }
  //   useEffect(() => {
  //     const listener = event => {
  //       if (event.code === "Enter" || event.code === "NumpadEnter") {
  //         console.log("Enter key was pressed. Run your function.");
  //         // callMyFunction();
  //       }
  //     };
  //     document.addEventListener("keydown", listener);
  //     return () => {
  //       document.removeEventListener("keydown", listener);
  //     };
  //   }, []);

    //  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    //   // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    //   if (event.key === 'Enter') {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     this.onSubmit();
    //   }
    // }

  const submit = async (event) => {
    event.preventDefault();
    console.log("call")
    // const res = checkEmpty(userData);
    // console.log("res" , res);
    // setInputErr(res);

    // if (res.length != 0) {
    //   return toast.error("All fields are mandatory");
    // }

   
    //user will enter these fields
    //we will check whether its null or not
    //if not null then will store them in local storage
    if (email !== null && password !== null && confirmpassword !== null) {
      if (password.length >= 8) {
        if (password === confirmpassword) {
          if (i_agree === true) {
            try {
              setLoading(true);
              let response = await dispatch(signupItem(userData));

              if (response.payload.isSuccessful) {
                // add logic for storing typid
                if (localStorage.getItem("redirectURL")) {
                  dispatch(
                    signup2({ typId: localStorage.getItem("referredBy") })
                  );
                  setLoading(false);
                  setModalClose("modal");
                  return toast.success("Signup Part 1 Successfull");
                }
                dispatch(signup2({ typId }));
                setLoading(false);
                setModalClose("modal");
                return toast.success("Signup Part 1 Successfull");
              }
              setLoading(false);
              setModalClose("modal");
              toast.error(response.payload.message.data.message);
            } catch (error) {
              toast.error(error);
            }
          } else {
            toast.error("Please Check terms and conditions");
          }
        } else {
          toast.error("Password and Confirm password Does not Match !!");
        }
      } else {
        toast.error("Password Must be 8 characters long !!");
      }
    }
  };
  const { email, password, confirmpassword, userType } = userData;

  useEffect(() => {
    // console.log('localStorage.getItem("redirectURL")  ', localStorage.getItem("redirectURL"));
    if (
      localStorage.getItem("redirectURL") == "null" ||
      localStorage.getItem("redirectURL") == null ||
      localStorage.getItem("redirectURL") == undefined
    ) {
      return setHaveRedirectReferral(false);
    }
    setHaveRedirectReferral(true);
  }, []);

  if (loggedIn === true) {
    return <Redirect to="/signup/part2"></Redirect>;
  }

  return (
    <>
      <section className="loginsection py-md-0">
        <div>
          {/* <div className="row tp-row">
            <div className="col-md-3">
              
            </div>
            <div className="col-md-6 text-center">
              <h3>Register Step 1</h3>
            </div>
          </div> */}
          <div className="d-flex justify-content-center">
            <h3 className="text-center p-0 m-0" style={{ fontWeight: "500" ,color:"#1d3883"}}>
              Sign Up
            </h3>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                class="close mr-2 position-relative"
                style={{ left: "115px" }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div>
            <div>
              <div className="mt-2">
                <div>
                  <div>
                    {/* <div className="login-img text-center text-lg-right"> */}
                    {/* <img src={loginrightimg} alt="" className="img-fluid" /> */}
                    {/* </div> */}
                    <form className="mx-4" onSubmit={submit}>
                      <div className="form-group">
                        <label className="font-size-10" for="">
                          <small>Email</small>
                        </label>

                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="form-control"
                          style={{ borderRadius: "10px", borderWidth: "2px" }}
                          onChange={change}
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-size-10" for="">
                          <small>Password</small>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"
                          style={{ borderRadius: "10px", borderWidth: "2px" }}
                          onKeyUp={() => setpassType1(true)}
                          onClick={() => setpassType1(true)}
                          value={password}
                          onChange={change}
                        />
                        {passType1 &&
                          (password.length >= 8 ? (
                            <p className="text-success m-1">
                              Strong password !
                            </p>
                          ) : (
                            <p className="text-danger m-1">
                              <small>Password Must Be 8 of characters!</small>
                            </p>
                          ))}
                      </div>

                      <div className="form-group">
                        <label className="font-size-10" for="">
                          <small>Confirm Password</small>
                        </label>
                        <input
                          type="password"
                          name="confirmpassword"
                          data-keyboard="false"
                          className="form-control"
                          style={{ borderRadius: "10px", borderWidth: "2px" }}
                          onKeyUp={() => setpassType(true)}
                          onClick={() => setpassType(true)}
                          value={confirmpassword}
                          onChange={change}
                        />
                        {passType &&
                          (password === confirmpassword ? (
                            <p className="text-success">
                              <small>Password matched</small>
                            </p>
                          ) : (
                            <p className="text-danger">
                              Password does not match! !
                            </p>
                          ))}
                      </div>
                      <div className="d-flex text-center align-items-center ">
                        <input
                          name="tandc"
                          type="checkbox"
                          defaultChecked={i_agree}
                          onChange={handleChangeCheckbox}
                        />{" "}
                        &nbsp; <small>I agree to all</small> &nbsp;
                        <Link
                          data-target="#terms"
                          data-toggle="modal"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          <small>Terms and Conditions .</small>
                        </Link>
                      </div>
                      <div
                        className="modal fade"
                        id="terms"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title m-auto text-center"
                                id="exampleModalLabel"
                              >
                                <h5>TERMS OF SERVICE AGREEMENT</h5>
                              </h5>
                              <button
                                type="button"
                                className="btn btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                            <div className="modal-body">
                              <TermsCondition />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-warning"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        <div className="row">
                          <div className="col">
                            {haveRedirectReferral ? (
                              `You are referred by ${localStorage.getItem(
                                "referredBy"
                              )}`
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setHasReferral(e.target.checked)
                                  }
                                />
                                <span className="ml-2 ">
                                  <small>You have any Referral ?</small>
                                </span>
                              </>
                            )}
                            {hasReferral ? (
                              <input
                                className="form-control my-2"
                                onChange={(e) => setTypId(e.target.value)}
                                placeholder="Please enter TYP-ID ..."
                              />
                            ) : null}
                          </div>
                        </div>
                      }
                      <div className="btn-bar">
                        <button
                          type="submit"
                          disabled={loading || !i_agree}
                          className="btn w-100 form-control text-white  h6 mt-sm-4 mt-3 mb-2"
                          data-dismiss={modalClose}
                          onClick={submit}
                          style={{ backgroundColor: "#1d3883" }}
                        >
                          Sign up
                          {loading && (
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </form>
                    <div>
                      <div
                        style={{
                          height: "1px",
                          borderTop: "1px solid rgb(144 157 168)",
                          textAlign: "center",
                          position: "relative",
                        }}
                        class="my-4"
                      >
                        <span
                          style={{
                            position: "relative",
                            top: "-.9em",
                            background: "white",
                            display: "inline-block",
                            padding: "0px 5px",
                          }}
                          class="text-secondary"
                        >
                          <small>or</small>
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ borderRadius: "5px" }}
                      class="border border-dark m-4 p-0"
                    >
                      <a className="linkedin text-decoration-none d-flex justify-content-center">
                        <LinkedIn
                          className="btn linkedin d-flex align-items-center mb-md-0 text-left p-0"
                          clientId={clientid}
                          scope="r_liteprofile,r_emailaddress"
                          onFailure={handleFailure}
                          onSuccess={handleSuccess}
                          redirectUri={redirectUri}
                        >
                          <img
                            src={LinkedInImage}
                            style={{ height: "30px", width: "30px" }}
                          />
                          {/* <i class="fab fa-linkedin text-white p-1" style={{fontSize: "25px",borderRadius:"5px",height:"30px",backgroundColor:"#038cfc"}}></i> */}

                          <span className="m-1 font-weight-normal">
                            Sign up via LinkedIn
                          </span>
                        </LinkedIn>

                        {load && (
                          <div className="text-center">
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                    <div className="text-center d-flex justify-content-center">
                      <p className="mb-2 mr-1">
                        <small>Have already an account? </small>
                      </p>

                      <Link
                        // to="/login"
                        data-toggle="modal"
                        data-dismiss="modal"
                        data-target="#loginModal"
                        className="text-decoration-none Fweight-600 text-nowrap"
                        style={{ color: "#038cfc" }}
                      >
                        <small>Login</small>
                      </Link>
                    </div>                   
                  </div>
                </div>
                <div
                  className="modal fade bd-example-modal-lg"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content rounded-lg">
                      <button
                        type="button"
                        className="close d-lg-none d-md-none"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa fa-close"></i>
                      </button>

                      <div className="modal-body p-md-5">
                        <h5
                          className="modal-title text-center"
                          id="exampleModalLabel"
                        >
                          Forgot Password ?
                        </h5>
                        <p className="text-center text-secondary mt-2">
                          {" "}
                          Lorem ipsum, or lipsum as it is sometimes known, is
                          dummy text used in laying out print, graphic or web
                          designs.
                        </p>

                        <label className="font-size-18" for="">
                          Email
                        </label>

                        <input
                          type="mail"
                          className="form-control form-control-lg"
                        />

                        <button
                          type="button"
                          className="btn btn-warning w-100 form-control-lg text-white h5 text-uppercase mt-md-4 mt-3"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
