import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsertypesItem } from "../../../data/reducers/admin/user-types/usertypes-admin.reducer";
import EditRoleConfigModel from "./Modals/Edit-roles.-configmodel";
import CreateRoleConfigModel from "./Modals/Create-roles-config-model";

//...
const RoleConfig = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const userType = useSelector((state) => state.authReducer).userType;
  const [render, setrender] = useState(false);
  const [loading, setloading] = useState(false);
  const rolesData = useSelector((state) => state.rolesAdminReducer)
    .userTypesData;

  useEffect(() => {
    if (!loggedIn) {
      history.push("/theyouthproject/admin/teams");
    } else {
      return userType === "admin"
        ? (setrender(true), fetchAllRolesConfig())
        : history.push("/");
    }
  }, []);

  const fetchAllRolesConfig = useCallback(async () => {
    try {
    
      setloading(true);
      let response = await dispatch(getAllUsertypesItem());
      // console.log('response: ', response);
      if (response.payload.isSuccessful) {
        return setloading(false);
      }
      setloading(false);
    } catch (error) {}
  }, [render]);

  return (
    <>
      {render && (
        <div style={{ marginTop: "110px" }}>
          <CreateRoleConfigModel  fetchRolesConfig={fetchAllRolesConfig} />
        </div>
      )}
      {render && rolesData?.length > 0 ? (
        <div className="col-md-12">
          {loading && "Loading.."}
          <div className="table-responsive mt-2  ">
            <table className="table table-hover border">
              <thead className="btn-secondary">
                <tr>
                  <th align="center" scope="col">
                    Role
                  </th>

                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {rolesData?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td align="left">{item?.roleName}</td>

                      <td align="center">
                        <EditRoleConfigModel
                          item={item}
                          fetchRolesConfig={fetchAllRolesConfig}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        "No roles created yet!"
      )}
    </>
  );
};

export default RoleConfig;
