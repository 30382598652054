import React from "react";

const Requestlist = (props) => {
  const requests = props.location.state.request;
  const first = props.location.state.first;
  const last = props.location.state.last;

  return (
    <>
      <h1 className="text-center">Request List by Admin</h1>
      <table className="table table-responsive">
        <thead>
          <th>#</th>

          <th>HR name</th>
          <th>Student Name</th>
          <th>Accept</th>
        </thead>
        <tbody>
          {requests?.map((item, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>
                {first} {last}
              </td>
              <td>
                {item.student.owner.firstName} {item.student.owner.lastName}
              </td>
              <td>{item.accept === true ? "true" : "false"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Requestlist;
