import React, { useState } from "react";
import blueLabel from "../../assets/images/bluelabel.png";
import dtProfile from "../../../src/assets/images/dtprofile.png";
import labelWhite from "../../../src/assets/images/labelwhite.png";
import iosApp from "../../../src/assets/images/iosapp.svg";
import iosImg1 from "../../../src/assets/images/ios-img-1.svg";
import LeftSideBar from "../../shared/components/leftSideBar/leftSideBar";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { NavLink, Redirect } from "react-router-dom";
import Education from "../EditPages/Education";
import AddSocialLinksModal from "../Addmodal/Addmodal";
import EditSocialLinksModal from "../Editmodal/Editmodal";
import {
  studentDeleteDetails,
  professionalDeleteDetails,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";

function StudentFullProfilePage() {
  const dispatch = useDispatch();
  //const user=useSelector(state=> state.authReducer).userInfo;
  //
  let user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const [education, setEducation] = useState([...user.education]);
  const [workExperience, setWorkExperience] = useState([
    ...user.workExperience,
  ]);
  const [certificate, setCertificate] = useState([...user.certificate]);
  const [projects, setProjects] = useState([...user.projects]);
  const [skills, setSkills] = useState([...user.skills]);

  let history = useHistory();

  if (loggedIn === false) {
    toast.error("Please Login or Signup");
    return <Redirect to="/signup"></Redirect>;
  }
  //  let value = false;
  // let ab = false;
  const button = (i, field, _id) => {
    if (field === "education") {
      history.push({
        pathname: "/educationEdit",
        state: { educationList: [...education], i: i },
      });
    } else if (field === "workExperience") {
      history.push({
        pathname: "/workExperienceEdit",
        state: { workExperienceList: [...workExperience], i: i },
      });
    } else if (field === "projects") {
      history.push({
        pathname: "/projectsEdit",
        state: { projectsList: [...projects], i, _id },
      });
    } else if (field === "certificate") {
      history.push({
        pathname: "/certificateEdit",
        state: { certificateList: [...certificate], i: i },
      });
    } else {
      history.push({
        pathname: "/skillsEdit",
        state: { skillsList: [...skills], i: i },
      });
    }
  };

  //addUserFields
  const addUserFields = (field) => {
    if (field === "education") {
      history.push({
        pathname: "/addEducation",
        state: { educationList: [...education] },
      });
    } else if (field === "workExperience") {
      //
      history.push({
        pathname: "/addWorkExperience",
        state: { workExperienceList: [...workExperience] },
      });
    } else if (field === "projects") {
      //
      history.push({
        pathname: "/addProjects",
        state: { projectsList: [...projects] },
      });
    } else if (field === "certificate") {
      //
      history.push({
        pathname: "/addCertificate",
        state: { certificateList: [...certificate] },
      });
    } else {
      //
      history.push({
        pathname: "/addSkills",
        state: { skillsList: [...skills] },
      });
    }
  };
  //delete
  const deleteFields = async (field, id) => {
    const payload = { field, id };
    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          toast.success("Delete Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          toast.success("Delete Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  return (
    <>
      <section className="midcontentsection" style={{ marginTop: "100px" }}>
        <div className="container-fluid pl-1">
          <div className="row">
            <div className="col-xl-2 col-lg-2">
              <LeftSideBar />
            </div>

            <div className="col-xl-10 col-lg-10">
              <div className="row mt-5">
                <div className="col-lg-4 col-md-12">
                  <div className="card Candidateprofile fulldetails">
                    <img
                      className="card-img-top"
                      src={user.owner.profilePic}
                      style={{ height: "400px" }}
                      alt="Card image cap"
                    />

                    <div className="card-body">
                      <div
                        className="bluelabel"
                        style={{
                          background: `url(${blueLabel}) top center no-repeat`,
                        }}
                      >
                        level {user.level || 1}
                      </div>
                      <div className="badgesbox border-top-0 border-bottom-0">
                        <strong> Badges: </strong>
                        <div className="w-100">
                          {user.badges.map((badge) => (
                            <span className="badge badge-secondary">
                              {badge}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="contactinfo">
                        <h5>Contact Info</h5>
                        <ul>
                          <li>
                            <div className="iconbox">
                              <i className="fa fa-phone"></i>
                            </div>
                            <div className="ctbox">
                              <h6>Phone</h6>
                              <a href="tel:+91 577 4563 564;">
                                {user.owner.mobileNumber}
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="iconbox">
                              <i className="fa fa-envelope"></i>
                            </div>
                            <div className="ctbox">
                              <h6>Email</h6>
                              <a href="mailto:leah@huganavi.org;">
                                {user.owner.email}
                              </a>
                            </div>
                          </li>
                          <li>
                            <div className="iconbox">
                              <i className="fa fa-map-marker"></i>
                            </div>
                            <div className="ctbox">
                              <h6>Address</h6>
                              <span>{user.owner.address}</span>
                            </div>
                          </li>
                          <li>
                            <div className="iconbox">
                              <i className="fa fa-home"></i>
                            </div>
                            <div className="ctbox">
                              <h6>DOB</h6>
                              <span>
                                {new Date(
                                  user.owner.dateOfBirth
                                ).toLocaleDateString()}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="contactinfo sociallinks">
                        <h5>Social Links </h5>
                        {user.linkedin &&
                        user.github &&
                        user.personalBlog &&
                        user.twitter ? (
                          ""
                        ) : (
                          <AddSocialLinksModal user={user} />
                        )}

                        <ul>
                          {user.personalBlog && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-blog"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Personal Blog</h6>
                                <a href={user.personalBlog} target="_blank">
                                  {user.personalBlog}
                                </a>
                                <EditSocialLinksModal
                                  user={user}
                                  value={user.personalBlog}
                                  link="personalBlog"
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    deleteFields("personalBlog", user._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </li>
                          )}
                          {user.github && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-github"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Github</h6>
                                <a href={user.github} target="_blank">
                                  {user.github}
                                </a>
                                <EditSocialLinksModal
                                  user={user}
                                  value={user.github}
                                  link="github"
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    deleteFields("github", user._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </li>
                          )}
                          {user.linkedin && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-linkedin"></i>
                              </div>
                              <div className="ctbox">
                                <h6>LinkedIn</h6>
                                <a href={user.linkedin} target="_blank">
                                  {user.linkedin}
                                </a>
                                <EditSocialLinksModal
                                  user={user}
                                  value={user.linkedin}
                                  link="linkedin"
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    deleteFields("linkedin", user._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </li>
                          )}
                          {user.twitter && (
                            <li>
                              <div className="iconbox">
                                <i className="fa fa-twitter"></i>
                              </div>
                              <div className="ctbox">
                                <h6>Twitter</h6>
                                <a href={user.twitter} target="_blank">
                                  {user.twitter}
                                </a>
                                <EditSocialLinksModal
                                  user={user}
                                  value={user.twitter}
                                  link="twitter"
                                />
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    deleteFields("twitter", user._id)
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>

                      <div className="candiprogress border-top-0 border-bottom-0">
                        <h5>Skills</h5>
                        <button
                          onClick={() => {
                            addUserFields("skills");
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                        {user.skills.map((skill, i) => (
                          <div key={skill._id} className="w-100">
                            <label for="">
                              {skill.name} <span>25%</span>
                              <button
                                className="btn btn-primary"
                                onClick={() => button(i, "skills")}
                              >
                                <i className="fa fa-edit"></i>
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  deleteFields("skills", skill._id)
                                }
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </label>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={
                                  ({ width: "25%" },
                                  { ariaValuenow: "25" },
                                  { ariaValuemin: "0" },
                                  { ariaValuemax: "100" })
                                }
                              >
                                {skill.name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="rightdetails">
                    <h2>
                      {user.owner.firstName} {user.owner.lastName}
                    </h2>
                    <p className="deg">{user.currentPosition}</p>
                    <h6>{user.owner.description}</h6>
                    <a href="#" className="btn btn-primary">
                      Contact Us
                    </a>
                    <a href="#" className="btn btn-secondary">
                      Download CV
                    </a>
                    <NavLink
                      to="/student/BasicDetails"
                      className="btn btn-default"
                    >
                      Edit Profile{" "}
                    </NavLink>

                    <div className="desbox">
                      <h3 className="leftstar">Description</h3>
                      <p className="content">{user.owner.description}</p>
                      <h3 className="leftstar">Education</h3>
                      <button
                        onClick={() => {
                          addUserFields("education");
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      {user.education.map((edu, i) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="content">
                            {edu.branch} in {edu.areaOfStudy} at {edu.institute}{" "}
                            from {new Date(edu.startDate).toLocaleDateString()}{" "}
                            to {new Date(edu.endDate).toLocaleDateString()}{" "}
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => button(i, "education")}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => deleteFields("education", edu._id)}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      ))}

                      <h3 className="leftstar">TYP Project</h3>
                      <button
                        onClick={() => {
                          addUserFields("projects");
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      {user.projects.map((project, i) => (
                        <div className="row typpoject">
                          <div className="col-md-4">
                            <img
                              src={project.video}
                              alt="project image"
                              className="typprojectimg img-fluid"
                            />
                          </div>
                          <div className="col-md-4">
                            <img
                              src={project.url}
                              alt="project image"
                              className="typprojectimg img-fluid"
                            />
                          </div>
                          <div className="col-md-8">
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                            <p>{project.views}</p>
                            {project.genre.map((genre) => {
                              return <p>{genre}</p>;
                            })}
                            <a
                              href={project.url}
                              target="_blank"
                              className="link"
                            >
                              View Project
                            </a>
                            <button
                              className="btn btn-primary"
                              onClick={() => button(i, "projects", project._id)}
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                deleteFields("projects", project._id)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                      <h3 className="leftstar ">Work Experiences</h3>
                      <button
                        onClick={() => {
                          addUserFields("workExperience");
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      {user.workExperience.map((work, i) => (
                        <>
                          <p className="content p-0">{work.workTitle}</p>
                          <p className="content p-0">
                            {work.projectDescription}
                          </p>
                          <p className="content p-0">
                            {new Date(work.startDate).toLocaleDateString()}
                          </p>
                          <p className="content p-0">
                            {new Date(work.endDate).toLocaleDateString()}
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => button(i, "workExperience")}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              deleteFields("workExperience", work._id)
                            }
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <hr />
                        </>
                      ))}
                      <h3 className="leftstar ">Certificates</h3>
                      <button
                        onClick={() => {
                          addUserFields("certificate");
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                      {user.certificate.map((certificate, i) => (
                        <>
                          <p className="content p-0">{certificate.name}</p>
                          <img
                            src={certificate.imageUrl}
                            alt="certificate"
                            width="100px"
                            height="50px"
                          />
                          <p className="content p-0">
                            {certificate.certificateId}
                          </p>
                          <p className="content p-0">
                            {certificate.description}
                          </p>
                          <button
                            className="btn btn-primary"
                            onClick={() => button(i, "certificate")}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              deleteFields("certificate", certificate._id)
                            }
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <hr />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StudentFullProfilePage;
