import { api, getAuthHeaders, get, patch, post, del } from "./services.common";

//Admin Referral Services

//creating action
export const createActionAPI = async (payload) => {
  let url = `${api}/admin/createAction`;
  let authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};

//fetching custom reqs
export const fetchAllRequestsAPI = async (payload) => {
  const { specific, reqCondition } = payload;
  let url = `${api}/admin/getRequests/?specific=${specific}&REQ_STATUS=${reqCondition}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//fetching all actions
export const fetchActionsAPI = async () => {
  let url = `${api}/admin/getAllActions`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//updating action
export const updateActionAPI = async (payload) => {
  const { _id, editAction } = payload;
  let url = `${api}/admin/updateAction/${_id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, editAction, { ...authHeader });
};

//updating action status
export const updateActionStatusAPI = async (payload) => {
  const { _id, status } = payload;
  let url = `${api}/admin/updateActionStatus/${_id}`;
  let authHeader = getAuthHeaders();
  return await patch(
    url,
    {
      status,
    },
    { ...authHeader }
  );
};

//deleting action
export const deleteActionAPI = async (_id) => {
  let url = `${api}/admin/deleteAction/${_id}`;
  let authHeader = getAuthHeaders();
  return await del(url, { ...authHeader });
};

export const toggleRedeemRequestAPI = async (payload) => {
  const { _id, reqStatus, description } = payload;
  let url = `${api}/admin/toggleRedeemRequest/${_id}/?accepted=${reqStatus}`;
  let authHeader = getAuthHeaders();

  return await patch(url, { description }, { ...authHeader });
};

// ..
export const validateActionInRedeemRequestAPI = async (payload) => {
  const { _id, acceptedRequestActions, declinedRequestActions } = payload;
  let url = `${api}/admin/validateActionInRedeemRequest/${_id}`;
  let authHeader = getAuthHeaders();

  return await patch(
    url,
    { acceptedRequestActions, declinedRequestActions },
    { ...authHeader }
  );
};

// ..
export const sendRewardAPI = async (payload) => {
  const { _id } = payload;
  let url = `${api}/admin/sendReward/${_id}`;
  let authHeader = getAuthHeaders();
  //
  return await patch(url, {}, { ...authHeader });
};

export const proceedPaymentAPI = async (payload) => {
  const { _id } = payload;
  let url = `${api}/admin/proceedPayment/${_id}`;
  let authHeader = getAuthHeaders();
  //
  return await patch(url, {}, { ...authHeader });
};
// ****************************

//User Referral Services
export const fetchActionsUserAPI = async (payload) => {
  const { specific, action_status } = payload;
  const url = `${api}/getActions?ACTION_STATUS=${action_status}&a=${specific}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//User request
export const fetchRequestsUserAPI = async (payload) => {
  const { specific, request_status } = payload;

  const url = `${api}/getRequests?REQUEST_STATUS=${request_status}&a=${specific}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//User actions Services
export const fetchActionsCreatedUserAPI = async (payload) => {
  const { specific, action_status } = payload;
  const url = `${api}/getActions?ACTION_STATUS=${action_status}&a=${specific}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//User redeem request
export const fetchRedeemRequestUserAPI = async (payload) => {
  const { specific, request_status } = payload;

  const url = `${api}/getRequests?REQUEST_STATUS=${request_status}&a=${specific}`;
  const authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

//Create request service
export const createRequestUserAPI = async (payload) => {
  const url = `${api}/createRequest`;
  const authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};
