import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Model = ({ item, toggleReq }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <button
        className="btn btn-secondary"
        onClick={() => {
          OpenModal();
        }}
      >
        Accept
      </button>
      <div className="container">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 1,
            },
            content: {
              color: "#1D3883",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              flexDirection: "column",
            },
          }}
        >
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <h3 className="text-center">
                  Are you sure you want to accept?
                </h3>
              </div>
            </div>
            <div className="row d-flex justify-content-center p-3">
              <div className="col-12 ">
                <div className="col-12 d-flex justify-content-center p-0">
                  <button
                    className="btn text-white m-1"
                    style={{ backgroundColor: "#5A6268" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn m-1 text-white"
                    style={{ backgroundColor: "#C82333" }}
                    onClick={() => {
                      closeModal();
                      toggleReq(item._id, true);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Model;
