import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../Shared/Pagination/Pagination";
import {
  participantListAdminItem,
  contestListAdminItem,
  toggleApproveAdminItem,
} from "../../../../data/reducers/admin.reducer";
import Search from "../../Shared/Search/Search";
import Loader from "../../../../shared/components/Loaders/Loader";
import {Redirect, useHistory } from "react-router-dom";

const ParticipantList = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState();
  const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;
  const [imageModal, setimageModal] = useState("");
  const contests = useSelector((state) => state.adminReducer).contestList;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const participants = useSelector((state) => state.adminReducer)
    .participantList;

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(async () => {
    setLoading(true);
    try {
      let response = await dispatch(contestListAdminItem());

      //   setParticipantList(contests);
    } catch (error) { }

    setLoading(false);
  }, []);

  const approve = async (id, approval) => {
    const response = prompt("Enter the Password to Approve Project");
    if (response === "theyouthproject") {
      const payload = {
        status: !approval,
        id,
      };

      try {
        let response = await dispatch(toggleApproveAdminItem(payload));
      } catch (error) { }
    }
  };

  const goPrevPage = () => {
    history.goBack();
  }
  const contestData = useMemo(() => {
    let contestData = contests;

    if (search) {
      contestData = contestData ?.filter(
        (item) => item ?.fullName ?.toLowerCase().includes(search.toLowerCase())
        // item?.contestId?.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(contestData ?.length);
    // curreent pages
    return contestData ?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [contests, currentPage, search]);

  const participantsHandler = (id) => {
    //
    //

    // const data = participants?.filter((item) => item?.contestId?._id === id);
    //
    history.push({
      pathname: "/admin/contestParticipant",
      state: { id: id },
    });
  };
  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }  return (
    <>


      {!loading ? (
        <div className="container-fluid">
          <div className="row my-3">
            <div className="col d-flex align-items-center justify-content-between mb-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">Contests</h3>
              <div onClick={goPrevPage} class="btn btn-primary">Back</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />

              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <th>#</th>
                    <th>Contest Title</th>
                    <th>Contest Type</th>
                    <th>Image</th>
                    <th>Active</th>
                    <th>Participant List</th>
                  </thead>
                  <tbody>
                    {contestData ?.map((item, i) => (
                      <tr>
                        <td style={{ width: "5%" }}>{i + 1}</td>
                        <td style={{ width: "25%" }}>{item ?.title}</td>
                        <td style={{ width: "5%" }}>{item ?.contestType}</td>
                        <td style={{ width: "5%" }}>
                          {item ?.img.search("cloudinary") > 0 && (
                            <button
                              data-toggle="modal"
                              data-backdrop="static" data-keyboard="false"
                              data-target="#image"
                              onClick={() => setimageModal(item.img)}
                              className="btn btn-success"
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          )}
                        </td>
                        <td style={{ width: "5%" }}>
                          {item ?.isActive ? (
                            <p className="text-secondary">Active</p>
                          ) : (
                              <p className="text-danger">Not Active</p>
                            )}
                        </td>
                        <td style={{ width: "5%" }}>
                          <button
                            className="btn btn-secondary"
                            onClick={() => participantsHandler(item ?._id)}
                          >
                            Participants
                    </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      ) : (
          <div className="">
            <Loader />
          </div>
        )}

      <div
        className="modal fade"
        id="image"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <img
                className="img-thumbnail ml-1 mr-1"
                src={imageModal}
                alt="img"
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantList;
