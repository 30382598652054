import {
  api,
  getAuthHeaders,
  post,
  putFormData,
  put,
} from "../../services.common";

//add review
export const adminAddReviewAPI = async (payload) => {
  let url = `${api}/admin/add/review`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//fetch review
export const adminFetchReviewAPI = async (payload) => {
  let url = `${api}/admin/reviews`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//edit review
export const adminEditReviewAPI = async (payload) => {
  let url = `${api}/admin/review/edit`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//edit review
export const adminEditStatusReviewAPI = async (payload) => {
  let url = `${api}/admin/review/status`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//upload perosn pic
export const adminUploadPersonPictureAPI = async (payload) => {
  let url = `${api}/admin/review/person/image`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

//upload reference pic
export const adminUploadReferenceImageAPI = async (payload) => {
  let url = `${api}/admin/review/reference/image`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};
