import React from "react";

const Hiring = () => {
  return (
    <div className="p-5 m-5">
      <h2 className="text-center">Hiring data</h2>
      <div className="d-flex align-items-center text-center ">
        <table className="m5-5 pl-5 table table-hover table-responsive">
          <thead>
            <th>No #</th>
            <th>HR Name</th>
            <th>Student Name</th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>hfsh</td>
              <td>hfsh</td>
              <td>hfsh</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Hiring;
