import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getBatchesItem } from "../../../data/reducers/admin/batches/batches-admin.reducer";
import BatchCard from "./BatchCard";
import AddBatchModal from "./Modals/AddBatchModal";

const Batches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const batches = useSelector((state) => state.batchAdminReducer).batches;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const getBatches = async () => {
    const response = await dispatch(getBatchesItem());
    console.log("response in batches module : ", response);
    if (!response.payload.isSuccessful) {
      return toast.error(response.payload.message.data.message);
    }
  };
  useEffect(() => {
    //   console.log("sadsad");
    if (!loggedIn) {
      history.push("/theyouthproject/admin/teams");
      return;
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      history.push("/");
      return;
    } else if (loggedIn && usertype === "admin") {
      getBatches();
    }
  }, []);
  return (
    <div style={{ marginTop: "70px" }}>
      <div className="container py-5">
        <div className="row px-3">
          <div className="col-md d-flex justify-content-end">
            <AddBatchModal />
          </div>
        </div>
        <div className="row px-3">
          {batches?.map((batch) => (
            <BatchCard batch_detail={batch} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Batches;
