import {
    api,
    crm_api,
    getAuthHeaders,
    get,
    post,
    put,
    update,
    del,
    patch,
  } from "../../services.common";

  export const getUpcomingBatchesAPI = async() => {
      // console.log('I am here in service og getUpcomingBatches') ;
      const authHeader = getAuthHeaders() ;
    return await get(`${api}/getBatches?isUpcoming=true`, authHeader) ;
  }

  export const addUpcomingBatchAPI = async(payload) => {
  console.log("🚀 ~ file: batches.service.js ~ line 20 ~ addUpcomingBatchAPI ~ payload", payload)
    const authHeader = getAuthHeaders() ;
    return await post(`${api}/addBatch/true`, payload, authHeader) ;
  }

  export const deleteUpcomingBatchAPI = async(payload) => {
    const {_id} = payload ;
    console.log("🚀 ~ file: batches.service.js ~ line 26 ~ deleteUpcomingBatchAPI ~ _id", _id)
    const authHeader = getAuthHeaders() ;
    return await put(`${api}/deleteBatch/${_id}`, {}, authHeader) ;
  }

  export const editUpcomingBatchAPI = async(payload) => {
    console.log('payload in editBatch : ', payload) ;
    const {_id} = payload ;
    delete payload._id ;
    const authHeader = getAuthHeaders() ;
    return await put(`${api}/editBatch/${_id}`, payload, authHeader) ;
  }

  export const uploadImageUpcomingBatchAPI = async(payload) => {
    console.log('payload :', payload) ;
    const {imageURI} = payload ;
    const authHeader = getAuthHeaders() ;
    return await put(`${api}/uploadUpcomingbatchImage/${payload.batch_name}`, {imageURI}, authHeader) ;
  }