import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Educationadd = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const educationList = props.location.state.educationList;

  const [newEducation, setNewEducation] = useState({
    institute: "",
    endDate: "",
    startDate: "",
    areaOfStudy: "",
    branch: "",
    completionYear: "",
  });
  const [education, setEducation] = useState([...educationList]);

  let history = useHistory();
  const handleEducationInputChange = (e) => {
    const { name, value } = e.target;
    setNewEducation({ ...newEducation, [name]: value });
  };

  const submit = async () => {
    education.push(newEducation);
    //
    const payload = { education, id };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };
  const {
    institute,
    startDate,
    endDate,
    areaOfStudy,
    branch,
    completionYear,
  } = newEducation;
  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>Education Details</h3>
      </div>
      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">School / College / University name</label>
            <input
              type="text"
              name="institute"
              onChange={(e) => handleEducationInputChange(e)}
              value={institute}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Start Date</label>
            <input
              type="date"
              max={new Date().toLocaleDateString()}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="startDate"
              onChange={(e) => handleEducationInputChange(e)}
              value={startDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> End Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="endDate"
              onChange={(e) => handleEducationInputChange(e)}
              value={endDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Area of Study</label>
            <input
              type="text"
              name="areaOfStudy"
              onChange={(e) => handleEducationInputChange(e)}
              value={areaOfStudy}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Branch</label>
            <input
              type="text"
              name="branch"
              onChange={(e) => handleEducationInputChange(e)}
              value={branch}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Completion Year</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="completionYear"
              onChange={(e) => handleEducationInputChange(e)}
              value={completionYear}
              className="form-control"
            />
          </div>
        </div>
        <button type="button" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default Educationadd;
