import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 50,
  currentPage = 0,
  onPageChange,
}) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pages;
  }, [totalPages, currentPage]);
  if (totalPages === 0)  return null;
  return (
    <>
      <Pagination style={{display:"grid",gridTemplateColumns:"auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto"}}>
        <Pagination.Prev
         className="btn"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >Previous</Pagination.Prev>
        <button className="btn d-flex ml-1">{paginationItems}</button>
        <Pagination.Next
        className="btn"
       
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >Next</Pagination.Next>
      </Pagination>
    </>
  );
};

export default PaginationComponent;
