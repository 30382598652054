import {
  api,
  getAuthHeaders,
  get,
  post,
  put,
  update,
  del,
  patch,
} from "./services.common";

export const professionalByOwnerIdAPI = async (id) => {
  let url = `${api}/getProfessionalByOwnerId/${id}`;

  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
export const professionalAPI = async (id) => {
  let url = `${api}/getProfessionalById/` + id;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};
