import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  studentEditProject,
  studentDeleteDetails,
  studentAddProject,
  professionalEditProject,
  professionalDeleteDetails,
  professionalAddProject,
} from "../../../data/reducers/auth.reducer";
import { constants } from "../../../config";
import Loader from "../../../shared/components/Loaders/Loader";
import ProjectShareModal from "./Share-modal";
import { checkEmpty, checkLinks } from "../../../shared/Utils/method";
import ReactReadMoreReadLess from "react-read-more-read-less";

const ProjectsProfile = () => {
  const {
    cloudinaryImageLink,
    cloudinaryVideoLink,
    uploadImagePreset,
    uploadVideoPreset,
  } = constants;
  const fileRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const usertype = useSelector((state) => state.authReducer).userType;
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [size, setSize] = useState(false);
  const [projects, setprojects] = useState([...user.projects]);
  const [id, setId] = useState("");
  const [area, setArea] = useState("false");
  // loaders states
  const [projectAdd, setProjectAdd] = useState(false);
  const [projectEdit, setProjectEdit] = useState(false);
  const [projectDelete, setProjectDelete] = useState(false);
  //initial edit details
  const [proj, setProj] = useState({
    title: "",
    video: "",
    views: "",
    image: "",
    description: "",
    url: "",
    uploadDate: "",
    genre: "",
  });

  //new add
  const [newprojects, setNewprojects] = useState({
    title: "",
    description: "",
    genre: "",
    uploadDate: "",
    url: "",
    video: "",
    image: "",
  });

  //links check initail state
  const [projectUrl, setProjectUrl] = useState(true);
  const [editProjectUrl, setEditProjectUrl] = useState(true);

  useEffect(() => {
    newprojects?.url !== ""
      ? setProjectUrl(checkLinks(newprojects?.url))
      : setProjectUrl(true);
  }, [newprojects.url]);

  useEffect(() => {
    proj?.url !== ""
      ? setEditProjectUrl(checkLinks(proj?.url))
      : setEditProjectUrl(true);
  }, [proj?.url]);

  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;
    document.getElementById("uploadDate").setAttribute("max", maxDate);
    document.getElementById("uploadDate1").setAttribute("max", maxDate);
  });

  useEffect(async () => {
    setprojects([...user.projects]);
  }, [user]);

  //edit education handle change
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    setProj({ ...proj, [name]: value });

    // setEmptyEdit(checkEmpty({ ...proj, [name]: value }));
  };

  //add education handle change
  const handlenewprojectsChange = (e) => {
    const { name, value } = e.target;
    setNewprojects({ ...newprojects, [name]: value });

    // setEmpty(checkEmpty({ ...newprojects, [name]: value }));
    //
  };

  //edit submit
  const submit = async (e) => {
    e.preventDefault();
    const res = checkEmpty(proj, ["url"]);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("Please fill highlighted fields!");
    }
    let result = proj?.url !== "" ? checkLinks(proj.url) : true;
    // console.log("editprojectUrl: ", result);
    if (!result) {
      return toast.error("Please provide a valid project link!");
    }
    if (proj.description.length < 200) {
      toast.error("Description Must be of 200 Characters ");
    } else {
      //
      setProjectEdit(true);
      const payload = { proj, id };
      //
      //
      //
      try {
        if (usertype === "student") {
          let response = await dispatch(studentEditProject(payload));
          //
          if (response.payload.isSuccessful) {
            document.getElementById("editimageupload").value = "";
            document.getElementById("editvideoupload").value = "";
            toast.success("Edit Successfully");
            setInputErr([]);
            setProjectEdit(false);
            return setProj(null);
          }
          setInputErr([]);
          setProjectEdit(false);
          document.getElementById("editimageupload").value = "";
          document.getElementById("editvideoupload").value = "";
          toast.error(response.payload.message.data.message);
        } else if (usertype === "professional") {
          let response = await dispatch(professionalEditProject(payload));
          //
          if (response.payload.isSuccessful) {
            document.getElementById("editimageupload").value = "";
            document.getElementById("editvideoupload").value = "";
            toast.success("Edit Successfully");
            setInputErr([]);
            setProjectEdit(false);
            return setProj(null);
          }
          document.getElementById("editimageupload").value = "";
          document.getElementById("editvideoupload").value = "";
          setInputErr([]);
          setProjectEdit(false);
          toast.error(response.payload.message.data.message);
        }
      } catch (error) {}
    }
    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //set id and field
  const deleteButton = (field, id, i) => {
    localStorage.setItem("field", field);
    localStorage.setItem("deleteId", id);
    localStorage.setItem("iP", i);
    // setField(field);
    // setDeleteId(id);
  };

  //delete api
  const deleteFields = async () => {
    setProjectDelete(true);
    const field = localStorage.getItem("field");
    const deleteId = localStorage.getItem("deleteId");
    const iP = localStorage.getItem("iP");
    // projects.splice(iP, 1);
    //
    //
    const payload = { field, deleteId };
    //
    try {
      if (usertype === "student") {
        let response = await dispatch(studentDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setProjectDelete(false);
          return toast.success("Delete Successfully");
        }
        setProjectDelete(false);
        // toast.success("Delete Successfully");
        toast.error(response.payload.message.data.message);
      } else if (usertype === "professional") {
        let response = await dispatch(professionalDeleteDetails(payload));

        if (response.payload.isSuccessful) {
          setProjectDelete(false);
          return toast.success("Delete Successfully");
        }
        setProjectDelete(false);
        // toast.success("Delete Successfully");
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {}

    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //add submit
  const submitnewprojects = async (e) => {
    e.preventDefault();
    if (newprojects.video === "") {
      return toast.error("Please upload the video!");
    }
    if (newprojects.image === "") {
      return toast.error("Please upload the image!");
    }
    const res = checkEmpty(newprojects, ["url"]);
    // console.log("res: ", res);

    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("Please fill highlighted fields!");
    }
    let result = newprojects?.url !== "" ? checkLinks(newprojects.url) : true;
    // console.log("addprojectUrl: ", result);
    if (!result) {
      return toast.error("Please provide a valid project link!");
    }
    // if (newprojects?.url === "") {
    //   setProjectUrl(true);
    // }
    //  else {
    if (newprojects.description.length < 200) {
      toast.error("Description Must be of 200 Characters ");
    } else {
      const payload = { newprojects };
      console.log("newprojects: ", newprojects);

      setProjectAdd(true);
      try {
        if (usertype === "student") {
          let response = await dispatch(studentAddProject(payload));

          if (response.payload.isSuccessful) {
            setProjectAdd(false);
            setNewprojects({
              title: "",
              description: "",
              genre: "",
              uploadDate: "",
              url: "",
              video: "",
              image: "",
            });
            setInputErr([]);
            document.getElementById("imageupload").value = "";
            document.getElementById("videoupload").value = "";

            return toast.success("Add Successfully");
          }
          setInputErr([]);
          setNewprojects({
            title: "",
            description: "",
            genre: "",
            uploadDate: "",
            url: "",
            video: "",
            image: "",
          });
          document.getElementById("imageupload").value = "";
          document.getElementById("videoupload").value = "";
          setProjectAdd(false);
          toast.error(response.payload.message.data.message);
        } else if (usertype === "professional") {
          let response = await dispatch(professionalAddProject(payload));

          if (response.payload.isSuccessful) {
            setProjectAdd(false);
            setNewprojects({
              title: "",
              description: "",
              genre: "",
              uploadDate: "",
              url: "",
              video: "",
              image: "",
            });
            setInputErr([]);
            document.getElementById("imageupload").value = "";
            document.getElementById("videoupload").value = "";
            return toast.success("Add Successfully");
          }
          setInputErr([]);
          setNewprojects({
            title: "",
            description: "",
            genre: "",
            uploadDate: "",
            url: "",
            video: "",
            image: "",
          });
          document.getElementById("imageupload").value = "";
          document.getElementById("videoupload").value = "";
          setProjectAdd(false);
          toast.error(response.payload.message.data.message);
        }
      } catch (error) {}
    }
    // }

    // window.location.reload();
    // return history.push("/studentFullProfile");
  };

  //edit upload
  const editUpload = async (e) => {
    const files = e.target.files;
    //

    if (!files[0]) {
      return toast.error("No file Selected");
    }

    if (files[0].type === "video/mp4") {
      if (files[0].size > 41943040) {
        //
        setSize(true);
        setLoading(false);
        return toast.error("File size should be less than 40 mb!");
      }
      setSize(false);
      const data = new FormData();
      data.append("file", files[0]);
      data.append("upload_preset", uploadVideoPreset);
      setLoading(true);

      const res = await fetch(
        cloudinaryVideoLink,
        {
          method: "POST",
          body: data,
        }
      );

      const file = await res.json();

      //
      setProj({ ...proj, ["video"]: file.secure_url });
      // setImage(file.secure_url);
      setSize(false);
      setLoading(false);

      // setEmptyEdit(checkEmpty({ ...proj, ["video"]: file.secure_url }));

      if (file.secure_url !== "") {
        toast.success("Video Uploaded");
      } else {
        toast.error("Video Not Uploaded");
      }
    } else {
      toast.error("video must be mp4");
    }
  };

  //add image upload
  const uploadImage = async (e) => {
    const files = e.target.files;
    if (!files[0]) {
      return toast.error("No file Selected");
    }

    const data = new FormData();
    data.append("file", files[0]);

    data.append("upload_preset", uploadImagePreset);
    setImageLoading(true);

    const res = await fetch(
     cloudinaryImageLink,
      {
        method: "POST",
        body: data,
      }
    );

    const file = await res.json();

    //
    setNewprojects({ ...newprojects, ["image"]: file.secure_url });
    // setImage(file.secure_url);
    setImageLoading(false);
    if (file.secure_url !== "") {
      toast.success("Image Uploaded");
    } else {
      toast.error("Image Not Uploaded");
    }
  };

  //edit imgage upload
  const editUploadImage = async (e) => {
    const files = e.target.files;
    if (!files[0]) {
      return toast.error("No file Selected");
    }
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", uploadImagePreset);
    setImageLoading(true);

    const res = await fetch(
      cloudinaryImageLink,
      {
        method: "POST",
        body: data,
      }
    );

    const file = await res.json();
    setProj({ ...proj, ["image"]: file.secure_url });
    // setNewCertificate({ ...newCertificate, ["imageUrl"]: file.secure_url });
    // setImage(file.secure_url);
    setImageLoading(false);

    // setEmptyEdit(checkEmpty({ ...proj, ["image"]: file.secure_url }));
    if (file.secure_url !== "") {
      toast.success("Image Uploaded");
    } else {
      toast.error("Image Not Uploaded");
    }
  };

  //add upload
  const upload = async (e) => {
    const files = e.target.files;
    //

    if (!files[0]) {
      return toast.error("No file Selected");
    }

    if (files[0].type === "video/mp4") {
      if (files[0].size > 41943040) {
        //
        setSize(true);
        setLoading(false);
        return toast.error("File size should be less than 40 mb!");
      }
      setSize(false);
      setLoading(true);
      const data = new FormData();
      data.append("file", files[0]);
      data.append("upload_preset", uploadVideoPreset);
      // setLoading(true);

      const response = await fetch(
        cloudinaryVideoLink,
        {
          method: "POST",
          body: data,
        }
      );

      const file = await response.json();

      setNewprojects({ ...newprojects, video: file.secure_url });

      // setEmpty(checkEmpty({ ...newprojects, video: file.secure_url }));
      setLoading(false);
      if (file.secure_url !== "") {
        toast.success("Video Uploaded");
      } else {
        toast.error("Video Not Uploaded");
      }
    } else {
      toast.error("Video file type must be mp4");
    }
  };

  const {
    title,
    url,
    video,
    description,
    genre,
    image,
    uploadDate,
  } = newprojects;
  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h4 className="mb-0">TYP Project</h4>
        <i
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          data-target="#TYP_Project"
          className="fa fa-plus-circle font-20 text-success ml-3 cursor-pointer"
        />
        <div className="ml-2">
          {(projectAdd || projectEdit || projectDelete) && (
            <>
              <Loader />
            </>
          )}
        </div>
      </div>
      {projects?.length > 0 ? (
        <div className="content p-0 m-0 border p-2">
          {projects?.length > 0 &&
            projects?.map((projects, i) => (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="dropdown  cust_notify">
                      <i
                        className="fa fa-edit  font-20 text-l-blue cursor-pointer dropdown-toggle mb-0 pt-2"
                        role="button"
                        id="dropdownDis"
                        data-toggle="modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        data-target="#TYP_ProjectEdit"
                        aria-haspopup="true"
                        aria-expanded={area}
                        onClick={() => {
                          const x = new Array(
                            projects?.genre.map((item) => item)
                          ).toString();
                          setProj({
                            ...proj,
                            title: projects?.title,
                            video: projects?.video,
                            views: projects?.views,
                            description: projects?.description,
                            url: projects?.url,
                            uploadDate: projects?.uploadDate,
                            genre: x,
                            image: projects?.image,
                          });
                          setId(projects?._id);
                        }}
                      />
                    </div>

                    <div className="dropdown  cust_notify"></div>
                    <ProjectShareModal
                      project={projects}
                      _id={projects._id}
                      endpoint={`${constants.endpoint}`}
                    />
                  </div>
                  <div className="dropdown  cust_notify">
                    <i
                      data-toggle="modal"
                      data-backdrop="static"
                      data-keyboard="false"
                      onClick={() => deleteButton("projects", projects?._id)}
                      data-target="#ConfDeleteprojects"
                      className="fa fa-trash font-20 text-danger ml-3 cursor-pointer align-self-end"
                      aria-haspopup="true"
                      aria-expanded={area}
                    />
                  </div>
                </div>
                <ReactPlayer
                  width="99%"
                  height="300px"
                  controls
                  // url="https://www.youtube.com/watch?v=0GQ63bAaQjM"
                  url={projects?.video}
                />
                <p className="content p-0">
                  <h4 className="mt-2">Title : {projects?.title}</h4>
                  {/* Add collapseable div */}
                  <div className="card-body card ">
                    <div className="App">
                      <ReactReadMoreReadLess
                        charLimit={100}
                        readMoreText={
                          <button className="btn btn-secondary text-white  px-4 py-2 mt-2 rounded border-0 font-size-14 font-weight-bold ">
                            Read more
                          </button>
                        }
                        readLessText={
                          <button className="btn btn-secondary text-white  px-4 py-2 mt-2 rounded border-0 font-size-14 font-weight-bold ">
                            Read less
                          </button>
                        }
                        readMoreClassName="read-more-less--more"
                        readLessClassName="read-more-less--less"
                      >
                        {projects?.description}
                      </ReactReadMoreReadLess>
                    </div>
                  </div>
                  <br />
                  Technology :{" "}
                  {projects?.genre.map((genre) => (
                    <p
                      style={{
                        display: "inline-block",
                        border: "1px solid #ccc",
                        margin: "2px",
                        borderRadius: "5px",
                        padding: "3px",
                      }}
                    >
                      {" "}
                      {genre}{" "}
                    </p>
                  ))}
                </p>
                {projects?.url && (
                  <a
                    href={`${projects?.url}`}
                    className="btn btn-primary p-1 m-1"
                    target="_blank"
                  >
                    View Project
                  </a>
                )}
                <hr />
              </>
            ))}
        </div>
      ) : null}

      {/* TYP projects add modal */}
      <div
        className="modal fade"
        id="TYP_Project"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setInputErr([]);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Title
                  </label>
                  <input
                    type="text"
                    className={`form-control  ${
                      inputErr.includes("title") ? "is-invalid" : ""
                    }`}
                    disabled={loading || imageLoading}
                    placeholder="title"
                    name="title"
                    value={title}
                    onChange={handlenewprojectsChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Project Link
                  </label>
                  <input
                    type="text"
                    disabled={loading || imageLoading}
                    className={`form-control`}
                    placeholder="http://www..."
                    name="url"
                    value={url}
                    onChange={handlenewprojectsChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Upload video
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="videoupload"
                    accept="video/*"
                    disabled={loading || imageLoading}
                    className={`form-control p-1 ${
                      inputErr.includes("file") ? "is-invalid" : ""
                    }`}
                    placeholder="Upload Video"
                    ref={fileRef}
                    onChange={upload}
                  ></input>
                  {loading ? (
                    <p className="text-danger h6 mb-0 pt-1">
                      {" "}
                      <Loader />
                    </p>
                  ) : null}
                  {size ? (
                    <>
                      <a
                        target="_blank"
                        className="btn btn-dark m-2"
                        href="https://www.freeconvert.com/video-compressor"
                      >
                        Reduce File size
                      </a>
                    </>
                  ) : null}
                </div>
                <div className="form-group ">
                  <label className=" mb-2 text-secondary Fweight-600 h6">
                    Upload Image (Upload best snapshot of your project.)
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="imageupload"
                    accept="image/*"
                    disabled={loading || imageLoading}
                    className={`form-control p-1 ${
                      inputErr.includes("image") ? "is-invalid" : ""
                    }`}
                    placeholder="Upload Image"
                    ref={fileRef}
                    onChange={uploadImage}
                  ></input>
                  {imageLoading ? (
                    <p className="text-danger h6 mb-0 pt-1">
                      {" "}
                      <Loader />
                    </p>
                  ) : null}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    description
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    className={`form-control overflow-hidden overflow-auto ${
                      inputErr.includes("description") ? "is-invalid" : ""
                    }`}
                    disabled={loading || imageLoading}
                    placeholder="Enter Here..."
                    defaultValue={""}
                    name="description"
                    minLength={200}
                    value={description}
                    onChange={handlenewprojectsChange}
                  />
                  {description.length >= 200 ? (
                    ""
                  ) : (
                    <p className="text-danger" style={{ fontSize: "15px" }}>
                      {" "}
                      Project Description Must be 200 Characters long .{" "}
                    </p>
                  )}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Genre
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inputErr.includes("genre") ? "is-invalid" : ""
                    }`}
                    placeholder="i.e. HTML , CSS , JAVASCRIPT"
                    disabled={loading || imageLoading}
                    name="genre"
                    value={genre}
                    onChange={handlenewprojectsChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    upload Date
                  </label>
                  <input
                    id="uploadDate"
                    type="date"
                    className={`form-control ${
                      inputErr.includes("uploadDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                    disabled={loading || imageLoading}
                    name="uploadDate"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    value={uploadDate}
                    onChange={handlenewprojectsChange}
                  />
                </div>
                <div className="text-right">
                  <button
                    data-dismiss="modal"
                    disabled={loading || imageLoading}
                    // href="#"

                    onClick={() => {
                      // document.getElementById("imageupload").value = "";
                      // document.getElementById("videoupload").value = "";
                      setInputErr([]);
                    }}
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                  >
                    Cancel
                  </button>

                  <button
                    disabled={loading || imageLoading}
                    // href="/studentFullProfile"
                    // disabled={empty}
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                    data-dismiss={
                      checkEmpty(newprojects, ["url"]).length === 0
                        ? projectUrl
                          ? newprojects?.description.length >= 200
                            ? "modal"
                            : null
                          : null
                        : null
                    }
                    onClick={submitnewprojects}
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>

      {/* Delete conformation modal */}
      <div
        className="modal fade"
        id="ConfDeleteprojects"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <i className="fa fa-4x fa-exclamation-circle text-danger" />
              <p className=" Fweight-600 h3">Want to delete?</p>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                data-dismiss="modal"
                onClick={deleteFields}
                className="btn btn-danger"
              >
                Yes,delete it
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* TYP projects edit modal */}
      <div
        className="modal fade"
        id="TYP_ProjectEdit"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onKeyPress={(e) => {
          if (e.which == "13") {
            e.preventDefault();
          }
        }}
      >
        <div className="modal-dialog border-0 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title border-0" id="exampleModalLabel" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setInputErr([])}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body text-center pt-0">
              <form className="text-left">
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Title
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inputErr.includes("title") ? "is-invalid" : ""
                    }`}
                    placeholder="title"
                    disabled={loading || imageLoading}
                    name="title"
                    value={proj?.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Project Link
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inputErr.includes("url") ? "is-invalid" : ""
                    }`}
                    placeholder="http://www..."
                    disabled={loading || imageLoading}
                    name="url"
                    value={proj?.url}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Upload Video
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="editvideoupload"
                    accept="video/*"
                    ref={fileRef}
                    className={`form-control pt-1 ${
                      inputErr.includes("file") ? "is-invalid" : ""
                    }`}
                    disabled={loading || imageLoading}
                    placeholder="Upload Video"
                    onChange={editUpload}
                  ></input>
                  {loading ? (
                    <p className="text-danger h6 mb-0 pt-1">Please Wait!</p>
                  ) : null}
                  {size ? (
                    <a
                      style={{ fontSize: "15px" }}
                      target="_blank"
                      className="btn btn-dark"
                      href="https://www.freeconvert.com/video-compressor"
                    >
                      Reduce file size.
                    </a>
                  ) : null}
                </div>
                <div className="form-group ">
                  <label className=" mb-2 text-secondary Fweight-600 h6">
                    Upload Image (Upload best snapshot of your project.)
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="editimageupload"
                    accept="image/*"
                    ref={fileRef}
                    className={`form-control pt-1 ${
                      inputErr.includes("image") ? "is-invalid" : ""
                    }`}
                    disabled={loading || imageLoading}
                    placeholder="Upload Image"
                    onChange={editUploadImage}
                  ></input>
                  {imageLoading ? (
                    <p className="text-danger h6 mb-0 pt-1">Please Wait!</p>
                  ) : null}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    description
                  </label>
                  <textarea
                    rows={3}
                    type="text"
                    className={`form-control ${
                      inputErr.includes("description") ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Here..."
                    // defaultValue={""}
                    name="description"
                    disabled={loading || imageLoading}
                    minLength={200}
                    value={proj?.description}
                    onChange={handleChange}
                  />
                  {proj?.description.length >= 200 ? (
                    ""
                  ) : (
                    <p className="text-danger" style={{ fontSize: "15px" }}>
                      Minimum 200 Characters Required !
                    </p>
                  )}
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    Genre
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      inputErr.includes("genre") ? "is-invalid" : ""
                    }`}
                    placeholder="Genre"
                    disabled={loading || imageLoading}
                    name="genre"
                    value={proj?.genre}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ">
                  <label className="text-capitalize mb-2 text-secondary Fweight-600 h6">
                    upload Date
                  </label>
                  <input
                    id="uploadDate1"
                    type="date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    className={`form-control ${
                      inputErr.includes("uploadDate") ? "is-invalid" : ""
                    }`}
                    placeholder="dd-mm-yy"
                    disabled={loading || imageLoading}
                    name="uploadDate"
                    value={proj?.uploadDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="text-right">
                  <button
                    data-dismiss="modal"
                    // href="#"
                    disabled={loading || imageLoading}
                    onClick={() => {
                      // document.getElementById("editvideoupload").value = "";
                      // document.getElementById("editimageupload").value = "";
                      setInputErr([]);
                    }}
                    className="btn btn-danger mx-0 text-capitalize px-4 py-2 mr-2"
                  >
                    Cancel
                  </button>

                  <button
                    disabled={loading || imageLoading}
                    // href="/studentFullProfile"
                    className="btn btn-secondary mx-0 text-capitalize px-4 py-2"
                    data-dismiss={
                      checkEmpty(proj, ["url"]).length === 0
                        ? editProjectUrl
                          ? proj?.description.length >= 200
                            ? "modal"
                            : null
                          : null
                        : null
                    }
                    onClick={submit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsProfile;
