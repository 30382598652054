import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Redirect } from "react-router-dom";
import { constants } from "../../../config";
import {
  createContestAdminItem,
  contestListAdminItem,
} from "../../../data/reducers/admin.reducer";
import { toast } from "react-toastify";
import Loader from "../../../shared/components/Loaders/Loader";
// import { NULL } from "node-sass";

const ContestsAdd = (props) => {
  const { cloudinaryImageLink, uploadImagePreset } = constants;
  // const contestList = props.location.state.contestList;
  // const index = props.location.state.length;
  let history = useHistory();
  const usertype = useSelector((state) => state.authReducer).userType;
  // const [contest, setContest] = useState([...contestList]);
  const [newContest, setnewContest] = useState({
    img: "",
    title: "",
    start_date: "",
    end_date: "",
    genre: "",
    description: "",
    supporting_doc: "",
    reward: "",
    contestType: "",
    // isActive: "",
  });
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const [loading, setLoading] = useState(false);
  const handleContestChange = (e) => {
    const { name, value } = e.target;

    setnewContest({ ...newContest, [name]: value });
  };
  const add = async () => {
    if (
      newContest?.img?.length > 0 &&
      newContest?.reward?.length > 0 &&
      newContest?.contestType?.length > 0 &&
      newContest?.start_date != null &&
      newContest?.end_date != null
    ) {
      try {
        setLoading(true);
        let response = await dispatch(createContestAdminItem(newContest));
        if (response.payload.isSuccessful) {
          setLoading(true);
          return history.push({
            pathname: "/admin/contests",
          });
        }
        setLoading(true);
        toast.error(response.payload.message.data.message);
      } catch (error) {}
    } else {
      toast.error("All fields are mandatory");
    }
    // try {
    //   // setLoading(true);
    //   let response = await dispatch(contestListAdminItem());
    //
    //   // setContests(contest);
    // } catch (error) {
    //
    // }
  };
  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", uploadImagePreset);

    const res = await fetch(cloudinaryImageLink, {
      method: "POST",
      body: data,
    });
    const file = await res.json();

    setnewContest({ img: file.secure_url });
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (loggedIn === true && usertype != "admin") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin") {
    return (
      <div className="m-2">
        <div className="btn-warning m-auto col-lg-4 p-3">
          <h3 className="text-center  text-white mb-2 p-2">Add New Contests</h3>
          <div className="">
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor=""> Contest Image</label>
                <input
                  className="file"
                  type="file"
                  className="form-control pt-1 form-control-lg"
                  onChange={uploadImage}
                  // accept="image/*"
                />
                {newContest?.img?.length > 0 && (
                  <i className="fas fa-check">"Image Uploaded"</i>
                )}
              </div>
              {newContest?.img?.length > 0 ? (
                <>
                  <div className="form-group col-md-12">
                    <label htmlFor=""> Contest Title</label>
                    <input
                      placeholder="Title Of Contest"
                      type="text"
                      name="title"
                      value={newContest.title}
                      onChange={handleContestChange}
                      className="form-control form-control-lg"
                    />
                  </div>
                  <>
                    <div className="form-group col-md-6">
                      <label htmlFor="">Start Date</label>
                      <input
                        type="Date"
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                        value={newContest.start_date}
                        onChange={handleContestChange}
                        name="start_date"
                        className="form-control form-control-lg"
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="">End Date</label>
                      <input
                        type="Date"
                        onKeyPress={(e) => {
                          e.preventDefault();
                        }}
                        value={newContest.end_date}
                        onChange={handleContestChange}
                        name="end_date"
                        className="form-control form-control-lg"
                      />
                    </div>
                  </>
                  <div className="form-group col-md-12">
                    <label htmlFor="">Genre</label>
                    <input
                      placeholder="Genre e.g. HTML, CSS, JS"
                      type="text"
                      value={newContest.genre}
                      name="genre"
                      onChange={handleContestChange}
                      className="form-control form-control-lg"
                    />
                  </div>{" "}
                  <div className="form-group col-md-12">
                    <label htmlFor="">Reward</label>
                    <input
                      placeholder="Reward"
                      type="text"
                      value={newContest.reward}
                      onChange={handleContestChange}
                      name="reward"
                      className="form-control form-control-lg"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="">Contest Type</label>
                    <div className="d-flex justify-content-center">
                      <div className="form-group d-flex text-center m-2">
                        <input
                          type="radio"
                          onChange={handleContestChange}
                          id="male"
                          className="mt-1 mr-1"
                          name="contestType"
                          value="code"
                        />
                        <label for="male" className="">
                          Code
                        </label>
                      </div>
                      <div className="form-group d-flex text-center m-2">
                        <input
                          type="radio"
                          onChange={handleContestChange}
                          className="mt-1 mr-1"
                          id="female"
                          name="contestType"
                          value="image"
                        />
                        <label for="female">Image</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="">Description</label>
                    <textarea
                      placeholder="Description"
                      type="text"
                      name="description"
                      value={newContest.description}
                      onChange={handleContestChange}
                      className="form-control form-control-lg"
                    />
                  </div>
                </>
              ) : (
                " Please upload the  Image first"
              )}
            </div>

            <div className="">
              <button
                disabled={loading}
                onClick={add}
                className="btn btn-secondary m-2"
              >
                Add
              </button>
            </div>
            {loading ? (
              <div className="ml-4">
                <span>
                  <Loader />
                </span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
};

export default ContestsAdd;
