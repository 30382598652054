import React, { useState } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  sendOTPItem,
  verifyOTPItem,
} from "../../../data/reducers/auth.reducer";
import {
  checkEmail,
  checkEmpty,
  checkNumber,
  checkString,
} from "../../Utils/method";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
  },
};

//************************************************** */
const ContactUsVerifyModal = ({ userData, setInputErr, submit }) => {
  ReactModal.setAppElement("#root");
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const validateBody = () => {
    const res = checkEmpty(userData);
    setInputErr(res);
    if (res.length !== 0) {
      return toast.error("All fields are mandatory");
    }

    if (!checkNumber(userData.phone) || !checkEmail(userData.email)) {
      return toast.error("Please check Email ID / Contact Number format");
    }

    if (!checkString(userData.name)) {
      return toast.error("Please check name format");
    }

    sendOTP();
  };

  const sendOTP = async () => {
    try {
      const response = await dispatch(sendOTPItem(userData.phone));
      //   console.log("response: ", response);

      if (response?.payload?.type === "success") {
        toast.info("Verify your mobile number first.");
        openModal();
        return;
      } else if (
        response?.payload?.type !== "success" ||
        !response?.payload?.isSuccessful
      ) {
        //response.payload.message.data.message
        return toast.error(response?.payload?.message?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoad(true);
      const response = await dispatch(
        verifyOTPItem({
          otp: enteredOtp,
          mobileNumber: userData?.phone,
          verifyFor: "CONTACTUS",
        })
      );
      //   console.log("response1: ", response);

      if (response?.payload?.type === "success") {
        setLoad(false);
        submit(closeModal);
        return;
      } else if (
        response?.payload?.type !== "success" ||
        !response?.payload?.isSuccessful
      ) {
        setLoad(false);
        //response.payload.message.data.message
        return toast.error(response?.payload?.message?.data?.message);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      <button onClick={validateBody}>SUBMIT</button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <center>
          <h6 className="text-muted">Enter OTP sent to mobile number</h6>
        </center>

        <div className="row my-2">
          <div className="col">
            <div className="row my-2">
              <div className="col">
                <input
                  type="number"
                  className={`form-control`}
                  onChange={(e) => setEnteredOtp(e.target.value)}
                  name="enteredOTP"
                  placeholder="Enter OTP"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col-sm-12">
            <div className=" d-flex justify-content-center">
              <button
                disabled={load || enteredOtp.length !== 4}
                className="btn btn-success px-sm-4 px-2 mr-2"
                onClick={() => {
                  verifyOTP();
                  //   submit(closeModal);
                }}
              >
                {load ? (
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Verify"
                )}
              </button>
              <button
                disabled={load}
                className="btn btn-danger px-sm-4 px-2"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default ContactUsVerifyModal;
