import React, { useState, useRef } from "react";
import EditBatchModal from "./Modals/EditBatchModal";
import DeleteBatchModal from "./Modals/DeleteBatchModal";
import "./styles/review-pool-card.component.css";
import { uploadUpcomingBatchImageItem } from "../../../data/reducers/admin/batches/batches-admin.reducer";
import { Imageuploader } from "../../../shared/Utils/method";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//Dashboard pools
const BatchCard = ({ batch_detail }) => {
  console.log(
    "🚀 ~ file: BatchCard.jsx ~ line 12 ~ BatchCard ~ batch_detail",
    batch_detail
  );

  const upload_image = useRef();
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);

  const chooseImage = async (e) => {
    setLoad(true) ;
    console.log("clicked on input");
    console.log("e.target.files : ", e.target.files);
    const { secure_url } = await Imageuploader(e.target.files);
    console.log("secure_url : ", secure_url);

    const pool = localStorage.getItem("review_pool");
    //Service to be implemented

    // const response = await uploadUpcomingPoolImage({imageURI : secure_url, token : authState.token, pool}) ;
  
    const response = await dispatch(
      uploadUpcomingBatchImageItem({
        batch_name: batch_detail.name,
        imageURI: secure_url,
      })
    );
    if (response.payload.isSuccessful) {
      setLoad(false) ;
      return toast.success("Image upload done");
    }
    toast.error(response.payload.message.data.message);
  };

  const uploadImage = async (e) => {
    console.log("Clicking on button : ", upload_image);
    upload_image.current.click();
  };

  return (
    <div className="mr-sm-3 mb-3 border rounded-lg p-3 col-xl-3 col-lg-4 col-md-5 col-sm-6">
      <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-4">
        <h5 className="leadpool-name text-capitalize mb-0">
          {batch_detail.name}
        </h5>
        <div className="d-flex">
          {/* Add edit batch option */}
          <EditBatchModal batch={batch_detail} _id={batch_detail._id} />
          {/* delete batch option */}
          <DeleteBatchModal batchId={batch_detail._id} />
        </div>
      </div>
      <div>
        <div className="">
          <div className="text-center mb-1">
            {/* //Add upload image functionality here */}
            <input
              hidden
              type="file"
              accept="image/*"
              onChange={chooseImage}
              ref={upload_image}
            />
            <button
              className="btn d-flex align-items-center btn-primary mx-3"
              disabled={load}
              onClick={() => {
                uploadImage();
              }}
            >
              Upload Pool Image
              {load && (
                <div class="spinner-grow ml-2" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BatchCard;
