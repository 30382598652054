import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import {
  professionalAPI,
  professionalByOwnerIdAPI,
} from "../services/professional.service";

export const professionalByProjectItem = createAsyncThunk(
  "student/professionalByProjectItem",
  async (payload, thunkAPI) => {
    let response = await professionalByOwnerIdAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const professionalItem = createAsyncThunk(
  "student/professionalItem",
  async (payload, thunkAPI) => {
    let response = await professionalAPI(payload);

    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

const userSlice = createSlice({
  name: "student",
  initialState: {
    singleStudent: [],
    particularProfessional: [],
  },
  reducers: {},
  extraReducers: {
    [professionalByProjectItem.fulfilled]: (state, action) => {
      // if (action.payload) {
      //   // state.projects = action.payload;
      //   //
      // } else {
      //
      // }
    },
    [professionalItem.fulfilled]: (state, action) => {
      if (action.payload.data) {
        //
        // state.particularProfessional = action.payload;
        // state.singleStudent.push(action.payload);
        localStorage.setItem(
          "particularProfessional",
          JSON.stringify(action.payload.data)
        );
        state.particularProfessional = JSON.parse(
          localStorage.getItem("particularProfessional")
        );
        // console.log(action.payload.data);
      }
    },
  },
});

export default userSlice.reducer;
