import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  adminSendCustomBulkEventEmailAPI,
  adminSendTemplateLinkAPI,
  adminCountDocumentsForHomePageAPI,
  studentListAdminAPI,
  projectListAdminAPI,
  projectApprovalAdminAPI,
  professionalListAdminAPI,
  createContestAdminAPI,
  editContestAdminAPI,
  hrListAdminAPI,
  contestListAdminAPI,
  contestDeleteAdminAPI,
  participantListAdminAPI,
  toggleApproveAdminAPI,
  toggleStudentService,
  createEventAPI,
  getEventsAPI,
  deletEventAPI,
  editEventAPI,
  toggleStudentAPI,
  getRewardConfigAPI,
  createRewardConfigAPI,
  editRewardConfigAPI,
  deleteRewardConfigAPI,
  toggleRewardConfigAPI,
  createRewardAPI,
  toggleTYPStudentAPI,
  setUserLevelAPI,
} from "../services/admin.service";

//send custom email to registered users in events
export const adminSendCustomBulkEventEmailItem = createAsyncThunk(
  "admin/adminSendCustomBulkEventEmailItem",
  async (payload, thunkAPI) => {
    let response = await adminSendCustomBulkEventEmailAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//sned template links to event registered users
export const adminSendTemplateLinkItem = createAsyncThunk(
  "admin/adminSendTemplateLinkItem",
  async (payload, thunkAPI) => {
    let response = await adminSendTemplateLinkAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get counts for students users project badges
export const adminCountDocumentsForHomePageItem = createAsyncThunk(
  "admin/adminCountDocumentsForHomePageItem",
  async (payload, thunkAPI) => {
    let response = await adminCountDocumentsForHomePageAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const studentListAdminItem = createAsyncThunk(
  "admin/studentListAdminItem",
  async (payload, thunkAPI) => {
    let response = await studentListAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const professionalListAdminItem = createAsyncThunk(
  "admin/professionalListAdminItem",
  async (payload, thunkAPI) => {
    let response = await professionalListAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const hrListAdminItem = createAsyncThunk(
  "admin/hrListAdminItem",
  async (payload, thunkAPI) => {
    let response = await hrListAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const projectListAdminItem = createAsyncThunk(
  "admin/projectListAdminItem",
  async (payload, thunkAPI) => {
    let response = await projectListAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const projectApprovalAdminItem = createAsyncThunk(
  "admin/projectApprovalAdminItem",
  async (payload, thunkAPI) => {
    let response = await projectApprovalAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const createContestAdminItem = createAsyncThunk(
  "admin/createContestAdminItem",
  async (payload, thunkAPI) => {
    let response = await createContestAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const editContestAdminItem = createAsyncThunk(
  "admin/editContestAdminItem",
  async (payload, thunkAPI) => {
    let response = await editContestAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const contestListAdminItem = createAsyncThunk(
  "admin/contestListAdminItem",
  async (payload, thunkAPI) => {
    let response = await contestListAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);
export const contestDeleteAdminItem = createAsyncThunk(
  "admin/contestDeleteAdminItem",
  async (payload, thunkAPI) => {
    let response = await contestDeleteAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const participantListAdminItem = createAsyncThunk(
  "admin/participantListAdminItem",
  async (payload, thunkAPI) => {
    let response = await participantListAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const toggleApproveAdminItem = createAsyncThunk(
  "admin/toggleApproveAdminItem",
  async (payload, thunkAPI) => {
    let response = await toggleApproveAdminAPI(payload);
    if (response.isSuccessful === true) {
      return response.data;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const toggleStudentActivateItem = createAsyncThunk(
  "admin/toggleStudentActivate",
  async (payload, thunkAPI) => {
    let response = await toggleStudentAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const createEventItem = createAsyncThunk(
  "admin/createEvents",
  async (payload, thunkAPI) => {
    let response = await createEventAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const getEventsItem = createAsyncThunk(
  "admin/getEvents",
  async (payload, thunkAPI) => {
    let response = await getEventsAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const deleteEventItem = createAsyncThunk(
  "admin/deleteEvent",
  async (payload, thunkAPI) => {
    let response = await deletEventAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const editEventItem = createAsyncThunk(
  "admin/editEvent",
  async (payload, thunkAPI) => {
    let response = await editEventAPI(payload);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const getRewardConfigItem = createAsyncThunk(
  "admin/getRewardConfig",
  async (payload, thunkAPI) => {
    let response = await getRewardConfigAPI();
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue("network call failed");
  }
);

export const createRewardConfigItem = createAsyncThunk(
  "admin/createRewardConfig",
  async (payload, thunkAPI) => {
    let response = await createRewardConfigAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue("network call failed");
  }
);

export const editRewardConfigItem = createAsyncThunk(
  "admin/editRewardConfig",
  async (payload, thunkAPI) => {
    let response = await editRewardConfigAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue("network call failed");
  }
);

export const deleteRewardConfigItem = createAsyncThunk(
  "admin/deleteRewardConfig",
  async (payload, thunkAPI) => {
    let response = await deleteRewardConfigAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue("network call failed");
  }
);

export const toggleRewardConfigItem = createAsyncThunk(
  "admin/toggleRewardConfig",
  async (payload, thunkAPI) => {
    let response = await toggleRewardConfigAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue("network call failed");
  }
);

export const createRewardItem = createAsyncThunk(
  "admin/createReward",
  async (payload, thunkAPI) => {
    let response = await createRewardAPI(payload);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

export const toggleTYPStudentItem = createAsyncThunk(
  "admin/toggleTYPStudent",
  async (payload, thunkAPI) => {
    let response = await toggleTYPStudentAPI(payload);
    // console.log('togresponse: ', response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//set user level
export const setUserLevelItem = createAsyncThunk(
  "admin/setUserLevelItem",
  async (payload, thunkAPI) => {
    let response = await setUserLevelAPI(payload);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
const userSlice = createSlice({
  name: "admin",
  initialState: {
    studentList: [],
    studentLength: "",
    professionalsLength: "",
    hrLength: "",
    projectList: [],
    professionalList: [],
    hrList: [],
    participantList: [],
    contestList: [],
    events: [],
    rewardConfigList: [],
    projectSkip: null,
    projectToggleView: "",
    totalCountsForHomePage: "",
  },
  reducers: {
    //action creators
    setProjectSkip: (state, action) => {
      // console.log("action: ", action);
      state.projectSkip = action.payload;
      // state.projectToggleView = action.payload.toggleView;
    },
    setProjectView: (state, action) => {
      // console.log("action: ", action);
      state.projectToggleView = action.payload;
      // state.projectToggleView = action.payload.toggleView;
    },
  },
  extraReducers: {
    [adminCountDocumentsForHomePageItem.fulfilled]: (state, action) => {
      state.totalCountsForHomePage = action.payload.data;
    },
    [studentListAdminItem.fulfilled]: (state, action) => {
      state.studentList = [...action.payload.data];
      state.studentLength = action.payload.data.length;
    },
    [toggleStudentActivateItem.fulfilled]: (state, action) => {
      // console.log("action.payload in toggle student list : ", action);
      state.studentList = [...action.payload.data];
      state.studentLength = action.payload.data.length;
    },
    [professionalListAdminItem.fulfilled]: (state, action) => {
      state.professionalList = [...action.payload];
      state.professionalLength = action.payload.length;
    },
    [hrListAdminItem.fulfilled]: (state, action) => {
      state.hrList = [...action.payload];
      state.hrLength = action.payload.length;
    },
    [projectListAdminItem.fulfilled]: (state, action) => {
      state.projectList = [...action.payload.data];
    },
    [projectApprovalAdminItem.fulfilled]: (state, action) => {
      state.projectList = action.payload.data;
    },
    [createContestAdminItem.fulfilled]: (state, action) => {
      state.contestList = action.payload.data;
    },
    [editContestAdminItem.fulfilled]: (state, action) => {
      state.contestList = action.payload;
    },
    [contestListAdminItem.fulfilled]: (state, action) => {
      state.contestList = action.payload;
    },
    [contestDeleteAdminItem.fulfilled]: (state, action) => {
      state.contestList = action.payload;
    },
    [participantListAdminItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.participantList = action.payload;
      }
    },
    [toggleApproveAdminItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.participantList = action.payload;
      }
    },
    [createEventItem.fulfilled]: (state, action) => {
      // if (action.payload) {
      //   state.events = action.payload.data;
      // }
    },
    [getEventsItem.fulfilled]: (state, action) => {
      if (action.payload) {
        state.events = action.payload.data;
      }
    },
    [deleteEventItem.fulfilled]: (state, action) => {
      // if (action.payload) {
      //   state.events = action.payload.data;
      // }
    },
    [editEventItem.fulfilled]: (state, action) => {
      // if (action.payload) {
      //   state.events = action.payload.data.data;
      // }
    },
    [getRewardConfigItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
        state.rewardConfigList = action.payload.data;
      }
    },
    [createRewardConfigItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
        state.rewardConfigList = action.payload.data;
      }
    },
    [editRewardConfigItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
        state.rewardConfigList = action.payload.data;
      }
    },
    [deleteRewardConfigItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
        state.rewardConfigList = action.payload.data;
      }
    },
    [toggleRewardConfigItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
        state.rewardConfigList = action.payload.data;
      }
    },
    [createRewardItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload : ", action.payload);
      }
    },
    [toggleTYPStudentItem.fulfilled]: (state, action) => {
      if (action.payload) {
        // console.log("action.payload in toggleTYPStudent : ", action.payload);
        state.studentList = [...action.payload.data];
        state.studentLength = action.payload.data.length;
      }
    },
    [setUserLevelItem.fulfilled]: (state, action) => {
      state.studentList = [...action.payload.data];
      state.studentLength = action.payload.data.length;
    },
  },
});
export const { setProjectSkip, setProjectView } = userSlice.actions;
export default userSlice.reducer;
