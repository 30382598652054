import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUserToZohoService,
  updateUserToZohoService
} from '../services/zoho.service' ;

export const addUserToZohoItem = createAsyncThunk(
  "admin/addDataToZohoCRM",
  async (payload, thunkAPI) => {
    let response = await addUserToZohoService(payload);
    // console.log('response in zoho reducer : ', response);
    if (response.isSuccessful === true) {
      return response.data;
    } else return  thunkAPI.rejectWithValue("network call failed");
  }
);

export const updateUserToZohoItem = createAsyncThunk(
    "admin/updateDataToZohoCRM",
    async (payload, thunkAPI) => {
      let response = await updateUserToZohoService(payload);
      // console.log('response: ', response);
      if (response.isSuccessful === true) {
        return response.data;
      } else return  thunkAPI.rejectWithValue("network call failed");
    }
  );


const userSlice = createSlice({
  name: "zoho",
  initialState: {
    
  },

  extraReducers: {
    [addUserToZohoItem.fulfilled]: (state, action) => {
      // console.log("added successfully");
    },
    [updateUserToZohoItem.fulfilled]: (state, action) => {
      // console.log("updated successfully");
    },
  },
});

export default userSlice.reducer;
