import React, { useState } from "react";

const Search = ({ onSearch }) => {
  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  return (
    <>
      <input
        type="text"
        className="form-control rounded-0 border"
        id="inlineFormInputGroupUsername"
        placeholder="Search projects by username"
        aria-label="Sizing example input"
        // aria-describedby="inputGroup-sizing-lg"
        // className="form-control"
        // placeholder="search"
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
        style={{ width: "250px" }}
      />
    </>
  );
};

export default Search;
