import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  changeTestState,
  createCampusTestItem,
  emptyQuestionArray,
  emptyTestInitialState,
} from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";

//******************************************************** */
const CampusTest = (props) => {
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const testInitialReduxState = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).testInitialState;

  const questionReduxState = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).questionArray;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const [campusId, setCampusId] = useState();

  const [testData, setTestData] = useState();

  useEffect(() => {
    if (!loggedIn) {
      return history.push('/');
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      if (props?.location?.state?.campusId) {
        setCampusId(props?.location?.state?.campusId);
      }
      if (props?.location?.state?.action === "ADD") {
        setTestData({
          // totalAllowedTimeForTest: { totalHours: "", totalMinutes: "" },
          questionArray: [],
          passingPercentage: 0,
          testDate: "",
          startTimeSlotForTest: { startTime: "", endTime: "" },
        });
      }

      //   else if (props?.location?.state?.action === "EDIT") {
      // setTestData({
      //   totalAllowedTimeForTest:
      //     props?.location?.state?.totalAllowedTimeForTest,
      //   questionArray: props?.location?.state?.questionArray,
      //   totalMarksForTest: props?.location?.state?.totalMarksForTest,
      //   testDate: props?.location?.state?.testDate,
      //   startTimeSlotForTest: props?.location?.state?.startTimeSlotForTest,
      // });
      //   }
    }
  }, [props]);

  useEffect(() => {
    // console.log("testInitialReduxState: ", testInitialReduxState);
    setTestData(testInitialReduxState);
  }, [testInitialReduxState]);

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setTestData({ ...testData, [name]: value });
  };

  //create test
  const createTestApi = async () => {
    try {
      const lastDate = new Date(
        props?.location?.state?.registrationLastDate
      ).getTime();

      if (new Date(testData?.testDate).getTime() < lastDate)
        return toast.error(
          "Test date must be greater than registration last date."
        );

      // if (
      //   new Date(
      //     props?.location?.state?.registrationLastDate
      //   ).toLocaleDateString() ===
      //   new Date(testData?.testDate).toLocaleDateString()
      // )
      //   return toast.error(
      //     "Test date must be greater than registration last date."
      //   );

      if (questionReduxState && questionReduxState.length <= 4)
        return toast.error("Atleast select 5 questions.");

      setLoading(true);
      const payload = {
        ...testData,
        campusId,
        questionArray: questionReduxState,
      };

      let response = await dispatch(createCampusTestItem(payload));

      if (response.payload.isSuccessful) {
        toast.success("Test successfully created.");
        setLoading(false);
        dispatch(emptyQuestionArray({}));
        dispatch(emptyTestInitialState({}));
        history.push("/admin/campusPlacementDrive");
        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <div className="container">
        <div className="container-fluid m-3">
          <div className="row d-flex justify-content-center">
            {/* <div className="col-12">
            <h5 className="text-center font-weight-bold">Create Question</h5>
          </div> */}
            <div className="col-md-9">
              {" "}
              <h3 className="btn-secondary text-center p-2">Create test</h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3 ">
            <div className="col-8 border p-3">
              {/* <div className="input-group mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Total time
                </label>
                <input
                  onChange={(e) => {
                    setTestData((preVal) => {
                      return {
                        ...preVal,
                        totalAllowedTimeForTest: {
                          ...preVal.totalAllowedTimeForTest,
                          ["totalHours"]: e.target.value,
                        },
                      };
                    });
                  }}
                  placeholder="Enter hours.. "
                  type="text"
                  className="form-control form-control-lg "
                  value={testData?.totalAllowedTimeForTest?.totalHours}
                />
                <span className="input-group-text m-1">hours</span>
                <input
                  onChange={(e) => {
                    setTestData((preVal) => {
                      return {
                        ...preVal,
                        totalAllowedTimeForTest: {
                          ...preVal.totalAllowedTimeForTest,
                          ["totalMinutes"]: e.target.value,
                        },
                      };
                    });
                  }}
                  placeholder="Enter minutes.. "
                  type="text"
                  className="form-control form-control-lg"
                  value={testData?.totalAllowedTimeForTest?.totalMinutes}
                />
                <span className="input-group-text m-1">min</span>
              </div> */}
              <div className="input-group mb-3 border p-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Time slots
                </label>
                <label
                  htmlFor="appt-time"
                  className="form-label font-weight-bold text-dark"
                >
                  Start time
                </label>
                <input
                  placeholder="Enter start time.. "
                  type="time"
                  onChange={(e) => {
                    setTestData((preVal) => {
                      return {
                        ...preVal,
                        startTimeSlotForTest: {
                          ...preVal.startTimeSlotForTest,
                          ["startTime"]: e.target.value,
                        },
                      };
                    });
                  }}
                  className="form-control form-control-lg "
                  value={testData?.startTimeSlotForTest?.startTime}
                />
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  End time
                </label>
                <input
                  placeholder="Enter end time.. "
                  type="time"
                  onChange={(e) => {
                    setTestData((preVal) => {
                      return {
                        ...preVal,
                        startTimeSlotForTest: {
                          ...preVal.startTimeSlotForTest,
                          ["endTime"]: e.target.value,
                        },
                      };
                    });
                  }}
                  className="form-control form-control-lg"
                  value={testData?.startTimeSlotForTest?.endTime}
                />
              </div>

              <div className="input-group mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Passing percentage
                </label>
                <input
                  onChange={handleAddChange}
                  value={testData?.passingPercentage}
                  type="number"
                  name="passingPercentage"
                  className="form-control form-control-lg"
                />
              </div>

              <div className="input-group mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Test date
                </label>
                <input
                  onChange={handleAddChange}
                  value={testData?.testDate}
                  type="date"
                  name="testDate"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="col-12 p-0">
                <button
                  className="btn m-1 text-white btn-dark"
                  onClick={() => {
                    dispatch(
                      changeTestState({
                        // totalHours:
                        //   testData?.totalAllowedTimeForTest?.totalHours,
                        // totalMinutes:
                        //   testData?.totalAllowedTimeForTest?.totalMinutes,
                        passingPercentage: testData?.passingPercentage,
                        testDate: testData?.testDate,
                        startTime: testData?.startTimeSlotForTest?.startTime,
                        endTime: testData?.startTimeSlotForTest?.endTime,
                      })
                    );
                    history.push({
                      pathname: "/admin/campusPlacementDrive/Questions",
                      state: { comingFrom: "TEST" },
                    });
                  }}
                >
                  Choose questions
                </button>
                <span>
                  Total question selected : {questionReduxState?.length}
                </span>
              </div>
              <div className="col-12 p-0 ">
                <button
                  disabled={loading}
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    createTestApi();
                  }}
                >
                  Submit
                  {loading && (
                    <span
                      className="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampusTest;
