import React, { useState, useEffect } from "react";
import CreateRewardModal from "./Modals/Create-reward.modal";
import EditRewardModal from "./Modals/Edit-reward.modal";
import DeleteRewardModal from "./Modals/Delete-reward.modal";
import { toggleRewardConfigItem } from "../../../data/reducers/admin.reducer";
import { getRewardConfigItem } from "../../../data/reducers/admin.reducer";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ToggleStatus from "react-toggle";

//...

export default function Config() {
  const dispatch = useDispatch();

  const rewardConfigs = useSelector((state) => state.adminReducer)
    .rewardConfigList;
  console.log("rewardConfigs: ", rewardConfigs);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    console.log("Calling this");
    const response = await dispatch(getRewardConfigItem());
    console.log("response: ", response);
    if (response.payload.isSuccessful) {
      return;
    }
    toast.error(response.payload.message.data.message);
  };

  const handleEditStatus = async (_id, status) => {
    console.log("status: ", status);
    const payload = { _id, status };
    console.log("payload: ", payload);
    try {
      console.log("OCming here");
      const response = await dispatch(toggleRewardConfigItem(payload));
      if (response.payload.isSuccessful) {
        console.log("I am in");
        return toast.success("Reward status updated successfully!");
      }
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  return (
    <>
    <div className="col d-flex align-items-center justify-content-between my-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">Configuration</h3>
              <div><CreateRewardModal /></div>
            </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="table-responsive mt-2  ">
            <table className="table table-hover border">
              <thead className="bg-primary text-white">
                <tr>
                  <th align="center" scope="col">
                    Reward Name
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Reward Type
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Reward Points
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Reward Status
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Reward Measures in
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Edit
                  </th>
                  <th align="center" className="text-center" scope="col">
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {rewardConfigs?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td align="left">{item.rewardName}</td>
                      <td className="text-center">{item.type}</td>
                      <td className="text-center">
                        {item.rewardPoints ? item.rewardPoints : "---"}
                      </td>
                      <td className="text-center">
                        <ToggleStatus
                          defaultChecked={item?.status}
                          icons={false}
                          onClick={(e) => {
                            handleEditStatus(item._id, e.target.checked);
                          }}
                        />
                      </td>
                      <td className="text-center">{item.measure}</td>
                      <td className="text-center">
                        <EditRewardModal item={item} index={i} />
                      </td>
                      <td className="text-center">
                        <DeleteRewardModal item={item} index={i} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
