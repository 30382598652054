import React, { useState } from "react";
import Modal from "react-modal";
import { sendRewardItem } from "../../../../data/reducers/referral.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Model = ({ item }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle submit
  const handleSubmit = async () => {
    const { _id } = item;
    //

    try {
      const response = await dispatch(sendRewardItem({ _id }));
      if (response.payload.isSuccessful) {
        return toast.success("Reward sent successfully!");
      }
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };
  return (
    <>
      <button className="btn btn-warning text-white" onClick={OpenModal}>
        Send Reward
      </button>
      <div className="container">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 1,
            },
            content: {
              color: "#1D3883",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              flexDirection: "column",
            },
          }}
        >
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-12">
                <h3 className="text-center">
                  Are you sure want to send reward ?
                </h3>
              </div>
            </div>
            <div className="row d-flex justify-content-center p-3">
              <div className="col-12">
                <div className="col-12 d-flex justify-content-center p-0">
                  <button
                    className="btn text-white m-1"
                    style={{ backgroundColor: "#5A6268" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn m-1 text-white"
                    style={{ backgroundColor: "#C82333" }}
                    onClick={() => {
                      closeModal();
                      handleSubmit();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Model;
