import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addQuestionAPI,
  editQuestionAPI,
  getQuestionListsAPI,
} from "../../../services/admin/campus-placement Drive/question.service";

//thunks
//get  question
export const getQuestionListsItem = createAsyncThunk(
  "user/getQuestionListsItem",
  async (payload, thunkAPI) => {
    let response = await getQuestionListsAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//edit  question
export const editQuestionItem = createAsyncThunk(
  "user/editQuestionItem",
  async (payload, thunkAPI) => {
    let response = await editQuestionAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//add  question
export const addQuestionItem = createAsyncThunk(
  "user/addQuestionItem",
  async (payload, thunkAPI) => {
    let response = await addQuestionAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
