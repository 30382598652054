import React, { useState } from "react";
import LeftSideBar from "../../shared/components/leftSideBar/leftSideBar";
import { useDispatch, useSelector } from "react-redux";
import { studentEditProfileItem } from "../../data/reducers/auth.reducer";
import { Redirect } from "react-router-dom";

const StudentProfile1Edit = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  if (!loggedIn) {
    <Redirect to="/" />;
  }

  const id = user._id;

  const [next, setNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(user.profilePic);
  const [userData, setUserData] = useState({
    firstName: user.owner.firstName,
    lastName: user.owner.lastName,
    email: user.owner.email,
    mobileNumber: user.owner.mobileNumber,
    address: user.address,
    state: user.state,
    city: user.city,
    dateOfBirth: user.owner.dateOfBirth,
    gender: user.owner.gender,
    description: user.owner.description,
    currentPosition: user.currentPosition,
    skills: user.skills,
    personalBlog: user.personalBlog,
    github: user.github,
    linkedin: user.linkedin,
    twitter: user.twitter,
  });
  //
  //
  let projectAddRemove = true;
  const [education, setEducation] = useState([...user.education]);
  const [skillsList, setSkillsList] = useState([...user.skills]);
  const [workList, setWorkList] = useState([...user.workExperience]);
  const [certificateList, setCertificateList] = useState([...user.certificate]);
  const [typProjectList, setTypProjectList] = useState([...user.projects]);
  const { skills, personalBlog, github, linkedin, twitter } = userData;
  const [projectAdd, setProjectAdd] = useState(true);
  const [workAdd, setWorkAdd] = useState(true);
  const [certificateAdd, setCertificateAdd] = useState(true);
  const [educationAdd, setEducationAdd] = useState(true);
  const [skillsAdd, setSkillsAdd] = useState(true);
  // const skillsArray = skills.split(",");

  const change = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const Next = (e) => {
    e.preventDefault();

    setNext(false);
  };
  const previous = (e) => {
    e.preventDefault();
    //
    setNext(true);
  };

  const submit = async (e) => {
    e.preventDefault();
    //
    const payload = {
      firstName,
      id,
      lastName,
      gender,
      state,
      email,
      city,
      skills: skillsList,
      profilePic: image,
      dateOfBirth,
      address,
      currentPosition,
      description,
      mobileNumber,
      education,
      github,
      linkedin,
      personalBlog,
      twitter,
      typProjects: typProjectList,
      workExperience: workList,
      certificate: certificateList,
    };

    try {
      let response = await dispatch(studentEditProfileItem(payload));
    } catch (error) {}
    setNext(true);
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      address: "",
      state: "",
      city: "",
      dob: "",
      gender: "Select",
      description: "",
      skills: "",
      personalBlog: "",
      github: "",
      linkedin: "",
      twitter: "",
    });
  };
  const handleEducationInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...education];
    list[index][name] = value;
    setEducation(list);
  };
  // handle click event of the Remove button
  const handleEducationRemoveClick = (index) => {
    const list = [...education];
    list.splice(index, 1);
    setEducation(list);
  };

  // handle click event of the Add button
  const handleEducationAddClick = (e) => {
    setEducationAdd(false);
    e.preventDefault();
    setEducation([
      ...education,
      {
        institute: "",
        startDate: "",
        endDate: "",
        areaOfStudy: "",
        stream: "",
      },
    ]);
  };

  const handleProjectInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...typProjectList];
    list[index][name] = value;
    setTypProjectList(list);
  };
  // handle click event of the Remove button
  const handleProjectRemoveClick = (index) => {
    const list = [...typProjectList];
    list.splice(index, 1);
    setTypProjectList(list);
  };

  // handle click event of the Add button
  const handleProjectAddClick = (e) => {
    setProjectAdd(false);
    // projectAddRemove = false;
    e.preventDefault();
    setTypProjectList([
      ...typProjectList,
      {
        projectTitle: "",
        url: "",
        projectDescription: "",
      },
    ]);
  };
  const handleWorkInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...workList];
    list[index][name] = value;
    setWorkList(list);
  };
  // handle click event of the Remove button
  const handleWorkRemoveClick = (index) => {
    const list = [...workList];
    list.splice(index, 1);
    setWorkList(list);
  };

  // handle click event of the Add button
  const handleWorkAddClick = () => {
    setWorkAdd(false);
    setWorkList([
      ...workList,
      {
        workTitle: "",
        startDate: "",
        endDate: "",
        projectDescription: "",
      },
    ]);
  };

  const handleSkillsInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...skillsList];
    list[index][name] = value;
    setSkillsList(list);
  };
  // handle click event of the Remove button
  const handleSkillRemoveClick = (index) => {
    const list = [...skillsList];
    list.splice(index, 1);
    setSkillsList(list);
  };

  // handle click event of the Add button
  const handleSkillAddClick = () => {
    setSkillsAdd(false);
    setSkillsList([
      ...skillsList,
      {
        name: "",
      },
    ]);
  };
  const handleCertificateInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...certificateList];
    list[index][name] = value;
    setCertificateList(list);
  };
  // handle click event of the Remove button
  const handleCertificateRemoveClick = (index) => {
    const list = [...certificateList];
    list.splice(index, 1);
    setCertificateList(list);
  };

  // handle click event of the Add button
  const handleCertificateAddClick = () => {
    setCertificateAdd(false);
    setCertificateList([
      ...certificateList,
      {
        title: "",
        id: "",
        imgUrl: "",
      },
    ]);
  };

  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "theyouthproject");
    setLoading(true);
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/dt8ivto0k/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();

    setImage(file.secure_url);
    setLoading(false);
  };

  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    address,
    state,
    currentPosition,
    city,
    dateOfBirth,
    gender,
    description,
  } = userData;
  return (
    <>
      {next ? (
        <section className="midcontentsection"  >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-2  col-lg-3 pl-0">
                <LeftSideBar />
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="profilebox text-center">
                  <div className="file-upload">
                    <div className="image-upload-wra">
                      <img
                        className="card-img-top"
                        src={image}
                        style={{
                          width: "300px",
                          borderRadius: "50%",
                          border: "2px solid red",
                        }}
                      />
                      <div className="drag-text">
                        {/* <!-- <h3>Drag and drop a file or select add Image</h3> --> */}
                      </div>
                      <input
                        className=""
                        type="file"
                        className="form-control pt-1"
                        onChange={uploadImage}
                        // accept="image/*"
                      />
                    </div>
                  </div>

                  <h3 className="mb-4 border-bottom text-left pb-3 border-secondary">
                    Student Details
                  </h3>

                  <form action="">
                    <div className="row text-left">
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={change}
                            value={firstName}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={change}
                            value={lastName}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            onChange={change}
                            value={email}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Phone Number{" "}
                          </label>
                          <input
                            type="phone"
                            name="mobileNumber"
                            onChange={change}
                            value={mobileNumber}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Address{" "}
                          </label>

                          <input
                            name="address"
                            rows="4"
                            onChange={change}
                            value={address}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            State
                          </label>
                          <input
                            type="text"
                            name="state"
                            onChange={change}
                            value={state}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            onChange={change}
                            value={city}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Date Of Birth
                          </label>
                          <input
                            type="Date"
                            onKeyPress={(e) => {
                              e.preventDefault();
                            }}
                            name="dateOfBirth"
                            onChange={change}
                            value={dateOfBirth}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Select Gender{" "}
                          </label>
                          <select
                            name="gender"
                            value={gender}
                            onChange={change}
                            className="form-control form-control-lg"
                            id=""
                          >
                            <option value="Select">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Current Position{" "}
                          </label>

                          <input
                            name="currentPosition"
                            onChange={change}
                            value={currentPosition}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            About Description{" "}
                          </label>

                          <textarea
                            name="description"
                            rows="4"
                            onChange={change}
                            value={description}
                            className="form-control form-control-lg"
                          ></textarea>
                        </div>
                      </div>

                      <div className="text-right col-12">
                        <button
                          onClick={Next}
                          className="btn btn-warning form-control-lg text-white text-uppercase h6 mt-sm-4 mt-3 mb-2 px-5"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="midcontentsection">
          <div className="container-fluid pl-1">
            <div className="row">
              <div className="col-md-2 pl-0">
                <LeftSideBar />
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="profilebox p-5 my-5 text-center">
                  <h3>Professional Details</h3>

                  <form action="">
                    <div className="row text-left">
                      <div className="col-md-12">
                        <h3>Skills</h3>
                      </div>
                      {skillsList.map((skill, i) => (
                        <>
                          {" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Skills
                              </label>
                              <input
                                type="text"
                                name="name"
                                onChange={(e) => handleSkillsInputChange(e, i)}
                                defaultValue={skill.name}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-3  offset-md-9 text-right">
                            {skills.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => handleSkillRemoveClick(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}
                            {true && (
                              <button
                                type="button"
                                className="btn btn-success m-2"
                                onClick={handleSkillAddClick}
                              >
                                <i className="fas fa-plus-circle"></i>
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="col-md-3  offset-md-9 text-right">
                        {true && (
                          <button
                            type="button"
                            className="btn btn-success m-2"
                            onClick={handleSkillAddClick}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        )}
                      </div>

                      <div className="col-md-12">
                        <h3>Education Details</h3>
                      </div>
                      {education.map((education, i) => (
                        <>
                          {" "}
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                School / College / University name
                              </label>
                              <input
                                type="text"
                                name="institute"
                                onChange={(e) =>
                                  handleEducationInputChange(e, i)
                                }
                                defaultValue={education.institute}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                {" "}
                                Start Date
                              </label>
                              <input
                                type="date"
                                onKeyPress={(e) => {
                                  e.preventDefault();
                                }}
                                name="startDate"
                                onChange={(e) =>
                                  handleEducationInputChange(e, i)
                                }
                                defaultValue={education.startDate}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                {" "}
                                End Date
                              </label>
                              <input
                                type="date"
                                onKeyPress={(e) => {
                                  e.preventDefault();
                                }}
                                name="endDate"
                                onChange={(e) =>
                                  handleEducationInputChange(e, i)
                                }
                                defaultValue={education.endDate}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Area of Study
                              </label>
                              <input
                                type="text"
                                name="areaOfStudy"
                                onChange={(e) =>
                                  handleEducationInputChange(e, i)
                                }
                                defaultValue={education.areaOfStudy}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Stream
                              </label>
                              <input
                                type="text"
                                name="stream"
                                onChange={(e) =>
                                  handleEducationInputChange(e, i)
                                }
                                defaultValue={education.stream}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-3  offset-md-9 text-right">
                            {education.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => handleEducationRemoveClick(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}
                            {true && (
                              <button
                                type="button"
                                className="btn btn-success m-2"
                                onClick={handleEducationAddClick}
                              >
                                <i className="fas fa-plus-circle"></i>
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="col-md-3  offset-md-9 text-right">
                        {!education.length >= 0 && educationAdd && (
                          <button
                            type="button"
                            className="btn btn-success m-2"
                            onClick={handleEducationAddClick}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        )}
                      </div>
                      <div className="col-md-12">
                        <h3>TYP projects</h3>
                      </div>
                      {typProjectList.map((typProject, i) => (
                        <>
                          {" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Project Title
                              </label>
                              <input
                                type="text"
                                name="projectTitle"
                                onChange={(e) => handleProjectInputChange(e, i)}
                                defaultValue={typProject.projectTitle}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Github URL / Deployed URL
                              </label>
                              <input
                                type="text"
                                name="url"
                                onChange={(e) => handleProjectInputChange(e, i)}
                                defaultValue={typProject.url}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18">
                                Upload Image Of Your Project
                              </label>
                              <input
                                className=""
                                type="file"
                                defaultValue={typProject.image}
                                // accept="image/*"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Description
                              </label>
                              <input
                                type="text"
                                name="projectDescription"
                                onChange={(e) => handleProjectInputChange(e, i)}
                                defaultValue={typProject.projectDescription}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-3  offset-md-9 text-right">
                            {typProjectList.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => handleProjectRemoveClick(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}

                            {true && (
                              <button
                                type="button"
                                className="btn btn-success m-2"
                                onClick={handleProjectAddClick}
                              >
                                <i className="fas fa-plus-circle"></i>
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="col-md-3  offset-md-9 text-right">
                        {!typProjectList.length >= 0 && projectAdd && (
                          <button
                            type="button"
                            className="btn btn-success m-2"
                            onClick={handleProjectAddClick}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        )}
                      </div>

                      <div className="col-md-12">
                        <h3>Work Experience Details</h3>
                      </div>
                      {workList.map((work, i) => (
                        <>
                          {" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Work Title
                              </label>
                              <input
                                type="text"
                                name="workTitle"
                                onChange={(e) => handleWorkInputChange(e, i)}
                                defaultValue={work.workTitle}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Work Description
                              </label>
                              <input
                                type="text"
                                name="projectDescription"
                                onChange={(e) => handleWorkInputChange(e, i)}
                                defaultValue={work.projectDescription}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                {" "}
                                Start Date
                              </label>
                              <input
                                type="date"
                                onKeyPress={(e) => {
                                  e.preventDefault();
                                }}
                                name="startDate"
                                onChange={(e) => handleWorkInputChange(e, i)}
                                defaultValue={work.startDate}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                {" "}
                                End Date
                              </label>
                              <input
                                type="date"
                                onKeyPress={(e) => {
                                  e.preventDefault();
                                }}
                                name="endDate"
                                onChange={(e) => handleWorkInputChange(e, i)}
                                defaultValue={work.endDate}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-3  offset-md-9 text-right">
                            {workList.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => handleWorkRemoveClick(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}
                            {true && (
                              <button
                                type="button"
                                className="btn btn-success m-2"
                                onClick={handleWorkAddClick}
                              >
                                <i className="fas fa-plus-circle"></i>
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="col-md-3  offset-md-9 text-right">
                        {!workList.length >= 0 && workAdd && (
                          <button
                            type="button"
                            className="btn btn-success m-2"
                            onClick={handleWorkAddClick}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        )}
                      </div>

                      <div className="col-md-12">
                        <h3>Certificate Details</h3>
                      </div>
                      {certificateList.map((certificate, i) => (
                        <>
                          {" "}
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Certificate Title
                              </label>
                              <input
                                type="text"
                                name="name"
                                onChange={(e) =>
                                  handleCertificateInputChange(e, i)
                                }
                                defaultValue={certificate.name}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                id
                              </label>
                              <input
                                type="text"
                                name="id"
                                onChange={(e) =>
                                  handleCertificateInputChange(e, i)
                                }
                                defaultValue={certificate.id}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                Description
                              </label>
                              <input
                                type="text"
                                name="description"
                                onChange={(e) =>
                                  handleCertificateInputChange(e, i)
                                }
                                defaultValue={certificate.description}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-size-18" for="">
                                upload certificate
                              </label>
                              <input
                                type="file"
                                name="imageUrl"
                                onChange={(e) =>
                                  handleCertificateInputChange(e, i)
                                }
                                defaultValue={certificate.imageUrl}
                                className="form-control form-control-lg"
                              />
                            </div>
                          </div>
                          <div className="col-md-3  offset-md-9 text-right">
                            {certificateList.length !== 1 && (
                              <button
                                type="button"
                                className="btn btn-danger m-2"
                                onClick={() => handleCertificateRemoveClick(i)}
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            )}
                            {true && (
                              <button
                                type="button"
                                className="btn btn-success m-2"
                                onClick={handleCertificateAddClick}
                              >
                                <i className="fas fa-plus-circle"></i>
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="col-md-3  offset-md-9 text-right">
                        {!certificateList.length >= 0 && certificateAdd && (
                          <button
                            type="button"
                            className="btn btn-success m-2"
                            onClick={handleCertificateAddClick}
                          >
                            <i className="fas fa-plus-circle"></i>
                          </button>
                        )}
                      </div>

                      <div className="col-md-12">
                        <h3>Social Links</h3>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            {" "}
                            Personal Blog{" "}
                          </label>
                          <input
                            type="text"
                            name="personalBlog"
                            className="form-control form-control-lg"
                            onChange={change}
                            value={personalBlog}
                            placeholder=" http://www.Blog.com"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            {" "}
                            Github{" "}
                          </label>
                          <input
                            type="text"
                            name="github"
                            value={github}
                            onChange={change}
                            className="form-control form-control-lg"
                            placeholder="  http://www.Github.com"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            {" "}
                            Linkedin{" "}
                          </label>
                          <input
                            type="text"
                            name="linkedin"
                            value={linkedin}
                            onChange={change}
                            className="form-control form-control-lg"
                            placeholder="http://www.Linkedin.com"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            {" "}
                            Twitter{" "}
                          </label>
                          <input
                            type="text"
                            name="twitter"
                            value={twitter}
                            onChange={change}
                            className="form-control form-control-lg"
                            placeholder="http://www.Twitter.com"
                          />
                        </div>
                      </div>

                      <div
                        className="col-12 text-right"
                        style={{ display: "flex" }}
                      >
                        <button
                          onClick={previous}
                          className=" m-2 btn btn-secondary"
                        >
                          Back
                        </button>
                        <button
                          onClick={submit}
                          className=" m-2 btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default StudentProfile1Edit;
