import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//...
const Model = ({ link, value, user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const usertype = useSelector((state) => state.authReducer).userType;
  //
  const id = user._id;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [socialLink, setSocialLink] = useState({
    linkedin: "",
    github: "",
    twitter: "",
    personalBlog: "",
  });

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //add socialLink
  const handleAddSocialLink = (e) => {
    const { name, value } = e.target;
    //
    if (name === "github") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        linkedin: user.linkedin,
        twitter: user.twitter,
        personalBlog: user.personalBlog,
      });
    } else if (name === "linkedin") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        github: user.github,
        twitter: user.twitter,
        personalBlog: user.personalBlog,
      });
    } else if (name === "twitter") {
      setSocialLink({
        ...socialLink,
        [name]: value,
        github: user.github,
        linkedin: user.linkedin,
        personalBlog: user.personalBlog,
      });
    } else {
      setSocialLink({
        ...socialLink,
        [name]: value,
        github: user.github,
        twitter: user.twitter,
        linkedin: user.linkedin,
      });
    }
  };

  const addsocialLink = async () => {
    const payload = { socialLink, id };
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));
        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));
        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  return (
    <div className="container">
      <h4>
        <i className="fa fa-edit" onClick={OpenModal}></i>
      </h4>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "orange",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <p style={{ fontWeight: "bold" }}>Edit {link} Link</p>

        <input
          type="text"
          name={link}
          onChange={handleAddSocialLink}
          defaultValue={value}
          placeholder={link}
        />
        <button
          className="btn btn-dark "
          style={{ margin: "3px" }}
          name="action"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-dark "
          style={{ margin: "3px" }}
          name="action"
          onClick={() => {
            addsocialLink();
            closeModal();
          }}
        >
          Yes
        </button>
      </Modal>
    </div>
  );
};

export default Model;
