import React, { useState } from "react";
import LeftSideBar from "../../shared/components/leftSideBar/leftSideBar";

const StudentProfile2Edit = () => {
  const [userData, setUserData] = useState({
    skills: "",
    personalBlog: "",
    github: "",
    linkedin: "",
    twitter: "",
  });
  const [educationList, setEducationList] = useState([
    { institute: "", startDate: "", endDate: "", areaOfStudy: "", stream: "" },
  ]);
  const [workList, setWorkList] = useState([
    { company: "", startDate: "", endDate: "", position: "" },
  ]);
  const [certificateList, setCertificateList] = useState([
    { title: "", id: "", imgUrl: "" },
  ]);
  const { skills, personalBlog, github, linkedin, twitter } = userData;
  const skillsArray = skills.split(",");

  //
  const change = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
  };
  const handleEducationInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...educationList];
    list[index][name] = value;
    setEducationList(list);
  };
  // handle click event of the Remove button
  const handleEducationRemoveClick = (index) => {
    const list = [...educationList];
    list.splice(index, 1);
    setEducationList(list);
  };

  // handle click event of the Add button
  const handleEducationAddClick = () => {
    setEducationList([
      ...educationList,
      {
        institute: "",
        startDate: "",
        endDate: "",
        areaOfStudy: "",
        stream: "",
      },
    ]);
  };
  const handleWorkInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...workList];
    list[index][name] = value;
    setWorkList(list);
  };
  // handle click event of the Remove button
  const handleWorkRemoveClick = (index) => {
    const list = [...workList];
    list.splice(index, 1);
    setWorkList(list);
  };

  // handle click event of the Add button
  const handleWorkAddClick = () => {
    setWorkList([
      ...workList,
      {
        company: "",
        startDate: "",
        endDate: "",
        position: "",
      },
    ]);
  };
  const handleCertificateInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...certificateList];
    list[index][name] = value;
    setCertificateList(list);
  };
  // handle click event of the Remove button
  const handleCertificateRemoveClick = (index) => {
    const list = [...certificateList];
    list.splice(index, 1);
    setCertificateList(list);
  };

  // handle click event of the Add button
  const handleCertificateAddClick = () => {
    setCertificateList([
      ...certificateList,
      {
        title: "",
        id: "",
        imgUrl: "",
      },
    ]);
  };
  return (
    <>
      <section className="midcontentsection"  >
        <div className="container-fluid pl-1">
          <div className="row">
            <div className="col-md-2 pl-0">
              <LeftSideBar />
            </div>

            <div className="col-md-10">
              <div className="profilebox p-5 my-5 text-center">
                <h3>Professional Details</h3>

                <form action="">
                  <div className="row text-left">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label for="">Skills</label>

                        <input
                          type="text"
                          placeholder="e.g.  html , css , js , react "
                          name="skills"
                          value={skills}
                          onChange={change}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <h3>Education Details</h3>
                    </div>
                    {educationList.map((education, i) => (
                      <>
                        {" "}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="">
                              School / College / University name
                            </label>
                            <input
                              type="text"
                              name="institute"
                              onChange={(e) => handleEducationInputChange(e, i)}
                              value={education.institute}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for=""> Start Date</label>
                            <input
                              type="date"
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              name="startDate"
                              onChange={(e) => handleEducationInputChange(e, i)}
                              value={education.startDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for=""> End Date</label>
                            <input
                              type="date"
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              name="endDate"
                              onChange={(e) => handleEducationInputChange(e, i)}
                              value={education.endDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="">Area of Study</label>
                            <input
                              type="text"
                              name="areaOfStudy"
                              onChange={(e) => handleEducationInputChange(e, i)}
                              value={education.areaOfStudy}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="">Stream</label>
                            <input
                              type="text"
                              name="stream"
                              onChange={(e) => handleEducationInputChange(e, i)}
                              value={education.stream}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3  offset-md-9 text-right">
                          {educationList.length !== 1 && (
                            <button
                              className="btn btn-danger m-2"
                              onClick={() => handleEducationRemoveClick(i)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                          {educationList.length - 1 === i && (
                            <button
                              className="btn btn-success m-2"
                              onClick={handleEducationAddClick}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </button>
                          )}
                        </div>
                      </>
                    ))}
                    <div className="col-md-12">
                      <h3>Work Experience Details</h3>
                    </div>
                    {workList.map((work, i) => (
                      <>
                        {" "}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="">Company name</label>
                            <input
                              type="text"
                              name="company"
                              onChange={(e) => handleWorkInputChange(e, i)}
                              value={work.company}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="">Position</label>
                            <input
                              type="text"
                              name="position"
                              onChange={(e) => handleWorkInputChange(e, i)}
                              value={work.position}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for=""> Start Date</label>
                            <input
                              type="date"
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              name="startDate"
                              onChange={(e) => handleWorkInputChange(e, i)}
                              value={work.startDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for=""> End Date</label>
                            <input
                              type="date"
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              name="endDate"
                              onChange={(e) => handleWorkInputChange(e, i)}
                              value={work.endDate}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3  offset-md-9 text-right">
                          {workList.length !== 1 && (
                            <button
                              className="btn btn-danger m-2"
                              onClick={() => handleWorkRemoveClick(i)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                          {workList.length - 1 === i && (
                            <button
                              className="btn btn-success m-2"
                              onClick={handleWorkAddClick}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </button>
                          )}
                        </div>
                      </>
                    ))}

                    <div className="col-md-12">
                      <h3>Certificate Details</h3>
                    </div>
                    {certificateList.map((certificate, i) => (
                      <>
                        {" "}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="">Certificate Title</label>
                            <input
                              type="text"
                              name="title"
                              onChange={(e) =>
                                handleCertificateInputChange(e, i)
                              }
                              value={certificate.title}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="">id</label>
                            <input
                              type="text"
                              name="id"
                              onChange={(e) =>
                                handleCertificateInputChange(e, i)
                              }
                              value={certificate.id}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="">
                              image / document of certificate
                            </label>
                            <input
                              type="file"
                              name="imgUrl"
                              onChange={(e) =>
                                handleCertificateInputChange(e, i)
                              }
                              value={certificate.imgUrl}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-3  offset-md-9 text-right">
                          {certificateList.length !== 1 && (
                            <button
                              className="btn btn-danger m-2"
                              onClick={() => handleCertificateRemoveClick(i)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                          {certificateList.length - 1 === i && (
                            <button
                              className="btn btn-success m-2"
                              onClick={handleCertificateAddClick}
                            >
                              <i className="fas fa-plus-circle"></i>
                            </button>
                          )}
                        </div>
                      </>
                    ))}

                    <div className="col-md-12">
                      <h3>Social Links</h3>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label for=""> Personal Blog </label>
                        <input
                          type="text"
                          name="personalBlog"
                          className="form-control"
                          onChange={change}
                          value={personalBlog}
                          placeholder=" http://www.Blog.com"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label for=""> Github </label>
                        <input
                          type="text"
                          name="github"
                          value={github}
                          onChange={change}
                          className="form-control"
                          placeholder="  http://www.Github.com"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label for=""> Linkedin </label>
                        <input
                          type="text"
                          name="linkedin"
                          value={linkedin}
                          onChange={change}
                          className="form-control"
                          placeholder="http://www.Linkedin.com"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label for=""> Twitter </label>
                        <input
                          type="text"
                          name="twitter"
                          value={twitter}
                          onChange={change}
                          className="form-control"
                          placeholder="http://www.Twitter.com"
                        />
                      </div>
                    </div>

                    <div className="col-12 text-right">
                      <button
                        onClick={submit}
                        className="btn btn-warning form-control-lg text-white text-uppercase h6 mt-sm-4 mt-3 mb-2 px-5"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentProfile2Edit;
