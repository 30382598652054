import React, { useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//...
const Model = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  //   const user = useSelector((state) => state.authReducer).userInfo;
  //   const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  //

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    linkedin: user.linkedin,
    github: user.github,
    twitter: user.twitter,
    personalBlog: user.personalBlog,
  });
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //add socialLinks
  const handleAddsocialLinks = (e) => {
    const { name, value } = e.target;

    setSocialLinks({
      ...socialLinks,
      [name]: value,
    });
  };

  const addsocialLinks = async () => {
    const payload = { socialLinks, id };
    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };

  return (
    <div className="container">
      <i
        className="fa fa-plus p-2"
        onClick={OpenModal}
        style={{ cursor: "pointer", backgroundColor: "#F9C258" }}
      ></i>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "orange",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <p style={{ fontWeight: "bold" }}>Add Social socialLinks</p>
        <input
          type="text"
          name="linkedin"
          onChange={handleAddsocialLinks}
          defaultValue={user.linkedin}
          placeholder="Linkedin"
        />
        <input
          type="text"
          name="github"
          onChange={handleAddsocialLinks}
          defaultValue={user.github}
          placeholder="Github"
        />
        <input
          type="text"
          name="twitter"
          onChange={handleAddsocialLinks}
          defaultValue={user.twitter}
          placeholder="Twitter"
        />
        <input
          type="text"
          name="personalBlog"
          onChange={handleAddsocialLinks}
          defaultValue={user.personalBlog}
          placeholder="Personal Blog"
        />
        <button
          className="btn btn-dark "
          style={{ margin: "3px" }}
          name="action"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-dark "
          style={{ margin: "3px" }}
          name="action"
          onClick={() => {
            addsocialLinks();
            closeModal();
          }}
        >
          Yes
        </button>
      </Modal>
    </div>
  );
};

export default Model;
