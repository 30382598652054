import React, { useEffect, useState, useMemo } from "react";
import Search from "../../Shared/Search/Search";
import { useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { professionalListAdminItem } from "../../../../data/reducers/admin.reducer";

const Professional = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const usertype = useSelector((state) => state.authReducer).userType;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const professionalList = useSelector((state) => state.adminReducer)
    .professionalList;
  // console.log("professionalList: ", professionalList);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [click, setClick] = useState("");

  useEffect(async () => {
    fetchProfessionals();
  }, [skip]);

  const fetchProfessionals = async () => {
    try {
      setLoader(true);
      let response = await dispatch(professionalListAdminItem({ limit, skip }));
      if (response.payload.isSuccessful) {
        setClick("");
        return setLoader(false);
      }
      setLoader(false);
    } catch (error) {}
  };

  const professionalData = useMemo(() => {
    let professionalData = professionalList;
    if (search) {
      professionalData = professionalData.filter(
        (professional) =>
          professional.ownerField.firstName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          professional.ownerField.email
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          professional.ownerField.isVerified
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          professional.ownerField.mobileNumber
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    return professionalData;
  }, [professionalList, search]);

  const goPrevPage = () => {
    history.goBack();
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (loggedIn === true && usertype !== "admin" && usertype !== "technical-operation") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin" || usertype === "technical-operation") {
    return (
      <div className="container-fluid">
        <div>
          <div className="row mt-3">
            <div className="col d-flex align-items-center justify-content-between mb-4">
              <h3 className="text-primary Fweight-600 text-center mt-3">
                Professional List
              </h3>
              <div onClick={goPrevPage} class="btn btn-primary">
                Back
              </div>
            </div>
          </div>
          <div className="text-right pb-3">
            <Search
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>

          <div className="table-responsive">
            <table className="table table-hover">
              <thead className="bg-primary text-white">
                <th>No #</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>Verified</th>
                <th>Edit</th>
                <th>CRM</th>
              </thead>
              <tbody>
                {professionalData.map((professional, i) => (
                  <tr key={professional?._id}>
                    <td className="h5">{skip + i + 1}</td>
                    <td>
                      {professional.ownerField.firstName}{" "}
                      {professional.ownerField.lastName}
                    </td>
                    <td>{professional.ownerField.email}</td>
                    <td>{professional.ownerField.mobileNumber}</td>
                    <td>
                      {professional.ownerField.isVerified === "true" ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        <i className="fa fa-times"></i>
                      )}
                    </td>

                    <td>
                      <button className="fa fa-pencil btn text-success"></button>
                    </td>
                    <td>
                      <button className="btn btn-warning text-white btn-sm">
                        View CRM
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-center mb-4">
            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={skip === 0}
              onClick={() => {
                setClick("prev");
                setSkip((preVal) => {
                  return preVal - 10;
                });
              }}
            >
              {loader && click === "prev" ? "Prev.." : "Prev"}
              {loader && click === "prev" ? (
                <span
                  id="studentTop"
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>

            <button
              style={{ backgroundColor: "#1D3883" }}
              className="btn text-white m-1"
              disabled={
                professionalData.length === 0 || professionalData.length < 10
              }
              onClick={() => {
                setClick("next");
                setSkip((preVal) => {
                  return preVal + 10;
                });
              }}
            >
              {loader && click === "next" ? "Next.." : "Next"}
              {loader && click === "next" ? (
                <span
                  class="spinner-grow spinner-grow-sm ml-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Professional;
