import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventsItem,
  // deleteEventItem,
} from "../../../data/reducers/admin.reducer";
import { toast } from "react-toastify";

import DeleteEventModal from "./DeleteEvent.modal";
import EditEventModal from "./EditEvent.modal";
// import ToggleStatus from "react-toggle";

const Model = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const events = useSelector((state) => state.adminReducer).events;
  // console.log("events: ", events);

  const getEvents = async () => {
    // console.log("I am here");
    const response = await dispatch(getEventsItem());
    // console.log("response: ", response);
    if (response.payload.isSuccessful) {
      return console.log("All events are here");
    }
    toast.error(response?.payload?.data?.message);
  };

  //chnage event status
  // const changeEventStatusaApi = async () => {
  //   const payload = { eventStatus, eventId };
  //   console.log('payload: ', payload);
  //   // try {
  //   //   const response = await dispatch(deleteEventItem(payload));
  //   //   if (response.payload.isSuccessful) {
  //   //     return toast.success("Changed Successfully");
  //   //   } else if (!response.payload.isSuccessful) {
  //   //     return toast.error(response.payload.message.data.message);
  //   //   }
  //   // } catch (error) {
  //   //   console.log("error: ", error);
  //   // }
  // };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <button onClick={OpenModal} className="btn btn-warning">
        All Events
      </button>

      <div className="">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 1,
            },
            // Modal: {
            //     position: "absolute",
            //     top: 40,
            //     left: 40,
            //     right: 40,
            //     bottom: 40,

            // },
            content: {
              color: "#1D3883",
              top: "50%",
              left: "50%",
              right: "40px",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",

              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <center>
            <h4 className="text-muted">All Events</h4>
          </center>

          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Event Title</th>
                <th scope="col">Event Poster URL</th>
                <th scope="col">Event Place</th>
                <th scope="col">Event Date</th>
                <th scope="col">Last Date</th>
                <th scope="col">Training Start Date</th>{" "}
                <th scope="col">Training End Date</th>
                <th scope="col">Certificate Issued Date</th>
                <th scope="col">Event Status</th>
                <th scope="col">Event Mode</th>
                <th scope="col">Subject</th>
                <th scope="col">Content</th>
                <th scope="col">Grant certificate</th>
                <th scope="col">Template name</th>
                <th scope="col">Dynamic fields</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {events &&
                events?.map((item, index) => {
                  return (
                    <tr key={item?._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.eventTitle}</td>
                      <td>
                        {item?.eventPoster?.url ? item?.eventPoster?.url : "--"}
                      </td>
                      <td>{item?.eventPlace}</td>
                      <td>{item?.eventDate}</td>
                      <td>
                        {item?.registrationLastDate
                          ? new Date(
                              item?.registrationLastDate
                            ).toLocaleDateString()
                          : "--"}
                      </td>
                      <td>
                        {item?.trainingStartDate
                          ? item?.trainingStartDate
                          : "--"}
                      </td>
                      <td>
                        {item?.trainingEndDate ? item?.trainingEndDate : "--"}
                      </td>
                      <td>
                        {item?.certificateIssuedDate
                          ? new Date(
                              item?.certificateIssuedDate
                            ).toLocaleDateString()
                          : "--"}
                      </td>
                      <td>{item?.eventIsActive ? "Active" : "InActive"}</td>
                      <td>{item?.eventMode}</td>
                      <td>{item?.emailSubject ? item?.emailSubject : "--"}</td>
                      <td>{item?.emailContent ? item?.emailContent : "--"}</td>

                      <td>{item?.grantCertificate ? "YES" : "NO"}</td>
                      <td>
                        {item?.certificationConfig?.templateName
                          ? item?.certificationConfig?.templateName
                          : "--"}
                      </td>
                      <td>
                        {item?.certificationConfig?.dynamicFields
                          ? item?.certificationConfig?.dynamicFields
                          : "--"}
                      </td>
                      <td className="d-flex justify-content-around">
                        <DeleteEventModal
                          getEvents={getEvents}
                          _id={item._id}
                          item={item}
                        />

                        <EditEventModal getEvents={getEvents} item={item} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="row w-100 d-flex justify-content-center">
            <div className="col-5 d-flex justify-content-around">
              <button className="btn btn-danger" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Model;
