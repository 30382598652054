// import { render } from "@testing-library/react";
import React from "react";
import { Link } from "react-router-dom";
import empower from "../../assets/images/aboutNewImages/empower.png"
import how from "../../assets/images/aboutNewImages/how.png"
import interact from "../../assets/images/aboutNewImages/interact.png"
import jobs from "../../assets/images/aboutNewImages/jobs.png"
import typ from "../../assets/images/aboutNewImages/typ.png"
import Frame from "../../assets/images/aboutNewImages/Frame.png"
import image from "../../assets/images/aboutNewImages/image15.png"
const aboutNew = () => {
    return (
        <>
            {/* <div id="about-cntnt">
            <section>
                <div data-aos="fade-down"  data-aos-duration="2000" className="bg-primary" id="blue-curve">
               
               
                </div>
                <div className=" p-4" id="existToEmpower">
                    <div data-aos="fade-down"  data-aos-duration="2000" className="text-center">
                        <img src={empower}/>
                    </div>
                    <div data-aos="fade-up"  data-aos-duration="2000" className="text-center" id="bluePart-h">
                        <h3 className='weexisttoempoweru'>
                            We Exist To Empower You
                        </h3>
                        <p>
                            and ensure you get the best job you deserve.
                        </p>
                    </div>
                </div>
            </section>
            <section className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-12 col-12 text-center">
                    <img data-aos="fade-right"  data-aos-duration="2000" src={interact} className="aboutimageResize" />
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-12  ">
                    <h3 data-aos="fade-left"  data-aos-duration="2000" className="heading px-5 ">
                                Interact and grow with us
                            </h3>
                            <p data-aos="fade-left"  data-aos-duration="2000" className="paragraph px-5">
                                The Youth Project is an initiative to fill in the gap between the current university education and market requirement.
                            </p>
                    </div>
                </div> */}
            {/* <div className="row mt-5">
                    <div data-aos="flip-right"  data-aos-duration="2000" className="col-md-6 col-lg-6 order-1 col-sm-12 mt-5">
                    <p className="paragraph px-4">
                                The Youth Project has developed their own placement program for a better employment with the help of eminent companies.
                            </p>
                    </div>
                    <div data-aos="flip-left"  data-aos-duration="2000" className="col-md-6 order-sm-12 col-lg-6 col-sm-12 text-center ">
                         <img src={jobs} className="aboutimageResize"  />    
                    </div>
                </div>
                <div className="row" style={{marginTop:"10%"}}>
                <div className="col-md-6 p-0  col-lg-6 col-sm-12 col-12">
                    <img src={typ}   /> */}
            {/* <div className=" bg-primary rounded-blue">
                             <img data-aos="fade-right"  data-aos-duration="2000" src={typ} className="typimage aboutimageResize1" />
                         </div>  
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-12 col-12  ">
                    <h3 data-aos="fade-left"  data-aos-duration="2000" style={{
                        color: `red`,marginBottom:`2%`}}
                      className="heading px-4" id="heading-red">The Youth Project,</h3>
                            <p data-aos="fade-left"  data-aos-duration="2000" className="paragraph px-4">Community of innovator, where aspiring upcoming developers will learn modern technology with the help of our designed product.</p>
                    </div>
                </div>
                <div className="text-center text-blue  mt-5"  id="we-open-door">
                <p data-aos="zoom-in"  data-aos-duration="2000">“We open the door, but you must enter by yourself”</p>
            </div>
            </section>
            <section>
                <div className="container-fluid " id="interactJobTyp">
                    {/* <div className="my-5 row" id="interact">
                        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center ">
                            <img src={interact} className=" aboutimageResize" id="typ-image1"/>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <div id="interact-content">
                            <h3 className="heading ">
                                Interact and grow<br/> with us
                            </h3>
                            <p className="paragraph">
                                The Youth Project is an initiative to fill in the gap between the current university education and market requirement.
                            </p>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="my-5 row" id="job">
                        <div className="col-md-8 col-lg-6 order-1 col-sm-12">
                          
                            <p className="paragraph">
                                The Youth Project has developed their own placement program for a better employment with the help of eminent companies.
                            </p>
                        </div>
                        <div className="col-md-4 order-sm-12 col-lg-6 col-sm-12 d-flex justify-content-center">
                            <div id="job-image">
                                <img src={jobs} className=" aboutimageResize" id="typ-image2"/>
                            </div>    
                        </div>
                    </div> */}
            {/* <div className="row   my-5" id="typ">
                        
                        <div className="col-md-6 rounded-blue bg-primary col-lg-6 col-sm-12 d-flex justify-content-center">
                         <div className="typimage">
                             <img src={typ} className=" aboutimageResize" id="typ-image3"/>
                         </div>   
                         
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <div id="typ-content">
                            <h3  style={{
                        color: `red`,marginBottom:`2%`}}
                      className="heading" id="heading-red">The Youth Project,</h3>
                            <p className="paragraph">Community of innovator, where aspiring upcoming developers will learn modern technology with the help of our designed product.</p>
                            </div>
                        </div>
                    </div> */}

            {/* </div>
            </section> */}
            {/*           
            <section>
                <div className=" bg-primary" id="how-typ-work">
                    <h4 data-aos="zoom-out"  data-aos-duration="2000" className="text-center  text-white p-2">How TYP Works?</h4> */}
            {/* <div className="row p-3">
                        <div className="col-md-6 col-lg-6 col-sm-12 d-flex justify-content-center">
                              <img data-aos="fade-right"  data-aos-duration="2000" src={how}/>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12 text-white">
                            <div id="hTW-paragraph">
                            <p data-aos="fade-left"  data-aos-duration="2000"className=" text-white  paragraph">We provide proper fellowship to students and a guided program by our experienced mentors.
                                We believe that students should do self-grooming by the help of our advices which we share.
                                We here, at TYP not just focuses on the completion of course but also on the complete individual growth of an individual.</p>
                            </div>    
                        </div>
                    </div>
                </div> */}
            {/* <div className="text-center p-3" >
                    <button className="btn btn-primary rounded-pill text-center text-white">Know more</button>
                </div> */}
            {/* </section> */}
            {/* // </div> */}
            <section style={{ marginTop: "170px" ,marginBottom:"70px"}}>
                <div class="container-fluid">
                    <ul className="shapes">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12 col-sm-12 textAlignEnd" >
                            <h1 style={{ color: "#1D3883", fontFamily: "font-family: 'Poppins', sans-serif", textAlign: 'center' }}>We Exist to<br />
                                Empower You</h1>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 col-sm-12 dots">
                            <img src={Frame} className="aboutimageResize" />
                        </div>
                        <div class="col-lg-4 col-md-4 col-12 col-sm-12 textAlignStart" >
                            <h4 style={{ color: "#1D3883", fontFamily: "font-family: 'Poppins', sans-serif", textAlign: 'center', fontWeight: "lighter" }}>And Ensure You Get The<br />Best Job You Deserve.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <div class="container-fluid mt-4">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-12 col-sm-12 mb-3">
                            <div class="card" style={{ "boxShadow": "0 8px 16px 0 rgba(0,0,0,0.2",height:"100%" }}>
                                <div class="card-body m-2 p-3">
                                    <h4 class="card-title" style={{ color: "#1D3883", fontFamily: "font-family: 'Poppins', sans-serif", textAlign: 'center' }}>Interactive And Grow With Us</h4>
                                    <img src={empower} className="aboutimageResize" />
                                    <p className="card-text mt-4" style={{ color: "#808080", textAlign: 'center' }}>The Youth Project is an initiative to fill in the gap between the current university education and market requirement.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 col-sm-12 mb-3">
                            <div class="card" style={{ "boxShadow": "0 8px 16px 0 rgba(0,0,0,0.2",height:"100%" }}>
                                <div class="card-body m-2 p-3">
                                    <h4 class="card-title" style={{ color: "#1D3883", fontFamily: "font-family: 'Poppins', sans-serif", textAlign: 'center' }}>Our Modern Workspaces</h4>
                                    <img src={interact} className="aboutimageResize" />
                                    <p class="card-text mt-4" style={{ color: "#808080", textAlign: 'center' }}>The Youth Project has developed their own placement program for a better employment with the help of eminent companies.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12 col-sm-12 mb-3">
                            <div class="card" style={{ "boxShadow": "0 8px 16px 0 rgba(0,0,0,0.2",height:"100%" }}>
                                <div class="card-body m-2 p-3">
                                    <h4 class="card-title" style={{ color: "#1D3883", fontFamily: "font-family: 'Poppins', sans-serif", textAlign: 'center' }}>Community of innovator</h4>
                                    <img src={typ} className="aboutimageResize" />
                                    <p class="card-text mt-4" style={{ color: "#808080", textAlign: 'center' }}><span style={{
                                        color: `red`
                                    }}>
                                        The Youth Project,</span>Community of innovator, where aspiring upcoming developers will learn modern technology with the help of our designed product.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                {/* <ul className="shapes1">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul> */}
                <div class="container-fluid">
                    <ul className="shapes2">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>

                    </ul>
                    <div class="row">
                        <div class="col-12">
                            <img src={image} className="aboutimageResize" style={{ height: "300px" }} />
                        </div>
                        <div className="col-12 dots">
                            <div style={{ backgroundColor: "#FF8227", height: "", textAlign: "center", color: "white",marginTop:"-15px" }}>
                                <h3 className="dots p-4" style={{ margin: "auto", textAlign: 'center' }}>"We open the door,but you must<br />enter by yourself"</h3></div>
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <div class="container" style={{ marginTop: '50px' }}>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 col-12 dots">
                            <div>
                                <h1 style={{ color: "#1D3883" }}>How TYP <br /> Works?</h1>
                                {/* <h1 style={{ color: "#1D3883"}}>Works?</h1> */}
                                {/* <button className="knowmoebutton">Know More</button> */}
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-6 col-sm-12 col-12">
                            <p>• We provide proper fellowship to students and a guided program by our experienced mentors.</p>
                            <p> • We believe that students should do self-grooming by the help of our advices which we share.</p>
                            <p> • We here, at TYP not just focuses on the completion of course but also on the complete individual growth of an individual.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default aboutNew






