import React, { useState, useRef } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { checkEmpty } from "../../../shared/Utils/method";
import { toast } from "react-toastify";
import { createEventItem } from "../../../data/reducers/admin.reducer";
import ReactSelect from "react-select";
import moment from "moment";

const Model = ({ getEvents }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [loader, setloader] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    eventTitle: "",
    eventPoster: "",
    eventDate: "",
    eventPlace: "",
    eventRecText: "",
    eventMode: "",
    emailContent: "",
    emailSubject: "",
    trainingStartDate: "",
    trainingEndDate: "",
    registrationLastDate: "",
    grantCertificate: "",
    certificateIssuedDate: "",
    certificationConfig: { templateName: "", signature: "", dynamicFields: "" },
    isSpecialEvent: "",
  });
  const dispatch = useDispatch();

  const [fileImage, setFileImage] = useState();
  const [displayImage, setDisplayImage] = useState();

  const ref = useRef();

  const selectOptions = [
    { label: "YES", value: true },
    { label: "NO", value: false },
  ];

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = async () => {
    try {
      if (!displayImage) {
        return toast.error("Please choose event image.");
      }

      let A = moment(eventDetails.eventDate);
      let B = moment(eventDetails?.registrationLastDate);

      let A1 = moment(eventDetails.trainingEndDate);
      let B1 = moment(eventDetails?.trainingStartDate);

      if (A.diff(B, "days") < 0 || A.diff(B, "days") === 0) {
        return toast.error(
          "Event date should be greater than registration date."
        );
      }

      if (A1.diff(B1, "days") < 0 || A1.diff(B1, "days") === 0) {
        return toast.error(
          "Training start date should be greater than training end date."
        );
      }

      // const res = checkEmpty(eventDetails, [
      //   "emailSubject",
      //   "emailContent",
      //   "certificationConfig",
      //   "dynamicFields",
      //   "eventPoster",
      // ]);
      // setInputErr(res);
      // if (res.length !== 0) {
      //   return toast.error("All fields are mandatory");
      // }
      setloader(true);
      var bodyFormData = new FormData();

      for (const property in eventDetails) {
        // console.log("property: ", eventDetails[property]);

        bodyFormData.append(property, eventDetails[property]);
      }
      bodyFormData.delete("certificationConfig");

      if (fileImage) {
        bodyFormData.append("images", fileImage);
      }

      bodyFormData.append(
        "certificationConfig",
        JSON.stringify(eventDetails.certificationConfig)
      );

      const payload = { bodyFormData };
      const response = await dispatch(createEventItem(payload));
      if (response.payload.isSuccessful) {
        setloader(false);
        getEvents();
        closeModal();
        return toast.success("Added Successfully");
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      setloader(false);
      console.log("error: ", error);
    }
  };

  const handleChange = (e) => {
    setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
    // console.log("{ ...eventDetails, [e.target.name]: e.target.value } : ", {
    //   ...eventDetails,
    //   [e.target.name]: e.target.value,
    // });
  };

  return (
    <>
      <button onClick={OpenModal} className="btn btn-secondary">
        Add event
      </button>

      <div className="">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.4)",
              zIndex: 10000,
            },
            content: {
              color: "#1D3883",
              top: "30%",
              left: "50%",
              height: "80vh",
              right: "auto",
              bottom: "30%",
              marginRight: "-50%",
              transform: "translate(-50%, -30%)",
              // borderRadius:"35px",
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          <div className="row d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              <h5 className="font-weight-bold">Create Event</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="font-weight-bold form-label text-dark"
                >
                  Event Title
                </label>
                <input
                  placeholder="event title .. "
                  onChange={handleChange}
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventTitle") ? "is-invalid" : ""
                  }`}
                  name="eventTitle"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Event Poster
                </label>
                {/* Add cloudinary */}
                <input
                  type="file"
                  ref={ref}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      setFileImage(e.target.files[0]);
                      // console.log('e.target.files[0]: ', e.target.files[0]);
                      const reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = () => {
                        // console.log("reader.result: ", reader.result);
                        setDisplayImage(reader.result);
                      };
                    }
                  }}
                  accept="image/png,  image/jpeg"
                  className={`form-control form-control-lg `}
                  name="eventPoster"
                />
                <img
                  src={displayImage}
                  alt="display_image"
                  width="400px"
                  height="400px"
                />
                {displayImage && (
                  <i
                    className="fa fa-close"
                    style={{ cursor: "pointer" }}
                    onClick={() => setDisplayImage(undefined)}
                  ></i>
                )}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Registration last date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("registrationLastDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="registrationLastDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Event Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventDate") ? "is-invalid" : ""
                  }`}
                  name="eventDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Training Start Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("trainingStartDate") ? "is-invalid" : ""
                  }`}
                  name="trainingStartDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Training End Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("trainingEndDate") ? "is-invalid" : ""
                  }`}
                  name="trainingEndDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Certificate Issued Date
                </label>
                <input
                  onChange={handleChange}
                  type="date"
                  className={`form-control form-control-lg ${
                    inputErr.includes("certificateIssuedDate")
                      ? "is-invalid"
                      : ""
                  }`}
                  name="certificateIssuedDate"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Event Place
                </label>
                <input
                  onChange={handleChange}
                  type="text"
                  placeholder="event place .."
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventPlace") ? "is-invalid" : ""
                  }`}
                  name="eventPlace"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Event Recognized As
                </label>
                <input
                  onChange={handleChange}
                  placeholder="Event Recognition name .."
                  type="text"
                  className={`form-control form-control-lg ${
                    inputErr.includes("eventRecText") ? "is-invalid" : ""
                  }`}
                  name="eventRecText"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Email Subject
                </label>
                <textarea
                  onChange={handleChange}
                  placeholder="Event content.."
                  className={`form-control form-control-lg `}
                  name="emailSubject"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Email Content
                </label>
                <textarea
                  onChange={handleChange}
                  placeholder="Event content.."
                  className={`form-control form-control-lg `}
                  name="emailContent"
                />
              </div>
              {/* Dropdown event mode */}
              <div className="form-group">
                <label className="test-dark">Mode</label>
                <select
                  onChange={handleChange}
                  name="eventMode"
                  className={`custom-select custom-select-lg  ${
                    inputErr.includes("eventMode") ? "is-invalid" : ""
                  }`}
                >
                  <option value="">mode</option>
                  <option value="ONLINE">Online</option>
                  <option value="OFFLINE">Offline</option>
                </select>
              </div>
              <div className="form-group">
                <label
                  for="exampleInputEmail1"
                  className=" text-dark font-weight-bold mt-4"
                >
                  Grant certification{" "}
                </label>
                <ReactSelect
                  // value={selectedOption}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      grantCertificate: e.value,
                    });
                  }}
                  options={selectOptions}
                />
              </div>
              <div className="form-group">
                <label
                  for="exampleInputEmail1"
                  className=" text-dark font-weight-bold mt-4"
                >
                  Special event
                </label>
                <ReactSelect
                  // value={selectedOption}
                  onChange={(e) => {
                    setEventDetails({
                      ...eventDetails,
                      isSpecialEvent: e.value,
                    });
                  }}
                  options={selectOptions}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Template name
                </label>
                <input
                  onChange={(e) =>
                    setEventDetails({
                      ...eventDetails,
                      certificationConfig: {
                        ...eventDetails?.certificationConfig,
                        templateName: e.target.value,
                      },
                    })
                  }
                  placeholder="Template name.."
                  type="text"
                  className={`form-control form-control-lg `}
                  name="templateName"
                  value={eventDetails?.certificationConfig?.templateName}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-dark font-weight-bold"
                >
                  Dynamic template fields{" "}
                  <span className="text-muted">
                    *(Comma separated camelcase)
                  </span>
                </label>
                <input
                  onChange={(e) =>
                    setEventDetails({
                      ...eventDetails,
                      certificationConfig: {
                        ...eventDetails?.certificationConfig,
                        dynamicFields: e.target.value,
                      },
                    })
                  }
                  placeholder="eventTitle,templateName...."
                  type="text"
                  className={`form-control form-control-lg `}
                  name="dynamicFields"
                  value={eventDetails?.certificationConfig?.dynamicFields}
                />
              </div>
              <div className="col-12 p-0">
                <button
                  className="btn mx-2 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(eventDetails, [
                    //   "emailSubject",
                    //   "emailContent",
                    //   "certificationConfig",
                    // ]).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Create
                </button>
                <button
                  className="btn text-white mx-1 btn-warning"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    setEventDetails({
                      eventTitle: "",
                      eventPoster: "",
                      eventDate: "",
                      eventPlace: "",
                      eventIFrameLink: "",
                      eventMode: "",
                    });
                    closeModal();
                    setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Model;
