import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getActiveEventsItem } from "../../data/reducers/auth.reducer";
import { registerForEventItem } from "../../data/reducers/events/event-reducer";
import { getEventRegisteredUsersAdminItem } from "../../data/reducers/admin/events/admin-event-reducer";
import AddEventModal from "./Modals/AddEvent.modal";
import { addUserToZohoItem } from "../../data/reducers/zoho.reducer";
import { checkEmpty, checkNumber, checkEmail } from "../../shared/Utils/method";
import DeleteEventModal from "./Modals/DeleteEvent.modal";
import EditEventModal from "./Modals/EditEvent.modal";
import EventDetailModal from "./Modals/EventDetail";
import AllEventsModal from "./Modals/AllEvents.modal";
import { sendDataToTelegramItem } from "../../data/reducers/telegram.reducer";
import LoginModal from "../AuthPages/Login-modal";
import SendTemplateLinkModal from "./Modals/Send-template-link.modal";

import event1 from "../../assets/images/event1.png";
import event2 from "../../assets/images/event2.png";
import event3 from "../../assets/images/event3.png";
import event4 from "../../assets/images/event4.png";
import { getEventsItem } from "../../data/reducers/admin.reducer";
import { useHistory } from "react-router-dom";

export default function Events() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEmpty, setIsEmpty] = useState(true);
  const [place, setPlace] = useState("");
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const userInfo = useSelector((state) => state.authReducer).userInfo.owner;
  // console.log("loggedIn: ", loggedIn);

  const [load, setLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showRegisteredUser, setShowRegisteredUser] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [registeredUser, setRegisteredUsers] = useState([]);

  // console.log("Info", userInfo.firstName);
  const [studentDetails, setStudentDetails] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Mobile: "",
  });
  const [eventList, setEventList] = useState([]);
  const [limit, setLimit] = useState(2);
  const [click, setClick] = useState("");
  const [skip, setSkip] = useState(0);
  const [show, setShow] = useState(false);
  const [eventnames, seteventnames] = useState([
    {
      img: event1,
      name: "Title of event",
      post: "Location-Indore",
    },

    {
      img: event2,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event3,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event4,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event1,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event2,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event3,
      name: "Title of event",
      post: "Location-Indore",
    },
    {
      img: event4,
      name: "Title of event",
      post: "Location-Indore",
    },
  ]);
  const listSort = [...eventnames];
  const usertype = useSelector((state) => state.authReducer).userType;
  // console.log('usertype: ', usertype);

  const valueStyle = {
    fontSize: "20pz",
  };

  //   useEffect(() => {
  //     // if (skip !== 0) {
  //       getEventRegisteredUsersAdminApi();
  //     // }
  //   }, [skip]);

  const events = useSelector((state) => state.authReducer).activeEvents;
  const $events = useSelector((state) => state.adminReducer).events;
  // console.log('active events: ', events);

  const handleLocationSelect = (e) => {
    // console.log('eventPlace : ', e.target.value);
    setPlace(e.target.value);
    if (e.target.value == "") {
      return setIsEmpty(true);
    }
    setIsEmpty(false);
    const filteredEvents =
      usertype !== "admin"
        ? events?.filter((event) => event.eventPlace == e.target.value)
        : $events?.filter((event) => event.eventPlace == e.target.value);
    // console.log('filteredEvents: ', filteredEvents);
    setEventList(filteredEvents);
  };

  const updateEventOnEdit = () => {
    const filteredEvents =
      usertype !== "admin"
        ? events?.filter((event) => event.eventPlace == place)
        : $events?.filter((event) => event.eventPlace == place);
    // console.log('filteredEvents: ', filteredEvents);
    setEventList(filteredEvents);
    // console.log('events: ', events);
  };

  const handleChange = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };
  const sendDataToTelegram = async (payload) => {
    const data = `Name: ${payload.First_Name} ${payload.Last_Name} \n
Email: ${payload.Email} \n
Phone: ${payload.Mobile}, \n
UserAction: ${payload.UserAction} \n 
timeStamp:${new Date(Date.now()).toLocaleString()}
`;
    const encoded = encodeURI(data);
    const response = await dispatch(sendDataToTelegramItem(encoded));
  };
  const eventPlaceList =
    usertype !== "admin"
      ? events?.map((item) => item.eventPlace)
      : $events?.map((item) => item.eventPlace);
  const eventPlaceSet = new Set(eventPlaceList);
  const eventPlaces = Array.from(eventPlaceSet);
  // console.log("eventPlaces: ", eventPlaces);

  const getEvents = async () => {
    const payload = {
      isSpecialEvent: history?.location?.pathname === "/new/events",
    };
   
    if (history?.location?.pathname === "/new/events" && loggedIn) {
      var response =
        usertype !== "admin"
          ? await dispatch(getActiveEventsItem(payload))
          : await dispatch(getEventsItem());
    } else if (history?.location?.pathname === "/new/events" && loggedIn) {
      var response =
        usertype !== "admin" ? "" : await dispatch(getEventsItem());
    } else if (history?.location?.pathname === "/events") {
      var response =
        usertype !== "admin"
          ? await dispatch(getActiveEventsItem(payload))
          : await dispatch(getEventsItem());
    }

    // console.log('response: ', response.payload.isSuccessful);
    if (response?.payload?.isSuccessful) {
      return;
    }
    toast.error(response?.payload?.message?.data?.message);
  };

  const handleOnEventSubmit = async (e, item) => {
    const res = checkEmpty(studentDetails);
    // console.log('res: ', res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("Please fill all fields");
    }
    if (!checkNumber(studentDetails.Mobile)) {
      return toast.error("Wrong number format .. !");
    }
    if (!checkEmail(studentDetails.Email)) {
      return toast.error("Wrong email format .. !");
    }
    // console.log('item: ', item);
    // console.log({ ...studentDetails, "userAction": `EVENT_${item.eventRecText}` });
    setLoad(true);

    // console.log('zohoResponse.data.data[0].status: ', zohoResponse);
    const typApiResponse = await dispatch(
      registerForEventItem({
        first_Name: studentDetails?.First_Name,
        last_Name: studentDetails?.Last_Name,
        email: studentDetails?.Email,
        mobile: studentDetails?.Mobile,
        eventId: item?._id,
      })
    );
    // console.log("typApiResponse: ", typApiResponse);
    //in case of api sucess
    if (typApiResponse.payload.isSuccessful) {
      sendDataToTelegram({
        ...studentDetails,
        UserAction: `EVENT_${item.eventRecText}`,
      });
      const zohoResponse = await dispatch(
        addUserToZohoItem({
          payload: {
            ...studentDetails,
            userId: userInfo._id,
            UserAction: `EVENT_${item.eventRecText}`,
          },
        })
      );
      console.log("zohoResponse: ", zohoResponse);
      if (
        zohoResponse?.payload?.data &&
        zohoResponse?.payload?.data[0]?.status === "success"
      ) {
        setLoad(false);
        setStudentDetails({
          First_Name: "",
          Last_Name: "",
          Email: "",
          Mobile: "",
        });
        return toast.success("Registered suessfully");
      }
      setLoad(false);
      toast.error(
        zohoResponse?.payload?.data && zohoResponse?.payload?.data[0]?.message
      );
      return;
    }
    //in case of api err
    else if (!typApiResponse.payload.isSuccessful) {
      setLoad(false);
      toast.error(typApiResponse.payload.message.data.message);
      return;
    }
  };

  //get registered users
  const getEventRegisteredUsersAdminApi = async (eventId) => {
    // console.log("eventId: ", eventId);
    setLoader(true);
    const response = await dispatch(
      getEventRegisteredUsersAdminItem({ eventId, skip, limit })
    );
    // console.log("response: ", response);
    setLoader(true);
    if (response.payload.isSuccessful) {
      setLoader(false);
      setRegisteredUsers(response.payload?.data);
      return;
    } else if (!response.payload.isSuccessful) {
      setLoader(false);
      toast.error(response.payload.message.data.message);
      return;
    }
  };

  useEffect(() => {
    // console.log("sett");
    // console.log('localStorage.getItem("userType"): ', localStorage.getItem("userType"));
    if (userInfo && userInfo?.userType && userInfo?.userType !== "admin") {
      // console.log("setting userinfo");
      setStudentDetails({
        First_Name: loggedIn ? userInfo?.firstName : "",
        Last_Name: loggedIn ? userInfo?.lastName : "",
        Email: loggedIn ? userInfo?.email : "",
        Mobile: loggedIn ? userInfo?.mobileNumber : "",
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (localStorage.getItem("redirectTo")) {
      localStorage.removeItem("redirectTo");
    }
    getEvents();
  }, []);

  //   useEffect(() => {
  //     console.log("registeredUser: ", registeredUser);
  //   }, [registeredUser]);

  useEffect(() => {
    updateEventOnEdit();
  }, [events, $events]);

  return (
    <div className="container-fluid" style={{ marginTop: "100px" }}>
      {/* <center><h4 className='text-muted mt-3'><u>Events Section</u> {usertype === "admin" ? "( Admin Preview )" : null}</h4></center> */}

      {/* <div className="row">
        <div
          className="col-12 text-center"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <h4 className="font-weight-bold mb-md-5 mb-3">
            Upcoming Events {usertype === "admin" ? "( Admin Preview )" : null}
          </h4>
        </div>
      </div> */}
      <h1
        style={{
          fontFamily: "Poppins",
          fontSize: "36px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "54px",
          zIndex: "21",
          letterSpacing: "0em",
          textAlign: "center",
        }}
      >
        UPCOMING EVENTS {usertype === "admin" ? "( Admin Preview )" : null}
      </h1>
      <div className="col-md-7">
        {usertype === "admin" ? (
          <div className="row d-flex justify-content-md-end justify-content-center">
            <label className="text-white">user only </label>
            {/* add add event modal */}
            <div className="mr-2">
              <AddEventModal getEvents={getEvents} />
            </div>
            <AllEventsModal />
          </div>
        ) : null}
      </div>
      {
        eventPlaces.length == 0 ? (
          <div className="row  d-flex justify-content-center">
            <div className="col-md-9 d-flex justify-content-center">
              <center>
                <p className="h3 text-muted">
                  We are soon coming to your city for some cool Hackathons,
                  Contests and Job Drives ... Stay Tuned .. !! 😎😎
                </p>
              </center>
            </div>
          </div>
        ) : (
          <div
            style={{ width: "100%" }}
            class="d-felx  px-4 pb-4 mt-3 col-lg-3 col-md-6 col-sm-12 col-12"
          >
            <select
              style={{
                // width: "300px",
                borderRadius: "5px",

                boxShadow: " 0rem .2rem .2rem rgba(0,0,0,.3)",
              }}
              onChange={handleLocationSelect}
              className="custom-select  form-control form-control-lg "
            >
              <option value="">Choose Location</option>
              {eventPlaces.map((place) => {
                return <option value={place}>{place}</option>;
              })}
            </select>
          </div>
        )
        // (
        //   <div className="row">
        //     <div className="col-md-5">
        //       <div className="form-group">
        //         <label data-aos="zoom-in" data-aos-duration="2000">
        //           Choose Your location
        //         </label>
        //         <select
        //           data-aos="zoom-in"
        //           data-aos-duration="2000"
        //           onChange={handleLocationSelect}
        //           className="custom-select form-control form-control-lg "
        //         >
        //           <option value="">Choose Location</option>
        //           {eventPlaces.map((place) => {
        //             return <option value={place}>{place}</option>;
        //           })}
        //         </select>
        //       </div>
        //     </div>
        //   </div>
        // )
      }

      {eventPlaces.length != 0 ? (
        isEmpty ? (
          <center>
            <h4 className="py-5" data-aos="zoom-in" data-aos-duration="2000">
              SELECT EVENT LOCATION
            </h4>
          </center>
        ) : (
          <div>
            <div className="row">
              {eventList?.map((item) => (
                // <div className="container px-0">
                //   <div className="row">
                //     <div className="col-12">
                //       <div className="p-4 border rounded mt-md-5 mt-4">
                //         <div className="row mb-2">
                //           <div className="col-md-5">
                //             <div
                //               className="event-poster mx-auto border rounded-lg"
                //               style={{
                //                 backgroundImage: `url(${
                //                   item?.eventPoster?.url
                //                     ? item?.eventPoster?.url
                //                     : "--"
                //                 })`,
                //               }}
                //             ></div>
                //             {/* <img className="img-fluid rounded-lg" src={item.eventPoster} /> */}
                //           </div>
                //           <div className="col-md-7">
                //             <div>
                //               <h2
                //                 className="mb-3 mb-lg-5 mt-4 mt-md-0"
                //                 data-aos="fade-right"
                //               >
                //                 Event Details
                //               </h2>
                //               <div data-aos="fade-left" data-aos-duration="2000">
                //                 <div className="row mb-2">
                //                   <h5 className="text-secondary col-4">
                //                     {" "}
                //                     Title :{" "}
                //                   </h5>
                //                   <h5
                //                     className="col-8 text-capitalize"
                //                     style={valueStyle}
                //                   >
                //                     {item.eventTitle}
                //                   </h5>
                //                 </div>
                //                 <div className="row mb-2">
                //                   <h5 className="text-secondary col-4"> Date : </h5>
                //                   <h5
                //                     className="col-8 text-capitalize"
                //                     style={valueStyle}
                //                   >
                //                     {item.eventDate}
                //                   </h5>
                //                 </div>
                //                 {console.log("item.eventMode  ", item.eventMod)}
                //                 <div className="row mb-2">
                //                   <h5 className="text-secondary col-4">
                //                     {" "}
                //                     {item.eventMode == "ONLINE"
                //                       ? "Link"
                //                       : "Place"}{" "}
                //                     :{" "}
                //                   </h5>
                //                   <h5
                //                     className="col-8 text-capitalize"
                //                     style={valueStyle}
                //                   >
                //                     {item.eventPlace}
                //                   </h5>
                //                 </div>
                //                 <div className="row mb-2">
                //                   <h5 className="text-secondary col-4"> Mode : </h5>
                //                   <h5
                //                     className="col-8 text-capitalize"
                //                     style={valueStyle}
                //                   >
                //                     {item.eventMode}
                //                   </h5>
                //                 </div>
                //               </div>
                //               {usertype == "admin" ? (
                //                 <>
                //                   <div className="row mb-2">
                //                     <h5 className="text-secondary col-4">
                //                       {" "}
                //                       Recognized as :
                //                     </h5>
                //                     <h5
                //                       className="col-8 text-capitalize"
                //                       style={valueStyle}
                //                     >
                //                       {item.eventRecText}
                //                     </h5>
                //                   </div>
                //                   <div className="row mb-2">
                //                     <button
                //                       disabled={loader}
                //                       onClick={() => {
                //                         setShowRegisteredUser(true);
                //                         getEventRegisteredUsersAdminApi(item?._id);
                //                       }}
                //                       className="btn btn-primary"
                //                     >
                //                       Show registered users
                //                       {loader ? (
                //                         <span
                //                           id="studentTop"
                //                           class="spinner-grow spinner-grow-sm ml-1"
                //                           role="status"
                //                           aria-hidden="true"
                //                         ></span>
                //                       ) : null}
                //                     </button>
                //                   </div>
                //                 </>
                //               ) : null}
                //             </div>
                //           </div>
                //           <div className="col-md-12">
                //             {showRegisteredUser ? (
                //               <RegisteredUser
                //                 skip={skip}
                //                 eventId={item?._id}
                //                 registeredItems={registeredUser}
                //               />
                //             ) : null}
                //           </div>
                //         </div>
                //         {usertype === "admin" ? null : (
                //           <div className="col-12">
                //             <h2 className="my-3  my-md-5 text-center">
                //               Register Here
                //             </h2>
                //             <div className="row ">
                //               <div className="col-md-12 col-lg-12 col-sm-12 col-12">
                //                 <div className="bgn-wf-row mb-3">
                //                   <div className="bgn-wf-label mb-2">
                //                     First Name
                //                     <span className="bgn-star">&#42;</span>
                //                   </div>
                //                   <div className="bgn-wf-field">
                //                     <input
                //                       name="First_Name"
                //                       value={studentDetails.First_Name}
                //                       className={`form-control form-control-lg  ${
                //                         inputErr.includes("First_Name")
                //                           ? "is-invalid"
                //                           : ""
                //                       }`}
                //                       type="text"
                //                       onChange={handleChange}
                //                       valdefaultValueue=""
                //                       placeholder="first name .."
                //                       disabled={loggedIn}
                //                     />
                //                   </div>
                //                 </div>
                //                 <div className="bgn-wf-row mb-3">
                //                   <div className="bgn-wf-label mb-2">
                //                     Email
                //                     <span className="bgn-star">&#42;</span>
                //                   </div>
                //                   <div className="bgn-wf-field">
                //                     <input
                //                       name="Email"
                //                       value={studentDetails.Email}
                //                       className={`form-control form-control-lg  ${
                //                         inputErr.includes("Email")
                //                           ? "is-invalid"
                //                           : ""
                //                       }`}
                //                       type="text"
                //                       onChange={handleChange}
                //                       valdefaultValueue=""
                //                       placeholder="email .."
                //                     />
                //                   </div>
                //                 </div>
                //                 <div className="bgn-wf-row mb-3">
                //                   <div className="bgn-wf-label mb-2">
                //                     Last Name
                //                     <span className="bgn-star">&#42;</span>
                //                   </div>
                //                   <div className="bgn-wf-field">
                //                     <input
                //                       name="Last_Name"
                //                       value={studentDetails.Last_Name}
                //                       className={`form-control form-control-lg  ${
                //                         inputErr.includes("Last_Name")
                //                           ? "is-invalid"
                //                           : ""
                //                       }`}
                //                       type="text"
                //                       onChange={handleChange}
                //                       valdefaultValueue=""
                //                       placeholder="last name .."
                //                       disabled={loggedIn}
                //                     />
                //                   </div>
                //                 </div>

                //                 <div className="bgn-wf-row mb-3">
                //                   <div className="bgn-wf-label mb-2">
                //                     Phone Number
                //                     <span className="bgn-star">&#42;</span>
                //                   </div>
                //                   <div className="bgn-wf-field">
                //                     <input
                //                       name="Mobile"
                //                       value={studentDetails.Mobile}
                //                       className={`form-control form-control-lg  ${
                //                         inputErr.includes("Mobile")
                //                           ? "is-invalid"
                //                           : ""
                //                       }`}
                //                       type="text"
                //                       onChange={handleChange}
                //                       valdefaultValueue=""
                //                       placeholder="mobile number .."
                //                       disabled={loggedIn}
                //                     />
                //                   </div>
                //                 </div>
                //               </div>
                //               {loggedIn && (
                //                 <button
                //                   disabled={load}
                //                   className="btn btn-secondary btn-lg px-5"
                //                   onClick={(e) => {
                //                     handleOnEventSubmit(e, item);
                //                   }}
                //                 >
                //                   Submit
                //                 </button>
                //               )}
                //               {!loggedIn && (
                //                 <div
                //                   className="col-12 text-center mt-3"
                //                   // data-aos="zoom-in"
                //                   data-aos-duration="2000"
                //                 >
                //                   <LoginModal purpose="event"></LoginModal>
                //                 </div>
                //               )}
                //             </div>
                //           </div>
                //         )}
                //       </div>
                //     </div>
                //   </div>
                // </div>

                <div className="col-lg-3 col-md-4 col-sm-12 col-12  ">
                  <>
                    <div
                      class="card mb-3"
                      style={{
                        borderRadius: "2%",
                        boxShadow:
                          " inset 0rem 0.5rem .5rem rgba(0,0,0,.3),0rem .2rem .2rem rgba(0,0,0,.3)",
                      }}
                    >
                      <img
                        class="card-img-top p-2"
                        style={{ height: "220px" }}
                        src={
                          item?.eventPoster?.url
                            ? item?.eventPoster?.url
                            : event1
                        }
                        alt="Card image cap"
                      ></img>
                      <div
                        class="card-body"
                        style={{ paddingTop: "0", paddingBottom: "0" }}
                      >
                        <h5
                          class="card-title designEventText"
                          style={{ marginBottom: "0" }}
                        >
                          {item.eventRecText}
                        </h5>
                        <div className="d-flex justify-content-between mb-2">
                          <div
                            class="card-text designEventText"
                            style={{ color: "#1D3883" }}
                          >
                            Location - {item.eventPlace}
                          </div>
                          <div
                            class="card-text"
                            style={{ marginTop: "3px", cursor: "pointer" }}
                          >
                            {/* <small class="text-muted">
                                <strong>see more</strong>
                              </small> */}
                            <EventDetailModal
                              RegisteredUser={RegisteredUser}
                              registeredUser={registeredUser}
                              showRegisteredUser={showRegisteredUser}
                              skip={skip}
                              data={item}
                              usertype={usertype}
                              loggedIn={loggedIn}
                              studentDetails={studentDetails}
                              loader={loader}
                              setShowRegisteredUser={setShowRegisteredUser}
                              getEventRegisteredUsersAdminApi={
                                getEventRegisteredUsersAdminApi
                              }
                            ></EventDetailModal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

//registered users
const RegisteredUser = ({ registeredItems, skip, eventId }) => {
  return (
    <>
      {registeredItems && registeredItems.length > 0 ? (
        <SendTemplateLinkModal eventId={eventId} />
      ) : null}

      <div
        style={{
          position: "relative",
          height: "500px",
          overflow: "auto",
          display: "block",
        }}
      >
        <table className="table table-responsive-lg overflow-auto">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">First name</th>
              <th scope="col">Last name</th>
              <th scope="col">Email</th>
              <th scope="col">Mobile number</th>
            </tr>
          </thead>
          <tbody
          // style={{
          //   height: "auto",
          //   maxHeight: "200px",
          //   overflowX: "hidden",
          // }}
          >
            {registeredItems && registeredItems.length > 0
              ? registeredItems?.map((item, index) => {
                  return (
                    <tr key={item?._id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item?.registeredUsers?.firstName}</td>
                      <td>{item?.registeredUsers?.lastName}</td>
                      <td>{item?.registeredUsers?.email}</td>
                      <td>{item?.registeredUsers?.mobileNumber}</td>
                    </tr>
                  );
                })
              : "No data"}
          </tbody>
        </table>
      </div>
    </>
  );
};

const Event = () => {
  // return (
  //   <>
  //     <section style={{ marginTop: "100px" }}>
  //       <h1
  //         style={{
  //           fontFamily: "Poppins",
  //           fontSize: "36px",
  //           fontStyle: "normal",
  //           fontWeight: "600",
  //           lineHeight: "54px",
  //           zIndex:"21",
  //           letterSpacing: "0em",
  //           textAlign: "center",
  //         }}
  //       >
  //         UPCOMING EVENTS
  //       </h1>
  //       <div
  //         style={{ width: "100%", textAlign: "end" }}
  //         class="d-felx justify-content-end px-4 pb-4"
  //       >
  //         <select
  //           style={{
  //             width: "300px",
  //             borderRadius: "5px",
  //             boxShadow: " 0rem .2rem .2rem rgba(0,0,0,.3)",
  //           }}
  //           // onChange={handleLocationSelect}
  //           className="custom-select form-control form-control-lg "
  //         >
  //           <option value="">Choose Location</option>
  //           {/* {eventPlaces.map((place) => {
  //                 return <option value={place}>{place}</option>;
  //               })} */}
  //         </select>
  //       </div>
  //   <div className="container-fluid">
  //   <div className="row">
  //     {listSort.map((eventnames) => (
  //       <div className="col-lg-3 col-md-4 col-sm-12 col-12  ">
  //         <>
  //           <div
  //             class="card mb-3"
  //             style={{
  //               borderRadius: "5%",
  //               boxShadow:
  //                 " inset 0rem 0.5rem .5rem rgba(0,0,0,.3),0rem .2rem .2rem rgba(0,0,0,.3)",
  //             }}
  //           >
  //             <img
  //               class="card-img-top p-2"
  //               src={eventnames.img}
  //               alt="Card image cap"
  //             ></img>
  //             <div
  //               class="card-body"
  //               style={{ paddingTop: "0", paddingBottom: "0" }}
  //             >
  //               <h5
  //                 class="card-title designEventText"
  //                 style={{ marginBottom: "0" }}
  //               >
  //                 {eventnames.name}
  //               </h5>
  //               <div className="d-flex justify-content-between mb-2">
  //                 <div
  //                   class="card-text designEventText"
  //                   style={{ color: "#1D3883" }}
  //                 >
  //                   {eventnames.post}
  //                 </div>
  //                 <div class="card-text" style={{ marginTop: "3px",cursor:"pointer" }}>
  //                   {/* <small class="text-muted">
  //                     <strong>see more</strong>
  //                   </small> */}
  //                   <EventDetailModal></EventDetailModal>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </>
  //       </div>
  //     ))}
  //   </div>
  // </div>
  //     </section>
  //   </>
  // );
};

// export default Event;
