import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import "../../shared/styles/styles.css";
import Background from "../../assets/images/abtbg.png";
import { checkNumber, checkEmail, checkEmpty } from "../../shared/Utils/method";
import { addUserToZohoItem } from "../../data/reducers/zoho.reducer";
import { sendDataToTelegramItem } from "../../data/reducers/telegram.reducer";

const Contact = () => {
  const dispatch = useDispatch();
  const [inputErr, setInputErr] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const sendDataToTelegram = async (payload) => {
    const data = `Name: ${payload.First_Name} ${payload.Last_Name} \n
Email: ${payload.Email} \n
Subject: ${payload.Subject} \n
Phone: ${payload.Phone}, \n
Message: ${payload.Message}, \n
UserAction: ${payload.UserAction} `;
    const encoded = encodeURI(data);
    const response = await dispatch(sendDataToTelegramItem(encoded));
    // console.log("response telegram: ", response);
    // if (response.payload.status == "success") {
    //   return toast.success("Data Sent!!");
    // }
    // console.log("payloaf", response.payload.status);
  };
  const submit = async () => {
    console.log("Something is here");
    const res = checkEmpty(userData);
    console.log("res: ", res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("All fields are mandatory");
    }

    if (!checkNumber(userData.phone) || !checkEmail(userData.email)) {
      return toast.error("Please check Email ID / Contact Number format");
    }

    let [First_Name, Last_Name] = userData.name.split(" ");
    if (!Last_Name) Last_Name = " -- ";
    // console.log("First_Name, Last_Name: ", First_Name, Last_Name);
    const payload = {
      First_Name,
      Last_Name,
      Email: userData.email,
      Subject: userData.subject,
      Phone: userData.phone,
      Message: userData.message,
      UserAction: "CONTACT_US",
    };
    sendDataToTelegram(payload);
    // const response = await dispatch(addUserToZohoItem(payload));
    // console.log('response: ', response);
    // if (response.payload.data[0].status == "success") {
    //   setUserData({
    //     name: "",
    //     email: "",
    //     subject: "",
    //     phone: "",
    //     message: "",
    //   });
    //   return toast.success("We'll get back to you soon ... !!");
    // }
    // toast.error(response.payload.data[0].message);
  };
  const { name, email, subject, phone, message } = userData;
  return (
    <>
      <section
        className="innerbanner "
        style={{
          background: `url(${Background}) center center/cover no-repeat transparent`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1 className="display-4 K-font Fweight-500">
                Enroll now and let us help you
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="contactsection sc-pd text-center py-sm-5 py-4">
        <div className="container">
          <h3>
            If you are tired of approaching companies and not finding the right
            opportunity, or afraid of missing out on new technologies because of
            lack of knowledge, please enroll now and let us help you.
          </h3>
          <p className="text-gray line-H-12 font-size-18 Fweight-600 pt-3">
            The Youth Project is created by a group of high skilled engineers
            who have worked as team leaders, CXO's and professional developers,
            and have felt the need to do something about the problem with the
            current workforce. We realized that in today's industry,
            <b>"BEING FRESHER IS A MYTH."</b>
            Companies have right money to give to the skilled professionals but
            not enough to train them for a certain skills. It is this gap in the
            industry which is leading to unemployed crowd, slow development
            cycles in company due to lack of resources and eventually a slow
            economy at large.
          </p>
        </div>
      </section>
      <section className="contantform">
        <div className="container">
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="h6" htmlFor="">
                    Your Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={name}
                    className={`form-control form-control-lg ${
                      inputErr.includes("name") ? "is-invalid" : ""
                    }`}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="h6" htmlFor="">
                    Your Email
                  </label>
                  <input
                    type="mail"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    className={`form-control form-control-lg ${
                      inputErr.includes("email") ? "is-invalid" : ""
                    }`}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="h6" htmlFor="">
                    Your Subject*
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="subject"
                    value={subject}
                    className={`form-control form-control-lg ${
                      inputErr.includes("subject") ? "is-invalid" : ""
                    }`}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="h6" htmlFor="">
                    Your Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    value={phone}
                    className={`form-control form-control-lg ${
                      inputErr.includes("phone") ? "is-invalid" : ""
                    }`}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="h6" htmlFor="">
                    Your Message*
                  </label>
                  <textarea
                    name=""
                    id=""
                    rows="5"
                    name="message"
                    onChange={handleChange}
                    value={message}
                    className={`form-control form-control-lg ${
                      inputErr.includes("message") ? "is-invalid" : ""
                    }`}
                  ></textarea>
                </div>
              </div>
              <div className="col-sm-3 mb-3">
                <button
                  type="button"
                  onClick={submit}
                  // href="#"
                  className="btn btn-warning w-100 py-2 text-white text-uppercase h5 my-sm-4 my-3"
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
