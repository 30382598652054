import React, { useState, useEffect } from "react";
import LeftSideBar from "../../shared/components/leftSideBar/leftSideBar";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "../../config";
import {
  studentEditProfileItem,
  professionalEditProfileItem,
  studentUploadProfilePicItem,
  professionalUploadProfilePicItem,
} from "../../data/reducers/auth.reducer";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { checkEmpty } from "../../shared/Utils/method";
import { useHistory } from "react-router-dom";

const StudentProfile3Edit = () => {
  const {
    cloudinaryImageLink,
    uploadImagePreset,
    
  } = constants;
  console.log('uploadImagePreset: ', uploadImagePreset);

  console.log('cloudinaryImageLink: ', cloudinaryImageLink);
  const history = useHistory();
  const dispatch = useDispatch();
  const [imageLoader, setImageLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const user = useSelector((state) => state.authReducer).userInfo;
  // console.log('user: ', user);

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user.owner._id;

  const userType = user.owner.userType;

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(user?.owner?.profilePic);

  const [userData, setUserData] = useState({
    firstName: user.owner.firstName,
    lastName: user.owner.lastName,
    email: user.owner.email,
    mobileNumber: user.owner.mobileNumber,
    address: user.owner.address,
    state: user.owner.state,
    city: user.owner.city,
    dateOfBirth: user.owner.dateOfBirth,
    gender: user.owner.gender,
    description: user.owner.description,
    currentPosition: user.owner.currentPosition,
    profilePic: user.owner.profilePic,
  });
  const [inputErr, setInputErr] = useState([]);

  useEffect(() => {
    setInputErr([]);
  }, []);

  const change = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  //student
  const submit = async (e) => {
    e.preventDefault();
    const result = checkEmpty({ firstName, lastName });
    setInputErr(result);
    if (result.length != 0) {
      return toast.warning("Please fill the highlighted fields!");
    }

    setSubmitLoader(true);
    //
    const payload = {
      firstName,
      id,
      lastName,
      gender: gender === "Select" ? "" : gender,
      state,
      email,
      city,
      dateOfBirth,
      address,
      currentPosition,
      description,
      mobileNumber,
    };

    try {
      let response = await dispatch(studentEditProfileItem(payload));

      if (response.payload.isSuccessful) {
        setInputErr([]);
        setUserData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          address: "",
          state: "",
          city: "",
          dob: "",
          gender: "",
          description: "",
          currentPosition: "",
        });
        setSubmitLoader(false);
        history.push("/FullProfile");
        return toast.success("Edit Successfully");
      }
      setSubmitLoader(false);
      // history.push("/studentFullProfile");
      setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (error) {}

    //  else {
    //   toast.error("Firstname, Lastname are mandatory!");
    // }
  };

  //professional
  const submitPro = async (e) => {
    e.preventDefault();
    const result = checkEmpty({ firstName, lastName });
    setInputErr(result);
    if (result.length != 0) {
      return toast.warning("Please fill the highlighted fields!");
    }
    setSubmitLoader(true);
    //
    const payload = {
      firstName,
      id,
      lastName,
      gender: gender === "Select" ? "" : gender,
      state,
      email,
      city,
      dateOfBirth,
      address,
      currentPosition,
      description,
      mobileNumber,
    };
    // console.log("payload: ", payload);
    try {
      let response = await dispatch(professionalEditProfileItem(payload));

      if (response.payload.isSuccessful) {
        toast.success("Edit Successfully");
        setInputErr([]);
        setUserData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          address: "",
          state: "",
          city: "",
          dob: "",
          gender: "",
          description: "",
          currentPosition: "",
        });
        setSubmitLoader(false);
        return history.push("/FullProfile");
      }
      setInputErr([]);
      setSubmitLoader(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {}
  };

  //upload profile pic
  const uploadProfilePicFunction = async () => {
    const payload = { id, image };
    console.log("payload: ", payload);
    try {
      setImageLoader(true);
      let response =
        userType === "student"
          ? await dispatch(studentUploadProfilePicItem(payload))
          : await dispatch(professionalUploadProfilePicItem(payload));
      if (response.payload.isSuccessful) {
        toast.success("Uploaded Successfully");
        setImageLoader(false);
      }

      setImageLoader(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {}
  };

  const uploadImage = async (e) => {
    setImageLoader(true);
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", uploadImagePreset);
    setLoading(true);
    const res = await fetch(
      cloudinaryImageLink,
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();

    setImage(file.secure_url);
    setImageLoader(false);
  };
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    address,
    state,
    currentPosition,
    city,
    dateOfBirth,
    gender,
    description,
  } = userData;
  if (loggedIn === false) {
    return <Redirect to="/" />;
  }

  if (userType === "student") {
    return (
      <>
        <section className="midcontentsection" >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-1  col-lg-1 pl-0">
                {/* <LeftSideBar /> */}
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="profilebox text-center p-5 my-5">
                  <div className="file-upload">
                    <div className="image-upload-wrap rounded p-3 border position-relative" style={{ background: `url(${image})` }} data-aos="zoom-in">
                      <div
                        htmlFor="upload"
                        className=" font-size-18 bg-primary text-light mt-n3 mx-0 upload-icon rounded-circle d-flex align-items-center justify-content-center mx-auto"
                      >
                        {" "}
                        <i className="fa fa-pencil text-white"></i>

                      </div>
                      <input
                        className=""
                        type="file"
                        id="upload"
                        onChange={uploadImage}
                        // accept="image/*"
                      />
                      {/* <img
                        className="card-img-top"
                        src={image}
                        style={{
                          maxHeight: "100%",
                          borderRadius: "4%",
                        }}
                      /> */}
                    </div>

                    <div className="drag-text">
                      {/* <!-- <h3>Drag and drop a file or select add Image</h3> --> */}
                    </div>
                    {/* <div>
                      
                      <input
                        className=""
                        type="file"
                        id="upload"
                        style={{ display: "none" }}
                        onChange={uploadImage}
                        // accept="image/*"
                      />
                    </div> */}
                    <div className="mt-3 mb-2">
                      <button
                        className="btn font-size-18 btn-secondary my-3 mx-0"
                        onClick={uploadProfilePicFunction}
                        data-aos="zoom-in" data-aos-duration="2000"
                      >
                        {" "}
                        Upload image
                      </button>
                    </div>
                    {imageLoader && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                  <div></div>
                  <h3 className="mb-4 border-bottom text-left pb-3 border-secondary" data-aos="fade-left" data-aos-duration="2000">
                    Student Details
                  </h3>

                  <form action="">
                    <div className="row text-left">
                      <div className="col-md-6">
                        <div className="form-group " data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={change}
                            value={firstName}
                            className={`form-control form-control-lg ${
                              inputErr.includes("firstName") ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group " data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={change}
                            value={lastName}
                            className={`form-control form-control-lg ${
                              inputErr.includes("lastName") ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group " data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            disabled
                            onChange={change}
                            value={email}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group " data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Phone Number{" "}
                          </label>
                          <input
                            type="phone"
                            name="mobileNumber"
                            disabled
                            onChange={change}
                            value={mobileNumber}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Address{" "}
                          </label>

                          <input
                            name="address"
                            rows="4"
                            onChange={change}
                            value={address}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            State
                          </label>
                          <input
                            type="text"
                            name="state"
                            onChange={change}
                            value={state}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            onChange={change}
                            value={city}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Date Of Birth
                          </label>
                          <input
                            type="Date"
                            onKeyPress={(e) => {
                              e.preventDefault();
                            }}
                            name="dateOfBirth"
                            onChange={change}
                            value={dateOfBirth}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Select Gender{" "}
                          </label>
                          <select
                            name="gender"
                            value={gender}
                            onChange={change}
                            className="form-control form-control-lg"
                            id=""
                          >
                            <option disabled value="Select">
                              Select
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group" data-aos="fade-right" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            Current Position{" "}
                          </label>

                          <input
                            name="currentPosition"
                            onChange={change}
                            minLength={20}
                            value={currentPosition}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group" data-aos="zoom-in" data-aos-duration="2000">
                          <label className="font-size-18" for="">
                            About Description{" "}
                          </label>

                          <textarea
                            name="description"
                            rows="4"
                            minLength={200}
                            onChange={change}
                            value={description}
                            className="form-control form-control-lg"
                          ></textarea>
                          {/* {description.length >= 150 ? (
                            ""
                          ) : (
                            <p className="text-danger m-2">
                              Minimum 150 Characters
                            </p>
                          )} */}
                        </div>
                      </div>

                      <div className="col-12 text-right" data-aos="zoom-in" data-aos-duration="1000">
                        <button
                          onClick={submit}
                          className="btn btn-warning form-control-lg text-white h6 mt-sm-4 mt-3 mb-2 px-5"
                        >
                          Submit{" "}
                          {submitLoader && (
                            <div
                              className="spinner-border text-center"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </button>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else if (userType === "professional") {
    return (
      <>
        <section className="midcontentsection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-1  col-lg-1 pl-0">
                {/* <LeftSideBar /> */}
              </div>

              <div className="col-xl-10 col-lg-9">
                <div className="profilebox text-center p-5 my-5">
                  <div className="file-upload ">
                  <div className="image-upload-wrap rounded p-3 border position-relative" style={{ background: `url(${image})` }}>
                      <div
                        htmlFor="upload"
                        className=" font-size-18 bg-primary text-light mt-n3 mx-0 upload-icon rounded-circle d-flex align-items-center justify-content-center mx-auto"
                      >
                        {" "}
                        <i className="fa fa-plus text-white"></i>

                      </div>
                      <input
                        className=""
                        type="file"
                        id="upload"
                        onChange={uploadImage}
                        // accept="image/*"
                      />
                      {/* <img
                        className="card-img-top"
                        src={image}
                        style={{
                          maxHeight: "100%",
                          borderRadius: "4%",
                        }}
                      /> */}
                    </div>
                    {/* <div
                      className="image-upload-wrap rounded p-3 border"
                      style={{ background: `url(${image})` }}
                    >
                      <img
                        className="card-img-top"
                        src={image}
                        style={{
                          maxHeight: "100%",
                          borderRadius: "4%",
                        }}
                      />
                    </div> */}
                    <div className="drag-text">
                      {/* <!-- <h3>Drag and drop a file or select add Image</h3> --> */}
                    </div>
                    {/* <div>
                      <label
                        htmlFor="upload"
                        className="btn font-size-18 bg-primary text-light my-3 mx-0"
                      >
                        {" "}
                        Choose image
                      </label>
                      <input
                        className=""
                        type="file"
                        id="upload"
                        style={{ display: "none" }}
                        onChange={uploadImage}
                        // accept="image/*"
                      />
                    </div> */}
                    <div className="mt-3 mb-2">
                      <button
                        className="btn font-size-18 bg-primary text-light my-3 mx-0"
                        onClick={uploadProfilePicFunction}
                      >
                        {" "}
                        Upload image
                      </button>
                    </div>
                    {imageLoader && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>

                  <h3 className="mb-4 border-bottom text-left pb-3 border-secondary">
                    Student Details
                  </h3>

                  <form action="">
                    <div className="row text-left">
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={change}
                            value={firstName}
                            className={`form-control form-control-lg ${
                              inputErr.includes("firstName") ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={change}
                            value={lastName}
                            className={`form-control form-control-lg ${
                              inputErr.includes("lastName") ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            disabled
                            onChange={change}
                            value={email}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group ">
                          <label className="font-size-18" for="">
                            Phone Number{" "}
                          </label>
                          <input
                            type="phone"
                            name="mobileNumber"
                            disabled
                            onChange={change}
                            value={mobileNumber}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Address{" "}
                          </label>

                          <input
                            name="address"
                            rows="4"
                            onChange={change}
                            value={address}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            State
                          </label>
                          <input
                            type="text"
                            name="state"
                            onChange={change}
                            value={state}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            onChange={change}
                            value={city}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Date Of Birth
                          </label>
                          <input
                            type="Date"
                            onKeyPress={(e) => {
                              e.preventDefault();
                            }}
                            name="dateOfBirth"
                            onChange={change}
                            value={dateOfBirth}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Select Gender{" "}
                          </label>
                          <select
                            name="gender"
                            value={gender}
                            onChange={change}
                            id="gender"
                            className="form-control form-control-lg"
                          >
                            <option value="Select">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            Current Position{" "}
                          </label>

                          <input
                            name="currentPosition"
                            onChange={change}
                            minLength={20}
                            value={currentPosition}
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="font-size-18" for="">
                            About Description{" "}
                          </label>

                          <textarea
                            name="description"
                            rows="4"
                            minLength={200}
                            onChange={change}
                            value={description}
                            className="form-control form-control-lg"
                          ></textarea>
                          {/* {description.length >= 150 ? (
                            ""
                          ) : (
                            <p className="text-danger m-2">
                              Minimum 150 Characters
                            </p>
                          )} */}
                        </div>
                      </div>

                      <div className="col-12 text-right">
                        <button
                          onClick={submitPro}
                          className="btn btn-warning form-control-lg text-white h6 mt-sm-4 mt-3 mb-2 px-5"
                          // type="button"
                          
                        >
                            Submit
                          {submitLoader && (
                            <span
                              className="ml-1 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        
                        </button>
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default StudentProfile3Edit;
