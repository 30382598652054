import Modal from "react-modal";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkEmailExistenceItem,
  emptyEmailOtp,
} from "../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import LoginModal from "../AuthPages/Login-modal";
import {
  changeOpenLoginModal,
  // hrHireStudentItem,
} from "../../data/reducers/auth.reducer";
//*********************************** */

const Model = ({ setEmailVerfied, setUserEmail, userEmail, campusCode }) => {
  const { campusId, collegeName } = useParams();
  const dispatch = useDispatch();

  const [modalIsOpen, setIsOpen] = useState(false);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [verifyEmail, setVerifyEmail] = useState();

  const emailOtp = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).emailOtp;

  const otpTimeOut = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).otpTimeOut;

  const handleChange = (e) => {
    const { value } = e.target;
    setUserEmail(value);
  };

  function OpenModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const checkEmailExistenceApi = async () => {
    try {
      if (userEmail === "") return toast.error("Email is required.");
      setLoading(true);
      const payload = { userEmail, campusId };
      // console.log("payload: ", payload);
      let response = await dispatch(checkEmailExistenceItem(payload));
      // console.log('response: ', response);

      if (response.payload.isSuccessful) {
        setLoading(false);

        if (response.payload.data.message === "VALID") {
          toast.success("Enter OTP sent to this email.");
          // setEmailVerfied(true);
        }

        return;
      } else if (!response.payload.isSuccessful) {
        if (response.payload.message.data.message === "INVALID") {
          toast.error("Please enter the registered email.");
        } else {
          toast.error(response.payload.message.data.message);
        }
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  return (
    <>
      <button
        className="btn btn-primary px-3 py-0 m-0 admin-form-btn"
        style={{ fontSize: "10x", borderRadius: "25px" }}
        onClick={() => {
          if (!loggedIn) {
            toast.success("Please login first.");
            localStorage.setItem(
              "campusRoutePage",
              `/user/campus/registration/${collegeName}/${campusId}?campusCode=${campusCode}`
            );
            dispatch(changeOpenLoginModal({ modalVal: true }));
            // <LoginModal purpose="event"></LoginModal>
            // history.push("/login");
            return;
          } else {
            OpenModal();
          }
        }}
      >
        Register
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 200000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            width: "300px",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            // width:"f",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-start">
            <div className="">
              <h5 className=" font-weight-bold">
                Please verify your email first
              </h5>
            </div>
            <div className="input-group  mt-3 mb-3">
              <label for="">Enter your college registered email</label>
              <input
                type="text"
                onChange={handleChange}
                className="form-control col-12"
                placeholder="Email"
                aria-label="Username"
                value={userEmail}
                aria-describedby="basic-addon1"
              />
            </div>
            {emailOtp && emailOtp !== "" && (
              <div className="input-group mb-3 ">
                <label for="">Enter OTP</label>
                <input
                  type="text"
                  onChange={(e) => {
                    if (e.target.value) {
                      setVerifyEmail(e.target.value);
                      // setEmailVerfied(true);
                    }
                  }}
                  className="form-control col-12"
                  placeholder="Enter OTP."
                />
                <span className="text-primary col-12">
                  OTP is valid for only 2 min.
                  {/* {setTimeout(() => { ""}, 1000)} */}
                </span>
              </div>
            )}
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="col-12 p-0">
                {emailOtp && emailOtp !== "" ? (
                  <button
                    disabled={loading || verifyEmail?.length !== 4}
                    className="btn m-1 text-white btn-primary"
                    onClick={() => {
                      if (otpTimeOut) {
                        const hour = otpTimeOut.split(":")[0];
                        const min = otpTimeOut.split(":")[1];
                        const currentHour = new Date(Date.now()).getHours();
                        const currentMin = new Date(Date.now()).getMinutes();
                        if (hour != currentHour) {
                          toast.success("OTP expired, please try again.");
                          dispatch(emptyEmailOtp({}));
                          return;
                        } else if (currentMin > min) {
                          toast.success("OTP expired, please try again.");
                          dispatch(emptyEmailOtp({}));
                          return;
                        }
                      }
                      if (emailOtp == verifyEmail) {
                        setEmailVerfied(true);
                        toast.success("Email successfully verified");
                        dispatch(emptyEmailOtp({}));
                        return;
                      } else {
                        toast.error("Invalid OTP");
                      }
                    }}
                  >
                    Verify OTP
                    {loading && (
                      <span
                        class="spinner-border ml-1 spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <button
                    disabled={loading}
                    className="btn m-1 text-white btn-primary"
                    onClick={() => {
                      checkEmailExistenceApi();
                    }}
                  >
                    Check
                    {loading && (
                      <span
                        class="spinner-border ml-1 spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                )}

                <button
                  className="btn text-white m-1 btn-danger"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={() => {
                    dispatch(emptyEmailOtp({}));
                    setVerifyEmail();
                    closeModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Model;
