import React, { useEffect } from "react";
import { Link, NavLink, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  studentListAdminItem,
  hrListAdminItem,
  professionalListAdminItem,
} from "../../../data/reducers/admin.reducer";

const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const studentList = useSelector((state) => state.adminReducer).studentList;
  const profList = useSelector((state) => state.adminReducer).professionalList;
  const hrList = useSelector((state) => state.adminReducer).hrList;
  const totalCountsForHomePage = useSelector(
    (state) => state.adminReducer
  ).totalCountsForHomePage;

  const goPrevPage = () => {
    history.goBack();
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (
    loggedIn === true &&
    usertype !== "admin" &&
    usertype !== "technical-operation"
  ) {
    return <Redirect to="/"></Redirect>;
  } else if (
    (loggedIn === true && usertype === "admin") ||
    usertype === "technical-operation"
  ) {
    return (
      <div className="bg-light" style={{ marginTop: "70px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 px-sm-5">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="p-0 text-left text-dark pb-3 pt-4 Fweight-600">
                  Users
                </h4>
                <div onClick={goPrevPage} class="btn btn-primary">
                  Back
                </div>
              </div>
              <div className="bg-white admin-card rounded-lg p-4 d-flex justify-content-center flex-wrap align-items-stretch mb-5 py-5">
                <NavLink
                  to="/admin/users/Students"
                  className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                >
                  <div className="">
                    <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                      Students
                    </h4>
                    <p className="d-flex justify-content-between">
                      <span>TYP-STUDENTS:</span>{" "}
                      <span className="text-primary col-2 pr-0">
                        {" "}
                        {totalCountsForHomePage &&
                          totalCountsForHomePage !== "" &&
                          totalCountsForHomePage?.totalUsersCount[0]
                            ?.typStudents}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between">
                      <span>NON-TYP-STUDENTS:</span>{" "}
                      <span className="text-primary col-2 pr-0">
                        {" "}
                        {totalCountsForHomePage &&
                          totalCountsForHomePage !== "" &&
                          totalCountsForHomePage?.totalUsersCount[0]
                            ?.nonTypStudents}
                      </span>
                    </p>
                    <p className="d-flex justify-content-between border-top mb-0 pt-3">
                      <span>Student:</span>{" "}
                      <span className="text-primary col-2 pr-0">
                        {totalCountsForHomePage &&
                          totalCountsForHomePage !== "" &&
                          totalCountsForHomePage?.totalUsersCount[0]
                            ?.typStudents +
                            totalCountsForHomePage?.totalUsersCount[0]
                              ?.nonTypStudents}
                      </span>
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/admin/Professionals"
                  className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                >
                  <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                    Professionals
                  </h4>
                  <div className=" d-flex align-items-center justify-content-center h-100">
                    <p className="d-flex flex-column align-items-center">
                      <span className="display-4 text-primary font-weight-bold">
                        {totalCountsForHomePage &&
                          totalCountsForHomePage !== "" &&
                          totalCountsForHomePage?.totalProfessionalCount}
                      </span>
                      Total
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to="/admin/hr"
                  className=" card p-3 mr-3 mb-3 font-weight-bold text-decoration-none text-dark"
                >
                  <h4 className="border-bottom pb-3 pt-2 mb-3 text-center">
                    Recruiter
                  </h4>
                  <div className=" d-flex align-items-center justify-content-center h-100">
                    <p className="d-flex flex-column align-items-center">
                      <span className="display-4 text-primary font-weight-bold">
                        0
                      </span>
                      Total
                    </p>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Users;
