import React from "react";
import Background from "../../assets/images/abtbg.png";
import { Link } from "react-router-dom";
// import "../../shared/styles/styles.css"

const Profile2 = () => {
  return (
    <div>
      <div>
      <section className="innerbanner " style={{ background: `url(${Background}) top center no-repeat` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center text-white">
              <h1 className="display-4 K-font Fweight-500">Student Projects</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to='/'>Student</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Projects</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
        
        <section>
          <div className="container">
            <div className="row py-5 mt-lg-5">
              <div className="col-lg-8">
                <div className>
                  <img src="images/1.jpg" className="img-fluid" />
                  <div className="d-flex font-size-14 text-dark py-4 justify-content-between text-secondary">
                    <p>
                      <i className="mb-0 mr-2 text-dark fa fa-calendar-alt" />
                      August 10,2020 | 10.20AM
                    </p>
                    <p>
                      <i className="mb-0 mr-2 text-dark fa fa-eye" />
                      180 views
                    </p>
                  </div>
                  <h2 className="text-dark Fweight-500">
                    Himanshu <span className="text-secondary">Created</span>{" "}
                    React <span className="text-secondary">Project</span>
                  </h2>
                  <div className="d-flex">
                    <button className="btn btn-gray mr-2 btn-sm text-dark font-weight-bold rounded-lg">
                      React
                    </button>
                    <button className="btn btn-gray btn-sm text-dark font-weight-bold rounded-lg">
                      Node.JS
                    </button>
                  </div>
                  <p className="text-secondary line-H-2 Fweight-normal py-4 font-weight-normal">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <button className="btn btn-primary text-uppercase font-weight-bold font-size-12 px-4 py-2">
                    Read more
                  </button>
                </div>
                <div className="py-md-5 py-4">
                  <img src="images/2.jpg" className="img-fluid" />
                  <div className="d-flex font-size-14 text-dark py-4 justify-content-between text-secondary">
                    <p>
                      <i className="mb-0 mr-2 text-dark fa fa-calendar-alt" />
                      August 10,2020 | 10.20AM
                    </p>
                    <p>
                      <i className="mb-0 mr-2 text-dark fa fa-eye" />
                      180 views
                    </p>
                  </div>
                  <h2 className="text-dark Fweight-500">
                    Himanshu <span className="text-secondary">Created</span>{" "}
                    React <span className="text-secondary">Project</span>
                  </h2>
                  <div className="d-flex">
                    <button className="btn btn-gray mr-2 btn-sm text-dark font-weight-bold rounded-lg">
                      React
                    </button>
                    <button className="btn btn-gray btn-sm text-dark font-weight-bold rounded-lg">
                      Node.JS
                    </button>
                  </div>
                  <p className="text-secondary line-H-2 Fweight-normal py-4 font-weight-normal">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </p>
                  <button className="btn btn-primary text-uppercase font-weight-bold font-size-12 px-4 py-2">
                    Read more
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <h6 className="border-bottom pb-3 font-weight-bold mb-4">
                  Search
                </h6>
                <div className="input-group input-group-lg search">
                  <input
                    type="text"
                    className="form-control rounded-0 border-0"
                    placeholder="Search"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                  />
                </div>
                <h6 className="border-bottom pb-3 font-weight-bold my-4 pt-md-5 mt-md-5">
                  Categories
                </h6>
                <div className="d-flex justify-content-between h6 font-size-16 text-secondary Fweight-500 mb-3">
                  <span>React</span>
                  <span>(4)</span>
                </div>
                <div className="d-flex justify-content-between h6 font-size-16 text-secondary Fweight-500 mb-3">
                  <span>Node.JS</span>
                  <span>(5)</span>
                </div>
                <div className="d-flex justify-content-between h6 font-size-16 text-secondary Fweight-500 mb-3">
                  <span>HTML</span>
                  <span>(9)</span>
                </div>
                <div className="d-flex justify-content-between h6 font-size-16 text-secondary Fweight-500 mb-3">
                  <span>JavaScript</span>
                  <span>(3)</span>
                </div>
                <div className="d-flex justify-content-between h6 font-size-16 text-secondary Fweight-500 mb-3">
                  <span>jQuery</span>
                  <span>(6)</span>
                </div>
                <h6 className="border-bottom pb-3 font-weight-bold mb-4 mt-md-5">
                  Most Viewed Posts
                </h6>
                <div className>
                  <div className="media mb-3">
                    <img
                      src="images/ede.jpg"
                      className="mr-3"
                      alt="..."
                      width={90}
                    />
                    <div className="media-body text-secondary">
                      Lorem Ipsum is simply dummy text of the printing and...
                      <br />
                      <div className="text-dark font-size-14">
                        <i className="mb-0 mr-2  fa fa-eye mt-2" />
                        180 views
                      </div>
                    </div>
                  </div>
                  <div className="media mb-3">
                    <img
                      src="images/zasw.jpg"
                      className="mr-3"
                      alt="..."
                      width={90}
                    />
                    <div className="media-body text-secondary">
                      Lorem Ipsum is simply dummy text of the printing and...
                      <br />
                      <div className="text-dark font-size-14">
                        <i className="mb-0 mr-2  fa fa-eye mt-2" />
                        180 views
                      </div>
                    </div>
                  </div>
                  <div className="media mb-3">
                    <img
                      src="images/qwsxa.jpg"
                      className="mr-3"
                      alt="..."
                      width={90}
                    />
                    <div className="media-body text-secondary">
                      Lorem Ipsum is simply dummy text of the printing and...
                      <br />
                      <div className="text-dark font-size-14">
                        <i className="mb-0 mr-2  fa fa-eye mt-2" />
                        180 views
                      </div>
                    </div>
                  </div>
                  <div className="media mb-3">
                    <img
                      src="images/xqax.jpg"
                      className="mr-3"
                      alt="..."
                      width={90}
                    />
                    <div className="media-body text-secondary">
                      Lorem Ipsum is simply dummy text of the printing and...
                      <br />
                      <div className="text-dark font-size-14">
                        <i className="mb-0 mr-2  fa fa-eye mt-2" />
                        180 views
                      </div>
                    </div>
                  </div>
                  <div className="media mb-3">
                    <img
                      src="images/xwxasca.jpg"
                      className="mr-3"
                      alt="..."
                      width={90}
                    />
                    <div className="media-body text-secondary">
                      Lorem Ipsum is simply dummy text of the printing and...
                      <br />
                      <div className="text-dark font-size-14">
                        <i className="mb-0 mr-2  fa fa-eye mt-2" />
                        180 views
                      </div>
                    </div>
                  </div>
                </div>
                <a className="text-primary h6 text-center d-block">
                  <u>View All</u>
                </a>
              </div>
              <div className="col-12">
                <div className="pagination w-100 d-flex justify-content-center">
                  <div className="numbers">
                    <button
                      className="link disabled bg-dark px-sm-4 px-1 text-white border-0"
                      disabled
                    >
                      Preview
                    </button>
                    <button className="active">1</button>
                    <button>2</button>
                    <button>3</button>
                    <button>4</button>
                    <button>5</button>
                    <button className="link text-white btn-warning btn-wrng m-0 px-sm-4 px-1 border-0">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>
    </div>
  );
};

export default Profile2;
