import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchRedeemRequestUserItem } from "../../../../data/reducers/referral.reducer";
import { toast } from "react-toastify";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
  },
};

const RedeemHistoryModal = ({ actions, index, req }) => {
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <tr onClick={openModal}>
        <th >{index}</th>
        <td>{new Date(req.createdAt).toLocaleString()}</td>
        <td  className="text-center font-weight-bold">{req.pointsRequestedForRedemption}</td>
        <td className="text-center font-weight-bold">{req.pointsAcceptedForReward}</td>
        <td className="text-left">{req.status}</td>
      </tr>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h5 className="py-2">Actions of Redeem Requests</h5>
        <table className="table table-hover rwd-table mb-5">
          <thead className="btn-warning">
            <tr>
              <th className="text-white" >
                #
              </th>
              <th className="text-white" >
                Last Updated
              </th>
              <th className="text-white" >
                Description
              </th>
              <th className="text-white" >
                Action Points
              </th>
              <th className="text-white" >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {actions?.map((action, indx) => {
              return (
                <tr key={indx}>
                  <td data-th="#">{indx + 1}</td>
                  <td data-th="Last Updated">{new Date(action.updatedAt).toLocaleString()}</td>
                  <td data-th="Description">
                    {action.actionStatus.description
                      ? action.actionStatus.description
                      : "---"}
                  </td>
                  <td data-th="Action Points" className="font-weight-bold text-center">{action.actionPoints}</td>
                  <td data-th="Status" className="text-left">{action.actionStatus.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="row">
          <div className="col-md">
            <button className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RedeemHistoryModal;
