import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import studentReducer from "./student.reducer";
import adminReducer from "./admin.reducer";
import homeReducer from "./home.reducer";
import professionalReducer from "./professional.reducer";
import referralReducer from "./referral.reducer";
import badgesAdminReducer from "./admin/badges/badges-admin.reducer";
import batchAdminReducer from "./admin/batches/batches-admin.reducer";
import rolesAdminReducer from "./admin/user-types/usertypes-admin.reducer";
import campusPlacementAdminReducer from "./admin/campus-placement Drive/campusPlacementDrive.reducer";

const rootReducer = combineReducers({
  authReducer,
  studentReducer,
  adminReducer,
  homeReducer,
  referralReducer,
  professionalReducer,
  badgesAdminReducer,
  batchAdminReducer,
  rolesAdminReducer,
  campusPlacementAdminReducer,
});

export default rootReducer;
