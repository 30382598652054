import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// import FormData from "form-data";

import {
  addQuestionItem,
  editQuestionItem,
} from "../../../../data/reducers/admin/campus-placement Drive/question.reducer";

//************************************* */
export const QuestionForm = (props) => {
  const ref = useRef();
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const [loading, setLoading] = useState(false);
  const [fileImage, setFileImage] = useState();

  const [displayImage, setDisplayImage] = useState();
  const dispatch = useDispatch();

  let history = useHistory();
  const [questionData, setQuestionData] = useState({
    title: "",
    picture: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    correctAnswer: "",
    marks: "",
    time: "",
    category: "",
    level: "",
  });

  useEffect(() => {
    if (!loggedIn) {
      return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      // console.log(
      //   "props?.location?.state?.data: ",
      //   props?.location?.state?.data
      // );
      if (props?.location?.state?.data?.item) {
        setQuestionData({
          ...questionData,
          title: props?.location?.state?.data?.item?.title,
          picture: props?.location?.state?.data?.item?.picture,
          optionA: props?.location?.state?.data?.item?.optionA,
          optionB: props?.location?.state?.data?.item?.optionB,
          optionC: props?.location?.state?.data?.item?.optionC,
          optionD: props?.location?.state?.data?.item?.optionD,
          correctAnswer: props?.location?.state?.data?.item?.correctAnswer,
          marks: props?.location?.state?.data?.item?.marks,
          time: props?.location?.state?.data?.item?.time,
          category: props?.location?.state?.data?.item?.category,
          level: props?.location?.state?.data?.item?.level,
        });
        setDisplayImage(props?.location?.state?.data?.item?.picture);
      } else if (!props?.location?.state?.data?.item) {
        setQuestionData({
          title: "",
          picture: "",
          optionA: "",
          optionB: "",
          optionC: "",
          optionD: "",
          correctAnswer: "",
          marks: "",
          time: "",
          category: "",
          level: "",
        });
      }
    }
  }, [props]);

  const [loader, setloader] = useState();

  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setQuestionData({ ...questionData, [name]: value });
  };

  //add question
  const handleSubmit = async () => {
    try {
      // console.log(" questionData: ", questionData);
      if (
        questionData?.category === "Choose..." ||
        questionData?.category === ""
      ) {
        return toast.error("Please choose category.");
      }
      if (questionData?.level === "Choose..." || questionData?.level === "") {
        return toast.error("Please choose level.");
      }
      var bodyFormData = new FormData();

      for (const property in questionData) {
        bodyFormData.append(property, questionData[property]);
      }
      if (fileImage) {
        bodyFormData.append("images", fileImage);
      }

      setLoading(true);
      const payload = bodyFormData;
      // console.log("payload: ", payload);
      let response = await dispatch(addQuestionItem(payload));
      // console.log("response: ", response);

      if (response.payload.isSuccessful) {
        toast.success("Added");
        setLoading(false);
        history.push("/admin/campusPlacementDrive/Questions");

        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  //edit question
  const editHandleSubmit = async () => {
    try {
      if (
        questionData?.category === "Choose..." ||
        questionData?.category === ""
      ) {
        return toast.error("Please choose category.");
      }
      if (questionData?.level === "Choose..." || questionData?.level === "") {
        return toast.error("Please choose level.");
      }
      var bodyFormData = new window.FormData();

      for (const property in questionData) {
        bodyFormData.append(property, questionData[property]);
      }

      bodyFormData.append(
        "questionId",
        props?.location?.state?.data?.item?._id
      );

      if (fileImage) {
        let myFile = fileImage.name.split(".");

        const fileType = myFile[myFile.length - 1];

        const $fileName = fileImage.name.replace(fileType, "").slice(0, -1);

        if ($fileName !== questionData?.pictureOriginalName) {
          bodyFormData.append("images", fileImage);
          bodyFormData.append("isImageChanged", true);
        } else {
          bodyFormData.append("isImageChanged", false);
        }
      }

      setLoading(true);
      const payload = bodyFormData;

      let response = await dispatch(editQuestionItem(payload));

      if (response.payload.isSuccessful) {
        toast.success("Edited");
        setLoading(false);
        history.push("/admin/campusPlacementDrive/Questions");

        return;
      } else if (!response.payload.isSuccessful) {
        toast.error(response.payload.message.data.message);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {}
  };

  return (
    <div style={{ marginTop: "110px" }}>
      <div className="container">
        <div className="container-fluid m-3">
          <div className="row d-flex justify-content-center">
            {/* <div className="col-12">
              <h5 className="text-center font-weight-bold">Create Question</h5>
            </div> */}
            <div className="col-md-9">
              {" "}
              <h3 className="btn-secondary text-center p-2">
                {props?.location?.state?.data?.page} Question Form
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3 ">
            <div className="col-8 border p-3">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Title
                </label>
                <textarea
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="title"
                  value={questionData?.title}
                />
              </div>
              <div className="col-12 border p-3">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label font-weight-bold text-dark"
                  >
                    Choose Image
                  </label>
                  <input
                    type="file"
                    ref={ref}
                    className="mt-2 col-md-7"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setFileImage(e.target.files[0]);
                        const reader = new FileReader();
                        reader.readAsDataURL(e.target.files[0]);
                        reader.onloadend = () => {
                          // console.log("reader.result: ", reader.result);
                          setDisplayImage(reader.result);
                        };
                      }
                    }}
                    accept="image/png,  image/jpeg"
                  />

                  <img
                    src={displayImage}
                    alt="display_image"
                    width="400px"
                    height="400px"
                  />
                  {displayImage && (
                    <i
                      className="fa fa-close"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDisplayImage(undefined);
                        setQuestionData({ ...questionData, ["picture"]: null });
                      }}
                    ></i>
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Option A
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="optionA"
                  value={questionData?.optionA}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Option B
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="optionB"
                  value={questionData?.optionB}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Option C
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="optionC"
                  value={questionData?.optionC}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Option D
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="optionD"
                  value={questionData?.optionD}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Correct Answer{" "}
                  <span className="text-muted">(Format : "optionA")</span>
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="correctAnswer"
                  value={questionData?.correctAnswer}
                />
              </div>

              <div className="input-group mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Question Time
                </label>
                <input
                  onChange={handleAddChange}
                  type="text"
                  name="time"
                  className="form-control form-control-lg"
                  value={questionData?.time}
                />
                <span className="input-group-text">min</span>
              </div>
              <div className="input-group mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Marks
                </label>
                <input
                  onChange={handleAddChange}
                  value={questionData?.marks}
                  type="text"
                  name="marks"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="mb-3 ">
                {/* <div className="input-group-prepend"> */}
                <label
                  className="form-label font-weight-bold text-dark"
                  for="inputGroupSelect01"
                >
                  Category
                </label>
                {/* </div> */}
                <select
                  className="custom-select  form-label form-control form-control-lg "
                  id="inputGroupSelect01"
                  onChange={handleAddChange}
                  name="category"
                  // defaultValue=
                  value={questionData?.category}
                >
                  <option selected={questionData?.category === ""}>
                    Choose...
                  </option>
                  <option
                    selected={questionData?.category === "APTITUDE"}
                    value="APTITUDE"
                  >
                    APTITUDE
                  </option>
                  <option
                    selected={questionData?.category === "VERBAL_ABILITY"}
                    value="VERBAL_ABILITY"
                  >
                    VERBAL_ABILITY
                  </option>
                  <option
                    selected={questionData?.category === "CODING"}
                    value="CODING"
                  >
                    CODING
                  </option>
                </select>
              </div>

              <div className="mb-3 ">
                {/* <div className="input-group-prepend"> */}
                <label
                  className="form-label font-weight-bold text-dark"
                  for="inputGroupSelect02"
                >
                  Level
                </label>
                {/* </div> */}
                <select
                  className="custom-select  form-label form-control form-control-lg "
                  id="inputGroupSelect02"
                  onChange={handleAddChange}
                  name="level"
                  value={questionData?.level}
                >
                  <option selected>Choose...</option>
                  <option value="EASY">EASY</option>
                  <option value="MEDIUM">MEDIUM</option>
                  <option value="HARD">HARD</option>
                </select>
              </div>
              <div className="col-12 p-0 ">
                <button
                  disabled={loading}
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    if (props?.location?.state?.data?.page === "Add")
                      return handleSubmit();
                    else return editHandleSubmit();
                  }}
                >
                  Submit
                  {loading && (
                    <span
                      className="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
