import {
  api,
  crm_api,
  getAuthHeaders,
  get,
  post,
  update,
  del,
  patch,
} from "./services.common";

export const checkConnectionAPI = () => {
  return window.navigator.onLine;
};

export const loginAPI = async (userlogin) => {
  //path e.g.
  let url = `${api}/signin`;
  let authHeader = getAuthHeaders();
  return await post(url, userlogin, { ...authHeader });
};
export const AdminloginAPI = async (userlogin) => {
  //path e.g.
  let url = `${api}/adminSignin`;
  let authHeader = getAuthHeaders();
  return await post(url, userlogin, { ...authHeader });
};
export const signupAPI = async (userlogin) => {
  //another path e.g.
  let url = `${api}/signup`;
  let authHeader = getAuthHeaders();
  return await post(url, userlogin, { ...authHeader });
};
export const signupAPI2 = async (userlogin) => {
  //another path e.g.
  let url = `${api}/signup/part2`;
  let authHeader = getAuthHeaders();
  return await post(url, userlogin, { ...authHeader });
};
export const sendOTPAPI = async (mobileNumber) => {
  let url = `${api}/sendOTP/91${mobileNumber}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const verifyOTPAPI = async ({
  otp,
  mobileNumber,
  _id = "123",
  verifyFor = "SIGNUP",
}) => {
  let url = `${api}/verifyOTP/91${mobileNumber}/${otp}/${_id}?verifyFor=${verifyFor}`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const linkedinSignUpAPI = async (payload) => {
  let url = `${api}/exchange/code`;
  let authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};

export const linkedinLoginAPI = async (payload) => {
  let url = `${api}/exchange/code/login`;
  let authHeader = getAuthHeaders();
  return await post(url, payload, { ...authHeader });
};

export const studentListAPI = async () => {
  let url = `${api}/getAllStudents`;
  let authHeader = getAuthHeaders();
  return await get(url, { ...authHeader });
};

export const editprofileAPI = async (payload) => {
  const id = payload.id;

  let url = `${api}/editPersonalDetails/${id}`;

  let authHeader = getAuthHeaders();

  return await patch(url, payload, { ...authHeader });
};
export const uploadProfilePicAPI = async (payload) => {
  const { id, image } = payload;

  let url = `${api}/student/uploadProfilePic/${id}`;

  let authHeader = getAuthHeaders();

  return await patch(url, { image }, { ...authHeader });
};

export const editTypProfileAPI = async (payload) => {
  const id = payload.id;

  let url = `${api}/editProfile/${id}`;

  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};

export const editProTypProfileAPI = async (payload) => {
  const id = payload.id;

  let url = `${api}/editProProfile/${id}`;

  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};

//edit project
export const editProjectAPI = async (payload) => {
  const id = payload.id;

  let url = `${api}/student/editProject/${id}`;

  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};

export const editProProjectAPI = async (payload) => {
  const id = payload.id;

  let url = `${api}/pro/editProject/${id}`;

  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};
//add project
export const addProjectAPI = async (payload) => {
  let url = `${api}/student/addProject`;
  //
  let authHeader = getAuthHeaders();

  // delete payload.id
  return await post(url, payload, { ...authHeader });
};
export const addProProjectAPI = async (payload) => {
  let url = `${api}/pro/addProject`;
  //
  let authHeader = getAuthHeaders();

  // delete payload.id
  return await post(url, payload, { ...authHeader });
};
//delete fields
//add project
export const deleteStudentProfileDetailsAPI = async (payload) => {
  const { field, deleteId } = payload;
  let url = `${api}/deleteProfileDetails/${field}/${deleteId}`;
  //
  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};
export const deleteProfessionalProfileDetailsAPI = async (payload) => {
  const { field, deleteId } = payload;
  let url = `${api}/deleteProProfileDetails/${field}/${deleteId}`;
  //
  let authHeader = getAuthHeaders();

  // delete payload.id
  return await patch(url, payload, { ...authHeader });
};

export const forgotPassowrdAPI = async (payload) => {
  let url = `${api}/forgot/password`;
  //
  let authHeader = getAuthHeaders();

  // delete payload.id
  return await post(url, payload, {});
};

export const confirmationAPI = async (payload) => {
  let url = `${api}/confirmation`;
  return await post(url, payload, {});
};

export const studentHirableAPI = async (payload) => {
  // let _id = payload.id;
  // let hiringstatus = payload.hiringstatus;
  let url = `${api}/hirable`;

  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const professionalHirableAPI = async (payload) => {
  // let _id = payload.id;
  // let hiringstatus = payload.hiringstatus;
  let url = `${api}/hireableProfessional`;

  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const hrHireStudentAPI = async (payload) => {
  let url = `${api}/hireStudent/${payload.studentId}/${payload.hrId}/?hiring=${payload.hiring}`;

  let authHeader = getAuthHeaders();
  return await patch(url, { ...authHeader });
};

export const hrHireProfessionalAPI = async (payload) => {
  let url = `${api}/hireProfessional/${payload.professionalId}/${payload.hrId}/?hiring=${payload.hiring}`;

  let authHeader = getAuthHeaders();
  return await patch(url, { ...authHeader });
};

export const editprofileAPIProfessional = async (payload) => {
  const id = payload.id;
  let url = `${api}/editProfessionalProfile/${id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, payload, { ...authHeader });
};

export const uploadProfilePicAPIProfessional = async (payload) => {
  const { id, image } = payload;
  let url = `${api}/professional/uploadProfilePic/${id}`;
  let authHeader = getAuthHeaders();
  return await patch(url, { image }, { ...authHeader });
};

export const contestSubmissionAPI = async (payload) => {
  let url = `${api}/submitContest/${payload.userId}/${payload.id}`;
  const data = {
    url: payload.url,
  };
  let authHeader = getAuthHeaders();
  return await post(url, data, { ...authHeader });
};

export const addStudentToUpcomingPoolAPI = async (payload) => {
  const { pool_id, student_details } = payload;

  let url = `${crm_api}/upcomingPool/addLead/${pool_id}`;
  return await post(url, { ...student_details });
};

export const updateStudentToUpcomingPoolAPI = async (payload) => {
  const { typ_id, extra_detail } = payload;

  let url = `${crm_api}/updatePool/lead/${typ_id}`;
  return await patch(url, { ...extra_detail });
};

export const getActiveEventsAPI = async ({ isSpecialEvent }) => {
  let url = `${api}/getActiveEvents?isSpecialEvent=${isSpecialEvent}`;
  return await get(url);
};
