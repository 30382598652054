import React, { useState } from "react";
import Modal from "react-modal";
import { CreateBadgeAdminItem } from "../../../../data/reducers/admin/badges/badges-admin.reducer";
import { constants } from "../../../../config";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { checkEmpty } from "../../../../shared/Utils/method";

const Model = () => {
  //
  const { cloudinaryImageLink, uploadPresetImage } = constants;
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  // const [inputErr, setInputErr] = useState([]);
  const [loader, setloader] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  //   init state of  form
  const [createBadge, setCreateBadge] = useState({
    name: "",
    points: null,
    icon: "",
  });

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle add change
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setCreateBadge({ ...createBadge, [name]: value });
  };

  // handle submit
  const handleSubmit = async () => {
    // const res = checkEmpty(editBadges);

    // setInputErr(res);
    // if (res.length != 0) {
    //   return toast.warning("All fields are mandatory!");
    // }
    if (createBadge.icon === "") {
      return toast.error("Please upload the icon!");
    }
    const payload = { ...createBadge };
    // console.log("payload: ", payload);
    try {
      setloader(true);
      const response = await dispatch(CreateBadgeAdminItem(payload));
      if (response.payload.isSuccessful) {
        document.getElementById("iconupload").value = "";
        setloader(false);
        closeModal();
        // setInputErr([]);
        return toast.success("Added successfully!");
      }
      setloader(false);
      // setInputErr([]);
      toast.error(response.payload.message.data.message);
    } catch (err) {}
  };

  // upload icon (add)
  const uploadIcon = async (e) => {
    const files = e.target.files;
    // console.log("files: ", files);
    if (!files[0]) {
      return toast.error("No file Selected");
    }

    const data = new FormData();
    data.append("file", files[0]);

    data.append("upload_preset", uploadPresetImage); //dynamic
    setImageLoading(true);

    const res = await fetch(cloudinaryImageLink, {
      method: "POST",
      body: data,
    });

    const file = await res.json();

    //
    setCreateBadge({ ...createBadge, ["icon"]: file.secure_url });
    setImageLoading(false);

    if (file.secure_url !== "") {
      toast.success("Image Uploaded");
    } else {
      toast.error("Image Not Uploaded");
    }
  };

  return (
    <div className="container">
      <button
        onClick={() => {
          OpenModal();
        }}
        className="btn btn-primary"
      >
        Add Badge
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Edit Badges</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Name
                </label>
                <input
                  disabled={loader || imageLoading}
                  onChange={handleAddChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="name"
                  value={createBadge?.name}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Points
                </label>
                <input
                  disabled={loader || imageLoading}
                  onChange={handleAddChange}
                  type="number"
                  className={`form-control form-control-lg `}
                  name="points"
                  value={createBadge?.points}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Upload icon{" "}
                  {imageLoading && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </label>
                <input
                  onChange={uploadIcon}
                  id="iconupload"
                  type="file"
                  className={`form-control form-control-lg `}
                  name="icon"
                />
              </div>
              <div className="col-12 p-0">
                <button
                  disabled={loader || imageLoading}
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(editBadges).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Yes
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  disabled={loader || imageLoading}
                  className="btn text-white m-1 btn-danger"
                  onClick={() => {
                    closeModal();
                    // setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
