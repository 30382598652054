import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllBadgesAdminAPI,
  getAllBadgesUserAPI,
  EditBadgeAdminAPI,
  CreateBadgeAdminAPI,
  toggleBadgesStatusAdminAPI,
  deleteBadgesAdminAPI,
  setBadgeAdminAPI,
  revokeBadgeAdminAPI,
} from "../../../services/admin/badges/badge-admin.service";

//async thunk
//get all badges
export const getAllBadgesUserItem = createAsyncThunk(
  "user/getAllBadgesUserItem",
  async (payload, thunkAPI) => {
    let response = await getAllBadgesUserAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//get all badges
export const getAllBadgesAdminItem = createAsyncThunk(
  "admin/getAllBadgesAdminItem",
  async (payload, thunkAPI) => {
    let response = await getAllBadgesAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
//edit badge
export const EditBadgeAdminItem = createAsyncThunk(
  "admin/EditBadgeAdminItem",
  async (payload, thunkAPI) => {
    let response = await EditBadgeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
//add badge
export const CreateBadgeAdminItem = createAsyncThunk(
  "admin/CreateBadgeAdminItem",
  async (payload, thunkAPI) => {
    let response = await CreateBadgeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//toggle badge status
export const toggleBadgesStatusAdminItem = createAsyncThunk(
  "admin/toggleBadgesStatusAdminItem",
  async (payload, thunkAPI) => {
    let response = await toggleBadgesStatusAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//toggle badge status
export const deleteBadgesAdminItem = createAsyncThunk(
  "admin/deleteBadgesAdminItem",
  async (payload, thunkAPI) => {
    let response = await deleteBadgesAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//set badge
export const setBadgeAdminItem = createAsyncThunk(
  "admin/setBadgeAdminItem",
  async (payload, thunkAPI) => {
    let response = await setBadgeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);

//revoke badge
export const revokeBadgeAdminItem = createAsyncThunk(
  "admin/revokeBadgeAdminItem",
  async (payload, thunkAPI) => {
    let response = await revokeBadgeAdminAPI(payload);
    // console.log("response: ", response);
    if (response.isSuccessful) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
//slice
const badgesSlice = createSlice({
  name: "badges-admin",
  initialState: {
    badges: [],
  },
  extraReducers: {
    [getAllBadgesUserItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [getAllBadgesAdminItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [EditBadgeAdminItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [CreateBadgeAdminItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [toggleBadgesStatusAdminItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [deleteBadgesAdminItem.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      state.badges = action.payload.data;
    },
    [setBadgeAdminAPI.fulfilled]: (state, action) => {
      // console.log('action.payload: ', action.payload);
      // state.badges = action.payload.data;
    },
  },
  [revokeBadgeAdminAPI.fulfilled]: (state, action) => {
    // console.log('action.payload: ', action.payload);
    // state.badges = action.payload.data;
  },
});

export default badgesSlice.reducer;
