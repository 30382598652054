import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkEmpty } from "../../../../../shared/Utils/method";
import ToggleStatus from "react-toggle";
import { toggleStudentActivateItem } from "../../../../../data/reducers/admin.reducer";
//...
const Model = ({ _id, status,limit,skip }) => {
  // console.log('status : ', status);
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [inputErr, setInputErr] = useState([]);
  const [active, setActive] = useState(status);
  const [description, setDescription] = useState("");

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //handle submit
  const handleSubmit = async () => {
    const res = checkEmpty({ description });
    setInputErr(res);
    if (res.length != 0) {
      return toast.warning("All fields are mandatory!");
    }
    const response = await dispatch(
      toggleStudentActivateItem({ _id, description, isActive: active,limit,skip })
    );
    console.log("response: ", response);
    if (response.payload.isSuccessful) {
      toast.success("Deactivated Successfully!");
      return closeModal();
    }
    closeModal();
    return toast.error(response.payload.message.data.message);
  };


  return (
    <div className="container-fluid">
      <ToggleStatus
        //Add default checked on isActive
        defaultChecked={active}
        icons={false}
        onClick={async (e) => {
          if (!e.target.checked) {
            setActive(e.target.checked);
            return OpenModal();
          }
          // console.log('_id: ', _id);
          const response = await dispatch(
            toggleStudentActivateItem({ _id, isActive: e.target.checked,limit,skip })
          );    console.log("response: ", response);
          if (response.payload.isSuccessful) {
            toast.success("Activated Successfully!");
            return closeModal();
          }
          closeModal();
          return toast.error(response.payload.message.data.message);
        }}
      />
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: "370px",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="row w-100">
          <div className="col mb-3">
            <textarea
              className={`form-control ${
                inputErr.includes("description") ? "is-invalid" : ""
              }`}
              name="description"
              placeholder="Reason to deactivate.."
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row w-100">
          <div className="col d-flex justify-content-center">
            <button className="btn btn-success mr-3" onClick={handleSubmit}>
              Done
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                closeModal();
                setActive(status);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
