import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PaginationComponent from "../Shared/Pagination/Pagination";
import Search from "../Shared/Search/Search";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  contestListAdminItem,
  contestDeleteAdminItem,
} from "../../../data/reducers/admin.reducer";
import { toast } from "react-toastify";
import { constants } from "../../../config";
import { Link, NavLink, Redirect } from "react-router-dom";

const adminPassword = constants.adminPassword;

const Contests = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const usertype = useSelector((state) => state.authReducer).userType;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const contest = useSelector((state) => state.adminReducer).contestList;
  //
  // const [contests, setContests] = useState([...contest]);
  const [loader, setLoader] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState();
  const ITEMS_PER_PAGE = 10;
  const [value, setValue] = useState(false);
  useEffect(async () => {
    setLoader(true);
    setValue(true);

    try {
      // setLoading(true);
      let response = await dispatch(contestListAdminItem());

      // setValue(true);
      // setContests(contest);
    } catch (error) {}
    setLoader(false);
    setValue(false);
  }, []);
  const isActivebutton = (active, id) => {
    const response = prompt("Enter the Password to Approve Project");
    if (response === "theyouthproject") {
      const payload = {
        isActive: !active,
        id,
      };
      try {
        let response = dispatch(contestDeleteAdminItem(payload));
      } catch (error) {
        toast.error(error);
      }
    } else {
      toast.warning("You dont Have Access to Approve the Project");
    }
  };
  // const [contests, setContests] = useState(contest);

  // const deleteContests = (index) => {
  //
  //
  //
  //   const list = [...contests];
  //   list.splice(index, 1);
  //   setContests(list);
  // };

  const goPrevPage = () => {
    history.goBack();
  };
  const contestData = useMemo(() => {
    let contestData = contest;

    if (search) {
      contestData = contestData?.filter(
        (contest) =>
          contest.description.toLowerCase().includes(search.toLowerCase()) ||
          contest.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(contestData?.length);
    // curreent pages
    return contestData?.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [contest, currentPage, search]);

  const editContests = (contest, id) => {
    const response = prompt("Enter the Password to Edit Contest");
    if (response === adminPassword) {
      toast.success("Access 🥳");
      history.push({
        pathname: "/admin/contestsEdit",
        state: { contest: contest, id: id },
      });
    } else {
      toast.warning("You have not the Access to Edit");
    }
    //
  };

  const addContests = () => {
    const response = prompt("Enter the Password to Add contest");
    if (response === adminPassword) {
      toast.success("Access 🥳");
      history.push({
        pathname: "/admin/contestsAdd",
      });
    } else {
      toast.warning("You have not the Access to Add");
    }
  };

  const [url, setUrl] = useState("");
  const imgModal = (link) => {
    setUrl(link);
  };
  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (loggedIn === true && usertype != "admin") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin") {
    return (
      <div>
        {!value && (
          <>
            <div className="container-fluid">
              <div className="row my-3">
                <div className="col d-flex align-items-center justify-content-between mb-4">
                  <h3 className="text-primary Fweight-600 text-center mt-3">
                    Contests
                  </h3>
                  <div onClick={goPrevPage} class="btn btn-primary">
                    Back
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="col d-flex align-items-center justify-content-between mb-4">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />

                    <button
                      className="btn btn-secondary Fweight-600"
                      type="button"
                      onClick={addContests}
                    >
                      <i className="fa fa-plus-circle mr-1"></i>Add
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {loader && (
                    <p className="text-center">
                      <Spinner
                        className="text-center"
                        animation="border"
                        variant="info"
                      />
                    </p>
                  )}

                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead className="bg-primary text-white">
                        <th>#</th>
                        <th>TitlePic</th>
                        <th>Title</th>
                        <th>StartDate</th>
                        <th>EndDate</th>
                        <th>Genre</th>
                        <th>Description</th>
                        <th>Contest Type</th>
                        <th>SupportingDoc</th>
                        <th>Reward</th>
                        <th>isActive</th>
                        <th></th>
                        <th></th>
                      </thead>
                      {contestData?.length === 0 && (
                        <p>No data To Display !!</p>
                      )}
                      <tbody>
                        {contestData?.map((contest, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              <button
                                className="btn"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-keyboard="false"
                                data-target="#exampleModal"
                                onClick={() => imgModal(contest.img)}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            </td>
                            <td>{contest.title}</td>
                            <td>
                              {new Date(
                                contest.start_date
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              {new Date(contest.end_date).toLocaleDateString()}
                            </td>
                            <td>
                              {contest.genre.map((genre) => (
                                <p style={{ display: "inline" }}>{genre} ,</p>
                              ))}
                            </td>
                            <td>{contest.description}</td>
                            <td>{contest.contestType}</td>
                            <td>{contest.supportingDoc}</td>
                            <td>{contest.reward}</td>

                            <td>
                              {" "}
                              <div className="form-group">
                                {/* <label>Is Active</label> */}
                                <button
                                  onClick={() =>
                                    isActivebutton(
                                      contest.isActive,
                                      contest._id
                                    )
                                  }
                                  className={
                                    contest.isActive
                                      ? "btn btn-success m-2"
                                      : "btn btn-danger m-2"
                                  }
                                >
                                  {contest.isActive === true
                                    ? "Active"
                                    : "Not Active"}
                                </button>
                              </div>
                            </td>
                            <td>
                              <button
                                type="button"
                                data-toggle="modal"
                                data-backdrop="static"
                                data-keyboard="false"
                                data-target="#myModal"
                                className="btn m-0 p-0"
                                onClick={() =>
                                  editContests(contest, contest._id)
                                }
                              >
                                <i className="fa fa-pencil text-success"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        className="btn btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                      <img
                        className="img-thumbnail ml-1 mr-1"
                        src={url}
                        alt="img"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default Contests;
