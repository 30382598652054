import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { editCampusItem } from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { constants } from "../../../../config";

const Model = ({ item, setCampusList, campusList, fetchCampusListsApi }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);

  const [editCampusPlacementDrive, setEditCampusPlacementDrive] = useState({
    collegeName: "",
    city: "",
    tpoEmail: "",
    registrationLastDate: "",
    campusCode: "",
  });

  useEffect(() => {
    if (item) {
      setEditCampusPlacementDrive({
        ...editCampusPlacementDrive,
        ["collegeName"]: item?.collegeName,
        ["city"]: item?.city,
        ["campusCode"]: item?.campusCode,
        ["tpoEmail"]: item?.tpoEmail,
        ["registrationLastDate"]: item?.registrationLastDate,
      });
    }
  }, [item]);

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //handle add change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCampusPlacementDrive({ ...editCampusPlacementDrive, [name]: value });
  };

  const findElementIndex = () => {
    const itemIndex = campusList.findIndex(
      (element) => element._id === item._id
    );
    return itemIndex;
  };

  const handleSubmit = async () => {
    try {
      setloader(true);
      const payload = {
        ...editCampusPlacementDrive,
        campusId: item._id,
        registrationLink:
         item?.registrationLink
          ? item?.registrationLink
          : `${constants?.endPointFrontend}/user/campus/registration/${item?.collegeName}/${item?._id}?campusCode=${item?.campusCode}`,
      };
      // console.log('payload: ', payload);
      let response = await dispatch(editCampusItem(payload));
      // console.log('response: ', response);

      if (response.payload.isSuccessful) {
        fetchCampusListsApi();
        closeModal();
        setloader(false);
        // const itemIndex = findElementIndex(setCampusList);
        // campusList[itemIndex] = response.payload.data;
        // setCampusList([...campusList]);
        toast.success("Edited successfully");
        return;
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
        return;
      }
      closeModal();
    } catch (error) {}
  };

  return (
    <div className="container">
      <i
        className="fa fa-edit font-size-15 text-success cursor-pointer"
        onClick={() => {
          OpenModal();
        }}
      ></i>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 10000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",

            backgroundColor: "#fff",
            height: "80vh",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">
                Edit Campus Placement Drive
              </h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  College Name
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="collegeName"
                  value={editCampusPlacementDrive?.collegeName}
                />
              </div>{" "}
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Campus code
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="campusCode"
                  value={editCampusPlacementDrive?.campusCode}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  City
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="city"
                  value={editCampusPlacementDrive?.city}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  TPO Email
                </label>
                <input
                  onChange={handleEditChange}
                  type="email"
                  className={`form-control form-control-lg `}
                  name="tpoEmail"
                  value={editCampusPlacementDrive?.tpoEmail}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  registration Last Date
                </label>
                <input
                  onChange={handleEditChange}
                  type="date"
                  className={`form-control form-control-lg `}
                  name="registrationLastDate"
                  value={editCampusPlacementDrive?.registrationLastDate}
                />
              </div>
              <div className="col-12 p-0">
                <button
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(editBadges).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Yes
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  onClick={() => {
                    closeModal();
                    // setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
