import React, { useState } from "react";
import Modal from "react-modal";
import { singleUploadEmailItem } from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

//************************************************ */
const Model = ({
  campusId,
  setStudentData,
  studentData,
  fetchCampusStudentListsApi,
}) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);

  const [singleUploadForm, setsingleUploadForm] = useState({
    emailId: "",
    studentName: "",
    enrollmentNumber: "",
  });

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //handle add change
  const handleEditChange = async (e) => {
    const { name, value } = e.target;
    setsingleUploadForm({ ...singleUploadForm, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      setloader(true);
      const payload = { ...singleUploadForm, campusId };
      // console.log('payload: ', payload);
      let response = await dispatch(singleUploadEmailItem(payload));

      if (response.payload.isSuccessful) {
        fetchCampusStudentListsApi();
        closeModal();
        setloader(false);
        setStudentData(response.payload.data);
        fetchCampusStudentListsApi();
        setsingleUploadForm({
          emailId: "",
          enrollmentNumber: "",
          studentName: "",
        });
        toast.success("Added successfully");
        return;
      } else if (!response.payload.isSuccessful) {
        setloader(false);
        toast.error(response.payload.message.data.message);
        return;
      }
      setsingleUploadForm({
        emailId: "",
        enrollmentNumber: "",
        studentName: "",
      });
      closeModal();
    } catch (error) {}
  };

  return (
    <div className="container">
      <button
        onClick={() => {
          OpenModal();
        }}
        className="btn btn-primary mt-2"
      >
        Single Upload
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Upload</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Student Name
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="studentName"
                  value={singleUploadForm?.studentName}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Email ID
                </label>
                <input
                  onChange={handleEditChange}
                  type="email"
                  className={`form-control form-control-lg `}
                  name="emailId"
                  value={singleUploadForm?.emailID}
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label font-weight-bold text-dark"
                >
                  Enrollment Number
                </label>
                <input
                  onChange={handleEditChange}
                  type="text"
                  className={`form-control form-control-lg `}
                  name="enrollmentNumber"
                  value={singleUploadForm?.enrollmentNumber}
                />
              </div>

              <div className="col-12 p-0">
                <button
                  className="btn m-1 text-white btn-secondary"
                  onClick={() => {
                    handleSubmit();
                    // return checkEmpty(editBadges).length === 0
                    //   ? closeModal()
                    //   : null;
                  }}
                >
                  Yes
                  {loader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  onClick={() => {
                    closeModal();
                    setsingleUploadForm({
                      emailId: "",
                      enrollmentNumber: "",
                      studentName: "",
                    });
                    // setInputErr([]);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
