import React from "react";

function Recruiter(){
    return(
        <div>
            <h1 className="text-primary p-5 m-5">Recruiter</h1>
        </div>
    );
}

export default Recruiter;