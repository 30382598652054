import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
  },
};

const RedeemHistoryRequestActionModal = ({ req, indx, actions }) => {
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {});

  return (
    <>
      <tr onClick={openModal}>
        <th >{indx}</th>
        <td className="text-left">
          {new Date(req.createdAt).toLocaleString()}
        </td>
        <td className="font-weight-bold">{req.pointsRequestedForRedemption}</td>
        <td className="font-weight-bold">{req.pointsAcceptedForReward}</td>
        <td className="text-left">{req.status}</td>
      </tr>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 className="text-muted my-1">Request Actions</h2>

        <table className="table table-hover rwd-table mb-5">
          <thead className="thead-dark">
            <tr>
              <th >#</th>
              <th >Action</th>
              <th >Description</th>
              <th >Status</th>
              <th >Points Earned</th>
            </tr>
          </thead>
          <tbody>
            {actions
              ?.slice()
              .reverse()
              .map((action, indx) => {
                return (
                  <tr key={indx}>
                    <td data-th="#">{indx + 1}</td>
                    <td data-th="Action">{action.actionName}</td>
                    <td data-th="Description">
                      {action.actionStatus.description
                        ? action.actionStatus.description
                        : "---"}
                    </td>
                    <td data-th="Status">{action.actionStatus.status}</td>
                    <td data-th="Pooints Earned">{action.actionPoints}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <button className="btn btn-danger mx-auto" onClick={closeModal}>
          Cancel
        </button>
      </Modal>
    </>
  );
};

export default RedeemHistoryRequestActionModal;
