import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequestHistory } from "../../../../data/reducers/referral.reducer";
import RedeemHistoryRequestAction from "./RedeemHistoryRequestAction.modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 1,
  },
  content: {
    color: "#1D3883",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flexDirection: "column",
  },
};

const RedeemHistoryModal = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const requestHistory = useSelector((state) => state.referralReducer)
    .userReqHistory;

  // const { redeemRequests } = useSelector(state => state.authReducer).userInfo.owner;
  //

  const updateHistoryRequest = async () => {
    setLoad(true);
    const response = await dispatch(fetchRequestHistory({ specific: false }));
    if (response.payload.isSuccessful) {
      setLoad(false);
      return console.log("getting history requests");
    }
  };

  useEffect(() => {
    updateHistoryRequest();
  }, []);

  return (
    <div>
      <button className="btn text-white font-weight-bold" onClick={openModal}>
        {" "}
        History
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h5 className="py-2 font-weight-bold">Redeem History</h5>
        {load ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : requestHistory.requests?.length !== 0 ? (
          <table class="table text-center table-hover">
            <thead class="btn-warning">
              <tr>
                <th className="text-white" scope="col">
                  #
                </th>
                <th className="text-white" scope="col">
                  Request Date
                </th>
                <th className="text-white" scope="col">
                  Requested Points
                </th>
                <th className="text-white" scope="col">
                  Rewarded Points
                </th>
                <th className="text-white text-left" scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {requestHistory.requests
                ?.slice()
                .reverse()
                .map((req, indx) => {
                  return (
                    <RedeemHistoryRequestAction
                      key={indx}
                      req={req}
                      indx={indx + 1}
                      actions={req.actions}
                    />
                  );
                })}
            </tbody>
          </table>
        ) : (
          <h6 className="my-3 text-center">No requests till date</h6>
        )}
        <div className="row">
          <div className="col-md">
            <button className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RedeemHistoryModal;
