import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const CertificateAdd = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const certificateList = props.location.state.certificateList;
  //
  const [newCertificate, setNewCertificate] = useState({
    name: "",
    imageUrl: "",
    certificateId: "",
    description: "",
  });
  const [certificate, setCertificate] = useState([...certificateList]);

  let history = useHistory();
  const handleCertificateInputChange = (e) => {
    const { name, value } = e.target;
    setNewCertificate({ ...newCertificate, [name]: value });
  };

  const submit = async () => {
    //
    certificate.push(newCertificate);
    const payload = { certificate, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };
  const { name, imageUrl, certificateId, description } = newCertificate;
  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>certificate Details</h3>
      </div>
      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              onChange={(e) => handleCertificateInputChange(e)}
              value={name}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Image Url</label>
            <input
              type="text"
              name="imageUrl"
              onChange={(e) => handleCertificateInputChange(e)}
              value={imageUrl}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Certificate Id</label>
            <input
              type="text"
              name="certificateId"
              onChange={(e) => handleCertificateInputChange(e)}
              value={certificateId}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Description</label>
            <input
              type="text"
              name="description"
              onChange={(e) => handleCertificateInputChange(e)}
              value={description}
              className="form-control"
            />
          </div>
        </div>
        <button type="button" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default CertificateAdd;
