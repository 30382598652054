import React, { useState, useEffect } from "react";
import RewardHeader from "./RewardComponents/RewardHeader";
import PointsEarned from "./RewardComponents/PointsEarned";
import RewardsReceived from "./RewardComponents/RewardsReceived";
import RedeemRequest from "./RewardComponents/RedeemRequest";
import { useSelector } from "react-redux";
import { Redirect,} from "react-router-dom";


export default function Rewards() {
  const [view, setView] = useState("points_earned");

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const toggleView = (e) => {
    setView(e.target.id);
    document.getElementById(e.target.id).classList.add("active");

    for (let childNode of document.getElementById("nav-menu").childNodes) {
      if (childNode.id == e.target.id) continue;
      childNode.className = "nav-item nav-link";
    }
  };

  useEffect(() => {
    document.getElementById(view)?.classList?.add("active");
  });
  
  if (loggedIn === false) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <div className="container tab-colr " style={{ marginTop: "100px" }}>
      <div className="text-center m-3">
        Soon you will be earning goodies, cash 💸 and more on kind of reach{" "}
        <i className="fas fa-chart-line" /> you get by sharing your profile and
        projects. So keep sharing! 🏆
      </div>
      <RewardHeader />
      <ul
        id="nav-menu"
        className="nav nav-tabs mt-2"
        data-aos="zoom-in-left"
        data-aos-duration="2000"
      >
        <a
          id="points_earned"
          class="nav-item nav-link"
          href="#"
          onClick={toggleView}
        >
          Points Earned
        </a>
        <a
          id="redeem_req"
          class="nav-item nav-link"
          href="#"
          onClick={toggleView}
        >
          Redeem Requests(Pending)
        </a>
        <a id="rewards" class="nav-item nav-link" href="#" onClick={toggleView}>
          Rewards
        </a>
      </ul>
      <div className="mt-2">
        {view === "points_earned" ? (
          <div>
            <PointsEarned />
          </div>
        ) : view === "redeem_req" ? (
          <div>
            <RedeemRequest />
          </div>
        ) : view === "rewards" ? (
          <div>
            <RewardsReceived />
          </div>
        ) : null}
      </div>
    </div>
  );
}
