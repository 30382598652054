import React, { useEffect } from "react";
// import { useHistory, withRouter } from "react-router-dom";
import PrimaryRoutes from "./router/primary.routes";
import { useSelector } from "react-redux";
import Footer from "./shared/components/Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./shared/components/Header/Header";
import "./shared/styles/custom.css";
import "./shared/styles/styles.css";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./shared/components/ScrollTop/ScrollToTop";
import Login from "./pages/AuthPages/Login.jsx";
import Signup from "./pages/AuthPages/Signup.jsx";



// import { checkConnection } from "./data/reducers/auth.reducer";
// import ReactGA from "react-ga";
// import createHistory from "history/createBrowserHistory";
// const history = createHistory();
// ReactGA.initialize("UA-194023405-1");
// history.listen((location, action) => {
//   ReactGA.pageview(location.pathname + location.search);
//   console.log(location.pathname);
// });

//Appsell
function AppShell() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <PrimaryRoutes />
      <Footer />
      <ToastContainer
        style={{ zIndex: 800000 }}
        autoClose={2500}
        hideProgressBar
        pauseOnHover={false}
        transition={Slide}
        newestOnTop
        position="top-right"
        pauseOnFocusLoss={false}
      />
    </>
  );
}

function App() {
  AOS.init();
  // const dispatch = useDispatch();
  // const history = useHistory();

  const isLoggedIn = useSelector((state) => state.authReducer).loggedIn;
  useEffect(async () => {
    // const status = await dispatch(checkConnection());
    // // console.log("status: ", status.payload);
    // if (status.payload) {
    //   console.log("status: ", status.payload);
    //   return history.push("/offline")

    // }

    if (!isLoggedIn) {
      if (!(localStorage.length > 1)) {
        window.localStorage.clear();
      }
    }
  }, []);
  return <AppShell />;
}

export default App;
