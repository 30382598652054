import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Model = ({ acceptedIdsState, declinedIdsState, yes, _id, length }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="container">
      <button
        disabled={
          //   acceptedIdsState.length !== 0 || declinedIdsState.length !== 0
          //     ? false
          //     : true
          acceptedIdsState.length + declinedIdsState.length === length
            ? false
            : true
        }
        className="btn m-1 text-white"
        style={{ backgroundColor: "#C82333" }}
        onClick={() => {
          OpenModal();
          //
        }}
      >
        Yes
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 1,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h3 className="text-center">Are you sure want to proceed?</h3>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12 ">
              <div className="col-12 d-flex justify-content-center p-0">
                <button
                  className="btn text-white m-1"
                  style={{ backgroundColor: "#5A6268" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="btn m-1 text-white"
                  style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    closeModal();
                    yes(_id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Model;
