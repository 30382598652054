import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const WorkExperienceAdd = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const workExperienceList = props.location.state.workExperienceList;
  //
  const [newWorkExperience, setNewWorkExperience] = useState({
    workTitle: "",
    endDate: "",
    startDate: "",
    projectDescription: "",
  });
  const [workExperience, setWorkExperience] = useState([...workExperienceList]);

  let history = useHistory();
  const handleWorkExperienceInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkExperience({ ...newWorkExperience, [name]: value });
  };

  const submit = async () => {
    //
    workExperience.push(newWorkExperience);
    const payload = { workExperience, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };
  const {
    workTitle,
    startDate,
    endDate,
    projectDescription,
  } = newWorkExperience;
  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>workExperience Details</h3>
      </div>
      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Work Title</label>
            <input
              type="text"
              name="workTitle"
              onChange={(e) => handleWorkExperienceInputChange(e)}
              value={workTitle}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> Start Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="startDate"
              onChange={(e) => handleWorkExperienceInputChange(e)}
              value={startDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for=""> End Date</label>
            <input
              type="date"
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              name="endDate"
              onChange={(e) => handleWorkExperienceInputChange(e)}
              value={endDate}
              className="form-control"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label for="">Area of Study</label>
            <input
              type="text"
              name="projectDescription"
              onChange={(e) => handleWorkExperienceInputChange(e)}
              value={projectDescription}
              className="form-control"
            />
          </div>
        </div>
        <button type="button" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default WorkExperienceAdd;
