import React, { useState, useEffect } from "react";
import { Redirect, Link ,useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getcampusResultItem } from "../../../../data/reducers/admin/campus-placement Drive/campusPlacementDrive.reducer";

//********************************************** */
const CampusResults = (props) => {
  const commonSkip = useSelector(
    (state) => state.campusPlacementAdminReducer
  ).skip;
  const [commonResultStatus, setResultStatus] = useState("PASSED");
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [resultList, setResultList] = useState([]);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(commonSkip);

  const [click, setClick] = useState("");
  const [loading, setLoading] = useState(false);

  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;

  const [campusId, setCampusId] = useState();

  useEffect(() => {
    if (!loggedIn) {
      return history.push('/');
    } else if (
      loggedIn &&
      usertype !== "admin" &&
      usertype !== "technical-operation"
    ) {
      return <Redirect to="/"></Redirect>;
    } else if (loggedIn && usertype === "admin") {
      if (props?.location?.state?.campusId) {
        setCampusId(props?.location?.state?.campusId);
      }

      fetchCampusResultApi(props?.location?.state?.campusId);
    }
  }, [skip, commonResultStatus, props]);

  const fetchCampusResultApi = async ($campusId) => {
    try {
      //   setLoading(true);
      const payload = {
        limit,
        skip,
        isPass: commonResultStatus === "PASSED" ? true : false,
        campusId: campusId ? campusId : $campusId,
      };
      //   console.log("payload: ", payload);
      let response = await dispatch(getcampusResultItem(payload));
      //   console.log("response: ", response);

      if (response.payload.isSuccessful) {
        setLoading(false);
        setResultList(response.payload.data);
        return;
      } else if (!response.payload.isSuccessful) {
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  return (
    <div style={{ marginTop: "110px" }}>
      <div className="col-md-12 d-flex   justify-content-center">
        <div className="form-check m-1  ">
          <input
            className="form-check-input"
            defaultChecked={commonResultStatus === "PASSED"}
            type="radio"
            name="FILTER_QUESTIONS"
            id="PASSED"
            onClick={() => setResultStatus("PASSED")}
          />
          <label className="form-check-label" htmlFor="CODING">
            PASSED
          </label>
        </div>
        <div className="form-check m-1  ">
          <input
            className="form-check-input"
            defaultChecked={commonResultStatus === "FAILED"}
            type="radio"
            name="FILTER_QUESTIONS"
            id="FAILED"
            onClick={() => setResultStatus("FAILED")}
          />
          <label className="form-check-label" htmlFor="CODING">
            FAILED
          </label>
        </div>
      </div>
      <div className="col-md-12">
        <div className="table-responsive mt-2  ">
          <table class="table table-hover ">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Contact number</th>
                <th scope="col">College </th>
                <th scope="col">Branch</th>

                <th scope="col">Passout year</th>
                {/* <th scope="col">Resume</th> */}
                <th scope="col">Total marks</th>
                <th scope="col">Aptitude marks</th>
                <th scope="col">Verbal marks</th>
                <th scope="col">Coding marks</th>
                <th scope="col">Percentage</th>
                <th scope="col">Resume</th>
              </tr>
            </thead>
            <tbody>
              {resultList && resultList.length > 0
                ? resultList?.map((item, index) => {
                    return (
                      <tr key={item?._id}>
                        <td scope="col">{skip + index + 1}</td>
                        <td scope="col">{item?.studentCollegeName}</td>
                        <td scope="col">{item?.studentCollegeEmailId}</td>
                        <td scope="col">{item?.studentCollegeContactNumber}</td>
                        <td scope="col">{item?.collegeName}</td>
                        <td scope="col">{item?.studentBranch}</td>
                        <td scope="col">{item?.studentPassoutYear}</td>
                        <td scope="col">{item?.totalMarksAchieved}</td>
                        <td scope="col">{item?.aptitudeMarks}</td>
                        <td scope="col">{item?.verbalMarks}</td>
                        <td scope="col">{item?.codingMarks}</td>
                        <td scope="col">{Math.round(item?.percentageAchieved)}%</td>
                        {/* <td scope="col">{item?.codingMarks}</td> */}

                        <td scope="col">
                          <a href={item?.studentResume}>Download</a>
                        </td>
                      </tr>
                    );
                  })
                : "No data"}
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12 text-center m-auto">
          <button
            style={{ backgroundColor: "#1D3883" }}
            className="btn text-white m-1"
            disabled={skip === 0}
            onClick={() => {
              setClick("prev");
              setSkip((preVal) => {
                return preVal - limit;
              });
            }}
          >
            {loading && click === "prev" ? "Prev.." : "Prev"}
            {loading && click === "prev" ? (
              <span
                id="studentTop"
                class="spinner-grow spinner-grow-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>

          <button
            style={{ backgroundColor: "#1D3883" }}
            className="btn text-white m-1"
            disabled={resultList.length === 0 || resultList.length < limit}
            onClick={() => {
              setClick("next");
              setSkip((preVal) => {
                return preVal + limit;
              });
            }}
          >
            {loading && click === "next" ? "Next.." : "Next"}
            {loading && click === "next" ? (
              <span
                class="spinner-grow spinner-grow-sm ml-1"
                role="status"
                aria-hidden="true"
              ></span>
            ) : null}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampusResults;
