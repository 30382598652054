import Modal from "react-modal";
import React, { useState } from "react";

const ConfirmationModal = ({
  commonLoader,
  callingFunction,
  buttonText,
  item,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  //Modal functions
  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div
      className="mx-4"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div>
        <button type="button" className="btn btn-primary  " onClick={OpenModal}>
          {buttonText ? buttonText : "Submit"}
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 30000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            flexDirection: "column",
          },
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <h5 className="text-center font-weight-bold">Are you sure ?</h5>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-12">
              <div className="col-12 p-0">
                <button
                  disabled={commonLoader}
                  className="btn m-1 text-white btn-secondary"
                  // style={{ backgroundColor: "#C82333" }}
                  onClick={() => {
                    callingFunction(closeModal, item);
                  }}
                >
                  Yes
                  {commonLoader && (
                    <span
                      class="spinner-border ml-1 spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
                <button
                  className="btn text-white m-1 btn-danger"
                  // style={{ backgroundColor: "#5A6268" }}
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
