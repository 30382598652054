import {
  api,
  getAuthHeaders,
  post,
  putFormData,
  put,
} from "../../services.common";

//send bulk email function
export const adminSendBulkEmailAPI = async (payload) => {
  let url = `${api}/admin/campus/bulk/email`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//fetch campus for emails
export const fetchCampusListsForEmailsAPI = async (payload) => {
  let url = `${api}/admin/email/campus/lists`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//fetch campus
export const fetchCampusListsAPI = async (payload) => {
  let url = `${api}/admin/campus/lists`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//fetch campus student lists
export const fetchCampusStudentListsAPI = async (payload) => {
  let url = `${api}/admin/campus/student/lists`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//add campus
export const addCampusAPI = async (payload) => {
  let url = `${api}/admin/campus/add`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//edit campus
export const editCampusAPI = async (payload) => {
  let url = `${api}/admin/campus/edit`;
  let authHeader = getAuthHeaders();
  return await put(url, { ...payload }, { ...authHeader });
};

//single upload emails
export const singleUploadEmailAPI = async (payload) => {
  let url = `${api}/admin/campus/single/upload`;
  let authHeader = getAuthHeaders();
  return await put(url, { ...payload }, { ...authHeader });
};

//update student
export const updateStudentAPI = async (payload) => {
  let url = `${api}/admin/campus/student/list/update`;
  let authHeader = getAuthHeaders();
  return await put(url, { ...payload }, { ...authHeader });
};

//bulk upload
export const bulkUploadEmailsAPI = async (payload) => {
  let url = `${api}/admin/campus/bulk/upload`;
  let authHeader = getAuthHeaders();
  return await put(url, { ...payload }, { ...authHeader });
};

//check email existence of user
export const checkEmailExistenceAPI = async (payload) => {
  let url = `${api}/user/check/email/existence`;
  let authHeader = getAuthHeaders();
  return await post(url, { ...payload }, { ...authHeader });
};

//submit campus registration form
export const submitCampusRegistrationFormAPI = async (payload) => {
  let url = `${api}/user/submit/registration/form`;
  let authHeader = getAuthHeaders();
  return await putFormData(url, payload, {
    ...authHeader,
    "Content-Type": "multipart/form-data",
  });
};

//create test
export const createCampusTestAPI = async (payload) => {
  let url = `${api}/admin/create/test`;
  let authHeader = getAuthHeaders();
  return await post(
    url,
    { ...payload },
    {
      ...authHeader,
      "Content-Type": "application/json",
      Accept: "*/*",
    }
  );
};

//get results
export const getcampusResultAPI = async (payload) => {
  let url = `${api}/admin/campus/results`;
  let authHeader = getAuthHeaders();
  return await post(
    url,
    { ...payload },
    {
      ...authHeader,
      "Content-Type": "application/json",
      Accept: "*/*",
    }
  );
};
