import React from "react";
import "../shared/styles/styles.css";
function Default() {
  return (
    <div style={{ margin: "100px" }}>
      <div className="default-page-div">
        <h1>404</h1>
        <h6>Not Found</h6>
        <p>The resource requested could not be found on this server!</p>
      </div>
    </div>
  );
}

export default Default;
