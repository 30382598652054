import { createAsyncThunk } from "@reduxjs/toolkit";
import { registerForEventAPI } from "../../services/events/event-service";

export const registerForEventItem = createAsyncThunk(
  "admin/registerForEventItem",
  async (payload, thunkAPI) => {
    let response = await registerForEventAPI(payload);
    if (response.isSuccessful === true) {
      return response;
    } else return thunkAPI.rejectWithValue(response);
  }
);
