import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  linkedinSignUpItem,
  signupItem,
  changeOpenLoginModal,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { signup2 } from "../../data/reducers/auth.reducer";

import {
  linkedinLoginItem,
  wantACallback,
} from "../../data/reducers/auth.reducer";
import {
  loginItem,
  forgotPassworditem,
} from "../../data/reducers/auth.reducer";
// import loginrightimg from "../../assets/images/loginrightimg.png";
import TermsCondition from "./TermsCondition";
import { checkEmail, checkEmpty } from "../../shared/Utils/method";

import LinkedIn from "react-linkedin-login-oauth2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { constants } from "../../config";
import LinkedInImage from "../../assets/images/linkedin-image.png";
import "../../shared/styles/custom.css";
// import { modal } from "react-bootstrap";
export const redirectUri = constants.redirectUri;
export const redirectUri1 = constants.redirectUri;
export const clientid = constants.clientid;

//*********************************************************************************************************** */
const Model = ({ purpose }) => {
  const location = useLocation();
  // console.log('location: ', location);
  const openLoginModal = useSelector(
    (state) => state.authReducer
  ).openLoginModal;
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);

  // const [modalIsOpen1, setIsOpen1] = useState();

  useEffect(() => {
    openLoginModal ? setIsOpen(true) : setIsOpen(false);
  }, [openLoginModal]);

  const [loader, setloader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (check) {
      dispatch(wantACallback({ wantACallback: false }));
    } else {
      if (
        localStorage.getItem("redirectURL") == "null" ||
        localStorage.getItem("redirectURL") == null ||
        localStorage.getItem("redirectURL") == undefined
      ) {
        return setHaveRedirectReferral(false);
      }
      setHaveRedirectReferral(true);
    }
  }, []);
  // var checkLogin = true

  const [load, setLoad] = useState(false);
  const [passType1, setpassType1] = useState(false);
  const [passType, setpassType] = useState(false);
  const [hasReferral, setHasReferral] = useState(false);
  const [i_agree, SetI_agree] = useState(false);
  const [haveRedirectReferral, setHaveRedirectReferral] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const [typId, setTypId] = useState(null);
  const [inputErr, setInputErr] = useState([]);

  const usertype = useSelector((state) => state.authReducer).userType;
  // let [userData, setUserdata] = useState({ email: "", password: "" });
  const [userData, setUserdata] = useState({
    email: "",
    password: "",
  });
  const [forgotEmail, setForgotEmail] = useState("");
  const [check, setCheck] = useState(true);
  // setCheck=true
  const [modalClose, setModalClose] = useState("");
  const change = (event) => {
    const { name, value } = event.target;
    setUserdata({ ...userData, [name]: value });
  };
  const handleChangeCheckbox = () => {
    SetI_agree(!i_agree);
  };

  const handleSuccess1 = async (data) => {
    const payload = {
      data,
      redirectUri1,
    };
    setLoad(true);
    try {
      let response = await dispatch(linkedinSignUpItem(payload));

      if (response.payload.isSuccessful) {
        setLoad(false);
        return toast.success("Signup Successfull");
      }
      setLoad(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };
  //linkedin login
  const handleSuccess = async (data) => {
    const payload = {
      data,
      redirectUri,
    };
    setLoad(true);
    try {
      let response = await dispatch(linkedinLoginItem(payload));

      if (response.payload.isSuccessful) {
        toast.success("Login Successfull");
        const routeTo = localStorage.getItem("campusRoutePage");
        // console.log("routeTo: ", routeTo);
        if (localStorage.getItem("linkPreviewId")) {
          setLoad(false);
          let id = localStorage.getItem("linkPreviewId");
          return history.push(`/profile/${id}`);
        } else if (routeTo) {
          setLoad(false);
          return history.push(routeTo);
        } else if (localStorage.getItem("redirectTo") === "/events") {
          setLoad(false);
          return history.push(localStorage.getItem("redirectTo"));
        } else if (localStorage.getItem("commonRedirectionAfterLogin")) {
          setLoading(false);
          return history.push(
            localStorage.getItem("commonRedirectionAfterLogin")
          );
        } else {
          setLoad(false);
          return history.push("/timeline");
        }

        return;
      }
      setLoad(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFailure = (error) => {
    toast.error(error);
  };

  const forgotPassword = async (e) => {
    e.preventDefault();

    const payload = {
      email: forgotEmail,
    };
    // setLoad(true);
    try {
      let response = await dispatch(forgotPassworditem(payload));

      if (response.payload === true) {
        return toast.success("Check your e-mail to reset password");
      } else if (response.payload.isSuccessful === false) {
        toast.error(response.payload.message.data.message);
      }
    } catch (error) {
      toast.error(error);
    }
    // setLoad(false);
    setForgotEmail("");
  };

  const signUp = () => {
    setCheck(false);
  };
  const login = () => {
    setCheck(true);
  };

  //submitSignUp
  const submit1 = async (event) => {
    // console.log("Something is here");
    const res = checkEmpty(userData);
    setInputErr(res);

    if (res.length != 0) {
      return toast.error("All fields are mandatory");
    }

    if (!checkEmail(userData.email)) {
      // setInputErr([...inputErr, "email"]);
      return toast.error("Please check Email ID format");
    }

    event.preventDefault();
    setLoading(true);
    //user will enter these fields
    //we will check whether its null or not
    //if not null then will store them in local storage
    if (email !== null && password !== null && confirmpassword !== null) {
      if (password.length >= 8) {
        if (password === confirmpassword) {
          if (i_agree === true) {
            try {
              let response = await dispatch(signupItem(userData));

              if (response.payload.isSuccessful) {
                // add logic for storing typid
                if (localStorage.getItem("redirectURL")) {
                  dispatch(
                    signup2({ typId: localStorage.getItem("referredBy") })
                  );
                  setLoading(false);
                  setModalClose("modal");
                  return toast.success("Signup Part 1 Successfull");
                }
                dispatch(signup2({ typId }));
                setLoading(false);
                closeModal();
                setModalClose("modal");
                return toast.success("Signup Part 1 Successfull");
              }
              setLoading(false);
              setModalClose("modal");

              toast.error(response.payload.message.data.message);
            } catch (error) {
              toast.error(error);
            }
          } else {
            toast.error("Please Check terms and conditions");
          }
        } else {
          toast.error("Password and Confirm password Does not Match !!");
        }
      } else {
        toast.error("Password Must be 8 characters long !!");
      }
    }
  };

  //simplelogin
  const submit = async (event) => {
    // console.log("Something is here");
    const res = checkEmpty(userData);
    // console.log("res: ", res);
    setInputErr(res);
    if (res.length != 0) {
      return toast.error("All fields are mandatory");
    }

    if (!checkEmail(userData.email)) {
      // setInputErr([...inputErr,'email']);
      return toast.error("Please check Email ID format");
    }

    event.preventDefault();
    setLoading(true);

    const { name, value } = event.target;
    try {
      let response = await dispatch(loginItem(userData));

      if (response.payload.isSuccessful) {
        toast.success("Login Successfull");
        closeModal();

        // $("body").removeClass("modal-open");
        // $(".modal-backdrop").remove();
        const routeTo = localStorage.getItem("campusRoutePage");
        // console.log("routeTo: ", routeTo);
        if (localStorage.getItem("linkPreviewId")) {
          setLoading(false);
          let id = localStorage.getItem("linkPreviewId");
          return history.push(`/profile/${id}`);
        } else if (routeTo) {
          setLoading(false);
          return history.push(routeTo);
        } else if (localStorage.getItem("redirectTo") === "/events") {
          setLoading(false);
          return history.push(localStorage.getItem("redirectTo"));
        } else if (localStorage.getItem("commonRedirectionAfterLogin")) {
          setLoading(false);
          return history.push(
            localStorage.getItem("commonRedirectionAfterLogin")
          );
        } else {
          setLoading(false);
          return history.push("/timeline");
        }
      }
      setLoading(false);
      toast.error(response.payload.message.data.message);
    } catch (error) {
      toast.error(error);
    }
  };

  if (check == true) {
    if (loggedIn === true && usertype === "student") {
      return <Redirect to={location.pathname + location.search} />;
    } else if (loggedIn === true && usertype === "admin") {
      return <Redirect to="/admin" />;
    } else if (loggedIn === true && usertype === "recruiter") {
      return <Redirect to={location.pathname + location.search} />;
    } else if (loggedIn === true && usertype === "professional") {
      return <Redirect to={location.pathname + location.search} />;
    }
  } else {
    if (loggedIn === true) {
      return <Redirect to="/signup/part2"></Redirect>;
    }
  }
  const { email, password, confirmpassword, userType } = userData;
  Modal.setAppElement("#root");

  function OpenModal() {
    setIsOpen(true);
  }

  function closeModal() {
    dispatch(changeOpenLoginModal({ modalVal: false }));
    setIsOpen(false);
  }

  return (
    <>
      {purpose == "Sign" && !loggedIn && (
        <button
          onClick={() => {
            OpenModal();
          }}
          className="nav-item loginbackground"
        >
          <span
            // exact
            // to="/login"

            className="nav-link"
            // activeClassName="active"
          >
            Sign In
          </span>
        </button>
      )}
      {purpose == "View Profile" && (
        <a
          onClick={() => {
            OpenModal();
          }}
          style={{ cursor: "pointer" }}
          class="link text-primary text-decoration-none d-flex align-items-center justify-content-center h6"
        >
          View Profile <i class="fa fa-chevron-right ml-2"></i>
        </a>
      )}
      {purpose == "enroll" && (
        <a
          onClick={() => {
            OpenModal();
          }}
          className="btn btn-outline-yellow btn-block font-size-14 Fweight-600 px-4"
        >
          ENROLL NOW
        </a>
      )}
      {purpose == "event" && (
        <div class="mb-5 mt-3  dots">
          <button
            onClick={() => {
              localStorage.setItem("redirectTo", "/events");
              OpenModal();
            }}
            style={{ background: "#1d3883", color: "#fff" }}
            class="btn dots"
          >
            Register Now
          </button>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        scrollable={true}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 200000,
          },
          content: {
            color: "#1D3883",
            top: "50%",
            left: "50%",
            width: "325px",
            zIndex: 999999,
            right: "auto",
            bottom: "auto",
            borderRadius: "6px",
            // scrollBehavior:"auto",
            marginRight: "-50%",
            // overflow:"auto",
            transform: "translate(-50%, -50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          },
        }}
      >
        <section className="loginsection py-md-0">
          <div>
            {/* <div className="row tp-row">
            <div className="col-md-3">
              <NavLink to="/" className="btn btn-default">
                <i className="fa  fa-chevron-left "></i> Back
              </NavLink>
            </div>
            <div className="col-md-6 text-center">
              <h3>Log In</h3>
            </div>
          </div> */}
            <div className="d-flex justify-content-center">
              {check === true && (
                <h3
                  className="text-center"
                  style={{
                    fontSize: "2em",
                    fontWeight: "500",
                    color: "#1d3883",
                    marginLeft: "30px",
                  }}
                >
                  Log In
                </h3>
              )}
              {check === false && (
                <h3
                  className="text-center"
                  style={{
                    fontSize: "2em",
                    fontWeight: "500",
                    color: "#1d3883",
                    marginLeft: "30px",
                  }}
                >
                  Sign Up
                </h3>
              )}
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  class="close mr-3 position-relative"
                  style={{ left: "65px" }}
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div>
              <div className="rounded-sm">
                <div>
                  <div>
                    {/* <div className="col-lg-6 order-lg-1">
                    <div className="login-img text-center text-lg-right">
                      <img src={loginrightimg} alt="" className="img-fluid" />
                    </div>
                  </div> */}
                    <form action="">
                      <div className="">
                        <div className="form-group">
                          <label className="font-size-10" for="">
                            <small>Email</small>
                          </label>

                          <input
                            type="email"
                            name="email"
                            value={email}
                            className="form-control"
                            style={{ borderRadius: "10px", borderWidth: "2px" }}
                            onChange={change}
                            className={`form-control shadow-none ${
                              inputErr.includes("email") ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                        {check == true && (
                          <div className="form-group mb-0">
                            <label className="font-size-10" for="">
                              <small>Password</small>
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              style={{
                                borderRadius: "10px",
                                borderWidth: "2px",
                              }}
                              value={password}
                              onChange={change}
                              className={`form-control shadow-none ${
                                inputErr.includes("password")
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                        {check == false && (
                          <div className="form-group">
                            <label className="font-size-10" for="">
                              <small>Password</small>
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              style={{
                                borderRadius: "10px",
                                borderWidth: "2px",
                              }}
                              onKeyUp={() => setpassType1(true)}
                              onClick={() => setpassType1(true)}
                              value={password}
                              onChange={change}
                              className={`form-control shadow-none ${
                                inputErr.includes("password")
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {passType1 &&
                              (password.length >= 8 ? (
                                <p className="text-success m-1">
                                  Strong password !
                                </p>
                              ) : (
                                <p className="text-danger m-1">
                                  <small>
                                    Password Must Be 8 of characters!
                                  </small>
                                </p>
                              ))}
                          </div>
                        )}
                        {check == false && (
                          <div className="form-group">
                            <label className="font-size-10" for="">
                              <small>Confirm Password</small>
                            </label>
                            <input
                              type="password"
                              name="confirmpassword"
                              data-keyboard="false"
                              className="form-control"
                              style={{
                                borderRadius: "10px",
                                borderWidth: "2px",
                              }}
                              onKeyUp={() => setpassType(true)}
                              onClick={() => setpassType(true)}
                              value={confirmpassword}
                              onChange={change}
                              className={`form-control shadow-none ${
                                inputErr.includes("confirmpassword")
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            {passType &&
                              (password === confirmpassword ? (
                                <p className="text-success">
                                  <small>Password matched</small>
                                </p>
                              ) : (
                                <p className="text-danger">
                                  Password does not match! !
                                </p>
                              ))}
                          </div>
                        )}
                        {check == true && (
                          <a
                            href="javascrit:void(0);"
                            className="link text-decoration-none pl-2"
                            data-toggle="modal"
                            data-backdrop="static"
                            data-keyboard="false"
                            data-target="#forgotPasswordPopup"
                            style={{ color: "#038cfc" }}
                          >
                            <small>Forgot password ?</small>
                          </a>
                        )}
                        {check == true && (
                          <div>
                            <div
                              className="modal fade bd-example-modal-lg"
                              id="forgotPasswordPopup"
                              tabindex="1"
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content rounded-lg">
                                  <button
                                    type="button"
                                    className="close mt-2"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <i className="fa fa-close"></i>
                                  </button>

                                  <div className="modal-body pt-md-4 pb-md-3 pl-md-5 pr-md-5">
                                    <h5
                                      className="modal-title text-center"
                                      id="exampleModalLabel"
                                    >
                                      Forgot password ?
                                    </h5>
                                    <p className="text-center text-secondary mt-2">
                                      {" "}
                                      Enter your registered email to reset
                                      password!
                                    </p>

                                    <label className="font-size-18" for="">
                                      Email
                                    </label>

                                    <input
                                      type="email"
                                      className="form-control form-control-lg"
                                      onChange={(e) => {
                                        setForgotEmail(e.target.value);
                                      }}
                                      name="forgotEmail"
                                      value={forgotEmail}
                                    />

                                    <button
                                      type="button"
                                      data-dismiss="modal"
                                      onClick={forgotPassword}
                                      className="btn btn-primary w-100 form-control-lg text-white h5 text-uppercase mt-md-4 mt-3"
                                    >
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {check == false && (
                          <div className="d-flex text-center align-items-center ">
                            <input
                              name="tandc"
                              type="checkbox"
                              defaultChecked={i_agree}
                              onChange={handleChangeCheckbox}
                            />{" "}
                            &nbsp; <small>I agree to all</small> &nbsp;
                            <a
                              data-target="#terms1"
                              data-toggle="modal"
                              className="text-decoration-none Fweight-300 text-nowrap"
                              style={{ cursor: "pointer" }}
                              data-backdrop="static"
                              data-keyboard="false"
                            >
                              <small>Terms and Conditions .</small>
                            </a>
                          </div>
                        )}
                        {check == false && (
                          <div>
                            <div
                              className="modal fade"
                              id="terms1"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title m-auto text-center"
                                      id="exampleModalLabel"
                                    >
                                      <h5>TERMS OF SERVICE AGREEMENT</h5>
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn btn-close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <TermsCondition />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {check == false && (
                          <div className="row">
                            <div className="col">
                              {haveRedirectReferral ? (
                                `You are referred by ${localStorage.getItem(
                                  "referredBy"
                                )}`
                              ) : (
                                <>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      setHasReferral(e.target.checked)
                                    }
                                  />
                                  <span className="ml-2 ">
                                    <small>You have any Referral ?</small>
                                  </span>
                                </>
                              )}
                              {hasReferral ? (
                                <input
                                  className="form-control my-2"
                                  onChange={(e) => setTypId(e.target.value)}
                                  placeholder="Please enter TYP-ID ..."
                                />
                              ) : null}
                            </div>
                          </div>
                        )}
                        {check == false && (
                          <div className="btn-bar">
                            <button
                              type="submit"
                              disabled={loading || !i_agree}
                              className="btn w-100 form-control text-white  h6 mt-sm-2 mt-1"
                              data-dismiss={modalClose}
                              onClick={submit1}
                              style={{ backgroundColor: "#1d3883" }}
                            >
                              Sign up
                              {loading && (
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </div>
                        )}
                        {check == true && (
                          <div className="btn-bar">
                            <button
                              type="submit"
                              disabled={loading}
                              className="btn w-100 text-white h6 mt-sm-4 mt-3"
                              onClick={submit}
                              style={{ backgroundColor: "#1d3883" }}
                            >
                              Log in
                              {loading && (
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </form>

                    <div>
                      <div
                        style={{
                          height: "1px",
                          borderTop: "1px solid rgb(144 157 168)",
                          textAlign: "center",
                          position: "relative",
                        }}
                        class="my-2"
                      >
                        <span
                          style={{
                            position: "relative",
                            top: "-.9em",
                            background: "white",
                            display: "inline-block",
                            padding: "0px 5px",
                          }}
                          class="text-secondary"
                        >
                          <small>or</small>
                        </span>
                      </div>
                      {check == true && (
                        <div
                          style={{ borderRadius: "5px" }}
                          class="border border-dark m-4 p-0"
                        >
                          <a className="linkedin text-decoration-none d-flex justify-content-center">
                            <LinkedIn
                              className="btn linkedin d-flex align-items-center mb-md-0 text-left p-0"
                              clientId={clientid}
                              scope="r_liteprofile,r_emailaddress"
                              onFailure={handleFailure}
                              onSuccess={handleSuccess}
                              redirectUri={redirectUri}
                            >
                              <img
                                src={LinkedInImage}
                                style={{ height: "30px", width: "30px" }}
                              />
                              {/* <i class="fab fa-linkedin text-white p-1" style={{fontSize: "25px",borderRadius:"5px",height:"30px",backgroundColor:"#038cfc"}}></i> */}
                              <span className="m-1 font-weight-normal">
                                Continue with LinkedIn
                              </span>
                            </LinkedIn>
                            {load && (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                          </a>
                        </div>
                      )}
                      {check == false && (
                        <div
                          style={{ borderRadius: "5px" }}
                          class="border border-dark m-4 p-0"
                        >
                          <a className="linkedin text-decoration-none d-flex justify-content-center">
                            <LinkedIn
                              className="btn linkedin d-flex align-items-center mb-md-0 text-left p-0"
                              clientId={clientid}
                              scope="r_liteprofile,r_emailaddress"
                              onFailure={handleFailure}
                              onSuccess={handleSuccess1}
                              redirectUri={redirectUri1}
                            >
                              <img
                                src={LinkedInImage}
                                style={{ height: "30px", width: "30px" }}
                              />
                              {/* <i class="fab fa-linkedin text-white p-1" style={{fontSize: "25px",borderRadius:"5px",height:"30px",backgroundColor:"#038cfc"}}></i> */}

                              <span className="m-1 font-weight-normal">
                                Sign up via LinkedIn
                              </span>
                            </LinkedIn>

                            {load && (
                              <div className="text-center">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                    {check == false && (
                      <div className="text-center d-flex justify-content-center">
                        <p className="mb-2 mr-1">
                          <small>Have already an account? </small>
                        </p>

                        <a
                          // to="/login"
                          // data-toggle="modal"
                          // data-dismiss="modal"
                          // data-target="#loginModal"
                          onClick={login}
                          className="text-decoration-none Fweight-600 text-nowrap"
                          style={{ color: "#038cfc", cursor: "pointer" }}
                        >
                          <small>Login</small>
                        </a>
                      </div>
                    )}
                    {check == true && (
                      <div className="text-center d-flex justify-content-center">
                        <p className="mb-2 mr-1">
                          <small>New here? </small>
                        </p>
                        <a
                          // to="/signup"
                          data-toggle="modal"
                          // data-target="#signUpModal"
                          className="text-decoration-none Fweight-300 text-nowrap"
                          style={{ color: "#038cfc", cursor: "pointer" }}
                          // data-dismiss="modal"
                          onClick={signUp}
                        >
                          <small> Sign up</small>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default Model;
