import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link, NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editContestAdminItem } from "../../../data/reducers/admin.reducer";
import { constants } from "../../../config";
const ContestsEdit = (props) => {
  const { cloudinaryImageLink, uploadImagePreset } = constants;
  const dispatch = useDispatch();
  const contestList = props.location.state.contest;
  const id = props.location.state.id;
  let history = useHistory();
  const [contest, setContest] = useState(contestList);
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;
  const usertype = useSelector((state) => state.authReducer).userType;
  const handleContestChange = (e) => {
    const { name, value } = e.target;
    setContest({ ...contest, [name]: value });
    // const list = [...contest];
    // list[index][name] = value;
    // /setContest(list);
  };
  const [active, setActive] = useState(contest.isActive);

  const isActivebutton = () => {
    setActive(!active);
  };
  const update = () => {
    const {
      img,
      title,
      description,
      genre,
      start_date,
      end_date,
      supporting_doc,
      reward,
      contestType,
    } = contest;
    const payload = {
      img,
      title,
      description,
      genre: genre.toString(),
      start_date,
      end_date,
      id,
      supporting_doc,
      reward,
      contestType,
    };

    try {
      let response = dispatch(editContestAdminItem(payload));

      history.push({
        pathname: "/admin/contests",
      });
    } catch (error) {}
  };
  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", uploadImagePreset);

    const res = await fetch(cloudinaryImageLink, {
      method: "POST",
      body: data,
    });
    const file = await res.json();

    setContest({ ...contest, titlePic: file.secure_url });
  };

  if (loggedIn === false) {
    return <Redirect to="/theyouthproject/admin/teams"></Redirect>;
  } else if (loggedIn === true && usertype != "admin") {
    return <Redirect to="/"></Redirect>;
  } else if (loggedIn === true && usertype === "admin") {
    return (
      <div className="m-5 p-5">
        <div className="jumbotron m-3 p-5">
          <h3 className="text-center text-secondary mb-3 p-0">Edit Contests</h3>
          <div className="col-lg-12">
            <div className="row">
              <div className="form-group col-md-12">
                <label htmlFor=""> Contest Image</label>
                <input
                  className="file"
                  type="file"
                  className="form-control pt-1"
                  defaultValue={contest.titlePic}
                  onChange={uploadImage}
                  // accept="image/*"
                />
                <img width="200px" height="200px" src={contest.img} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor=""> Contest Title</label>
                <input
                  placeholder="Title Of Contest"
                  type="text"
                  name="title"
                  defaultValue={contest.title}
                  onChange={handleContestChange}
                  className="form-control"
                />
              </div>
              <>
                <div className="form-group col-md-6">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="Date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    defaultValue={contest.startDate}
                    onChange={handleContestChange}
                    name="startDate"
                    className="form-control"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="">End Date</label>
                  <input
                    type="Date"
                    onKeyPress={(e) => {
                      e.preventDefault();
                    }}
                    defaultValue={contest.endDate}
                    onChange={handleContestChange}
                    name="lastDate"
                    className="form-control"
                  />
                </div>
              </>
              <div className="form-group col-md-6">
                <label htmlFor="">Genre</label>
                <input
                  placeholder="Genre"
                  type="text"
                  defaultValue={contest.genre}
                  name="genre"
                  onChange={handleContestChange}
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="">Description</label>
                <input
                  placeholder="Description"
                  type="text"
                  name="description"
                  defaultValue={contest.description}
                  onChange={handleContestChange}
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="">Supporting Doc</label>
                <input
                  placeholder="supporting doc"
                  type="text"
                  defaultValue={contest.supportingDoc}
                  onChange={handleContestChange}
                  name="supportingDoc"
                  className="form-control"
                />
              </div>
              <p>COntest Type is : {contest.contestType}</p>
              <label htmlFor="">COntest Type</label>
              <div className="form-group d-flex text-center m-2">
                <input
                  type="radio"
                  onChange={handleContestChange}
                  id="male"
                  className="mt-1 mr-1"
                  name="contestType"
                  value="code"
                />
                <label for="male" className="">
                  Code
                </label>
              </div>
              <div className="form-group d-flex text-center m-2">
                <input
                  type="radio"
                  onChange={handleContestChange}
                  className="mt-1 mr-1"
                  id="female"
                  value="image"
                  name="contestType"
                />
                <label for="female">Image</label>
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="">Reward</label>
                <input
                  placeholder="Reward"
                  type="text"
                  defaultValue={contest.reward}
                  onChange={handleContestChange}
                  name="reward"
                  className="form-control"
                />
              </div>
            </div>
            <div>
              <button onClick={update} className="btn btn-primary m-2">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ContestsEdit;
