import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../pages/AuthPages/Login";
import Signup from "../pages/AuthPages/Signup";
import Contact from "../pages/Contact/Contact";
import Homepage from "../pages/Homepage/Homepage";
import Signup2 from "../pages/AuthPages/Signup2";
import Admin from "../pages/Admin/Admin";
import Recruiter from "../pages/Recruiter/Recruiter";
import Professional from "../pages/Admin/Users/Professionals/Professionals";
import Hr from "../pages/Admin/Users/Hr/Hr";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import StudentProfile from "../pages/StudentPages/studentProfile";
import StudentList from "../pages/StudentPages/studentList";
// import About from "../pages/About/About";
import aboutNew from "../pages/About/aboutNew";
import StudentFullProfilePage from "../pages/StudentPages/studentFullProfilePage";
import StudentProfile1Edit from "../pages/StudentProfileEdit/StudentProfile1Edit";
import StudentProfile2Edit from "../pages/StudentProfileEdit/StudentProfile2Edit";
import StudentProfile3Edit from "../pages/StudentProfileEdit/StudentProfile3Edit";
import Message from "../pages/Message/message";
import AdminLogin from "../pages/AuthPages/AdminLogin";
import Timeline from "../pages/Timeline/Timeline";
import Contests from "../pages/Admin/Contests/Contests";
import Users from "../pages/Admin/Users/Users";
import Students from "../pages/Admin/Users/Students/Students";
import ContestsEdit from "../pages/Admin/Contests/ContestsEdit";
import ContestsAdd from "../pages/Admin/Contests/ContestsAdd";
import Education from "../pages/EditPages/Education";
import WorkExperience from "../pages/EditPages/Work-experience";
import Certificate from "../pages/EditPages/Certificate";
import Project from "../pages/EditPages/Project";
import EducationAdd from "../pages/AddPages/Educationadd";
import CertificateAdd from "../pages/AddPages/Certificateadd";
import ProjectAdd from "../pages/AddPages/Projectadd";
import WorkExperienceAdd from "../pages/AddPages/Workexperienceadd";
import Skills from "../pages/EditPages/Skills";
import SkillsAdd from "../pages/AddPages/Skillsadd";
import ProjectsApproval from "../pages/Admin/ProjectsApproval/ProjectsApproval";
import ProjectApproval from "../pages/Admin/ProjectsApproval/ProjectApproval";
import studentFullProfileNew from "../pages/StudentPages/studentFullProfileNew";
import Confirmation from "../pages/AuthPages/Confirmation";
import Profile2 from "../pages/Timeline/Profile2";
import Hiring from "../pages/Admin/Hiring/Hiring";
import Requestlist from "../pages/Admin/Users/Hr/Requestlist";
import contestPage from "../pages/Admin/Contests/contestPage";
import Events from "../pages/Events/Events";
import ContestParticipant from "../pages/Admin/Contests/contestParticipant";
import ParticipantList from "../pages/Admin/Contests/ContestParticipants/ParticipantList";
import Config from "../pages/Admin/Referral/Config";
import Rewards from "../pages/Rewards/Rewards";
import PlanReward from "../pages/Admin/Referral/Plan-reward";
import ProfessionalProfile from "../pages/Professional/ProfessionalProfile";
import Default from "../DefaultPage/Default";
import { ShareProject } from "../pages/StudentPages/StudentProfileComponents/Share-project";
// import Offline from "../pages/Offline/Offline";
import Badges from "../pages/Admin/Badge/Badges";
import EditUserProfile from "../pages/Admin/Edit-profile/User-profile";
import Batches from "../pages/Admin/Batches/Batches";

import RoleUsers from "../pages/Admin/Roles/Roles";
import RolesConfig from "../pages/Admin/Roles-config/Roles-config";
import { CampusPlacementDrive } from "../pages/Admin/CampusPlacementDrive/CampusPlacementDrive";
import { StudentEmails } from "../pages/Admin/CampusPlacementDrive/StudentEmails";
import { CampusDriveHomepage } from "../pages/Admin/CampusPlacementDrive/Campus-drive-homepage";
import { Questions } from "../pages/Admin/CampusPlacementDrive/Questions/Questions";
import { QuestionForm } from "../pages/Admin/CampusPlacementDrive/Questions/Question-form";
import CampusRegisterPage from "../pages/Campus-drive/Register-page-1";
import CampusTest from "../pages/Admin/CampusPlacementDrive/Test/Campus-test";
import CampusResults from "../pages/Admin/CampusPlacementDrive/Results/Results";
import BulkEmails from "../pages/Admin/Shared/bulk-email/Bulk-emails";
import CommonWorkShopComponent from "../pages/Templates/Common";
import Reviews from "../pages/Admin/Reviews/Reviews";
import WorkshopProgram from "../pages/Razorpay-program/Workshop-program";
// import paymentpage from "../pages/Templates/PaymentPage";
import CommonEmailTemplate from "../pages/Admin/Shared/bulk-email/Common-template";

const PrimaryRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/admin" component={Admin} />
      <Route
        exact
        path="/admin/edit/user/profile/:id/:userType"
        component={EditUserProfile}
      />
      <Route exact path="/recruiter" component={Recruiter} />
      <Route exact path="/professional" component={Professional} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/login" component={Login} />
      {/* <Route exact path="/signup" component={Signup} /> */}
      <Route exact path="/project/:id" component={ShareProject} />
      <Route exact path="/signup/part2" component={Signup2} />
      <Route exact path="/linkedin" component={LinkedInPopUp} />
      <Route path="/confirmation" component={Confirmation} />
      <Route path="/profile/:id" component={StudentProfile} />
      <Route exact path="/student/list" component={StudentList} />
      <Route exact path="/About" component={aboutNew} />
      <Route exact path="/config" component={Config} />
      <Route
        exact
        path="/studentProfilePage"
        component={StudentFullProfilePage}
      />
      <Route exact path="/rewards" component={Rewards} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/new/events" component={Events} />
      <Route
        exact
        path="/student/BasicDetails"
        component={StudentProfile1Edit}
      />
      <Route
        exact
        path="/student/BasicDetails2"
        component={StudentProfile2Edit}
      />
      <Route
        exact
        path="/student/PersonalDetails"
        component={StudentProfile3Edit}
      />
      <Route exact path="/message" component={Message} />
      <Route exact path="/theyouthproject/admin/teams" component={AdminLogin} />
      <Route exact path="/admin/roles" component={RoleUsers} />
      <Route exact path="/admin/roles/config" component={RolesConfig} />
      <Route exact path="/admin/contests" component={Contests} />
      <Route exact path="/admin/users" component={Users} />
      <Route exact path="/admin/users/students" component={Students} />
      <Route exact path="/admin/rewards/plan" component={PlanReward} />
      <Route
        exact
        path="/admin/projects-approval"
        component={ProjectsApproval}
      />
      <Route exact path="/upcomingBatches" component={Batches} />
      <Route exact path="/timeline" component={Timeline} />
      <Route exact path="/admin/contestsEdit" component={ContestsEdit} />
      <Route exact path="/admin/contestsAdd" component={ContestsAdd} />
      {/* <Route exact path="/timeline" component={Timeline} /> */}
      <Route exact path="/educationEdit" component={Education} />
      <Route exact path="/workExperienceEdit" component={WorkExperience} />
      <Route exact path="/projectsEdit" component={Project} />
      <Route exact path="/certificateEdit" component={Certificate} />
      <Route exact path="/addEducation" component={EducationAdd} />
      <Route exact path="/addCertificate" component={CertificateAdd} />
      <Route exact path="/addProjects" component={ProjectAdd} />
      <Route exact path="/addWorkExperience" component={WorkExperienceAdd} />
      <Route exact path="/skillsEdit" component={Skills} />
      <Route exact path="/addSkills" component={SkillsAdd} />
      <Route exact path="/admin/Professionals" component={Professional} />
      <Route exact path="/admin/hr" component={Hr} />
      <Route exact path="/admin/projectsApproval" component={ProjectApproval} />
      <Route exact path="/FullProfile" component={studentFullProfileNew} />
      <Route exact path="/project" component={Profile2} />
      <Route exact path="/admin/hiring" component={Hiring} />
      <Route exact path="/admin/hr/requestlist" component={Requestlist} />
      <Route exact path="/admin/ContestPage" component={contestPage} />
      <Route
        exact
        path="/admin/contestParticipant"
        component={ContestParticipant}
      />
      <Route
        exact
        path="/admin/contest/participantList"
        component={ParticipantList}
      />
      <Route
        exact
        path="/ProfessionalProfile/:id"
        component={ProfessionalProfile}
      />
      <Route exact path="/ProfessionalProfile/:id" component={Homepage} />
      {/* <Route
        exact
        path="/offline"
        component={Offline}
      /> */}
      <Route exact path="/admin/badges" component={Badges} />
      <Route
        exact
        path="/admin/campusPlacementDrive"
        component={CampusPlacementDrive}
      />
      <Route
        exact
        path="/admin/campusPlacementDrive/studentEmails"
        component={StudentEmails}
      />
      <Route
        exact
        path="/admin/campusPlacementDrive/Homepage"
        component={CampusDriveHomepage}
      />
      <Route
        exact
        path="/admin/campusPlacementDrive/Questions"
        component={Questions}
      />
      <Route
        exact
        path="/admin/campusPlacementDrive/Question/Form"
        component={QuestionForm}
      />
      <Route
        exact
        path="/user/campus/registration/:collegeName/:campusId"
        component={CampusRegisterPage}
      />
      <Route
        exact
        path="/admin/campus/placement/drive/create/test"
        component={CampusTest}
      />
      <Route
        exact
        path="/admin/campus/placement/drive/results"
        component={CampusResults}
      />{" "}
      <Route exact path="/admin/bulk/emails" component={BulkEmails} />
      <Route
        exact
        path="/api/event/generate/certificate"
        component={CommonWorkShopComponent}
      />
      <Route exact path="/admin/bulk/email" component={CommonEmailTemplate} />
      <Route exact path="/api/reviews" component={Reviews} />
      <Route exact path="/workshop/program" component={WorkshopProgram} />
      {/* <Route exact path="/paymentpage" component={paymentpage}></Route> */}
      <Route component={Default} />
    </Switch>
  );
};

export default PrimaryRoutes;
