import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  studentEditTypProfileItem,
  professionalEditTypProfileItem,
} from "../../data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SkillsAdd = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer).userInfo;
  const loggedIn = useSelector((state) => state.authReducer).loggedIn;

  const id = user._id;
  const usertype = useSelector((state) => state.authReducer).userType;
  const skillsList = props.location.state.skillsList;
  //
  const [newSkills, setNewSkills] = useState({
    name: "",
  });
  const [skills, setSkills] = useState([...skillsList]);

  let history = useHistory();
  const handleSkillsInputChange = (e) => {
    const { name, value } = e.target;
    setNewSkills({ ...newSkills, [name]: value });
    //
  };

  const submit = async () => {
    //
    skills.push(newSkills);
    const payload = { skills, id };

    try {
      if (usertype === "student") {
        let response = await dispatch(studentEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      } else if (usertype === "professional") {
        let response = await dispatch(professionalEditTypProfileItem(payload));

        if (response.payload.isSuccessful) {
          toast.success("Edit Successfully");
        }
      }
      // toast.error(response.payload.message.data.message);
    } catch (error) {}
    return history.push("/studentProfilePage");
  };
  const { name } = newSkills;
  return (
    <div className="p-5 m-5">
      <div className="col-md-12">
        <h3>skills Details</h3>
      </div>
      <>
        {" "}
        <div className="col-md-12">
          <div className="form-group">
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              onChange={(e) => handleSkillsInputChange(e)}
              value={name}
              className="form-control"
            />
          </div>
        </div>
        <button type="button" onClick={submit}>
          submit
        </button>
      </>
    </div>
  );
};

export default SkillsAdd;
